import EdlListLocal from "@/components/pages/List/EdlListLocal";
import EdlListRemote from "@/components/pages/List/EdlListRemote";
import EdlInfo from "@/components/pages/Edl/EdlInfo/EdlInfo.vue";
import EdlEdit from "@/components/pages/Edl/EdlEdit/EdlEdit.vue";
import ProfileSelection from "@/components/pages/User/ProfileSelection";
import UserAccount from "@/components/pages/User/UserAccount";
import UserList from "@/components/pages/User/UserManagement/UserList.vue";
import UserShow from "@/components/pages/User/UserManagement/UserShow.vue";
import UserAdd from "@/components/pages/User/UserManagement/UserAdd.vue";
import UserGroupList from "@/components/pages/User/UserGroupManagement/UserGroupList.vue";
import UserGroupShow from "@/components/pages/User/UserGroupManagement/UserGroupShow.vue";
import UserGroupAdd from "@/components/pages/User/UserGroupManagement/UserGroupAdd.vue";
import EdlRecap from "@/components/pages/Edl/EdlRecap/EdlRecap.vue";
import EdlSignature from "@/components/pages/Edl/EdlSignature";
import AutoEdlEdit from "@/components/pages/AutoEdl/AutoEdlEdit";
import Statistics from "@/components/pages/User/UsageStatistics";
import AutoEdlLang from "@/components/pages/AutoEdl/AutoEdlLang";
import AutoEdlInfo from "@/components/pages/AutoEdl/AutoEdlInfo";
import AutoEdlRecap from "@/components/pages/AutoEdl/AutoEdlRecap";
import AutoEdlSignature from "@/components/pages/AutoEdl/AutoEdlSignature";
import AutoEdlDone from "@/components/pages/AutoEdl/AutoEdlDone";
import AutoEdlList from "@/components/pages/User/AutoEdlManagement/AutoEdlList.vue";
import AutoEdlAdd from "@/components/pages/User/AutoEdlManagement/AutoEdlAdd.vue";
import EdlRecapConvention from "@/components/pages/Edl/EdlRecap/EdlRecapConvention.vue";
import EdlCosting from "@/components/pages/Costing/EdlCosting";
import EdlHistory from "@/components/pages/History/EdlHistory";
import MainLogin from "@/components/pages/MainLogin";
import UserCostingCalibration from "@/components/pages/Calibration/UserCostingCalibration";
import UserGroupCostingCalibration from "@/components/pages/Calibration/UserGroupCostingCalibration";
import PublicEdlCosting from "@/components/pages/Costing/PublicEdlCosting.vue";
import UserListGroup from "@/components/pages/User/UserManagement/UserListGroup.vue";

export const DEFAULT_TITLE = 'DDH Soft - Etat des lieux';

const updateMeta = (event, page) => { // eslint-disable-line no-unused-vars
  document.title = page.route.route.meta.title || DEFAULT_TITLE;
}

const routeDefinitions = [
  {
    path: '/',
    name: 'login',
    component: MainLogin,
    meta: {
      title: 'Login | '+DEFAULT_TITLE
    }
  },
  {
    path: '/selection-profil',
    name: 'profileSelection',
    component: ProfileSelection,
    meta: {
      title: 'Choisir un profil | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte',
    name: 'account',
    component: UserAccount,
    meta: {
      title: 'Mon compte | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/statistiques',
    name: 'statistics',
    component: Statistics,
    meta: {
      title: 'Statistiques | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/utilisateurs/groupe/:id',
    name: 'userListGroup',
    component: UserListGroup,
    meta: {
      title: 'Utilisateurs du Groupe | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/utilisateurs',
    name: 'users',
    component: UserList,
    meta: {
      title: 'Utilisateurs | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/utilisateurs/new',
    name: 'newUser',
    component: UserAdd,
    meta: {
      title: 'Nouvel utilisateur | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/utilisateurs/:id',
    name: 'user',
    component: UserShow,
    meta: {
      title: 'Utilisateur | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/groupes-utilisateurs',
    name: 'userGroups',
    component: UserGroupList,
    meta: {
      title: 'Groupes | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/groupes-utilisateurs/new',
    name: 'newUserGroup',
    component: UserGroupAdd,
    meta: {
      title: 'Nouveau groupe | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/groupes-utilisateurs/:id',
    name: 'userGroup',
    component: UserGroupShow,
    meta: {
      title: 'Groupe | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/groupes-utilisateurs/:id/calibrage',
    name: 'userGroupCostingCalibration',
    component: UserGroupCostingCalibration,
    meta: {
      title: 'Calibrage auto-chiffrage Groupe | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/auto-edls',
    name: 'autoEdls',
    component: AutoEdlList,
    meta: {
      title: 'Auto-EDLs | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/auto-edls/new',
    name: 'newAutoEdl',
    component: AutoEdlAdd,
    meta: {
      title: 'Nouvel Auto-EDL | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/calibrage',
    name: 'costingCalibration',
    component: UserCostingCalibration,
    meta: {
      title: 'Calibrage auto-chiffrage | '+DEFAULT_TITLE
    }
  },
  {
    path: '/mon-compte/utilisateurs/:id/calibrage',
    name: 'userCostingCalibration',
    component: UserCostingCalibration,
    meta: {
      title: 'Calibrage auto-chiffrage | '+DEFAULT_TITLE
    }
  },
  {
    path: '/edls/enregistres',
    name: 'edlsRemote',
    component: EdlListRemote,
    meta: {
      title: 'Mes EDLs enregistrés | '+DEFAULT_TITLE
    }
  },
  {
    path: '/edls/en-cours',
    name: 'edlsLocal',
    component: EdlListLocal,
    meta: {
      title: 'Mes EDLs en cours | '+DEFAULT_TITLE
    }
  },
  {
    path: '/nouvel-edl/:edlType/:edlTemplate',
    name: 'newEdl',
    component: EdlInfo,
    meta: {
      title: 'Nouvel EDL | '+DEFAULT_TITLE
    }
  },
  {
    path: '/dupliquer-edl/:duplicateFromId/:edlType/:source',
    name: 'duplicateEdl',
    component: EdlInfo,
    meta: {
      title: 'Duplication d\'un EDL | '+DEFAULT_TITLE
    }
  },
  {
    path: '/modifier-edl/:localId',
    name: 'editEdlInfo',
    component: EdlInfo,
    meta: {
      title: 'Modification de mon EDL | '+DEFAULT_TITLE
    }
  },
  {
    path: '/edl/:localId',
    name: 'edl',
    component: EdlEdit,
    meta: {
      title: 'Edition de mon EDL | '+DEFAULT_TITLE
    }
  },
  {
    path: '/edl/:localId/recapitulatif',
    name: 'edlRecap',
    component: EdlRecap,
    meta: {
      title: 'Récapitulatif | '+DEFAULT_TITLE
    }
  },
  {
    path: '/edl/:localId/recapitulatif-convention',
    name: 'edlRecapConvention',
    component: EdlRecapConvention,
    meta: {
      title: 'Récapitulatif - Convention de Sortie | '+DEFAULT_TITLE
    }
  },
  {
    path: '/edl/:localId/signature',
    name: 'edlSignature',
    component: EdlSignature,
    meta: {
      title: 'Signature | '+DEFAULT_TITLE
    }
  },
  {
    path: '/costing/:edlId',
    name: 'edlCosting',
    component: EdlCosting,
    meta: {
      title: 'Chiffrage | '+DEFAULT_TITLE
    }
  },
  {
    path: '/p/costing/:edlId/:publicToken',
    name: 'edlCosting',
    component: PublicEdlCosting,
    meta: {
      title: 'Chiffrage | '+DEFAULT_TITLE,
      public: true
    }
  },
  {
    path: '/history/:edlId',
    name: 'edlHistory',
    component: EdlHistory,
    meta: {
      title: 'Historique | '+DEFAULT_TITLE
    }
  },
  {
    path: '/auto-edl',
    name: 'autoEdl',
    component: AutoEdlEdit,
    meta: {
      title: 'Auto-EDL | '+DEFAULT_TITLE
    }
  },
  {
    path: '/auto-edl/lang',
    name: 'autoEdlLang',
    component: AutoEdlLang,
    meta: {
      title: 'Auto-EDL Language | '+DEFAULT_TITLE
    }
  },
  {
    path: '/auto-edl/info',
    name: 'autoEdlInfo',
    component: AutoEdlInfo,
    meta: {
      title: 'Auto-EDL Info | '+DEFAULT_TITLE
    }
  },
  {
    path: '/auto-edl/recapitulatif',
    name: 'autoEdlRecap',
    component: AutoEdlRecap,
    meta: {
      title: 'Auto-EDL Recap | '+DEFAULT_TITLE
    }
  },
  {
    path: '/auto-edl/signature',
    name: 'autoEdlSignature',
    component: AutoEdlSignature,
    meta: {
      title: 'Auto-EDL Signature | '+DEFAULT_TITLE
    }
  },
  {
    path: '/auto-edl/done',
    name: 'autoEdlDone',
    component: AutoEdlDone,
    meta: {
      title: 'Auto-EDL Sync | '+DEFAULT_TITLE
    }
  }
];

const defineRouteTitles = () => {
  return routeDefinitions.map((route) => {
    route.on = {
      pageBeforeIn: updateMeta
    };
    return route;
  })
}

const routes = defineRouteTitles();

export default routes;
