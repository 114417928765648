import {mapState, mapGetters, mapActions} from "vuex";
import { f7 } from "framework7-vue";

export default {
  computed: {
    ...mapState('me', [
      'me'
    ]),
    ...mapGetters('edlPersist', [
      'getCurrentLocalEdl'
    ]),
    userId: function () {
      return this.me ? this.me.id : null;
    },
    autoEdl: function () {
      return this.userId ? this.getAutoEdlById()(this.userId) : null;
    }
  },
  methods: {
    ...mapGetters('edlPersist', [
      'getAutoEdlById'
    ]),
    ...mapActions('security', [
      'resetJwtToken'
    ]),
    ...mapActions('edlPersist', [
      'setCurrentLocalEdlId',
      'updateAutoEdl'
    ]),
    logout() {
      this.resetJwtToken();
      f7.views.main.router.navigate({name: 'login'}, {reloadAll: true});
    },
  }
}
