<template>
  <f7-page-content
    v-if="entry && isPhotos"
  >
    <f7-row>
      <f7-col>
        <edl-edit-list-sub-entry-photos
          immersive
          :current-entry="entry"
          :current="current"
          :gallery="gallery"
          :adding-photos="addingPhotos"
          :opening-file-selector="openingFileSelector"
          @select="selectPhoto"
          @sort="sort"
          @delete="deleteElement"
          @delete-all="deleteAllFromPhotosEntry"
          @open-photo-input="openPhotoInput"
        />
      </f7-col>
      <f7-col
        v-if="selectedPhoto"
        class="immersive-photo-col"
      >
        <f7-list inset>
          <ul>
            <edl-edit-rate-photo
              class="immersive-photo"
              :rate="selectedPhoto?.rate"
              immersive
              @update="update"
            />
          </ul>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-page-content>
  <f7-page-content v-else-if="entry">
    <edl-edit-immersive-actions
      in-section
      :can-rename="canRename(entry.name)"
      :can-delete="canDelete(entry.name)"
      @delete="deleteSelf"
      @rename="renameSelf"
    />
    <edl-edit-list-sub-entry-custom
      v-if="canHaveCustomSubEntries(entry)"
      immersive
      :current-module="module"
      :current-entry="entry"
      :current="current"
      :cant-rename="cantRename"
      :cant-delete="cantDelete"
      @select="select"
      @sort="sort"
      @rename="renameElement"
      @delete="deleteElement"
      @unopened="unopened"
      @add-custom-sub-entry="addCustomSubEntry"
    />
    <edl-edit-list-sub-entry
      v-else
      immersive
      :current-module="module"
      :current-entry="entry"
      :current="current"
      :cant-rename="cantRename"
      :cant-delete="cantDelete"
      @select="select"
      @sort="sort"
      @rename="renameElement"
      @unopened="unopened"
      @delete="deleteElement"
    />
  </f7-page-content>
  <f7-page-content v-else-if="module">
    <edl-edit-immersive-actions
      in-section
      :can-rename="canRename(module.name)"
      :can-delete="canDelete(module.name)"
      @delete="deleteSelf"
      @rename="renameSelf"
    />
    <edl-edit-list-entry
      immersive
      :current-module="module"
      :current="current"
      :cant-rename="cantRename"
      :cant-delete="cantDelete"
      @select="select"
      @sort="sort"
      @rename="renameElement"
      @unopened="unopened"
      @delete="deleteElement"
    />
  </f7-page-content>
</template>

<script>
import EdlEditListSubEntryPhotos from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListSubEntryPhotos.vue";
import EdlEditListSubEntry from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListSubEntry.vue";
import EdlEditListEntry from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListEntry.vue";
import EdlEditRatePhoto from "@/components/pages/Edl/EdlEdit/EdlEditRate/EdlEditRatePhoto.vue";
import {canHaveCustomSubEntries} from "@/data/edlDataStructureModuleEntry";
import EdlEditListSubEntryCustom from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListSubEntryCustom.vue";
import EdlEditImmersiveActions from "@/components/pages/Edl/EdlEdit/EdlEditImmersive/EdlEditImmersiveActions.vue";

export default {
  name: "EdlEditImmersiveSection",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    EdlEditImmersiveActions,
    EdlEditListSubEntryCustom,
    EdlEditRatePhoto, EdlEditListEntry, EdlEditListSubEntry, EdlEditListSubEntryPhotos},
  props: {
    current: {
      type: Object,
      default: null
    },
    module: {
      type: Object,
      default: null
    },
    entry: {
      type: Object,
      default: null
    },
    subEntry: {
      type: Object,
      default: null
    },
    isPhotos: {
      type: Boolean,
      default: false
    },
    gallery: {
      type: Array,
      default: () => []
    },
    addingPhotos: {
      type: Number,
      default: 0
    },
    openingFileSelector: {
      type: Boolean,
      default: false
    },
    cantRename: {
      type: Array,
      default: () => []
    },
    cantDelete: {
      type: Array,
      default: () => []
    }
  },
  emits: ['select', 'sort', 'delete', 'delete-all', 'open-photo-input', 'rename', 'add-custom-sub-entry', 'unopened'],
  data: function () {
    return {
      selectedPhotoIndex: null
    }
  },
  computed: {
    selectedPhoto: function () {
      return this.selectedPhotoIndex !== null ? this.entry.content[this.selectedPhotoIndex] : undefined;
    }
  },
  methods: {
    canHaveCustomSubEntries,
    select(type, index) {
      this.$emit('select', type, index);
    },
    selectPhoto(type, index) {
      this.selectedPhotoIndex = index;
    },
    sort() {
      this.$emit('sort');
    },
    renameSelf() {
      this.$emit('rename');
    },
    renameElement(index) {
      this.$emit('rename', true, index);
    },
    canRename: function (name) {
      return !this.cantRename.includes(name);
    },
    unopened(type, index) {
      this.$emit('unopened', type, index);
    },
    deleteSelf() {
      this.$emit('delete');
    },
    deleteElement(index) {
      this.$emit('delete', true, index);
    },
    deleteAllFromPhotosEntry() {
      this.$emit('delete-all');
    },
    canDelete: function (name) {
      return !this.cantDelete.includes(name);
    },
    openPhotoInput() {
      this.$emit('open-photo-input');
    },
    addCustomSubEntry() {
      this.$emit('add-custom-sub-entry');
    }
  }
}
</script>

<style lang="scss" scoped>
  .immersive-photo-col {
    position: fixed;
    bottom: calc(50%);
    transform: translateY(calc(50% + 80px));
    right: 0;
  }
  .immersive-photo :deep(.item-media) img {
    position: relative;
    display: block;
    max-width: 100%;
    max-height: 500px;
    width: auto;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
