<template>
  <f7-list
    inset
    :class="immersive ? 'immersive' : ''"
  >
    <ul v-if="manualCosting">
      <f7-list-item
        class="manual-costing"
        title="à la charge du locataire"
      >
        <template #media>
          <f7-toggle
            class="manual-costing-toggle"
            :checked="entry.rate?.toQuote"
            @toggle:change="toggleToQuote"
          />
        </template>
      </f7-list-item>
    </ul>
    <ul v-if="hasConvention">
      <f7-list-item
        class="prequote-choice"
        :class="{active: entry.rate?.preQuote}"
        title="Pré-chiffrer"
      >
        <template #media>
          <f7-toggle
            class="prequote-toggle"
            :checked="entry.rate?.preQuote !== undefined && entry.rate?.preQuote !== null"
            @toggle:change="togglePreQuote"
          />
        </template>
      </f7-list-item>
      <f7-list-input
        v-if="preQuote"
        class="prequote-action"
        type="select"
        :value="preQuote.action"
        label="Action"
        floating-label
        @change="updatePreQuoteAction"
      >
        <option />
        <option
          v-for="(option, index) in calibrationConfigActions"
          :key="option+index"
          :value="option"
          :selected="preQuote.action === option"
        >
          {{ getActionLabel(option) }}
        </option>
      </f7-list-input>
      <!-- @blur event needed for chrome mobile keyboard closing -->
      <f7-list-input
        v-if="preQuote && entryCalibration"
        class="prequote-measure"
        :value="formatCurrency(preQuote.measure)"
        :label="(isCoating ? 'Mesure' : 'Unités') + ' (chiffrage automatique)'"
        floating-label
        autocomplete="off"
        @blur="updatePreQuoteMeasure"
        @change="updatePreQuoteMeasure"
        @focusin="selectAllInput"
      >
        <template #content-end>
          <span
            v-if="isCoating"
            class="input-cost-suffix"
          >m²</span>
        </template>
      </f7-list-input>
      <!-- @blur event needed for chrome mobile keyboard closing -->
      <f7-list-input
        v-if="preQuote"
        class="prequote-cost"
        :value="formatCurrency(preQuote.cost)"
        :label="'Coût (HT)'+entryCalibrationText"
        floating-label
        autocomplete="off"
        @blur="updatePreQuoteCost"
        @change="updatePreQuoteCost"
        @focusin="selectAllInput"
      >
        <template #content-end>
          <span class="input-cost-suffix">€</span>
        </template>
        <template #inner>
          <span class="input-cost-prefix">{{ entryAutoCostText }}</span>
        </template>
      </f7-list-input>
    </ul>
    <ul v-if="!autoEdl || (autoEdl && !autoEdlPhoto)">
      <edl-edit-rate-input
        v-for="(rate, rateName) in filteredRates"
        :key="rateName"
        :rate-info="getRateInfo(rateName)"
        :rate="rate"
        :readonly="readonly"
        :immersive="immersive"
        @update="update"
        @add-rate-info="addRateInfo"
      />
    </ul>
    <ul class="media-list">
      <edl-edit-rate-photo
        v-if="photo"
        :rate="entry.rate"
        @update="update"
      />
    </ul>
    <ul
      v-if="!autoEdl || (autoEdl && !autoEdlPhoto)"
      class="top-separator"
    >
      <i class="f7-icons rate-section-icon">bubble_left_fill</i>
      <f7-list-input
        id="observation"
        type="textarea"
        :value="observation"
        class="observation"
        :placeholder="$t('Observation')"
        @input="updateObservation($event.target.value)"
      />
      <f7-list-button
        v-if="!autoEdl"
        @click="observationPopupOpened = true"
      >
        {{ $t('Observation rapide') }}
      </f7-list-button>
    </ul>
    <ul>
      <f7-list-button
        v-if="hasConditionRate(filteredRates) && !autoEdl"
        class="renovation list-button-raised list-button-small"
        @click="resetRenovation"
      >
        Rénovation ?
      </f7-list-button>
      <div
        class="spacer"
        :class="{'has-renovation': hasConditionRate(filteredRates) && !autoEdl}"
      >&nbsp;</div>
    </ul>
    <edl-edit-rate-photo-gallery
      v-if="entry.name !== 'Photo' && (!autoEdl || (autoEdl && autoEdlPhoto))"
      :gallery="gallery"
      :nb-photos="nbPhotos"
      :adding-photos="addingPhotos"
      :opening-file-selector="openingFileSelector"
      :rate="entry.rate"
      @open-photo-input="openPhotoInput"
      @update="update"
    />
    <edl-edit-observation-popup
      :observation-text="observation"
      :auto-fill-observations="autoFillObservations"
      :opened="observationPopupOpened"
      @update="updateObservation"
      @close="closeObservationPopup"
    />
  </f7-list>
</template>

<script>
import EdlEditRateInput from "./EdlEditRateInput.vue";
import EdlEditRatePhoto from "./EdlEditRatePhoto.vue";
import {getConfig, getTypeRateForEntry, typeRateVariables} from "@/data/edlDataStructureModuleEntryRate";
import _ from "lodash";
import EdlEditObservationPopup from "../EdlEditObservation/EdlEditObservationPopup.vue";
import {autoEdlFilteredRatesByIndex, autoEdlFilteredRatesByLabel} from "@/data/edlDataBuilder";
import {f7} from "framework7-vue";
import costing from "@/components/mixins/costing";
import {unobserve} from "@/store/helper";
import {getCalibrationKeyByEntryName} from "@/composables/costing";
import EdlEditRatePhotoGallery from "@/components/pages/Edl/EdlEdit/EdlEditRate/EdlEditRatePhotoGallery.vue";

export default {
    name: "EdlEditRate",
    components: {EdlEditRatePhotoGallery, EdlEditObservationPopup, EdlEditRateInput, EdlEditRatePhoto},
    mixins: [costing],
    props: {
      entry: {
        type: Object,
        default: null
      },
      rateInfo: {
        type: Object,
        default: null
      },
      autoFillObservations: {
        type: Array,
        default: null
      },
      contextGallery: {
        type: Array,
        default: null
      },
      photoContext: {
        type: String,
        default: null
      },
      manualCosting: {
        type: Boolean,
        default: null
      },
      hasConvention: {
        type: Boolean,
        default: null
      },
      calibration: {
        type: Object,
        default: null
      },
      calibrationConfig: {
        type: Object,
        default: null
      },
      immersive: {
        type: Boolean,
        default: false
      },
      autoEdl: {
        type: Boolean,
        default: false
      },
      autoEdlPhoto: {
        type: Boolean,
        default: false
      },
      parent: {
        type: Object,
        default: null
      },
      addingPhotos: {
        type: Number,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update', 'addPhoto', 'addRateInfo'],
    data: function () {
      return {
        openingFileSelector: false,
        observationPopupOpened: false
      };
    },
    computed: {
      //"Type" rates must be filtered, as they are linked to entry but subEntries might show only some
      filteredRates: function () {
        if(this.entry === undefined) {
          return [];
        }
        return _.pickBy(this.entry.rate, (rate, index) => {
          let config;

          if(!typeRateVariables.includes(index)) { //Case non-types
            if (_.startsWith(index, 'typeCustom')) {
              index = 'typeCustom$';
            }

            config = getConfig(index);

            //don't keep rates like "typeWalls" as they are already set inside single "type" rate
            if(config !== undefined && typeRateVariables.includes(config.rateVariable) && config.customName !== true) {
              return false;
            }
          } else { //Case types
            config = this.parent ? getTypeRateForEntry(this.parent.name, this.entry.name) : getTypeRateForEntry(this.entry.name);
          }

          if (config === undefined) {
            //Not configured
            return false;
          } else if (config.customName === true){
            //case custom type
            return true;
          } else if (this.isNotForEntry(config)) {
            //Config does not match entry
            return false;
          } else if (index === 'photo') {
            return false;
          } else if (this.autoEdl &&
            (autoEdlFilteredRatesByIndex.includes(index) || autoEdlFilteredRatesByLabel.includes(config.label))
          ) {
            return false;
          }

          return true;
        });
      },
      photo: function () {
        return _.find(this.entry.rate, (rate, index) => {
          return index === 'photo';
        });
      },
      observation: function () {
        return this.entry.rate !== null && this.entry.rate?.other !== undefined ? this.entry.rate?.other : "";
      },
      gallery: function () {
        if(this.entry.rate !== "Photos" && this.entry.rate !== "Photo") {
          return _.map(this.contextGallery, (photo) => photo && photo.data !== undefined ? photo : null);
        } else {
          return null;
        }
      },
      nbPhotos: function () {
        return this.gallery ? this.gallery.length : 0;
      },
      preQuote: function () {
        return this.entry.rate?.preQuote !== undefined ? this.entry.rate.preQuote : null;
      },
      calibrationElementKey: function () {
        return this.preQuote ? getCalibrationKeyByEntryName(this.entry.name, this.parent?.name, this.calibrationConfig) : null;
      },
      calibrationElementConfig: function () {
        return this.calibrationElementKey && _.has(this.calibrationConfig.elements, this.calibrationElementKey) ? this.calibrationConfig.elements[this.calibrationElementKey] : null;
      },
      calibrationConfigActions: function () {
        return _.without(this.calibrationConfig.actions, 'all');
      },
      entryHasNature: function () {
        return this.filteredRates.type !== undefined && this.calibrationElementConfig?.natures !== undefined;
      },
      entryHasNatureDefined: function () {
        return this.entryHasNature && this.entry.rate?.type !== null && this.entry.rate?.type !== '';
      },
      preQuoteHasAction: function () {
        return _.has(this.preQuote, 'action') && this.preQuote.action && this.preQuote.action !== "";
      },
      fullCalibrationKey: function () {
        const parts = [];
        if(this.preQuote) {
          if(this.calibrationElementKey) {
            parts.push(this.calibrationElementKey);
          } else {
            return null;
          }

          //Nature
          if(this.entryHasNature) {
            parts.push(this.entryHasNatureDefined ? this.entry.rate.type : 'all');
          }

          //Action
          if(this.preQuoteHasAction) {
            parts.push(this.preQuote.action);
          } else {
            return null; //Must have an action defined to use calibrations
          }

          return parts.join('.');
        }

        return null;
      },
      calibrationKeysByPriority: function () {
        if(!this.fullCalibrationKey) {
          return null;
        }

        let calibrationKeys = [this.fullCalibrationKey];

        let calibrationWithAllNatures = (this.entryHasNatureDefined) ? this.fullCalibrationKey.replace(this.entry.rate.type, 'all') : null;
        if(calibrationWithAllNatures) {
          calibrationKeys.push(calibrationWithAllNatures);
        }

        let calibrationWithAllActions = this.fullCalibrationKey.replace(this.preQuote.action, 'all');
        calibrationKeys.push(calibrationWithAllActions);

        let calibrationWithAllNaturesAndActions = calibrationWithAllNatures ? calibrationWithAllNatures.replace(this.preQuote.action, 'all') : null;
        if(calibrationWithAllNaturesAndActions) {
          calibrationKeys.push(calibrationWithAllNaturesAndActions);
        }

        return calibrationKeys;
      },
      entryCalibration: function () {
        if(this.calibrationKeysByPriority === null) {
          return null;
        }

        let firstFound = undefined;
        let priority = 0;

        while(firstFound === undefined && this.calibrationKeysByPriority[priority] !== undefined) {
          firstFound = _.find(this.calibration, (value, key) => {
            return this.calibrationKeysByPriority[priority] ===  key;
          });
          priority++;
        }

        return firstFound !== undefined ? firstFound : null;
      },
      isCoating: function () {
        return this.calibrationElementConfig ? this.calibrationElementConfig.type === 'coating' : false;
      },
      entryCalibrationUnitCost: function () {
        if(!this.entryCalibration) {
          return null
        } else {
          if(_.has(this.entryCalibration, 'user')) {
            return this.entryCalibration.user.unitCost;
          } else if(_.has(this.entryCalibration, 'group')) {
            return this.entryCalibration.group.unitCost;
          } else {
            console.error('Calibration has no unitCost defined');
            return null;
          }
        }
      },
      entryCalibrationText: function () {
        return this.entryCalibrationUnitCost
          ? " - Calibré à "
            + this.formatCurrency(this.entryCalibrationUnitCost)
            + "€"
            + (this.isCoating ? "/m²" : "")
          : ""; //TODO add "autochiffré" if cost === autocost
      },
      isEntryAutoCost: function () {
        return (this.entryCalibrationUnitCost * this.preQuote.measure)/100 === this.preQuote.cost;
      },
      entryAutoCostText: function () {
        return this.isEntryAutoCost ? "Chiffrage Automatique" : "Chiffrage Manuel";
      }
    },
    watch: {
      nbPhotos: function (nbPhotos) {
        this.$nextTick(() => {
          if(this.$refs.gallery?.$el !== undefined) {
            let swiperInstance = f7.swiper.get(this.$refs.gallery.$el);
            swiperInstance.slideTo(nbPhotos);
          }
        });
      },
      entryCalibration: function (entryCalibration) {
        if(entryCalibration !== null) {
          let calibrationId = null;
          if(_.has(entryCalibration, 'user')) {
            calibrationId = entryCalibration.user.id;
          } else if(_.has(this.entryCalibration, 'group')) {
            calibrationId = entryCalibration.group.id;
          }
          this.updatePreQuoteElement('calibrationId', calibrationId);
        }
      }
    },
    methods: {
      update: function (payload) {
        this.$emit('update', payload);
      },
      updateObservation: _.debounce(function (value) {
        this.$emit('update', {
          rate: 'other',
          value
        });
      }, 200),
      isNotForEntry: function (config, entry = null, isParent = false) {
        if(!entry) {
          entry = this.entry;
        }
        if(config.entryName === undefined) {
          //No EntryName defined on given config (case base rates like "cleaning" that should be applied everywhere they are required)
          return false;
        } else {
          if(config.subEntryName !== undefined && this.parent && !isParent) {
            if(Array.isArray(config.subEntryName) && config.subEntryName.includes(entry.name)) {
              return this.isNotForEntry(config, this.parent, true);
            } else if(entry.name === config.entryName) {
              return this.isNotForEntry(config, this.parent, true);
            } else {
              return true;
            }
          } else {
            if(Array.isArray(config.entryName)) {
              return !config.entryName.includes(entry.name);
            } else {
              return entry.name !== config.entryName;
            }
          }
        }
      },
      openPhotoInput: function () {
        this.openingFileSelector = true;
        this.$emit('addPhoto', true);
        setTimeout(() => {
          this.openingFileSelector = false;
        }, 2000);
      },
      addRateInfo: function (data) {
        this.$emit('addRateInfo', data);
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      },
      toggleToQuote: function (value) {
        this.$emit('update', {
          rate: 'toQuote',
          value
        });
        this.togglePreQuote(true);
      },
      togglePreQuote: function (value) {
        if(value) {
          if(this.preQuote === null || this.preQuote.action === undefined) {
            this.$emit('update', {
              rate: 'preQuote',
              value: {
                action: null,
                measure: null,
                cost: null,
                calibrationId: null
              }
            });
          }
        } else {
          this.$emit('update', {
            rate: 'preQuote',
            value: null
          });
        }
      },
      updatePreQuoteElement: function (keys, values) {
        let newPreQuoteValue = unobserve(this.entry.rate.preQuote);

        if(_.isArray(keys)) {
          keys.forEach((key, index) => {
            newPreQuoteValue[key] = _.isArray(values) ? values[index] : values;
          });
        } else {
          newPreQuoteValue[keys] = values;
        }

        this.$emit('update', {
          rate: 'preQuote',
          value: newPreQuoteValue
        });
      },
      updatePreQuoteAction: function (event) {
        this.updatePreQuoteElement('action', event.target.value);
      },
      updatePreQuoteMeasure: function (event) {
        const measure = this.unformatCurrency(event.target.value);
        const autoCost = this.calculateAutoCost(measure);
        this.updatePreQuoteElement(['measure', 'cost'], [measure, autoCost]);
      },
      updatePreQuoteCost: function (event) {
        this.updatePreQuoteElement('cost', this.unformatCurrency(event.target.value));
      },
      calculateAutoCost: function (measure) {
        return this.entryCalibrationUnitCost * measure/100;
      },
      getRateInfo: function (rateName) {
        if(this.rateInfo === null) {
          return null;
        }
        if(this.rateInfo[rateName] !== undefined) {
          return this.rateInfo[rateName];
        } else {
          let typeRateConfig = this.parent !== undefined && this.parent !== null ?
            getTypeRateForEntry(this.parent.name, this.entry.name) :
            getTypeRateForEntry(this.entry.name);

          return typeRateConfig ?
            this.rateInfo[typeRateConfig.name] :
            this.rateInfo[rateName];
        }
      },
      hasConditionRate: function (rates) {
        return _.find(rates, (rate, rateName) => rateName === 'condition') !== undefined;
      },
      resetRenovation: function () {
        f7.dialog.confirm("En confirmant, l'état d'usage, le fonctionnement et les observations seront réinitialisés", () => {
          this.update({
            rate: 'condition',
            value: "Bon état"
          });
          if(this.entry.rate.state !== undefined && this.entry.rate.state !== null && this.entry.rate.state !== "") {
            this.update({
              rate: 'state',
              value: "Fonctionne"
            });
          }
          this.update({
            rate: 'other',
            value: ""
          });
        });
      },
      selectAllInput: function (event) {
        const input = event.target;
        const end = event.target.value.length;
        input.setSelectionRange(0, end);
        input.focus();
      },
      closeObservationPopup: function () {
        this.observationPopupOpened = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .rate-section-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    color: var(--f7-theme-color-dark-grey);
  }
  .manual-costing, .prequote-choice {
    background-color: var(--f7-theme-color-light-grey);
    color: var(--f7-theme-color-very-dark-grey);
    --f7-list-item-title-font-size: 14px;
    --f7-toggle-width: 35px;
    --f7-toggle-height: 20px;
  }
  .prequote-action, .prequote-measure, .prequote-cost {
    background-color: var(--f7-theme-color-light-grey);
    color: var(--f7-theme-color-very-dark-grey);
  }
  .prequote-measure :deep(input.input-with-value), .prequote-cost :deep(input.input-with-value) {
    text-align: right;
  }
  .prequote-cost :deep(input.input-with-value) {
    padding-right: 5px;
    margin-bottom: -3px;
  }
  .list-button-raised {
    background: var(--f7-theme-color-light-grey);
    font-size: 13px;
  }
  .input-cost-suffix {
    position: relative;
    right: 15px;
    top: 12px;
  }
  .input-cost-prefix {
    height: 5px;
    position: relative;
    font-size: 11px;
    top: -10px;
    color: var(--f7-theme-color-dark-grey);
  }
  .spacer {
    border: none;
    background-color: var(--f7-theme-color-light-grey);
    height: 10px;
  }
  .immersive {
    .prequote-choice {
      border-radius: 8px;
      &.active {
        border-radius: 8px 8px 0 0;
      }
    }

    ul.top-separator {
      margin-top: 10px;
      border: 1px solid var(--f7-block-strong-border-color);
    }
    .renovation {
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 60%;
      margin-left: 20%;
    }
    .spacer {
      background-color: white;
    }
    .observation :deep(textarea) {
      height: calc(2* var(--f7-textarea-height));
    }
  }
  .renovation {
    :deep(.list-button)::after {
      background-color: var(--f7-theme-color-light-grey);
    }
  }
  .spacer {
    border: none;
    background-color: var(--f7-theme-color-light-grey);
    &.has-renovation {
      height: 20px;
    }
    height: 40px;
  }
</style>
