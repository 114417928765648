<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top title="EDLs enregistrés">
      <template #global-actions="{toggleSubNav, showSubNav}">
        <f7-link
          class="button color-white"
          @click="toggleSubNav"
        >
          <i
            v-if="!showSubNav"
            class="f7-icons"
          >search</i><i
            v-else
            class="f7-icons"
          >chevron_up</i>
        </f7-link>
      </template>
      <template #subnav>
        <f7-row>
          <f7-col
            v-if="!me.uniqueProfile || me.groupSharesEdls"
            width="30">
            <label>Profil</label>
            <f7-input
              type="select"
              :value="filterProfileId"
              @change="updateFilterProfileId"
            >
              <option value="">
                Tous
              </option>
              <option
                v-for="profile in profiles"
                :key="'profil'+profile.id"
                :value="profile.id"
              >
                {{ profile.name }}
              </option>
            </f7-input>
          </f7-col>
          <f7-col :width="(!me.uniqueProfile || me.groupSharesEdls) ? 70 : 100">
            <f7-searchbar
              :value="remoteEdlsSearch"
              placeholder="Rechercher un EDL"
              disable-button-text="Annuler"
              custom-search
              @input="updateSearchFilter"
              @click:clear="resetSearchFilter"
              @click:disable="resetSearchFilter"
            />
          </f7-col>
        </f7-row>
      </template>
    </navigation-top>

    <navigation-bottom current="edls" />

    <f7-page-content>
      <f7-block-title v-if="me.groupSharesEdls">Liste des EDLs sauvegardés de {{me.group.name}}</f7-block-title>
      <f7-block-title v-else>Liste de mes EDLs sauvegardés en ligne</f7-block-title>
      <f7-block>
        <edl-list-pagination />
      </f7-block>

      <f7-list
        v-if="!noEdls"
        class="search-list"
      >
        <f7-list-item
          v-for="edl in remoteEdls"
          :key="'edlRemote'+edl.id"
          swipeout
          :header="getHeader(edl)"
          :title="edl.renter"
          @swipeout:deleted="deleteEdl(edl.id)"
        >
          <template #media>
            <div>
              <i class="f7-icons">doc_checkmark</i>
              <p class="edl-id">
                #{{ edl.id }}
              </p>
            </div>
          </template>
          <template #footer>
            <i v-if="edl.isPlatformConnected" class="f7-icons small">arrow_right_arrow_left_circle_fill</i>
            <span v-if="edl.fromAutoEdl">Auto-</span>EDL <span class="edl-io">{{ edl.edlIO }}</span> du <span class="edl-date">{{ formatDate(edl.date, 'PPPpp') }}</span>
            / <span class="edl-housing">{{ edl.housingSize }}</span> <span
              v-if="edl.housingRef"
              class="edl-reference"
            >({{ edl.housingRef }})</span>
          </template>
          <template #after>
            <div class="segmented segmented-raised-ios edl-actions">
              <f7-link
                v-if="hasHistory(edl)"
                class="button color-yellow"
                :class="{disabled: !isOnline}"
                tooltip="Voir l'historique"
                @click="goToHistory(edl.id)"
              >
                <i class="f7-icons">flowchart</i>
              </f7-link>

              <f7-link
                v-if="edlHasConvention(edl)"
                class="button"
                :class="{disabled: !isOnline, 'color-purple': edl.hasDefinedCosting, 'color-grey': !edl.hasDefinedCosting}"
                tooltip="Chiffrer la convention de sortie"
                @click="goToCosting(edl.id)"
              >
                <i class="f7-icons">money_euro_circle</i>
              </f7-link>

              <f7-button v-if="edlHasConvention(edl) && isDownloadingConventionHtml(edl.id)">
                <f7-preloader size="28" />
              </f7-button>
              <f7-link
                v-else-if="edlHasConvention(edl)"
                class="button color-deeppurple"
                :class="{disabled: !isOnline}"
                tooltip="Voir la convention de sortie de l'EDL"
                @click="showEdlConventionHtml(edl.id)"
              >
                <i class="f7-icons">doc_on_clipboard</i>
              </f7-link>

              <f7-button v-if="isDownloadingHtml(edl.id)">
                <f7-preloader size="28" />
              </f7-button>
              <f7-link
                v-else
                class="button color-green"
                :class="{disabled: !isOnline}"
                tooltip="Voir l'EDL"
                @click="showEdlHtml(edl.id)"
              >
                <i class="f7-icons">doc_richtext</i>
              </f7-link>

              <f7-link
                class="button color-orange"
                :class="{disabled: !isOnline}"
                tooltip="Dupliquer l'EDL"
                @click="duplicateEdl(edl)"
              >
                <i class="f7-icons">doc_on_doc</i>
              </f7-link>

              <f7-button v-if="isSending(edl.id)">
                <f7-preloader size="28" />
              </f7-button>
              <f7-button
                v-else-if="isSent(edl.id)"
                class="color-green"
              >
                <i class="f7-icons">checkmark</i>
              </f7-button>
              <f7-link
                v-else
                class="button color-blue"
                :class="{disabled: !isOnline}"
                tooltip="Envoyer l'EDL au locataire par email"
                @click="sendEdl(edl.id)"
              >
                <i class="f7-icons">envelope</i>
              </f7-link>

              <f7-button v-if="isSavingComment(edl.id)">
                <f7-preloader size="28" />
              </f7-button>
              <f7-button
                v-else
                :class="{'color-pink': hasComment(edl), 'color-grey': !hasComment(edl), disabled: !isOnline}"
                tooltip="Laisser un commentaire pour cet EDL"
                @click="openCommentPopup(edl)"
              >
                <i
                  v-if="hasComment(edl)"
                  class="f7-icons"
                >ellipses_bubble</i>
                <i
                  v-else
                  class="f7-icons"
                >bubble_left</i>
              </f7-button>
            </div>
          </template>
          <f7-swipeout-actions
            v-if="!!isOnline && canDelete(edl)"
            right
          >
            <f7-swipeout-button
              delete
              confirm-text="Êtes-vous certains de vouloir supprimer cet EDL du serveur ?"
            >
              Supprimer
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
      <f7-block
        v-else
        inset
        strong
      >
        <p>Vous n'avez pas encore d'EDL sauvegardés en ligne.</p>
        <p>Créez un nouvel EDL ou synchronisez vos EDLs en cours pour les voir apparaître ici.</p>
        <p>Ils seront ainsi partagés avec d'autres appareils utilisant ce même compte utilisateur.</p>
      </f7-block>
      <f7-block class="bottom-pagination">
        <edl-list-pagination />
      </f7-block>
    </f7-page-content>

    <edl-list-comment
      :opened="commentPopupOpened"
      :value="commentPopupContent"
      :edl-id="commentPopupEdlId"
      @update="updateCommentPopupContent"
      @save="saveCommentPopup"
    />

    <edl-list-preview
      :opened="previewPopupOpened"
      :type="previewPopupType"
      :content="previewPopupContent"
      :edl-id="previewPopupEdlId"
      @close="closePreviewPopup"
      @reload="reloadPreviewPopup"
    />

    <f7-actions :opened="duplicateEdlSelectTypeOpened">
      <f7-actions-group>
        <f7-actions-label>Dupliquer vers un EDL :</f7-actions-label>
        <f7-actions-button
          :bold="duplicateFromType === 'Sortant'"
          @click="doDuplicateEdl(duplicateFromId, 'entrant')"
        >
          Entrant
        </f7-actions-button>
        <f7-actions-button
          :bold="duplicateFromType === 'Entrant'"
          @click="doDuplicateEdl(duplicateFromId, 'sortant')"
        >
          Sortant
        </f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">
          Annuler
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import {mapActions, mapState, mapGetters, mapMutations} from "vuex";
  import NavigationTop from "../../layout/NavigationTop";
  import NavigationBottom from "../../layout/NavigationBottom";
  import api from '../../../api';
  import errors from "@/components/mixins/errors";
  import offline from "@/components/mixins/offline";
  import EdlListPagination from "@/components/pages/List/EdlListPagination";
  import _ from "lodash";
  import hasConvention from "@/components/mixins/hasConvention";
  import formatDate from '@/components/filters/format_date';
  import { f7 } from "framework7-vue";
  import EdlListComment from "@/components/pages/List/EdlListComment.vue";
  import EdlListPreview from "@/components/pages/List/EdlListPreview.vue";

  export default {
    name: "EdlListRemote",
    components: {EdlListPreview, EdlListComment, EdlListPagination, NavigationBottom, NavigationTop},
    mixins: [firewall, errors, offline, hasConvention],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        filterProfileId: null,
        downloadingHtml: [],
        downloadingConventionHtml: [],
        sending: [],
        sent: [],
        previewPopupOpened: false,
        previewPopupContent: null,
        previewPopupType: null,
        previewPopupEdlId: null,
        commentPopupOpened: false,
        commentPopupOriginalContent: null,
        commentPopupContent: null,
        commentPopupEdlId: null,
        commentSavingForEdlId: null,
        duplicateFromId: null,
        duplicateFromType: null,
        duplicateEdlSelectTypeOpened: false
      };
    },
    computed: {
      ...mapState('edlTemp', [
        'remoteEdls',
        'remoteEdlsPage',
        'remoteEdlsSearch',
        'userGroupProfiles'
      ]),
      ...mapState('me', [
        'selectedProfile'
      ]),
      noEdls: function () {
        return this.remoteEdls !== null && this.remoteEdls.length === 0;
      },
      profiles: function () {
        if(this.me.groupSharesEdls) {
          return this.userGroupProfiles;
        } else {
          return this.me.profiles;
        }
      }
    },
    watch: {
      filterProfileId: function () {
        this.load(true);
      },
      remoteEdlsSearch: function () {
        this.load(true);
      },
      remoteEdlsPage: function () {
        this.load(false);
      }
    },
    mounted: function () {
      this.resetSearchFilter();
      this.updateRemoteEdlsPage(1);
      this.$nextTick(() => {
        if(this.me.defaultListAllProfiles) {
          this.filterProfileId = '';
        } else {
          this.filterProfileId = this.selectedProfile?.id;
        }
      });
    },
    methods: {
      ...mapActions('edlTemp', [
        'fetchRemoteEdls',
        'fetchUserGroupProfiles',
        'deleteRemoteEdl',
        'updateEdlComment',
      ]),
      ...mapGetters('edlTemp', [
        'getRemoteEdlById'
      ]),
      ...mapMutations('edlTemp', {
        'updateRemoteEdlsSearch': 'remoteEdlsSearch',
        'updateRemoteEdlsPage': 'remoteEdlsPage'
      }),
      load: function (resetPagination = true) {
        f7.preloader.show();
        this.pagePreloaderRunning = true;
        if(this.me.groupSharesEdls) {
          this.fetchUserGroupProfiles(this.me.group.id).then(() => {
            this.fetchRemoteEdls({profileId: this.filterProfileId, resetPagination}).then(() => {
              f7.preloader.hide();
            });
          });
        } else {
          this.fetchRemoteEdls({profileId: this.filterProfileId, resetPagination}).then(() => {
            f7.preloader.hide();
          });
        }
      },
      getHeader: function (edl) {
        let header = '';
        if (edl.profileId !== this.selectedProfile.id && this.me.groupSharesEdls) {
          header += '['+edl.name + '] ';
        }
        if (edl.agent !== undefined && edl.agent !== null) {
          header += edl.agent;
        }
        if (edl.owner !== undefined && edl.owner !== null) {
          header += header !== '' ? ' - '+edl.owner : edl.owner;
        }
        return header;
      },
      duplicateEdl: function (edl) {
        this.duplicateFromId = edl.id;
        this.duplicateFromType = edl.edlIO;
        this.duplicateEdlSelectTypeOpened = true;
      },
      doDuplicateEdl: function (duplicateFromId, edlType) {
        this.f7router.navigate({
          name: 'duplicateEdl',
          params: {
            duplicateFromId,
            edlType,
            source: 'remote'
          }
        });
      },
      deleteEdl: function (id) {
        this.deleteRemoteEdl(id);
      },
      canDelete: function (edl) {
        return !this.me.groupSharesEdls || edl.name === this.selectedProfile.name;
      },
      sendEdl: function (id) {
        f7.dialog.confirm('Vous allez envoyer un email contenant l\'EDL en pièce jointe au(x) locataire(s). Confirmez-vous cette action ?', 'Confirmer l\'envoi', () => {
          this.sending.push(id);
          let sendingIndex = this.sending.indexOf(id);
          api.sendEdl(id).then(() => {
            this.sending.splice(sendingIndex, 1);
            this.sent.push(id);
            let sentIndex = this.sent.indexOf(id);
            setTimeout(() => {
              this.sent.splice(sentIndex, 1);
            }, 5000);
          });
        });
      },
      showEdlHtml: function (id) {
        let type = 'edl';
        this.downloadingHtml.push(id);
        let downloadingIndex = this.downloadingHtml.indexOf(id);
        api.downloadEdlHtml(id)
          .then(response => {
            const blob = new Blob([response.data], { type: 'text/html' })
            this.previewPopupContent = URL.createObjectURL(blob);
            this.previewPopupOpened = true;
            this.previewPopupType = type;
            this.previewPopupEdlId = id;

            this.downloadingHtml.splice(downloadingIndex, 1);
          }).catch((error) => {
          this.throwError(
            "Une erreur est survenue lors de l'affichage de l'EDL. Veuillez nous contacter si cela se reproduit.",
            "Erreur téléchargement",
            _.has(error, 'response.data.message') ? error.response.data.message : error.message,
            error
          );
          this.downloadingHtml.splice(downloadingIndex, 1);
        });
      },
      showEdlConventionHtml: function (id) {
        let type = 'convention';
        this.downloadingConventionHtml.push(id);
        let downloadingIndex = this.downloadingConventionHtml.indexOf(id);
        api.downloadEdlConventionHtml(id)
          .then(response => {
            const blob = new Blob([response.data], { type: 'text/html' })
            this.previewPopupContent = URL.createObjectURL(blob);
            this.previewPopupOpened = true;
            this.previewPopupType = type;
            this.previewPopupEdlId = id;

            this.downloadingConventionHtml.splice(downloadingIndex, 1);
          }).catch((error) => {
          this.throwError(
            "Une erreur est survenue lors du téléchargement de la convention de sortie. Veuillez nous contacter si cela se reproduit.",
            "Erreur téléchargement",
            _.has(error, 'response.data.message') ? error.response.data.message : error.message,
            error
          );
          this.downloadingConventionHtml.splice(downloadingIndex, 1);
        });
      },
      goToCosting: function (edlId) {
        this.f7router.navigate({name: 'edlCosting', params: {edlId}}, {reloadAll: true});
      },
      goToHistory: function (edlId) {
        console.log('goToHistory');
        this.f7router.navigate({name: 'edlHistory', params: {edlId}}, {reloadAll: true});
      },
      updateSearchFilter: _.debounce(function (event) {
        let query = event.target.value;
        this.updateRemoteEdlsSearch(query);
      }, 500),
      resetSearchFilter: function () {
        this.updateRemoteEdlsSearch(null);
      },
      isDownloadingHtml: function (id) {
        return this.downloadingHtml.includes(id);
      },
      isDownloadingConventionHtml: function (id) {
        return this.downloadingConventionHtml.includes(id);
      },
      isSending: function (id) {
        return this.sending.includes(id);
      },
      isSent: function (id) {
        return this.sent.includes(id);
      },
      updateFilterProfileId: function (event) {
        this.filterProfileId = event.target.value !== "" ? event.target.value : null;
      },
      closePreviewPopup: function () {
        this.previewPopupOpened = false;
        this.previewPopupContent = null;
      },
      reloadPreviewPopup: function (edlId, type) {
        switch(type) {
          case 'convention':
            this.showEdlConventionHtml(edlId);
            break;
          case 'edl':
            this.showEdlHtml(edlId);
            break;
          default:
            console.error('Type '+type+' is not defined in reloadPreviewPopup');
            break;
        }
      },
      openCommentPopup: function (edl) {
        this.commentPopupOpened = true;
        this.commentPopupEdlId = edl.id;
        this.commentPopupContent = this.commentPopupOriginalContent = edl.comment;
      },
      updateCommentPopupContent: function (value) {
        this.commentPopupContent = value;
      },
      saveCommentPopup: function () {
        this.commentPopupOpened = false;
        if(this.commentPopupOriginalContent !== this.commentPopupContent) {
          this.commentSavingForEdlId = this.commentPopupEdlId;
          this.updateEdlComment({
            id: this.commentPopupEdlId,
            comment: this.commentPopupContent
          }).then(this.resetCommentPopup);
        } else {
          this.resetCommentPopup();
        }
      },
      resetCommentPopup: function () {
        this.commentPopupContent = null;
        this.commentPopupOriginalContent = null;
        this.commentPopupEdlId = null;
        this.commentSavingForEdlId = null;
      },
      isSavingComment: function (id) {
        return this.commentSavingForEdlId !== null && this.commentSavingForEdlId === id;
      },
      hasComment: function (edl) {
        return edl.comment !== undefined && edl.comment !== null && edl.comment !== '';
      },
      hasHistory: function (edl) {
        return edl.duplicateFromEdlID !== null;
      },
      formatDate: function (value, pattern) {
        return formatDate(value, pattern);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .status {
    margin-right: 10px;
    font-size: 10px;
  }
  .edl-actions {
    a.button {
      min-width: 60px;
    }
  }
  .f7-icons {
    font-size: 30px;
    margin-right: 2px;
  }
  .list .item-after {
    flex-shrink: 2;
  }
  .button {
    height: 50px;
  }
  .edl-id {
    display: block;
    text-align: left;
    margin: 0;
    font-size: 8px;
    color: var(--f7-theme-color-dark-grey);
    width: 30px;
  }
  .subnavbar-inner .row {
    width: 100%;
    margin-top: -5px;
    label {
      font-size: 14px;
    }
    .input-dropdown {
      margin-top: -5px;
      font-size: 20px;
    }
  }
  :deep(.popup-nav-preloader) {
    --f7-preloader-size: 22px;
    --f7-preloader-color: white;
    .preloader {
      .preloader-inner-line {
        height: 35%;
        width: 10%;
      }
      margin-right: 5px;
    }
  }
  .bottom-pagination {
    margin-bottom: 45px;
    margin-top: 10px;
  }
  .edl-reference {
    font-weight: bold;
  }
  .comment-input {
    font-size: 16px;
  }
  .small {
    font-size: 12px;
    i {
      font-size: 18px;
    }
  }
  .toggle-show-costs-button {
    height: 20px;
    font-size: 14px;
    text-transform: none;
  }

  @media screen and (max-width: 720px) {
    .status {
      display: none;
    }
    .show-for-large {
      display: none !important;
    }
  }

  @media screen and (max-width: 550px) {
    .segmented {
      display: block;
    }
    .ios .segmented-raised-ios .button:not(.button-outline) {
      border-left: none;
    }
    .list .item-after {
      margin-left: 10px;
    }
    .edl-actions {
      .f7-icons {
        font-size: 30px;
      }
    }
    .button {
      height: 46px;
    }
    .subnavbar-inner .row {
      label {
        font-size: 12px;
      }
      .input-dropdown {
        margin-top: -5px;
        font-size: 16px;
      }
    }
    .show-for-large {
      display: none !important;
    }
  }
</style>
