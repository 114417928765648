import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import security from "@/store/modules/security";
import me from "@/store/modules/me";
import edlTemp from "@/store/modules/edlTemp";
import edlPersist from "@/store/modules/edlPersist";
import user from "@/store/modules/user";
import userGroup from "@/store/modules/userGroup";
import autoEdl from "@/store/modules/autoEdl";
import costing from "@/store/modules/costing";
import errors from "@/store/modules/errors";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    'security',
    'me',
    'edlPersist',
    'costing'
  ]
});

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    security,
    me,
    edlPersist,
    edlTemp,
    user,
    userGroup,
    autoEdl,
    costing,
    errors
  },
  plugins: [vuexLocal.plugin],
  strict: debug
});
