import format  from 'date-fns/format';
import parseISO from 'date-fns/parseISO'
import parse from 'date-fns/parse';
import fr from 'date-fns/locale/fr';

export default (value, pattern) => {
  if(value === undefined) {
    return null;
  }

  let parsed;
  if(typeof value === 'number') {
    parsed = parse(value, 'T', new Date());
  } else {
    parsed = parseISO(value);
  }

  return format(parsed, pattern, {locale: fr})
}
