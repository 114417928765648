import api from '../../api';

// initial state
const state = () => ({
  users: [],
})

// getters
const getters = {
  getAllUsers: state => state.users,
  getUserById: state => id => state.users.length > 0 ? state.users.find(user => user.id === id) : null,
  getUsersByGroupId: state => id => state.users.filter(user => user.groupId === id),
  getRootUsers: state => state.users.filter(user => user.groupId === null)
};

// actions
const actions = {
  fetchUsers({commit}, groupId = null) {
    return api.users(groupId)
      .then(response => {
        commit('pushUsers', response.data)
      })
  },
  fetchUser({commit}, id) {
    return api.user(id)
      .then((response) => {
        let user = response.data
        commit('pushUser', user)
      })
  },
  createUser({commit}, payload) {
    return api.createUser(payload)
      .then((response) => {
        let user = response.data
        commit('pushUser', user)

        return user
      })
  },
  updateUser({commit}, {id, changes, groupId = null}) {
    if(groupId) {
      changes.groupId = groupId;
    }
    return api.updateUser(id, changes)
      .then((response) => {
        let user = response.data
        commit('pushUser', user)

        return user
      })
  },
  deleteUser({commit}, user) {
    return api.deleteUser(user.id)
      .then(() => {
        commit('popUser', user);
      })
    ;
  }
};

// mutations
const mutations = {
  pushUsers(state, users) {
    state.users = state.users.filter(stateUser => ! users.find (newUser => stateUser.id === newUser.id) ).concat(users);
  },
  pushUser(state, user) {
    let index = state.users.findIndex(item => item.id === user.id)

    if (index >= 0)
      state.users.splice(index, 1, user)
    else
      state.users.push(user)
  },
  popUser(state, user) {
    state.users.splice(state.users.findIndex(item => item.id === user.id), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
