<template>
  <f7-navbar>
    <f7-nav-left v-visible="back !== null && back !== false">
      <slot name="back-actions">
        <a @click="goBack"><i class="f7-icons">chevron_left</i>{{ backTitle }}</a>
      </slot>
    </f7-nav-left>
    <f7-nav-title>{{ title }}</f7-nav-title>
    <f7-nav-right>
      <span
        v-if="!isOnline"
        class="offline-status"
      ><i class="f7-icons">wifi_slash</i></span>
      <slot
        name="global-actions"
        :toggle-sub-nav="toggleSubNav"
        :show-sub-nav="showSubNav"
      />
    </f7-nav-right>
    <f7-subnavbar
      v-if="showSubNav"
      :inner="true"
    >
      <slot name="subnav" />
    </f7-subnavbar>
  </f7-navbar>
</template>

<script>
  import offline from "@/components/mixins/offline";
  import { f7 } from "framework7-vue";

  export default {
    name: "NavigationTop",
    mixins: [offline],
    props: {
      'title': {
        type: String,
        default: 'DDH'
      },
      // eslint-disable-next-line vue/require-prop-types
      'back': { //can be string, boolean or object
        default: null
      },
      'backTitle': {
        type: String,
        default: 'Retour'
      }
    },
    data: function () {
      return {
        showSubNav: false
      };
    },
    methods: {
      goBack: function () {
        if(this.back === '') {
          f7.views.main.router.back();
        } else if (typeof this.back === 'string') {
          f7.views.main.router.navigate({name: this.back}, {transition: 'f7-fade'});
        } else if (typeof this.back === 'object' && this.back.name !== undefined && this.back.params !== undefined) {
          f7.views.main.router.navigate({name: this.back.name, params: this.back.params}, {transition: 'f7-fade'});
        } else if (typeof this.back === 'object' && this.back.name !== undefined && this.back.params === undefined) {
          f7.views.main.router.navigate({name: this.back.name}, {transition: 'f7-fade'});
        }
      },
      toggleSubNav: function () {
        this.showSubNav = !this.showSubNav;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar-inner {
    .f7-icons {
      font-size: 16px;
    }
    .offline-status {
      color: var(--f7-color-red);
      margin: 0 5px 0 5px;
      i {
        font-size: 20px;
      }
    }
  }

</style>
