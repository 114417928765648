export default {
  data: function () {
    return {
      availableLanguages: [
        {label: 'Français', locale: 'fr', flag: '🇫🇷'},
        {label: 'English', locale: 'en', flag: '🇬🇧'}
      ],
      showFullLanguageLabel: false
    };
  },
  computed: {
    locale: function () {
      if(this.isAutoEdl) {
        return this.autoEdl ? this.autoEdl.locale : 'fr';
      } else if (this.info !== undefined) {
        return this.info ? this.info.locale : 'fr';
      } else if (this.edl !== undefined) {
        return this.edl ? this.edl.locale : 'fr';
      }
    }
  },
  methods: {
    dynamicLanguageLabel: function (lang) {
      return this.showFullLanguageLabel ? lang.flag + " " + lang.label : lang.flag;
    },
  },
  watch: {
    locale: {
      handler: function (locale) {
        this.$i18n.locale = locale;
      },
      immediate: true
    }
  }
}
