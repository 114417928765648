<template>
  <f7-segmented
    v-if="(canRename || canDelete) && inSection"
    raised
    round
    class="immersive-actions immersive-actions-section large-screen"
  >
    <f7-button
      v-if="canRename"
      round
      :large="inSection"
      icon-f7="pencil"
      class="color-theme-blue"
      @click="renameSelf"
    >
      Renommer
    </f7-button>
    <f7-button
      v-if="canDelete"
      round
      :large="inSection"
      icon-f7="trash"
      class="color-red"
      @click="deleteSelf"
    >
      Supprimer
    </f7-button>
  </f7-segmented>
  <f7-segmented
    v-if="canRename || canDelete"
    raised
    round
    class="immersive-actions small-screen"
    :class="{ 'immersive-actions-section': inSection }"
  >
    <f7-button
      v-if="canRename"
      round
      icon-f7="pencil"
      class="color-theme-blue"
      @click="renameSelf"
    />
    <f7-button
      v-if="canDelete"
      round
      icon-f7="trash"
      class="color-red"
      @click="deleteSelf"
    />
  </f7-segmented>
</template>

<script>
export default {
  name: "EdlEditImmersiveActions",
  props: {
    inSection: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    canRename: {
      type: Boolean,
      default: true
    }
  },
  emits: ['rename', 'delete'],
  methods: {
    renameSelf() {
      this.$emit('rename')
    },
    deleteSelf() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
.immersive-actions {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &.small-screen {
    :deep(.f7-icons) {
      font-size: 16px;
    }
    &.immersive-actions-section {
      display: none;
    }
    width: 30%;
    margin-bottom: 20px;
  }
  &.large-screen {
    display: flex;
    width: 50%;
    margin-bottom: 40px;
  }
  &.immersive-actions-section {
    margin-top: -40px;
  }
}
@media screen and (max-width: 768px) {
  .immersive-actions.large-screen {
    display: none;
  }
  .immersive-actions.small-screen {
    display: flex;
    &.immersive-actions-section {
      display: flex;
    }
  }
}
</style>
