import api from '../../api';

const state = {
  login: null,
  jwtToken: null,
  refreshToken: null
};

// actions
const actions = {
  login({commit}, payload) {
    commit('login', payload.login);
    return api.login(payload.login, payload.password);
  },
  refreshLogin({commit, state}) {
    return api.refreshLogin(state.login, state.refreshToken).then((response) => {
      commit('refreshToken', response.data.refresh);
      commit('jwtToken', response.data.token);
    });
  },
  setJwtToken({commit}, jwtToken) {
    commit('jwtToken', jwtToken);
  },
  setRefreshToken({commit}, refreshToken) {
    commit('refreshToken', refreshToken);
  },
  resetJwtToken({commit}) {
    commit('jwtToken', null);
    commit('refreshToken', null);
  }
};

// mutations
const mutations = {
  login(state, payload) {
    state.login = payload;
  },
  jwtToken(state, payload) {
    state.jwtToken = payload;
  },
  refreshToken(state, payload) {
    state.refreshToken = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
