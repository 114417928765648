<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      :back="{name: 'editEdlInfo', params: {localId}}"
      back-title="Modifier les infos"
    >
      <template #global-actions>
        <f7-input
          type="select"
          :value="locale"
          @change="switchLanguage"
          @focus="showFullLanguageLabel = true"
          @blur="showFullLanguageLabel = false"
        >
          <option
            v-for="lang in availableLanguages"
            :key="'lang_'+lang.locale"
            :value="lang.locale"
          >
            {{ dynamicLanguageLabel(lang) }}
          </option>
        </f7-input>
        <f7-link
          tooltip="Basculer le mode permettant l'ajout de nouveaux éléments aux listes"
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="toggleAddingEnabled"
        >
          <i
            v-if="addingEnabled"
            class="f7-icons"
          >plus_circle_fill</i>
          <i
            v-else
            class="f7-icons"
          >plus_circle</i>
        </f7-link>
        <f7-link
          tooltip="Basculer le mode permettant le déplacement des éléments des listes"
          sortable-toggle=".sortable"
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="toggleMovingEnabled"
        >
          <i
            v-if="movingEnabled"
            class="f7-icons"
          >arrow_up_arrow_down_circle_fill</i>
          <i
            v-else
            class="f7-icons"
          >arrow_up_arrow_down_circle</i>
        </f7-link>
        <f7-link
          tooltip="Passer en mode immersif"
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="toggleImmersiveMode"
        >
          <i
            class="f7-icons"
          >eye</i>
        </f7-link>
      </template>
    </navigation-top>
    <navigation-bottom current="current" />

    <f7-page-content :class="'nbcols-'+nbCols">
      <edl-edit-breadcrumb-navigation
        :current-module="currentModule"
        :current-entry="currentEntry"
        :current-sub-entry="currentSubEntry"
        :is-module-selected="isModuleSelected"
        @back="breadcrumbNavigationGoBack"
      />

      <edl-edit-adding
        :adding-enabled="addingEnabled"
        :is-module-selected="isModuleSelected"
        :is-entry-selected="isEntrySelected"
        :is-sub-entry-selected="isSubEntrySelected"
        :has-sub-entries="hasSubEntries"
        :nb-cols="nbCols"
        @toggle="toggleAddingEnabled"
        @add-module="addModule"
        @add-entry="addEntry"
        @add-rate="addRate"
      />

      <f7-row class="" />

      <f7-row
        ref="mainView"
        class="extendable row-opaque-bg"
      >
        <!-- MODULE COLUMN -->
        <f7-col v-if="edl">
          <edl-edit-list-module
            :edl="edl"
            :current="current"
            @unopened="handleMarkAsUnopened"
            @select="select"
            @sort="sortModule"
            @rename="renameModule"
            @delete="deleteModule"
          />
        </f7-col>

        <!-- ENTRY COLUMN -->
        <f7-col v-if="isModuleSelected && currentModule">
          <edl-edit-list-entry
            :current-module="currentModule"
            :current="current"
            :cant-rename="cantRename"
            :cant-delete="cantDelete"
            :readonly="readonly"
            :current-photos-entry="currentPhotosEntry"
            :context-gallery="contextGallery"
            @unopened="handleMarkAsUnopened"
            @select="select"
            @sort="sortEntry"
            @rename="renameEntry"
            @delete="deleteEntry"
            @add-new-photos="addNewPhotos"
          />
          <input
            ref="photoInput"
            class="file-input"
            type="file"
            accept="image/*"
            multiple
            :capture="forceCameraCapture"
            @change="addNewPhotos"
          >
        </f7-col>

        <!-- SUB ENTRY COLUMN: Photos, SubEntries or Custom SubEntries -->
        <f7-col v-if="isEntrySelected && hasSubEntries && currentEntry">

          <!-- SUB ENTRY COLUMN: Photos -->
          <edl-edit-list-sub-entry-photos
            v-if="currentEntryIsPhotos"
            :current-entry="currentEntry"
            :current="current"
            :gallery="gallery"
            :adding-photos="addingPhotos"
            :opening-file-selector="openingFileSelector"
            @select="select"
            @sort="sortPhoto"
            @delete="deletePhoto"
            @delete-all="deleteAllFromPhotosEntry"
            @open-photo-input="openPhotoInput"
          />

          <!-- SUB ENTRY COLUMN: Custom SubEntries -->
          <edl-edit-list-sub-entry-custom
            v-else-if="canHaveCustomSubEntries(currentEntry)"
            :current-entry="currentEntry"
            :current="current"
            :current-module="currentModule"
            :context-gallery="contextGallery"
            :cant-rename="cantRename"
            :cant-delete="cantDelete"
            @select="select"
            @sort="sortSubEntry"
            @rename="renameSubEntry"
            @delete="deleteSubEntry"
            @unopened="handleMarkAsUnopened"
            @add-custom-sub-entry="addCustomSubEntry"
          />

          <!-- SUB ENTRY COLUMN: SubEntries -->
          <edl-edit-list-sub-entry
            v-else
            :current-entry="currentEntry"
            :current="current"
            :current-module="currentModule"
            :context-gallery="contextGallery"
            :cant-rename="cantRename"
            :cant-delete="cantDelete"
            @select="select"
            @sort="sortSubEntry"
            @rename="renameSubEntry"
            @delete="deleteSubEntry"
            @unopened="handleMarkAsUnopened"
          />

        </f7-col>

        <!-- RATE COLUMNS in Entry or SubEntry -->
        <!-- RATE COLUMNS: SubEntry rates -->
        <f7-col
          v-if="isSubEntrySelected && nbCols === 4 && currentSubEntry"
          class="rate-section"
        >
          <edl-edit-rate
            :entry="currentSubEntry"
            :parent="currentEntry"
            :rate-info="currentEntry.rateInfo"
            :context-gallery="currentContextGallery"
            :photo-context="currentPhotoContext"
            :manual-costing="isManualCosting"
            :has-convention="hasConvention"
            :calibration="calibration"
            :calibration-config="calibrationConfig"
            :auto-fill-observations="currentAutoFillObservations"
            :adding-photos="addingPhotos"
            :readonly="readonly.includes(currentSubEntry.name)"
            @update="updateRate"
            @add-photo="openPhotoInput"
            @add-rate-info="addRateInfo"
          />
        </f7-col>

        <!-- RATE COLUMNS: Entry rates -->
        <f7-col
          v-else-if="isEntrySelected && !hasSubEntries && nbCols === 3 && currentEntry"
          class="rate-section"
        >
          <edl-edit-rate
            :entry="currentEntry"
            :rate-info="currentEntry.rateInfo"
            :context-gallery="currentContextGallery"
            :photo-context="currentPhotoContext"
            :manual-costing="isManualCosting"
            :has-convention="hasConvention"
            :calibration="calibration"
            :calibration-config="calibrationConfig"
            :auto-fill-observations="currentAutoFillObservations"
            :adding-photos="addingPhotos"
            :readonly="readonly.includes(currentEntry.name)"
            @update="updateRate"
            @add-photo="openPhotoInput"
            @add-rate-info="addRateInfo"
          />
        </f7-col>
      </f7-row>

      <edl-edit-comment
        :info="info"
        @update="updateComment"
      />

      <edl-submit-row
        name="Signer et clôturer cet EDL"
        @submit="submit"
      />
    </f7-page-content>

    <edl-edit-immersive
      :opened="immersiveMode"
      :title="title"
      :info="info"
      :current="current"
      :nb-cols="nbCols"
      :current-module="currentModule"
      :previous-module="previousModule"
      :next-module="nextModule"
      :current-entry="currentEntry"
      :previous-entry="previousEntry"
      :next-entry="nextEntry"
      :current-sub-entry="currentSubEntry"
      :previous-sub-entry="previousSubEntry"
      :next-sub-entry="nextSubEntry"
      :current-entry-is-photos="currentEntryIsPhotos"
      :current-photo-context="currentPhotoContext"
      :current-photos-entry="currentPhotosEntry"
      :gallery="gallery"
      :context-gallery="currentContextGallery"
      :opening-file-selector="openingFileSelector"
      :current-auto-fill-observations="currentAutoFillObservations"
      :is-module-selected="isModuleSelected"
      :is-entry-selected="isEntrySelected"
      :is-sub-entry-selected="isSubEntrySelected"
      :has-sub-entries="hasSubEntries"
      :has-convention="hasConvention"
      :calibration="calibration"
      :calibration-config="calibrationConfig"
      :adding-photos="addingPhotos"
      :readonly="currentEntry !== null && readonly.includes(currentEntry.name)"
      :cant-delete="cantDelete"
      :cant-rename="cantRename"
      @init="initCurrent"
      @next="goToNextRate"
      @previous="goToPreviousRate"
      @next-module="goToNextModule"
      @previous-module="goToPreviousModule"
      @next-entry="goToNextEntry"
      @previous-entry="goToPreviousEntry"
      @next-sub-entry="goToNextSubEntry"
      @previous-sub-entry="goToPreviousSubEntry"
      @module-section="goToModuleSection"
      @entry-section="goToEntrySection"
      @close="toggleImmersiveMode"
      @update="updateRate"
      @add-photo="openPhotoInput"
      @add-rate-info="addRateInfo"
      @switch-language="switchLanguage"
      @delete-module="deleteModule"
      @delete-entry="deleteEntry"
      @delete-sub-entry="deleteSubEntry"
      @delete-all-photos-from-entry="deleteAllFromPhotosEntry"
      @rename-module="renameModule"
      @rename-entry="renameEntry"
      @rename-sub-entry="renameSubEntry"
      @select="select"
      @unopened="handleMarkAsUnopened"
      @open-photo-input="openPhotoInput"
      @add-custom-sub-entry="addCustomSubEntry"
    />
  </f7-page>
</template>

<script>
import firewall from "../../../mixins/firewall";
import NavigationTop from "../../../layout/NavigationTop.vue";
import NavigationBottom from "../../../layout/NavigationBottom.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import edlDataBuilder from "@/data/edlDataBuilder";
import EdlEditRate from "./EdlEditRate/EdlEditRate.vue";
import {getObservations} from "@/data/edlDataStructureAutoFillObservation";
import _ from "lodash";
import arrayMove from "array-move";
import {convertFileToBase64, PhotoDatabase, resizeBase64} from "@/composables/photoStorage";
import hasMedia from "@/components/mixins/hasMedia";
import {build, canHaveCustomSubEntries, getEntryVariableFromName} from "@/data/edlDataStructureModuleEntry";
import {getTypeRateForEntry, typeRateVariables} from "@/data/edlDataStructureModuleEntryRate";
import {getNewEdlDetails} from "@/data/edlInfoBuilder";
import hasConvention from "@/components/mixins/hasConvention";
import {unobserve} from "@/store/helper";
import {f7} from "framework7-vue";
import {fillIds, presetCleaning, presetCondition} from "@/composables/prepare";
import languageSelect from "@/components/mixins/language";
import EdlEditBreadcrumbNavigation from "@/components/pages/Edl/EdlEdit/EdlEditBreadcrumbNavigation.vue";
import EdlEditAdding from "@/components/pages/Edl/EdlEdit/EdlEditAdding.vue";
import EdlSubmitRow from "@/components/pages/Edl/EdlSubmitRow.vue";
import EdlEditComment from "@/components/pages/Edl/EdlEdit/EdlEditComment.vue";
import EdlEditImmersive from "@/components/pages/Edl/EdlEdit/EdlEditImmersive/EdlEditImmersive.vue";
import EdlEditListModule from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListModule.vue";
import EdlEditListEntry from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListEntry.vue";
import edlEditList from "@/components/mixins/edlEditList";
import EdlEditListSubEntryPhotos from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListSubEntryPhotos.vue";
import EdlEditListSubEntryCustom from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListSubEntryCustom.vue";
import EdlEditListSubEntry from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListSubEntry.vue";

const photoDb = new PhotoDatabase();

export default {
  name: "EdlEdit",
  components: {
    EdlEditListSubEntry,
    EdlEditListSubEntryCustom,
    EdlEditListSubEntryPhotos,
    EdlEditListEntry,
    EdlEditListModule,
    EdlEditImmersive,
    EdlEditComment,
    EdlSubmitRow,
    EdlEditAdding,
    EdlEditBreadcrumbNavigation,
    EdlEditRate,
    NavigationTop,
    NavigationBottom
  },
  mixins: [firewall, hasMedia, hasConvention, languageSelect, edlEditList],
  props: {
    localId: {
      type: String,
      default: null
    },
    f7router: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      info: null,
      edl: null,
      current: {
        module: null,
        entry: null,
        subEntry: null
      },
      immersiveMode: false,
      gallery: [],
      openingFileSelector: false,
      addingPhotos: 0,
      newPhotoContext: null,
      forceCameraCapture: null,
      addingEnabled: false,
      movingEnabled: false,
      renamingEnabled: false,
      calibration: null,
      //Special entries:
      cantRename: ['Photos', 'photos', 'Total des clés', 'total des clés'],
      cantDelete: ['Photos'],
      readonly: ['Total des clés'],
    };
  },
  computed: {
    ...mapState('edlPersist', [
      'localEdls'
    ]),
    ...mapGetters('edlPersist', [
      'getCurrentLocalEdl'
    ]),
    ...mapGetters('costing', [
      'getCalibrationByUserId',
      'calibrationConfig'
    ]),
    title: function () {
      let title = 'Edition EDL';
      if(this.info) {
        title += ' '+this.info.edlIO;
        if(this.info.duplicateFromEdlID) {
          title += ' dupliqué de l\'EDL #'+this.info.duplicateFromEdlID;
        }
      }
      return title;
    },
    currentPath: function () {
      let path = [];
      if(this.isModuleSelected) {
        path.push(this.current.module);
      }
      if(this.isEntrySelected) {
        path.push('entry');
        path.push(this.current.entry);
      }
      if(this.isSubEntrySelected) {
        path.push('content');
        path.push(this.current.subEntry);
      }

      return path;
    },
    nbCols: function () {
      switch(this.currentPath.length) {
        case 0:
          return 1;
        case 1:
          return 2;
        case 2:
        case 3:
          return 3;
        case 4:
        case 5:
        default:
          return 4;
      }
    },
    isModuleSelected: function () {
      return this.current.module !== null;
    },
    currentPathForModule: function () {
      if(!this.isModuleSelected) {
        return null;
      }
      let path = unobserve(this.currentPath);
      return path.slice(0,1);
    },
    previousPathForModule: function () {
      let path = unobserve(this.currentPathForModule);
      if (path === null) {
        return null;
      }
      path[path.length-1] = path[path.length-1] > 0 ? path[path.length-1] - 1 : null;
      return path;
    },
    nextPathForModule: function () {
      let path = unobserve(this.currentPathForModule);
      if (path === null) {
        return null;
      }
      path[path.length-1] = path[path.length-1] + 1;
      return path;
    },
    currentModule: function () {
      if(!this.isModuleSelected) {
        return null;
      }
      return _.get(this.edl, this.currentPathForModule);
    },
    previousModule: function () {
      let previousPath = this.previousPathForModule;
      if (previousPath === null || previousPath[previousPath.length - 1] === null) {
        return null;
      }
      return _.get(this.edl, previousPath, null);
    },
    nextModule: function () {
      let nextPath = this.nextPathForModule;
      if (nextPath === null) {
        return null;
      }
      return _.get(this.edl, nextPath, null);
    },
    isEntrySelected: function () {
      return this.current.entry !== null;
    },
    currentPathForEntry: function () {
      if(!this.isEntrySelected) {
        return null;
      }
      let path = unobserve(this.currentPath);
      return path.slice(0,3);
    },
    previousPathForEntry: function () {
      let path = unobserve(this.currentPathForEntry);
      if (path === null) {
        return null;
      }
      path[path.length-1] = path[path.length-1] > 0 ? path[path.length-1] - 1 : null;
      return path;
    },
    nextPathForEntry: function () {
      let path = unobserve(this.currentPathForEntry);
      if (path === null) {
        return null;
      }
      path[path.length-1] = path[path.length-1] + 1;
      return path;
    },
    currentEntry: function () {
      if(!this.isEntrySelected) {
        return null;
      }
      return _.get(this.edl, this.currentPathForEntry);
    },
    previousEntry: function () {
      let previousPath = this.previousPathForEntry;
      if (previousPath === null || previousPath[previousPath.length - 1] === null) {
        return null;
      }
      return _.get(this.edl, previousPath, null);
    },
    nextEntry: function () {
      let nextPath = this.nextPathForEntry;
      if (nextPath === null) {
        return null;
      }
      return _.get(this.edl, nextPath, null);
    },
    isSubEntrySelected: function () {
      return this.current.subEntry !== null;
    },
    hasSubEntries: function () {
      if(!this.isEntrySelected) {
        return false;
      }
      return this.currentEntry.content !== undefined;
    },
    currentEntryIsPhotos: function () {
      return this.currentEntry && this.currentEntry.name === "Photos";
    },
    currentPathForSubEntry: function () {
      if(!this.isSubEntrySelected) {
        return null;
      }
      return this.currentPath
    },
    previousPathForSubEntry: function () {
      let path = unobserve(this.currentPathForSubEntry);
      if (path === null) {
        return null;
      }
      path[path.length-1] = path[path.length-1] > 0 ? path[path.length-1] - 1 : null;
      return path;
    },
    nextPathForSubEntry: function () {
      let path = unobserve(this.currentPathForSubEntry);
      if (path === null) {
        return null;
      }
      path[path.length-1] = path[path.length-1] + 1;
      return path;
    },
    currentSubEntry: function () {
      if(!this.isSubEntrySelected) {
        return null;
      }
      return _.get(this.edl, this.currentPathForSubEntry);
    },
    previousSubEntry: function () {
      let previousPath = this.previousPathForSubEntry;
      if (previousPath === null || previousPath[previousPath.length - 1] === null) {
        return null;
      }
      return _.get(this.edl, previousPath, null);
    },
    nextSubEntry: function () {
      let nextPath = this.nextPathForSubEntry;
      if (nextPath === null) {
        return null;
      }
      return _.get(this.edl, nextPath, null);
    },
    currentAutoFillObservations: function () {
      let entry = null;
      if(this.currentSubEntry) {
        entry = this.currentSubEntry;
      } else if (this.currentEntry) {
        entry = this.currentEntry;
      }

      let observations = null;
      if(entry !== null) {
        //Try direct match through entry config
        if (entry.autoFillObservation !== undefined && _.isString(entry.autoFillObservation)) {
          observations = getObservations(entry.autoFillObservation, this.locale);
        }
        //If no direct match, try using entry name to handle legacy cases
        if (!observations) {
          observations = getObservations(entry.name, this.locale, true); //use default if not found
        }
      }
      return observations;
    },
    currentPhotoContext: function () {
      if (this.currentModule === null || this.currentEntry === null) {
        return null;
      }

      let contextIds =
        this.currentModule.id
        +"."
        + this.currentEntry.id;

      if(this.currentSubEntry) {
        contextIds += "."+this.currentSubEntry.id;
      }

      return contextIds;
    },
    currentPathForPhotosEntry: function () {
      if (this.currentModule === null) {
        return null;
      }
      let photosEntryIndex = _.findIndex(this.currentModule.entry, (entry) => {
        return entry.name === "Photos";
      });
      if (photosEntryIndex === undefined) {
        return null;
      } else {
        return [this.current.module, 'entry', photosEntryIndex];
      }
    },
    currentPhotosEntry: function () {
      if(!this.currentPathForPhotosEntry) {
        return null;
      }
      return _.get(this.edl, this.currentPathForPhotosEntry);
    },
    contextGallery: function () {
      return this.currentPhotosEntry ? _.map(this.gallery, (data, index) => {
        let contextPhoto = {data};
        let photo = this.currentPhotosEntry.content[index];
        if(photo !== null && photo !== undefined
          && photo.rate !== undefined && photo.rate.photo !== null && photo.rate.photo !== undefined) {
          contextPhoto.rate = photo.rate;
          if(photo.rate.photo._context !== undefined) {
            contextPhoto.context = photo.rate.photo._context;
          }
        }
        return contextPhoto;
      }): null;
    },
    currentContextGallery: function () {
      return this.contextGallery ? _.filter(this.contextGallery, (photo) => {
        return photo.context !== undefined && photo.context === this.currentPhotoContext;
      }): null;
    },
    isManualCosting: function () {
      return this.info.conventionSortieManuelle !== undefined && this.info.conventionSortieManuelle;
    },
  },
  watch: {
    edl: {
      handler: 'updateEdl',
      deep: true
    },
    info: {
      handler: 'updateInfo',
      deep: true
    },
    currentModule: function () {
      if(this.currentPhotosEntry) {
        this.loadGallery(this.currentPhotosEntry);
      } else {
        this.gallery = [];
      }
    },
    'current.module': function (after, before) {
      if(after !== before) {
        this.handleMarkAsOpened('module');
      }
    },
    'current.entry': function (after, before) {
      if(after !== before) {
        this.handleMarkAsOpened('entry');
      }
    },
    'current.subEntry': function (after, before) {
      if(after !== before) {
        this.handleMarkAsOpened('subEntry');
      }
    },
  },
  mounted: function () {
    let localId = +this.localId; //converts to number
    this.setCurrentLocalEdlId(localId);
    this.setup();
  },
  methods: {
    ...mapActions('edlPersist', [
      'setCurrentLocalEdlId',
      'updateCurrentLocalEdlData',
      'updateCurrentLocalEdlInfo'
    ]),
    ...mapActions('costing', [
      'fetchUserCalibration',
      'fetchCalibrationConfig'
    ]),
    setup: function () {
      if(this.getCurrentLocalEdl.edl === null) { //New
        this.info = this.getCurrentLocalEdl;
        this.info = _.omit(this.info, ['edl']);
        let details = getNewEdlDetails(this.info);
        this.edl = fillIds(edlDataBuilder.newEdlData(details));
        if(this.info.bien.etat !== undefined) {
          this.edl = presetCondition(unobserve(this.edl), this.info.bien.etat);
        }
        this.edl = presetCleaning(unobserve(this.edl), 'Propre');
        this.updateEdl();
      } else { //Duplicate
        let edl = unobserve(this.getCurrentLocalEdl);
        this.edl = edl.edl;
        this.info = _.omit(edl, ['edl']);
      }

      this.setupPreQuotes();
    },
    setupPreQuotes: function () {
      if(!this.isOnline && (this.getCalibrationByUserId(this.me.id) === null || this.calibrationConfig === null)) {
        //Warning removed because useless
        //this.throwError('Le pré-chiffrage ne sera pas disponible car vous êtes déconnectés et que celui-ci n\'a pas été chargé correctement');
      } else if (this.isOnline && (this.getCalibrationByUserId(this.me.id) === null || this.calibrationConfig === null)){
        this.fetchUserCalibration({userId: this.me.id});
        this.fetchCalibrationConfig({});
      }
      this.calibration = unobserve(this.getCalibrationByUserId(this.me.id));
    },
    initCurrent: function () {
      if (this.current.module === null) {
        this.current.module = 0;
      }
    },
    goToNextRate: function () {
      if (this.hasSubEntries) {
        this.goToNextSubEntry();
      } else {
        this.goToNextEntry();
      }
    },
    goToPreviousRate: function () {
      if (this.hasSubEntries) {
        this.goToPreviousSubEntry();
      } else {
        this.goToPreviousEntry();
      }
    },
    goToNextSubEntry: function () {
      let nextKey = this.current.subEntry !== null ? this.current.subEntry + 1 : 0;
      if(this.currentEntry.content[nextKey] !== undefined && !this.currentEntryIsPhotos) {
        this.current.subEntry = nextKey;
      } else {
        this.goToNextEntry();
      }
    },
    goToNextEntry: function () {
      if(this.current.subEntry !== null) {
        this.current.subEntry = null;
      }
      let nextKey = this.current.entry !== null ? this.current.entry + 1 : 0;
      if(this.currentModule.entry[nextKey] !== undefined) {
        this.current.entry = nextKey;
        if(this.hasSubEntries) {
          this.current.subEntry = null;
        }
      } else {
        this.goToNextModule();
      }
    },
    goToNextModule: function () {
      let nextKey = this.current.module !== null ? this.current.module + 1 : 0;
      if(this.edl[nextKey] !== undefined) {
        this.current.module = nextKey;
        this.current.entry = null;
        this.current.subEntry = null;
      } else {
        this.toggleImmersiveMode();
      }
    },
    goToPreviousSubEntry: function () {
      let previousKey = this.current.subEntry - 1;
      if(this.current.subEntry === null) {
        this.goToPreviousEntry();
      } else if(previousKey >= 0 && this.currentEntry.content[previousKey] !== undefined) {
        this.current.subEntry--;
      } else {
        this.current.subEntry = null;
      }
    },
    goToPreviousEntry: function () {
      let previousKey = this.current.entry - 1;
      if (this.current.entry === null) {
        this.goToPreviousModule();
      } else if(previousKey >= 0 && this.currentModule.entry[previousKey] !== undefined) {
        this.current.entry--;
        if(this.currentEntryIsPhotos) {
          this.current.subEntry = null;
        } else {
          this.current.subEntry = this.hasSubEntries && this.currentEntry.content.length > 0 ? this.currentEntry.content.length - 1 : null;
        }
      } else {
        this.current.entry = null;
      }
    },
    goToPreviousModule: function () {
      let previousKey = this.current.module - 1;
      if(previousKey >= 0 && this.edl[previousKey] !== undefined) {
        this.current.module--;
        this.current.entry = this.currentModule.entry.length > 0 ? this.currentModule.entry.length - 1 : null;
        if(this.currentEntryIsPhotos) {
          this.current.subEntry = null;
        } else {
          this.current.subEntry = this.hasSubEntries && this.currentEntry.content.length > 0 ? this.currentEntry.content.length - 1 : null;
        }
      } else {
        this.toggleImmersiveMode();
      }
    },
    goToModuleSection: function () {
      this.current.entry = null;
      this.current.subEntry = null;
    },
    goToEntrySection: function () {
      this.current.subEntry = null;
    },
    updateAtPath: function (path, value) {
      this.edl = _.set(unobserve(this.edl), path, value);
    },
    updateEdl: function () {
      this.updateCurrentLocalEdlData(this.edl);
    },
    updateInfo: function () {
      this.updateCurrentLocalEdlInfo(this.info);
    },
    updateRate: function ({rate, value}) {
      let ratePath = this.getCurrentPathForRate(rate);
      this.updateAtPath(ratePath, value);

      //Handle key totals (Remise des clés)
      if(rate === 'number' && this.currentEntry.name === 'Remise des clés') {
        this.updateKeysTotal(ratePath);
      }
    },
    updateComment: _.debounce(function (value) {
      this.info = _.set(unobserve(this.info), 'comment', value);
    }, 300),
    updateKeysTotal: function(ratePath) {
      let total = 0;
      let totalIndex = null;

      //Calculate total and get total keys index
      _.forEach(this.currentEntry.content, (subEntry, subEntryIndex) => {
        if(subEntry.name === 'Total des clés') {
          totalIndex = subEntryIndex;
        } else if (subEntry.rate.number !== null && subEntry.rate.number !== '') {
          total += parseInt(subEntry.rate.number);
        }
      });

      //Given a path to rate of key numbers, replace total keys index in path
      ratePath[ratePath.length - 3] = totalIndex;
      this.updateAtPath(ratePath, total.toString());
    },
    select: function (type, index) {
      switch(type) {
        case 'module':
          this.current.module = index;
          this.current.entry = this.current.subEntry = null;
          break;
        case 'entry':
          this.current.entry = index;
          this.current.subEntry = null;
          break;
        case 'subEntry':
          this.current.subEntry = index;
          break;
      }
      this.scrollToLastColumn();

      //Now handled via watch
      // this.$nextTick(() => {
      //   this.handleMarkAsOpened(type);
      // });
    },
    toggleImmersiveMode: function () {
      this.immersiveMode = !this.immersiveMode;
    },
    handleMarkAsOpened: function (type) {
      let current = null;
      let path = null;
      let checkAllOpened = null;
      if(type === 'subEntry' && this.currentSubEntry !== null) {
        current = unobserve(this.currentSubEntry);
        path = this.currentPathForSubEntry;
        this.checkMarkAsOpened(current, path, checkAllOpened);
      }
      if((type === 'entry' || type === 'subEntry') && this.currentEntry !== null) {
        current = unobserve(this.currentEntry);
        path = this.currentPathForEntry;
        checkAllOpened = current.content;
        this.checkMarkAsOpened(current, path, checkAllOpened);
      }
      if((type === 'module' || type === 'entry' || type === 'subEntry') && this.currentModule !== null) {
        current = unobserve(this.currentModule);
        path = this.currentPathForModule;
        checkAllOpened = current.entry;
        this.checkMarkAsOpened(current, path, checkAllOpened);
      }
    },
    handleMarkAsUnopened: function (type, index) {
      let element, path, parentPath = null;
      switch(type) {
        case 'module':
          element = this.edl[index];
          path = [index];
          break;
        case 'entry':
          element = this.currentModule.entry[index];
          parentPath = this.currentPathForModule;
          path = unobserve(parentPath);
          path.push('entry');
          path.push(index);
          break;
        case 'subEntry':
          element = this.currentEntry.content[index];
          parentPath = this.currentPathForEntry;
          path = unobserve(parentPath);
          path.push('content');
          path.push(index);
          break;
        default:
          console.error('Unhandled type for markAsUnopened:', type);
          break;
      }

      this.doMarkAsUnopened(element, path);
      if(parentPath) {
        this.checkMarkParentsAsUnopened(parentPath);
      }
    },
    checkMarkAsOpened: function (current, path, checkAllOpened) {
      if(current && (current.opened === undefined || current.opened === false)) {
        let markOpened;
        if(current.name !== "Photos" && checkAllOpened !== null && checkAllOpened !== undefined && checkAllOpened.length > 0) {
          markOpened = _.every(checkAllOpened, (element) => {
            return element.opened !== undefined && element.opened === true;
          })
        } else {
          markOpened = true;
        }

        if(markOpened) {
          this.doMarkAsOpened(current, path);
        }
      }
    },
    checkMarkParentsAsUnopened: function (parentPath) {
      let parent = _.get(this.edl, parentPath);
      if(parent.opened === true) {
        this.doMarkAsUnopened(parent, parentPath);
        if(parentPath.length > 1) {
          this.checkMarkParentsAsUnopened(parentPath.slice(0, -2));
        }
      }
    },
    doMarkAsOpened: function (current, path) {
      let toMark = unobserve(current);
      toMark.opened = true;
      this.updateAtPath(path, toMark);
    },
    doMarkAsUnopened: function (element, path) {
      let toMark = unobserve(element);
      toMark.opened = false;
      this.updateAtPath(path, toMark);
    },
    scrollToLastColumn: function () {
      this.$nextTick(() => {
        let el = this.$refs.mainView.$el;
        const elLeft = el.offsetLeft + el.offsetWidth;
        const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

        if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
          setTimeout(() => {
            el.parentNode.scrollTo({
              top: 0,
              left: elLeft - elParentLeft,
              behavior: 'smooth'
            });
          }, 100);
        }
      });
    },
    getCurrentPathForRate: function (rate) {
      let path = unobserve(this.currentPath);
      path.push('rate');
      path.push(rate);
      return path;
    },
    openPhotoInput: function (withContext = false) {
      this.openingFileSelector = true;

      if (!f7.device.desktop && !f7.device.ios) {
        f7.dialog.create({
          title: 'Ajouter une photo depuis',
          buttons: [
            {
              text: "L'appareil photo",
              onClick: () => {
                this.forceCameraCapture = true;
                this.$nextTick(() => {
                  this.doOpenPhotoInput(withContext);
                });
              }
            },
            {
              text: "Votre bibliothèque",
              onClick: () => {
                this.forceCameraCapture = null;
                this.$nextTick(() => {
                  this.doOpenPhotoInput(withContext);
                });
              }
            }
          ],
          verticalButtons: true,
        }).open();
      } else { //on desktop or ios, we just open the file selector (ios handles both photo library and camera)
        this.doOpenPhotoInput(withContext);
      }
    },
    doOpenPhotoInput: function (withContext = false) {
      this.$refs.photoInput.click();
      this.newPhotoContext = withContext ? this.currentPhotoContext : null;
      setTimeout(() => {
        this.openingFileSelector = false;
      }, 2000);
    },
    addNewPhotos: function (event) {
      let files = event.target.files;
      this.addingPhotos = files.length;
      Array.from(files).forEach((file) => {
        convertFileToBase64(file).then((data) => {
          resizeBase64(data, 800, 800, file.type).then((resizedData) => {
            this.addNewPhotoToModule(resizedData, file.name);
            event.target.value = null;
          }).catch((error) => {
            this.handleAddPhotoError(error);
          });
        }).catch((error) => {
          this.handleAddPhotoError(error);
        });
      });
    },
    addNewPhotoToModule: function (data, filename) {
      let now = new Date;
      let ref = "#Upload:"+filename+":"+now.toISOString();
      let photoContext = this.newPhotoContext !== null ? unobserve(this.newPhotoContext) : null;
      photoDb.addPhoto(ref, this.info.localId, photoContext, data)
        .then((id) => {
          let photo = {
            _ref: ref,
            _data: '#IndexedDb:'+id
          };
          if(this.newPhotoContext !== null) {
            photo._context = photoContext;
          }
          let rate = {
            photo,
            'other': null
          };
          let content = {
            name: "Photo",
            opened: false,
            rate
          };
          let photosEntry = unobserve(this.currentPhotosEntry);
          photosEntry.content.push(content);
          this.updateAtPath(this.currentPathForPhotosEntry, photosEntry);
          this.loadGallery(this.currentPhotosEntry);
          this.addingPhotos--;
        })
        .catch((error) => {
          this.handleAddPhotoError(error);
        })
      ;
    },
    handleAddPhotoError: function (error) {
      //Enable being able to try again before showing error
      this.addingPhotos--;
      this.$refs.photoInput.value = null;
      throw error;
    },
    deletePhoto: function (subEntryIndex) {
      let subEntry = this.currentEntry.content[subEntryIndex];
      let photoId = this.getPhotoId(subEntry.rate.photo);
      photoDb.deletePhoto(photoId).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.deleteSubEntry(subEntryIndex);
      });
    },
    deleteAllFromPhotosEntry: function () {
      f7.dialog.confirm("Êtes-vous certain de vouloir supprimer toutes les photos de cette liste ?", () => {
        let toDelete = this.currentEntry.content.length;
        let removeWhenAllDeleted = () => {
          if(toDelete === 0) {
            let entryWithDelete = unobserve(this.currentEntry);
            entryWithDelete.content = [];
            this.updateAtPath(this.currentPathForEntry, entryWithDelete);
            this.current.subEntry = null;
          }
        };
        _.each(this.currentEntry.content, (subEntry) => {
          let photoId = this.getPhotoId(subEntry.rate.photo);
          photoDb.deletePhoto(photoId).catch((error) => {
            console.error(error);
          }).finally(() => {
            toDelete --;
            removeWhenAllDeleted();
          });
        });
      });
    },
    deleteModule: function (moduleIndex, currentDelete = false) {
      const doDelete = () => {
        let edl = unobserve(this.edl)
        _.pullAt(edl, [moduleIndex]);
        this.edl = edl;
      }

      if (currentDelete) {
        f7.dialog.confirm("Êtes-vous certain de vouloir supprimer '"+this.currentModule.name+"' ?", () => {
          doDelete();
          this.goToPreviousModule();
          this.goToNextModule();
        });
      } else {
        doDelete();
      }
    },
    deleteEntry: function (entryIndex, currentDelete = false) {
      const doDelete = () => {
        //Remove the context from photos linked to this entry. The photos are kept but not their reference to this entry
        this.removeContextForPhotos(entryIndex, 'entry');

        let moduleWithDelete = unobserve(this.currentModule);
        _.pullAt(moduleWithDelete.entry, [entryIndex]);
        this.updateAtPath(this.currentPathForModule, moduleWithDelete);
      }

      if (currentDelete) {
        f7.dialog.confirm("Êtes-vous certain de vouloir supprimer '"+this.currentEntry.name+"' ?", () => {
          doDelete();
          this.goToPreviousEntry();
          this.goToNextEntry();
        });
      } else {
        doDelete();
      }
    },
    deleteSubEntry: function (subEntryIndex, currentDelete = false) {
      const doDelete = () => {
        //Remove the context from photos linked to this subentry. The photos are kept but not their reference to this subentry
        this.removeContextForPhotos(subEntryIndex, 'subEntry');

        let entryWithDelete = unobserve(this.currentEntry);

        _.pullAt(entryWithDelete.content, [subEntryIndex]);
        this.updateAtPath(this.currentPathForEntry, entryWithDelete);

        //Handle key totals (Remise des clés)
        if(entryWithDelete.name === 'Remise des clés') {
          //Prepare path to rate (final total path will be constructed in updateKeysTotal)
          let ratePath = unobserve(this.currentPathForEntry);
          ratePath.push(...['content', null, 'rate', 'number']);
          this.updateKeysTotal(ratePath);
        }
      }

      if (currentDelete) {
        f7.dialog.confirm("Êtes-vous certain de vouloir supprimer '"+this.currentSubEntry.name+"' ?", () => {
          doDelete();
          this.goToPreviousSubEntry();
          this.goToNextSubEntry();
        });
      } else {
        doDelete();
      }
    },
    loadGallery: function (photosEntry) {
      let photoIndexedDbIds = _.map(photosEntry.content, (photo) => this.getPhotoId(photo.rate.photo));
      let photoPromises = photoIndexedDbIds.map((id) => {
        return id ? photoDb.getPhoto(id) : null;
      });
      _.compact(photoPromises);
      Promise.all(photoPromises).then((photos) => {
        this.gallery = photos.filter((photo) => photo !== null).map((photo) => photo.data);
      }).catch((error) => {
        console.error('loadGallery, getPhotos promises ko', error);
        throw error;
      });
    },
    removeContextForPhotos: function (entryIndex, type, parent = null) {
      console.log('removeContextForPhotos', entryIndex, type, parent);
      let entryContext = this.currentModule.id;
      if(type === 'entry') {
        let entry = this.currentModule.entry[entryIndex];
        entryContext += "." + entry.id;
      } else if(type === 'subEntry') {
        if(!parent) {
          parent = this.currentEntry;
        }
        let entry = parent.content[entryIndex];
        entryContext += "." + parent.id + "." + entry.id;
      } else {
        console.error('removeContextForPhotos, unknown type', type);
        return;
      }

      return _.each(this.currentPhotosEntry.content, (photoSubEntry, photoIndex) => {
        if(photoSubEntry?.rate?.photo?._context === entryContext) {
          let contextPhoto = unobserve(photoSubEntry);
          let photoPath = this.currentPathForPhotosEntry;
          photoPath.push('content');
          photoPath.push(photoIndex);
          contextPhoto.rate.photo = _.omit(contextPhoto.rate.photo, ['_context']);
          this.updateAtPath(photoPath, contextPhoto);
        }
      });
    },
    toggleMovingEnabled: function () {
      this.movingEnabled = !this.movingEnabled;
    },
    sortModule: function (sort) {
      this.current.module = this.current.entry = this.current.subEntry = null;
      this.$nextTick(() => {
        let edl = unobserve(this.edl);
        arrayMove.mutate(edl, sort.from, sort.to);
        this.edl = edl;
      });
    },
    sortEntry: function (sort) {
      this.current.entry = this.current.subEntry = null;
      this.$nextTick(() => {
        let moduleToSort = unobserve(this.currentModule);
        arrayMove.mutate(moduleToSort.entry, sort.from, sort.to);
        this.updateAtPath(this.currentPathForModule, moduleToSort);
      });
    },
    sortSubEntry: function (sort) {
      this.current.subEntry = null;
      this.$nextTick(() => {
        let entryToSort = unobserve(this.currentEntry);
        arrayMove.mutate(entryToSort.content, sort.from, sort.to);
        this.updateAtPath(this.currentPathForEntry, entryToSort);
      });
    },
    sortPhoto: function (sort) {
      this.current.subEntry = null;
      this.$nextTick(() => {
        let entryToSort = unobserve(this.currentEntry);
        //Handle the fact that there is an item before (open gallery) and after (add photo button)
        if(sort.to === 0) {
          sort.to = 1;
        } else if (sort.to === entryToSort.content.length + 1) {
          sort.to = entryToSort.content.length;
        }
        arrayMove.mutate(entryToSort.content, sort.from - 1, sort.to - 1);
        this.updateAtPath(this.currentPathForEntry, entryToSort);
      });
    },
    renameModule: function (moduleIndex) {
      let moduleToRename = this.edl[moduleIndex];
      f7.dialog.prompt('Choisissez un nouveau nom pour remplacer "'+moduleToRename.name+'" :', 'Renommer l\'élément', (result) => {
        let renamedModule = unobserve(moduleToRename);
        renamedModule.name = result;
        this.updateAtPath([moduleIndex], renamedModule);
      }, null, moduleToRename.name);
    },
    renameEntry: function (entryIndex) {
      let entryToRename = this.currentModule.entry[entryIndex];
      f7.dialog.prompt('Choisissez un nouveau nom pour remplacer "'+entryToRename.name+'" :', 'Renommer l\'élément', (result) => {
        if(this.cantRename.includes(result)) {
          f7.dialog.alert('Le nom "'+result+'" est réservé pour des fonctions particulières, vous ne pouvez pas le renommer ainsi car cela pourrait engendrer des bugs.');
        } else {
          let renamedEntry = unobserve(entryToRename);
          renamedEntry.name = result;
          let pathForEntry = unobserve(this.currentPathForModule);
          pathForEntry.push('entry');
          pathForEntry.push(entryIndex);
          this.updateAtPath(pathForEntry, renamedEntry);
        }
      }, null, entryToRename.name);
    },
    renameSubEntry: function (subEntryIndex) {
      let subEntryToRename = this.currentEntry.content[subEntryIndex];
      f7.dialog.prompt('Choisissez un nouveau nom pour remplacer "'+subEntryToRename.name+'" :', 'Renommer l\'élément', (result) => {
        if(this.cantRename.includes(result)) {
          f7.dialog.alert('Le nom "'+result+'" est réservé pour des fonctions particulières, vous ne pouvez pas le renommer ainsi car cela pourrait engendrer des bugs.');
        } else {
          let renamedSubEntry = unobserve(subEntryToRename);
          renamedSubEntry.name = result;
          let pathForSubEntry = unobserve(this.currentPathForEntry);
          pathForSubEntry.push('content');
          pathForSubEntry.push(subEntryIndex);
          this.updateAtPath(pathForSubEntry, renamedSubEntry);
        }
      }, null, subEntryToRename.name);
    },
    toggleAddingEnabled: function () {
      this.addingEnabled = !this.addingEnabled;
    },
    addModule: function (moduleToAdd) {
      let newModule = fillIds(moduleToAdd);
      let highestId = this.edl.reduce((max, current) => {
        return current.id !== null && current.id > max ? current.id : max;
      }, 0);
      newModule.id = highestId+1;
      this.updateAtPath([this.edl.length], newModule);
      this.$forceUpdate();
    },
    addEntry: function (entryToAdd) {
      //Order subentries alphabetically if configured
      if(entryToAdd.content !== undefined) {
        if(entryToAdd.orderAlphabetically !== undefined && entryToAdd.orderAlphabetically === true) {
          entryToAdd.content.sort((a, b) => a.name.localeCompare(b.name));
        }
      }

      let newEntry = fillIds(build(entryToAdd));

      let highestId = this.currentModule.entry.reduce((max, current) => {
        return current.id !== null && current.id > max ? current.id : max;
      }, 0);
      newEntry.id = highestId+1;
      let path = unobserve(this.currentPathForModule);
      path.push('entry');
      path.push(this.currentModule.entry.length);
      this.updateAtPath(path, newEntry);
      this.$forceUpdate();
    },
    canHaveCustomSubEntries: function (entry) {
      return canHaveCustomSubEntries(entry);
    },
    addCustomSubEntry: function () {
      f7.dialog.prompt('Nom de l\'élément', 'Ajouter', (value) => {
        if(this.cantRename.includes(value)) {
          f7.dialog.alert('Le nom "'+value+'" est réservé pour des fonctions particulières, vous ne pouvez pas le nommer ainsi car cela pourrait engendrer des bugs.');
        } else {
          let subEntryToAdd = {
            name: value,
            rate: {}
          };
          //Add entry's base rates to new subEntry
          if (this.currentEntry.rateInfo !== undefined) {
            for (let rateName in this.currentEntry.rateInfo) {
              subEntryToAdd.rate[rateName] = null;
            }
          }
          let highestId = this.currentEntry.content.reduce((max, current) => {
            return current.id !== null && current.id > max ? current.id : max;
          }, 0);
          subEntryToAdd.id = highestId + 1;
          let path = unobserve(this.currentPathForEntry);
          path.push('content');
          path.push(this.currentEntry.content.length);
          this.updateAtPath(path, subEntryToAdd);
          this.$forceUpdate();
        }
      });
    },
    addRate: function (rateToAdd) {
      let entry = unobserve(this.currentEntry);
      let subEntry = this.isSubEntrySelected ? unobserve(this.currentSubEntry) : null;
      let rateName = rateToAdd.name;

      //Case typeCustom => create custom name (to not share values)
      if(rateToAdd.customName === true) {
        if(subEntry) {
          let subEntryVariable = getEntryVariableFromName(subEntry.name);
          if(subEntryVariable !== undefined) { //case custom entries
            rateName = rateName.replace('$', _.upperFirst(subEntryVariable));
          } else {
            rateName = rateName.replace('$', _.upperFirst(_.camelCase(subEntry.name)));
          }
        } else {
          let entryVariable = getEntryVariableFromName(entry.name);
          if(entryVariable !== undefined) { //case custom entries
            rateName = rateName.replace('$', _.upperFirst(entryVariable));
          } else {
            rateName = rateName.replace('$', _.upperFirst(_.camelCase(entry.name)));
          }
        }
      }

      //Add the rate and the rateInfo to the entry/subentry
      if(subEntry) {
        entry.rateInfo[rateName] = rateToAdd.value;
        this.updateAtPath(this.currentPathForEntry, entry);
        subEntry.rate[rateName] = null;
        this.updateAtPath(this.currentPathForSubEntry, subEntry);
      } else {
        entry.rate[rateName] = null;
        entry.rateInfo[rateName] = rateToAdd.value;
        this.updateAtPath(this.currentPathForEntry, entry);
      }
      this.$forceUpdate();
    },
    addRateInfo: function ({rateName, value}) {
      let rateInfoName = rateName;

      if(this.isSubEntrySelected) {
        let entry = unobserve(this.currentEntry);
        let subEntry = unobserve(this.currentSubEntry);
        if(typeRateVariables.includes(rateName)) {
          const fullTypeRateName = getTypeRateForEntry(entry.name, subEntry.name)?.name;
          if(fullTypeRateName !== undefined && entry.rateInfo[fullTypeRateName] !== undefined) {
            rateInfoName = fullTypeRateName;
          }
        }

        entry.rateInfo[rateInfoName].push(value);
        this.updateAtPath(this.currentPathForEntry, entry);
        subEntry.rate[rateName] = value;
        this.updateAtPath(this.currentPathForSubEntry, subEntry);
      } else {
        let entry = unobserve(this.currentEntry);
        if(typeRateVariables.includes(rateName)) {
          const fullTypeRateName = getTypeRateForEntry(entry.name)?.name;
          if(fullTypeRateName !== undefined && entry.rateInfo[fullTypeRateName] !== undefined) {
            rateInfoName = fullTypeRateName;
          }
        }

        entry.rateInfo[rateInfoName].push(value);
        entry.rate[rateName] = value;
        this.updateAtPath(this.currentPathForEntry, entry);
      }
    },
    switchLanguage: function (event) {
      this.info.locale = event.target.value !== "" ? event.target.value : null;
      this.updateInfo();
      this.showFullLanguageLabel = false;
    },
    submit: function () {
      if (this.info.edlIO === "Sortant" && (this.info.renter.sortantAddr === undefined || this.info.renter.sortantAddr === "")) {
        f7.dialog.create({
          title: 'Vous n’avez pas indiqué de nouvelle adresse postale pour le locataire sortant',
          buttons: [
            {
              text: "Finaliser quand même l'EDL",
              onClick: this.goToRecap
            },
            {
              text: "Renseigner l'adresse",
              bold: true,
              onClick: () => {
                this.f7router.navigate({name: 'editEdlInfo', params: {localId: this.localId}});
              }
            }
          ],
          verticalButtons: true,
        }).open();
      } else {
        this.goToRecap();
      }
    },
    goToRecap: function () {
      if (this.hasConvention) {
        this.f7router.navigate({name: 'edlRecapConvention', params: {'localId': this.localId}}, {reloadAll: true});
      } else {
        this.f7router.navigate({name: 'edlRecap', params: {'localId': this.localId}}, {reloadAll: true});
      }
    },
    breadcrumbNavigationGoBack: function (to = null) {
      switch(to) {
        case 'module':
          this.current.subEntry = this.current.entry = null;
          break;
        case 'entry':
          this.current.subEntry = null;
          break;
        case 'subEntry':
          //do nothing
          break;
        default:
          if(this.current.subEntry !== null) {
            this.current.subEntry = null;
          } else if(this.currentEntry !== null) {
            this.current.entry = null;
          } else if(this.currentModule !== null) {
            this.current.module = null;
          }
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
li.selected {
  background-color: var(--f7-theme-color);
  color: var(--f7-theme-color-light-grey);
  --f7-list-chevron-icon-color: var(--f7-theme-color-light-grey);
  :deep(.item-inner):after {
    background-color: var(--f7-theme-color);
  }
}
li:not(.selected).opened {
  background-color: var(--f7-theme-color-grey);
}
.list.inset {
  --f7-list-inset-side-margin: 8px;
}
.rate-section {
  .list {
    border-left: 4px solid var(--f7-theme-color-tint);
  }
}
.observation-extract {
  overflow: hidden;
  text-align: right;
  margin-right: 8px;
  white-space: pre-wrap;
  font-size: 10px;
  height: 22px;
  line-height: 1.1;
}
.nbcols-2 .observation-extract {
  max-width: 240px;
}
.nbcols-3 .observation-extract {
  max-width: 160px;
}
.nbcols-4 .observation-extract {
  max-width: 100px;
}
.rate-indicator.rate-indicator-other {
  display: none;
}
.general-comment {
  width: 100%;
  height: 100px;
  :deep(textarea) {
    height: 100px;
  }
}
.swiper-slide {
  background: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 200px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
:deep(.file-input) {
  visibility: hidden;
}
.media-item {
  --f7-list-media-item-title-font-weight: 400;
  :deep(.item-title) {
    font-size: 12px;
  }
  :deep(.item-subtitle) {
    font-style: italic;
    font-size: 14px;
  }
}
:deep(.list-button) i.f7-icons {
  position: relative;
  top: 5px;
}
.small-icon {
  font-size: 16px;
}
.medium-icon {
  font-size: 24px;
}
.list-action-button {
  z-index: 10;
  width: 10px;
}
.rate-indicator {
  display: block;
  font-size: 12px;
}
.photo-count, .number-count {
  width: 13px;
  height: 13px;
  background-color: var(--f7-theme-color-dark-grey);
  color: white;
  padding: 4px;
  border-radius: 50%;
  text-align: center;
  line-height: 13px;
  margin-left: -5px;
  margin-right: -4px;
}
.border-black {
  border: 1px solid black;
}
.border-shadow {
  box-shadow: 0 0 5px grey;
}
.text-black {
  color: black;
}
.list .custom-subentry-indication {
  color: var(--f7-theme-color-dark-grey);
  text-align: center;
}
.row-opaque-bg {
  position: relative;
  background-color: var(--f7-page-bg-color);
  z-index: 100;
}

//For Safari/iOS, helps fix problem of last col going to a new row:
.nbcols-3 .col {
  --f7-cols-per-row: 3;
}
.nbcols-4 .col {
  --f7-cols-per-row: 4;
}

@media screen and (max-width: 1024px) {
  .nbcols-2 .observation-extract {
    max-width: 180px;
  }
  .nbcols-3 .observation-extract {
    max-width: 100px;
  }
  .nbcols-4 {
    .observation-extract {
      display: none;
    }
    .rate-indicator.rate-indicator-other {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .observation-extract {
    display: none;
  }
  .rate-indicator.rate-indicator-other {
    display: block;
  }

  .nbcols-3 .row.extendable {
    width: 120%;
  }
  .nbcols-4 .row.extendable {
    width: 160%;
  }
}

@media screen and (max-width: 480px) {
  .nbcols-2 .row.extendable {
    width: 150%;
  }
  .nbcols-3 .row.extendable {
    width: 225%;
  }
  .nbcols-4 .row.extendable {
    width: 300%;
  }
}
</style>
