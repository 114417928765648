import _ from "lodash";
import {f7} from "framework7-vue";
import {mapActions} from "vuex";

export default {
  data: function () {
    return {
      publicToken: null
    };
  },
  computed: {
    hasPublicToken: function () {
      return this.publicToken !== null;
    },
    isPublicRoute: function () {
      return _.has(f7.view.main.router.currentRoute.route?.meta, 'public') && f7.view.main.router.currentRoute.route?.meta.public === true;
    }
  },
  methods: {
    ...mapActions('me', [
      'fetchUserForPublicAction',
    ]),
  }
}
