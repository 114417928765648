<template>
  <f7-row
    v-if="info"
    class="extendable row-opaque-bg"
  >
    <f7-col width="100">
      <f7-block-title><label for="comment">{{ $t('Commentaire général') }}</label></f7-block-title>
      <f7-block strong>
        <f7-input
          id="comment"
          type="textarea"
          :value="info.comment"
          class="general-comment"
          @input="updateComment"
        />
      </f7-block>
    </f7-col>
  </f7-row>
</template>

<script>
export default {
  name: "EdlEditComment",
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  emits: ['update'],
  methods: {
    updateComment(event) {
      this.$emit('update', event.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .general-comment > :deep(textarea) {
    resize: vertical;
    height: 100px;
  }
</style>
