<template>
  <span
    v-if="entry.rate.other !== undefined && entry.rate.other !== ''"
    class="observation-extract"
  >
    {{ entry.rate.other }}
  </span>
  <i
    v-if="entry.rate.state !== undefined && (entry.rate.state === 'Ne fonctionne pas' || entry.rate.state === 'Dysfonctionnement')"
    class="f7-icons rate-indicator rate-indicator-state"
  >bolt_slash_fill</i>
  <i
    v-if="entry.rate.cleaning !== undefined && entry.rate.cleaning !== null && entry.rate.cleaning !== '' && entry.rate.cleaning !== 'Propre'"
    class="f7-icons rate-indicator rate-indicator-cleaning"
  >paintbrush</i>
  <i
    v-if="entry.rate.other !== undefined && entry.rate.other !== ''"
    class="f7-icons rate-indicator rate-indicator-other"
  >bubble_left_fill</i>
  <i
    v-if="hasContextGallery"
    class="f7-icons rate-indicator"
  >photo</i>
</template>

<script>
export default {
  name: "EdlEditListRateIndicatorsAfter",
  props: {
    entry: {
      type: Object,
      required: true
    },
    hasContextGallery: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.observation-extract {
  overflow: hidden;
  text-align: right;
  margin-right: 8px;
  white-space: pre-wrap;
  font-size: 10px;
  height: 22px;
  line-height: 1.1;
}
.nbcols-2 .observation-extract {
  max-width: 240px;
}
.nbcols-3 .observation-extract {
  max-width: 160px;
}
.nbcols-4 .observation-extract {
  max-width: 100px;
}
.rate-indicator.rate-indicator-other {
  display: none;
}
:deep(.list-button) i.f7-icons {
  position: relative;
  top: 5px;
}
.rate-indicator {
  display: block;
  font-size: 12px;
}
@media screen and (max-width: 1024px) {
  .nbcols-2 .observation-extract {
    max-width: 180px;
  }
  .nbcols-3 .observation-extract {
    max-width: 100px;
  }
  .nbcols-4 {
    .observation-extract {
      display: none;
    }
    .rate-indicator.rate-indicator-other {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .observation-extract {
    display: none;
  }
  .rate-indicator.rate-indicator-other {
    display: block;
  }
}
</style>
