import api from "@/api";
import _ from "lodash";
import {extractCostingPath} from "@/composables/costing";

const state = {
  currentCostingEdlId: null,
  costings: {},
  calibrations: {user: {}, group: {}, config: null},
  isLoading: false,
  isSaving: false
};

const getters = {
  getCostingByEdlId: (state) => (edlId) => {
   return _.has(state.costings, edlId) ? state.costings[edlId] : null;
  },
  getCalibrationByUserId: (state) => (userId) => {
    return _.has(state.calibrations.user, userId) ? state.calibrations.user[userId] : null;
  },
  getCalibrationByUserGroupId: (state) => (userGroupId) => {
    return _.has(state.calibrations.group, userGroupId) ? state.calibrations.group[userGroupId] : null;
  },
  calibrationConfig: (state) => {
    return state.calibrations.config;
  }
};

const actions = {
  fetchCosting: ({commit}, {edlId, publicToken}) => {
    //publicToken is null if not used in PublicCosting
    commit('isLoading', true);
    return api.getCosting(edlId, publicToken).then((response) => {
      commit('costing', {edlId, data: response.data});
      commit('isLoading', false);
      return response;
    }).catch(() => {
      commit('isLoading', false);
    });
  },
  updateCosting: ({commit}, {edlId, costingId, attribute, value, publicToken = null}) => {
    commit('isSaving', true);
    let data = {};
    data[attribute] = value;
    commit('updateCostingAttribute', {edlId, costingId, attribute, value});
    return api.updateCosting(costingId, data, edlId, publicToken).then(() => {
      //Don't re-update after api save ok as this causes bad UX (new changes are overridden)
      //commit('updateCosting', {edlId, costingId: response.data.id, value: response.data});
      commit('isSaving', false);
    }).catch(() => {
      commit('isSaving', false);
    });
  },
  fetchCalibrationConfig: ({commit}, {edlId = null, publicToken = null}) => {
    return api.getCalibrationConfig(edlId, publicToken).then((response) => {
      commit('calibrationConfig', response.data);
    });
  },
  fetchUserGroupCalibration: ({commit}, {userGroupId}) => {
    commit('isLoading', true);
    return api.getUserGroupCalibration(userGroupId).then((response) => {
      commit('calibration', {userGroupId, data: response.data});
      commit('isLoading', false);
    }).catch(() => {
      commit('isLoading', false);
    });
  },
  fetchUserCalibration: ({commit}, {userId, edlId = null, publicToken = null}) => {
    commit('isLoading', true);
    return api.getUserCalibration(userId, edlId, publicToken).then((response) => {
      commit('calibration', {userId, data: response.data});
      commit('isLoading', false);
    }).catch(() => {
      commit('isLoading', false);
    });
  },
  updateUserGroupCalibration: ({commit, dispatch}, {userGroupId, calibrationId, attribute, value, fullUpdated}) => {
    commit('isSaving', true);
    commit('calibration', {userGroupId, data: fullUpdated});
    return dispatch('updateCalibration', {calibrationId, attribute, value});
  },
  updateUserCalibration: ({commit, dispatch}, {userId, calibrationId, attribute, value, fullUpdated}) => {
    commit('isSaving', true);
    commit('calibration', {userId, data: fullUpdated});
    return dispatch('updateCalibration', {calibrationId, attribute, value});
  },
  updateCalibration: ({commit}, {calibrationId, attribute, value}) => {
    let data = {};
    data[attribute] = value;
    return api.updateCalibration(calibrationId, data).then(() => {
      //Don't re-update after api save ok as this causes bad UX (new changes are overridden)
      commit('isSaving', false);
    }).catch(() => {
      commit('isSaving', false);
    });
  },
  createUserGroupCalibration: ({commit}, {userGroupId, data, fullUpdated}) => {
    commit('isSaving', true);
    commit('calibration', {userGroupId, data: fullUpdated});
    return api.createUserGroupCalibration(userGroupId, data).then((response) => {
      fullUpdated[data.unique].group.id = response.data.id;
      fullUpdated[data.unique].group.group = response.data.group;
      commit('calibration', {userGroupId, data: fullUpdated});
      //Don't re-update after api save ok as this causes bad UX (new changes are overridden)
      commit('isSaving', false);
      return fullUpdated[data.unique];
    }).catch(() => {
      commit('isSaving', false);
    });
  },
  createUserCalibration: ({commit}, {userId, data, fullUpdated}) => {
    commit('isSaving', true);
    commit('calibration', {userId, data: fullUpdated});
    return api.createUserCalibration(userId, data).then((response) => {
      fullUpdated[data.unique].user.id = response.data.id;
      fullUpdated[data.unique].user.user = response.data.user;
      commit('calibration', {userId, data: fullUpdated});
      //Don't re-update after api save ok as this causes bad UX (new changes are overridden)
      commit('isSaving', false);
      return fullUpdated[data.unique];
    }).catch(() => {
      commit('isSaving', false);
    });
  },
  deleteUserGroupCalibration: ({commit, dispatch}, {userGroupId, calibrationId, fullUpdated}) => {
    commit('isSaving', true);
    commit('calibration', {userGroupId, data: fullUpdated});
    return dispatch('deleteCalibration', calibrationId);
  },
  deleteUserCalibration: ({commit, dispatch}, {userId, calibrationId, fullUpdated}) => {
    commit('isSaving', true);
    commit('calibration', {userId, data: fullUpdated});
    return dispatch('deleteCalibration', calibrationId)
  },
  deleteCalibration: ({commit}, calibrationId) => {
    return api.deleteCalibration(calibrationId).then(() => {
      //Don't re-update after api save ok as this causes bad UX (new changes are overridden)
      commit('isSaving', false);
    }).catch(() => {
      commit('isSaving', false);
    });
  }
};

const mutations = {
  costing: (state, {edlId, data}) => {
    state.costings[edlId] = data;
  },
  calibration: (state, payload) => {
    if(_.has(payload, 'userGroupId')) {
      state.calibrations.group[payload.userGroupId] = payload.data;
    } else if (_.has(payload, 'userId')) {
      state.calibrations.user[payload.userId] = payload.data;
    } else {
      console.error('mutating calibration must send userId or userGroupId through payload');
    }
  },
  calibrationConfig: (state, payload) => {
    state.calibrations.config = payload;
  },
  isLoading: (state, payload) => {
    state.isLoading = payload;
  },
  isSaving: (state, payload) => {
    state.isSaving = payload;
  },
  updateCostingAttribute: (state, {edlId, costingId, attribute, value}) => {
    let {costingModule, costingIndex} = extractCostingPath(state.costings[edlId], costingId);
    state.costings[edlId][costingModule][costingIndex][attribute] = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
