<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      :back="{name: 'edl', params: {localId}}"
    >
      <template #global-actions>
        <f7-input
          type="select"
          :value="locale"
          @change="switchLanguage"
          @focus="showFullLanguageLabel = true"
          @blur="showFullLanguageLabel = false"
        >
          <option
            v-for="lang in availableLanguages"
            :key="'lang_'+lang.locale"
            :value="lang.locale"
          >
            {{ dynamicLanguageLabel(lang) }}
          </option>
        </f7-input>
      </template>
    </navigation-top>

    <navigation-bottom current="new" />

    <f7-page-content :ref="setRecapRef">
      <edl-recap-content
        :edl="edl"
        :info="info"
        :scroll="currentScroll"
        @track="trackScroll"
      />
      <edl-submit-row
        name="Signer et clôturer cet EDL"
        @submit="goToSignature"
      />
    </f7-page-content>
  </f7-page>
</template>

<script>
import firewall from "../../../mixins/firewall";
import NavigationTop from "../../../layout/NavigationTop.vue";
import NavigationBottom from "../../../layout/NavigationBottom.vue";
import EdlRecapContent from "@/components/pages/Edl/EdlRecap/EdlRecapContent.vue";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import hasConvention from "@/components/mixins/hasConvention";
import {unobserve} from "@/store/helper";
import language from "@/components/mixins/language";
import EdlSubmitRow from "@/components/pages/Edl/EdlSubmitRow.vue";

export default {
    name: "EdlRecap",
    components: {
      EdlSubmitRow,
      NavigationTop,
      NavigationBottom,
      EdlRecapContent
    },
    mixins: [firewall, hasConvention, language],
    props: {
      localId: {
        type: String,
        default: null
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        title: 'Récapitulatif',
        edl: null,
        info: null,
        currentScroll: 0,
        throttledHandleScroll: null,
        recapDom: null
      }
    },
    computed: {
      ...mapGetters('edlPersist', [
        'getCurrentLocalEdl'
      ])
    },
    mounted: function () {
      let id = +this.localId; //converts to number
      this.setCurrentLocalEdlId(id);
      this.setup();
    },
    beforeUnmount: function () {
      this.recapDom.removeEventListener('scroll', this.throttledHandleScroll);
    },
    methods: {
      ...mapActions('edlPersist', [
        'setCurrentLocalEdlId',
        'updateCurrentLocalEdlInfo'
      ]),
      setup: function () {
        let edl = unobserve(this.getCurrentLocalEdl);
        this.edl = edl.edl;
        this.info = _.omit(edl, ['edl']);
      },
      setRecapRef: function (el) {
        if (el) {
          this.recapDom = el.$el;
        }
      },
      goToSignature: function () {
        this.f7router.navigate({name: 'edlSignature', params: {'localId': this.localId}}, {reloadAll: true});
      },
      goToRecapConvention: function () {
        this.f7router.navigate({name: 'edlRecapConvention', params: {'localId': this.localId}}, {reloadAll: true});
      },
      trackScroll: function () {
        this.throttledHandleScroll = _.throttle(this.handleScroll, 100);
        this.recapDom.addEventListener('scroll', this.throttledHandleScroll);
      },
      handleScroll: function () {
        this.currentScroll = this.recapDom.scrollTop;
      },
      switchLanguage: function (event) {
        this.info.locale = event.target.value !== "" ? event.target.value : null;
        this.updateInfo();
        this.showFullLanguageLabel = false;
      },
      updateInfo: function () {
        this.updateCurrentLocalEdlInfo(this.info);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .current-module {
    position: fixed;
    height: 50px;
    width: 100%;
    z-index: 100;
    .header-module {
      margin: 0;
    }
  }
  .header-module {
    background: var(--f7-theme-color-grey);
    font-size: 34px;
  }
  .header-entry {
    font-size: 20px;
  }
  .header-sub-entry {
    color: black;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .rate-name {
    font-weight: bold;
    font-size: 16px;
  }
  .rate {
    font-size: 16px;
  }
  .photos {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--f7-theme-color-grey);
    :deep(.block-header) {
      width: 100%;
    }
    .photo {
      float: left;
      height: auto;
      img {
        width: 100%; /* or any custom size */
        height: auto;
        object-fit: contain;
      }
    }
  }
  .row.photos-context {
    margin-top: 10px;
  }
  .nl2br {
    white-space: pre-line;
  }
</style>
