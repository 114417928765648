<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Nouvel Auto-EDL"
      back="autoEdls"
    />

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list no-hairlines-md>
        <f7-list-item
          title="EDL à dupliquer"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            searchbarPlaceholder: 'Rechercher',
            closeOnSelect: true,
            popupCloseLinkText: 'Fermer',
            virtualList: true
          }"
          :value="autoEdl.duplicateFromId"
        >
          <select
            name="duplicateFromId"
            @change="autoEdl.duplicateFromId = +$event.target.value"
          >
            <option value="" />
            <option
              v-for="edl in remoteEdls"
              :key="edl.id"
              :value="edl.id"
            >
              {{ getEdlName(edl) }}
            </option>
          </select>
          <template #media>
            <f7-icon f7="doc_on_doc" />
          </template>
        </f7-list-item>

        <f7-list-input
          :value="autoEdl.email"
          label="E-mail du locataire"
          type="email"
          placeholder="tony@stark-industries.com"
          required
          validate
          validate-on-blur
          clear-button
          @input="autoEdl.email = $event.target.value"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-input>
        <f7-list-input
          :value="autoEdl.preData.etage"
          label="Étage / Porte"
          type="text"
          placeholder="3e étage, porte 2"
          required
          validate
          validate-on-blur
          clear-button
          @input="autoEdl.preData.etage = $event.target.value"
        >
          <template #media>
            <f7-icon f7="building_2_fill" />
          </template>
        </f7-list-input>

        <f7-list-input
          :value="autoEdl.preData.numAppart"
          label="Référence / Numéro d'appartement"
          type="text"
          placeholder="2046"
          required
          validate
          validate-on-blur
          clear-button
          @input="autoEdl.preData.numAppart = $event.target.value"
        >
          <template #media>
            <f7-icon f7="number_circle" />
          </template>
        </f7-list-input>

        <f7-list inset>
          <f7-list-button
            title="Créer & Envoyer email"
            @click="submit"
          />
        </f7-list>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapState, mapActions} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import { f7 } from "framework7-vue";
  import {nextTick} from "vue";

  export default {
    name: "AutoEdlAdd",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function() {
      return {
        autoEdl: {
          duplicateFromId: null,
          email: null,
          preData: {
            etage: null,
            numAppart: null
          }
        }
      }
    },
    computed: {
      ...mapState('edlTemp', [
        'remoteEdls'
      ]),
      ...mapState('me', [
        'selectedProfile'
      ]),
      formIsValid() {
        return this.autoEdl.email && this.autoEdl.duplicateFromId;
      }
    },
    mounted: function () {
      nextTick(() => {
        this.limitToCanAutoEdls();

        f7.preloader.show();
        this.pagePreloaderRunning = true;
        this.fetchRemoteEdls({
          profileId: this.selectedProfile.id
        }).then(() => {
          f7.preloader.hide();
        });
      });
    },
    methods: {
      ...mapActions('edlTemp', [
        'fetchRemoteEdls',
      ]),
      ...mapActions('autoEdl', [
        'createAutoEdl',
      ]),
      submit() {
        if (!this.formIsValid) {
          f7.dialog.alert('Veuillez corriger les informations renseignées !', 'Erreur')
          return
        }

        f7.preloader.show('Enregistrement en cours...');

        let data = this.autoEdl;
        data.profileId = this.selectedProfile.id;
        this.createAutoEdl(data).then(() => {
          f7.preloader.hide();
          this.f7router.navigate({name: 'autoEdls'});
        })
      },
      getEdlName: function (edl) {
        return '#'+ edl.id + ' - ' + edl.agent + ' - ' + edl.renter + ' [' + edl.edlIO + ']';
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
