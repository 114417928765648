export const translations = (locales) => {
  return locales.reduce((messages, locale) => {
    messages[locale] = require('./translations/' + locale + '.json');
    return messages;
  }, {});
};

export default {
  translations
};
