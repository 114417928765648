<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      show-sub-nav
      :back="backRoute"
      back-title="OK"
    >
      <template #global-actions>
        <p
          v-if="isLoading"
          class="status"
        >
          Chargement...
        </p>
        <p
          v-else-if="isSaving"
          class="status"
        >
          Enregistrement...
        </p>
        <p
          v-else-if="isSaved"
          class="status"
        >
          Enregistré !
        </p>
      </template>
    </navigation-top>
    <navigation-bottom current="edls" />

    <f7-page-content
      v-if="ready"
      ref="calibration"
    >
      <f7-list
        inset
        class="no-bg"
      >
        <f7-list-item
          class="no-bg"
          :class="{disabled: !isOnline}"
        >
          <template #inner-start>
            <f7-row class="header-row">
              <f7-col
                width="100"
                large="25"
              >
                <strong>Élément</strong> de l'EDL à auto-chiffrer
              </f7-col>
              <f7-col
                width="100"
                large="20"
              >
                <strong>Nature</strong> du revêtement (le cas échéant)
              </f7-col>
              <f7-col
                width="100"
                large="15"
              >
                <strong>Type d'action</strong> sur l'élément à auto-chiffrer
              </f7-col>
              <f7-col
                v-if="user?.group"
                width="80"
                large="15"
              >
                <strong>Coût unitaire</strong> groupe
              </f7-col>
              <f7-col
                width="80"
                :large="costColSize"
              >
                <span v-if="id"><strong>Coût unitaire</strong> {{ user?.login }}</span>
                <span v-else><strong>Mon coût unitaire</strong></span>
              </f7-col>
              <f7-col
                width="100"
                large="10"
              >
                <strong>Taux de TVA</strong>
              </f7-col>
            </f7-row>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-list inset>
        <f7-list-item
          v-for="element in calibration"
          :key="'element_'+element?.common?.unique"
          :class="{disabled: !isOnline}"
          swipeout
        >
          <f7-swipeout-actions right>
            <f7-swipeout-button
              v-if="!hasGroup(element)"
              delete
              @click="deleteElement(element)"
            >
              Supprimer
            </f7-swipeout-button>
          </f7-swipeout-actions>
          <template #inner-start>
            <f7-row class="element-row">
              <f7-col
                width="100"
                large="25"
              >
                <i
                  v-if="hasGroup(element)"
                  class="f7-icons group-icon"
                  tooltip="Élément défini par groupe"
                >
                  person_2_square_stack
                </i>
                <f7-list-input
                  v-if="!element.common.custom"
                  type="select"
                  floating-label
                  label="Élément"
                  class="label calibration-select"
                  :value="element.common.reference"
                  :disabled="hasGroup(element)"
                  @change="updateElement(element, 'reference', $event.target.value)"
                >
                  <option value="custom">
                    Élément personnalisé
                  </option>
                  <optgroup
                    v-for="(categoryElements, categoryName) in categorizedElements"
                    :key="'optgroup_'+categoryName"
                    :value="categoryName"
                    :label="categoryName"
                  >
                    <option
                      v-for="(availableElement, ref) in categoryElements"
                      :key="'option_'+ref"
                      :value="ref"
                    >
                      {{ availableElement.label }}
                    </option>
                  </optgroup>
                </f7-list-input>
                <f7-list-input
                  v-else
                  floating-label
                  label="Élément personnalisé"
                  class="label input-custom"
                  :value="element.common.name"
                  :disabled="hasGroup(element)"
                  @change="updateElement(element, 'name', $event.target.value)"
                />
              </f7-col>
              <f7-col
                width="100"
                large="20"
              >
                <f7-list-input
                  v-if="element.common.type === 'coating' && element.common.reference !== null"
                  label="Nature"
                  type="select"
                  placeholder="Nature"
                  :value="element.common.nature"
                  floating-label
                  class="calibration-select"
                  :disabled="hasGroup(element)"
                  @change="updateElement(element, 'nature', $event.target.value)"
                >
                  <option value="all">
                    Toutes
                  </option>
                  <option
                    v-for="nature in calibrationConfig.elements[element.common.reference].natures"
                    :key="nature"
                    :value="nature"
                  >
                    {{ nature }}
                  </option>
                </f7-list-input>
                <f7-list-item v-else>
                  -
                </f7-list-item>
              </f7-col>
              <f7-col
                width="100"
                large="15"
              >
                <f7-list-input
                  label="Action"
                  type="select"
                  placeholder="Action"
                  floating-label
                  :value="element.common.action"
                  class="calibration-select"
                  :disabled="hasGroup(element)"
                  @change="updateElement(element, 'action', $event.target.value)"
                >
                  <option
                    v-for="action in calibrationConfig.actions"
                    :key="action"
                    :value="action"
                  >
                    {{ getActionLabel(action) }}
                  </option>
                </f7-list-input>
              </f7-col>
              <f7-col
                v-if="user?.group"
                width="100"
                large="15"
              >
                <f7-list-input
                  v-if="element.group"
                  class="input-cost group-cost"
                  :class="{'current-cost': element.user === undefined || element.user?.unitCost === null}"
                  :label="element.group.group?.name"
                  type="text"
                  disabled
                  floating-label
                  :value="formatCurrency(element.group?.unitCost) || '-'"
                >
                  <template #content-end>
                    <span
                      v-if="element.common.type === 'coating'"
                      class="input-cost-suffix"
                    >€/m²</span>
                    <span
                      v-else
                      class="input-cost-suffix"
                    >€</span>
                  </template>
                </f7-list-input>
                <f7-list-input
                  v-else-if="element.parent"
                  class="input-cost group-cost"
                  :class="{'current-cost': element.user === undefined || element.user?.unitCost === null}"
                  :label="element.parent.group?.name"
                  type="text"
                  disabled
                  floating-label
                  :value="formatCurrency(element.parent?.unitCost) || '-'"
                >
                  <template #content-end>
                    <span
                      v-if="element.common.type === 'coating'"
                      class="input-cost-suffix"
                    >€/m²</span>
                    <span
                      v-else
                      class="input-cost-suffix"
                    >€</span>
                  </template>
                </f7-list-input>
                <f7-list-item v-else>
                  -
                </f7-list-item>
                <f7-list-input
                  v-if="element.group"
                  class="input-tva input-tva-group"
                  label="TVA"
                  type="select"
                  inline-label
                  disabled
                  placeholder="Taux de TVA"
                  :value="element.group?.taxRate"
                >
                  <option value="2000">
                    20%
                  </option>
                  <option value="1000">
                    10%
                  </option>
                  <option value="550">
                    5,5%
                  </option>
                  <option value="0">
                    Pas de TVA
                  </option>
                </f7-list-input>
                <f7-list-input
                  v-else-if="element.parent"
                  class="input-tva input-tva-group"
                  label="TVA"
                  type="select"
                  inline-label
                  disabled
                  placeholder="Taux de TVA"
                  :value="element.parent?.taxRate"
                >
                  <option value="2000">
                    20%
                  </option>
                  <option value="1000">
                    10%
                  </option>
                  <option value="550">
                    5,5%
                  </option>
                  <option value="0">
                    Pas de TVA
                  </option>
                </f7-list-input>
              </f7-col>
              <f7-col
                width="100"
                :large="costColSize"
              >
                <f7-list-input
                  :label="(id && element.user?.unitCost) ? user?.login : (element.user?.unitCost ? 'Mon coût unitaire' : 'Coût unitaire')"
                  class="input-cost"
                  :class="{'current-cost': element.user !== undefined && element.user?.unitCost !== null}"
                  type="text"
                  placeholder="Coût unitaire"
                  floating-label
                  :value="formatCurrency(element.user?.unitCost)"
                  @change="updateElement(element, 'unitCost', $event.target.value)"
                >
                  <template #content-end>
                    <i
                      v-if="hasGroup(element) && element.user?.unitCost !== undefined"
                      class="f7-icons clear"
                      @click="deleteUserElement(element)"
                    >clear_fill</i>
                    <span
                      v-if="element.common.type === 'coating'"
                      class="input-cost-suffix"
                    >€/m²</span>
                    <span
                      v-else
                      class="input-cost-suffix"
                    >€</span>
                  </template>
                </f7-list-input>
                <f7-list-input
                  class="input-tva"
                  label="TVA"
                  type="select"
                  inline-label
                  placeholder="Taux de TVA"
                  :value="element.user !== undefined ? element.user?.taxRate : null"
                  :disabled="element.user === undefined || element.user === null"
                  @change="updateElement(element, 'taxRate', parseInt($event.target.value))"
                >
                  <option value="" />
                  <option value="2000">
                    20%
                  </option>
                  <option value="1000">
                    10%
                  </option>
                  <option value="550">
                    5,5%
                  </option>
                  <option value="0">
                    Pas de TVA
                  </option>
                </f7-list-input>
              </f7-col>
            </f7-row>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-row>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            large
            raised
            fill
            :class="{disabled: !isOnline}"
            @click="addElement"
          >
            <i class="f7-icons">plus_circle</i>&nbsp;Ajouter un élément
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
import NavigationTop from "@/components/layout/NavigationTop";
import NavigationBottom from "@/components/layout/NavigationBottom";
import {mapActions, mapGetters, mapState} from "vuex";
import {unobserve} from "@/store/helper";
import _ from "lodash";
import {
  addEmptyCalibrationObject,
  isValidCalibration,
  removeCalibration,
  removeUserCalibration,
  updateCalibrationObject
} from "@/composables/costing";
import offline from "@/components/mixins/offline";
import errors from "@/components/mixins/errors";
import firewall from "@/components/mixins/firewall";
import costing from "@/components/mixins/costing";

export default {
  name: "UserCostingCalibration",
  components: {NavigationTop, NavigationBottom},
  mixins: [firewall, offline, errors, costing],
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    },
    f7router: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      ready: false,
      calibration: null,
      isSaved: false
    };
  },
  computed: {
    ...mapState('costing', [
      'isLoading',
      'isSaving'
    ]),
    ...mapGetters('costing', [
      'getCalibrationByUserId',
      'calibrationConfig'
    ]),
    ...mapGetters('user', [
      'getUserById',
    ]),
    ...mapState('userGroup', [
      'userGroups'
    ]),
    userId: function () {
      return this.id ? +this.id : this.me?.id;
    },
    user: function() {
      return this.id ? this.getUserById(this.userId) : this.me;
    },
    title: function () {
      if(this.id) {
        if(this.user?.login) {
          return "Calibrage pour auto-chiffrage de '"+this.user?.login+"'";
        } else {
          return "Calibrage pour auto-chiffrage";
        }
      } else {
        return  "Mon calibrage pour auto-chiffrage";
      }
    },
    backRoute: function () {
      return this.id ? {
        name: 'user',
        params: {
          id: this.id
        }
      } : {name: 'account'};
    },
    categorizedElements: function () {
      return _.reduce(this.calibrationConfig.elements, (categorized, element, key) => {
        if(!_.has(categorized, element.category)) {
          categorized[element.category] = {};
        }
        categorized[element.category][key] = element;
        return categorized;
      }, {});
    },
    costColSize: function () {
      return this.user?.group !== undefined ? '15' : '30';
    }
  },
  watch: {
    isSaving: function (isSaving, wasSaving) {
      if (wasSaving && !isSaving) {
        this.isSaved = true;
        setTimeout(() => {
          this.isSaved = false;
        }, 2000);
      }
    }
  },
  mounted: function () {
    this.fetchUserCalibration({userId: this.userId}).then(() => {
      this.calibration = unobserve(this.getCalibrationByUserId(this.userId));
    });
    this.fetchCalibrationConfig({}).then(() => {
      this.ready = true;
    });
  },
  methods: {
    ...mapActions('costing', [
      'fetchUserCalibration',
      'fetchCalibrationConfig',
      'updateUserCalibration',
      'createUserCalibration',
      'deleteUserCalibration'
    ]),
    ...mapActions('userGroup', [
      'fetchUserGroup'
    ]),
    ...mapGetters('userGroup', [
      'getUserGroupById'
    ]),
    updateElement: function (element, attribute, value) {
      //Case we are changing the element's reference (left column), we must update the "custom" attribute if relevant
      if (attribute === 'reference') {
        if(value === 'custom' && element.common.custom === false) {
          //if was not custom, switch to custom
          this.calibration = updateCalibrationObject(this.calibration, this.calibrationConfig, element, 'custom', true, 'user');
          if(element.user?.id !== undefined) {
            this.updateUserCalibration({
              userId: this.userId,
              calibrationId: element.user.id,
              attribute: 'custom',
              value: true,
              fullUpdated: unobserve(this.calibration)
            });
          }
          value = null;
        } else if(element.common.custom === true) {
          //if was custom, switch to non-custom
          this.calibration = updateCalibrationObject(this.calibration, this.calibrationConfig, element, 'custom', false, 'user');
          if(element.user?.id !== undefined) {
            this.updateUserCalibration({
              userId: this.userId,
              calibrationId: element.user.id,
              attribute: 'custom',
              value: false,
              fullUpdated: unobserve(this.calibration)
            });
          }
        }
      }

      if(attribute === 'unitCost') {
        value = this.unformatCurrency(value);
      }

      let success = true;
      try {
        this.calibration = updateCalibrationObject(this.calibration, this.calibrationConfig, element, attribute, value, 'user');
      } catch (e) {
        if(e === 'duplicate') {
          this.throwError('Un élément identique existe déjà, vous ne pouvez pas en avoir deux identiques !', 'Non-autorisé', null, null);
          success = false;
        }
      }

      if(success) {
        if (element.user.id !== undefined) {
          this.updateUserCalibration({
            userId: this.userId,
            calibrationId: element.user.id,
            attribute,
            value,
            fullUpdated: unobserve(this.calibration)
          });
        } else { //there was only a "parent" version, so we should create a "group" version
          //this should only be possible on a unitCost
          let newCalibration = element.common;
          newCalibration[attribute] = value;
          if (isValidCalibration(newCalibration)) {
            this.createUserCalibration({
              userId: this.userId,
              data: newCalibration,
              fullUpdated: unobserve(this.calibration)
            }).then((newCalibrationWithId) => {
              //Update local calibration
              this.calibration[newCalibrationWithId.common.unique] = unobserve(newCalibrationWithId);
            });
          }
        }
      }
    },
    getElementLabel: function (element) {
      if(element.common.name !== null) {
        return element.common.name;
      } else if(element.common.reference !== null && _.has(this.calibrationConfig.elements, element.common.reference)) {
        return this.calibrationConfig.elements[element.common.reference].label;
      } else {
        return "";
      }
    },
    getElementFullLabel: function (element) {
      let label = this.getElementLabel(element);
      if(element.common.type === 'coating') {
        label += ' > '+ element.common.nature;
      }
      label += ' > '+ element.common.action;

      return label;
    },
    getParentUnitCost: function (element) {
      if(this.hasParent(element)) {
        return element.parent.unitCost;
      }
    },
    hasGroup: function (element) {
      return element.group !== undefined || this.hasParent(element);
    },
    hasParent: function (element) {
      return element.parent !== undefined;
    },
    addElement: function () {
      this.calibration = addEmptyCalibrationObject(this.calibration, 'user');
    },
    deleteElement: function (element) {
      this.calibration = removeCalibration(this.calibration, element.common.unique);
      this.deleteCalibration(element);
    },
    deleteUserElement: function (element) {
      this.calibration = removeUserCalibration(this.calibration, element.common.unique);
      this.deleteCalibration(element);
    },
    deleteCalibration: function (element) {
      this.deleteUserCalibration({
        userId: this.id,
        calibrationId: element.user.id,
        fullUpdated: unobserve(this.calibration)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.element-row {
  width: 100%;
  background-color: white;
  margin: 5px;
  padding: 5px 0 5px 0;
  border-radius: 5px;
  .label :deep(input), .label :deep(select) {
    font-weight: bold;
  }
  .element-type {
    display: block;
  }
}
.header-row {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 12px;
}
.no-bg {
  background-color: var(--f7-page-bg-color);
}
.row [class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.item-content li.disabled {
  opacity: 1 !important;
  color: var(--f7-theme-color-very-dark-grey);
  background-color: var(--f7-theme-color-grey);
}
.item-content li.input-tva:not(.input-tva-group).disabled {
  opacity: 1 !important;
  color: var(--f7-theme-color-dark-grey);
  background-color: var(--f7-theme-color-light-grey);
}
.calibration-select, .input-cost, .input-custom {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: var(--f7-theme-color-light-grey);
}
:deep(.input-cost) {
  input.input-with-value {
    text-align: right;
  }
}
:deep(.current-cost) {
  input.input-with-value {
    font-weight: bold;
  }
}
.input-cost {
  .input-cost-suffix {
    margin-right: 10px;
    margin-left: 5px;
    position: relative;
    top: 12px;
    font-size: 12px;
  }
  :deep(.item-input-wrap) {
    right: -18px;
  }
}
.input-dropdown:before {
  right: 15px;
}
.input-cost :deep(.item-input-with-value) .item-floating-label {
  max-width: 100%;
}
i.group-icon {
  position: absolute;
  font-size: 16px;
  left: -4px;
  top: 25px;
  color: var(--f7-theme-color-dark-grey);
}
.disabled :deep(.input-dropdown)::before {
  display: none;
}
.clear {
  position: absolute;
  right: 18px;
  top: 15px;
  font-size: 12px;
  color: var(--f7-theme-color-dark-grey);
  cursor: pointer;
}
.input-tva {
  margin-top: -8px;
  background-color: var(--f7-theme-color-light-grey);
  border-radius: 3px;
  :deep(select), :deep(.item-label) {
    font-size: 12px;
    text-align: right;
  }
  :deep(.item-label) {
    top: 3px;
  }
}
@media screen and (max-width: 1300px) {
  .calibration-select, .input-cost, .input-custom {
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
