import _, {findKey, has, includes} from "lodash";

export const extractCostingPath = (costings, costingId) => {
  let costingIndex = null;
  let costingModule = null;
  _.each(costings, (module, moduleName) => {
    costingIndex = _.findIndex(module, moduleCosting => moduleCosting.id === costingId);
    if(costingIndex !== -1) {
      costingModule = moduleName;
      return false;
    }
  });

  return {costingModule, costingIndex};
};

export const updateCalibrationObject = (calibration, config, element, attribute, value, level) => {
  const uniqueKeyAttributes = ['reference', 'name', 'nature', 'action'];
  const oldUniqueKey = element.common.unique;
  if (uniqueKeyAttributes.includes(attribute)) {
    //Update type if changed
    if (attribute === 'reference' &&  value !== null && config.elements[value].type !== element.common.type) {
      _.set(calibration, [oldUniqueKey, 'common', 'type'], config.elements[value].type);
      _.set(element, ['common', 'type'], config.elements[value].type);
    }

    const newUniqueKeyParts = [];
    uniqueKeyAttributes.forEach((uniqueKeyAttribute) => {
      if(uniqueKeyAttribute !== attribute && element.common[uniqueKeyAttribute] !== undefined && element.common[uniqueKeyAttribute] !== null) {
        newUniqueKeyParts.push(element.common[uniqueKeyAttribute]);
      } else if(uniqueKeyAttribute === attribute) {
        newUniqueKeyParts.push(value);
      }
    });
    const newUniqueKey = newUniqueKeyParts.join('.');

    //Test if newUniqueKey already exists, if so, abort?
    if(_.has(calibration, newUniqueKey)) {
      throw 'duplicate';
    }

    _.set(calibration, [oldUniqueKey, 'common', attribute], value);
    _.set(calibration, [oldUniqueKey, 'common', 'unique'], newUniqueKey);
    calibration = _.mapKeys(calibration, function(value, key) {
      if(key === oldUniqueKey) {
        return newUniqueKey;
      } else {
        return key;
      }
    });
  } else if (attribute === 'custom') {
    _.set(calibration, [oldUniqueKey, 'common', attribute], value);
    //Force type to equipment
    _.set(calibration, [oldUniqueKey, 'common', 'type'], 'equipment');
  } else {
    _.set(calibration, [oldUniqueKey, level, attribute], value);
  }

  return calibration;
}

export const addEmptyCalibrationObject = (calibration, level) => {
  const tempUniqueKey = 'new_' + performance.now();

  if(calibration === null) {
    calibration = {};
  }

  calibration[tempUniqueKey] = {
    common: {
      type: null,
      reference: null,
      action: null,
      nature: null,
      custom: false,
      unique: tempUniqueKey
    },
  };
  calibration[tempUniqueKey][level] = {
    unitCost: null
  };
  return calibration;
}

export const removeCalibration = (calibration, uniqueKey) => {
  return _.omit(calibration, [uniqueKey]);
}

export const removeGroupCalibration = (calibration, uniqueKey) => {
  calibration[uniqueKey] = _.omit(calibration[uniqueKey], 'group');
  return calibration;
}

export const removeUserCalibration = (calibration, uniqueKey) => {
  calibration[uniqueKey] = _.omit(calibration[uniqueKey], 'user');
  return calibration;
}

export const isValidCalibration = (element) => {
  return element.type !== null
  && element.action !== null
  && (element.reference !== null || element.name !== null);
}

export const getCalibrationKeyByEntryName = (entryName, parentName, calibrationConfig) => {
  const found = findKey(calibrationConfig.elements, (element) => {
    return entryHasElementName(entryName, parentName, element);
  });
  return found || entryName;
};

export const entryHasElementName = (entryName, parentName, element) => {
  let found = false;
  if(parentName !== undefined && entryName !== undefined) {
    found = has(element,'entryName') && includes(element.entryName, parentName)
      && has(element,'subEntryName') && includes(element.subEntryName, entryName);
  }

  if(!found && entryName !== undefined) {
    found = has(element,'entryName') && includes(element.entryName, entryName);
  }

  return found;
};
