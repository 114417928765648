<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Groupes utilisateurs"
      back="account"
    >
      <template #global-actions="{toggleSubNav, showSubNav}">
        <f7-link
          :href="f7router.generateUrl({name: 'newUserGroup'})"
          :class="{disabled: !isOnline}"
          icon-f7="plus_circle"
          text="Nouveau"
        />
        <f7-link
          class="button color-white"
          @click="toggleSubNav"
        >
          <i
            v-if="!showSubNav"
            class="f7-icons"
          >search</i>
          <i
            v-else
            class="f7-icons"
          >chevron_up</i>
        </f7-link>
      </template>
      <template #subnav>
        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title"
          :disable-button="!theme.aurora"
        />
      </template>
    </navigation-top>

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list
        v-if="!noUserGroups"
        class="search-list"
      >
        <template
          v-for="userGroup in userGroups"
          :key="userGroup.id"
        >
          <f7-list-item
            swipeout
            :title="userGroup.name"
            :link="f7router.generateUrl({name: 'userGroup', params: {id: userGroup.id}})"
            :class="{disabled: !isOnline}"
            @swipeout:delete="deleteUserGroup(userGroup)"
          >
            <template #media>
              <i class="f7-icons">folder_badge_person_crop</i>
              <img
                v-if="userGroup.logo"
                :src="userGroup.logo"
                class="logo"
              >
            </template>
            <f7-swipeout-actions right>
              <f7-swipeout-button
                delete
                confirm-text="Êtes-vous certains de vouloir supprimer ce groupe ? Cette action est définitive."
              >
                Supprimer
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>

          <template
            v-for="childGroup in userGroup.children"
            :key="childGroup.id"
          >
            <f7-list-item
              swipeout
              :title="childGroup.name"
              :link="f7router.generateUrl({name: 'userGroup', params: {id: childGroup.id}})"
              class="margin-left"
              :class="{disabled: !isOnline}"
              @swipeout:delete="deleteUserGroup(childGroup)"
            >
              <template #media>
                <i class="f7-icons">arrow_turn_down_right</i>
                <img
                  v-if="childGroup.logo"
                  :src="childGroup.logo"
                  class="logo"
                >
              </template>
              <f7-swipeout-actions right>
                <f7-swipeout-button
                  delete
                  confirm-text="Êtes-vous certains de vouloir supprimer ce groupe ? Cette action est définitive."
                >
                  Supprimer
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </template>
        </template>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import { f7, theme } from "framework7-vue";
  import {nextTick} from "vue";
  import offline from "@/components/mixins/offline";

  export default {
    name: "UserGroupList",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall, offline],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters('userGroup', {
        'userGroups': 'getHierarchicalUserGroups',
      }),
      noUserGroups() {
        return this.userGroups !== null && this.userGroups.length === 0;
      },
      theme() {
        return theme;
      }
    },
    mounted() {
      nextTick(() => {
        this.limitToAdmins();

        f7.preloader.show();
        this.fetchUserGroups().finally(() => f7.preloader.hide());
      })
    },
    methods: {
      ...mapActions('userGroup', [
        'fetchUserGroups',
        'deleteUserGroup',
      ]),
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 25px;
    left: 25px;
  }
</style>
