<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top title="Langue / Language">
      <template #global-actions>
        <f7-link
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="logout"
        >
          <i class="f7-icons">power</i>
        </f7-link>
      </template>
    </navigation-top>
    <f7-page-content>
      <f7-list v-if="autoEdl">
        <f7-list-item
          v-for="language in availableLanguages"
          :key="language.locale"
          link
          @click="select(language.locale)"
        >
          <template #media>
            {{ language.flag }}
          </template>
          <template #title>
            {{ language.label }}
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import autoEdl from "@/components/mixins/autoEdl";
  import NavigationTop from "@/components/layout/NavigationTop";
  import languageSelect from "@/components/mixins/language";

  export default {
    name: "AutoEdlLang",
    components: {NavigationTop},
    mixins: [firewall, autoEdl, languageSelect],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    methods: {
      select: function (locale) {
        this.updateAutoEdl({id: this.userId, path: 'locale', value: locale});
        this.f7router.navigate({name: 'autoEdlInfo'});
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
