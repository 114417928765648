<template>
  <span
    v-if="isEntry && entry === currentPhotosEntry"
    class="rate-indicator photo-count"
  >{{ entry.content.length }}</span>
  <span
    v-else-if="isEntry && showCountLabelTotalOnEntry.includes(entry.name) && entryHasNumberCount(entry)"
    class="rate-indicator number-count"
    :class="getConditionColorClasses(entry, true)"
  >{{ entryHasNumberCount(entry) }}</span>
  <span
    v-else-if="subEntryHasNumberCount(entry)"
    class="rate-indicator number-count"
    :class="getConditionColorClasses(entry, true)"
  >{{ subEntryHasNumberCount(entry) }}</span><!-- case number directly in entry rate that has no subEntries -->
  <i
    v-else-if="getConditionColorClasses(entry)"
    class="f7-icons rate-indicator"
    :class="getConditionColorClasses(entry)"
  >circle_fill</i>
  <i
    v-else-if="entry.rate.condition !== undefined"
    class="f7-icons rate-indicator"
  >circle</i>
</template>

<script>
import edlEditList from "@/components/mixins/edlEditList";

export default {
  name: "EdlEditListRateIndicatorsBefore",
  mixins: [edlEditList],
  props: {
    type: {
      type: String,
      required: true
    },
    entry: {
      type: Object,
      required: true
    },
    currentPhotosEntry: {
      type: Object,
      default: null
    }
  },
  computed: {
    isEntry: function () {
      return this.type === "entry";
    }
  }
}
</script>

<style lang="scss" scoped>
.observation-extract {
  overflow: hidden;
  text-align: right;
  margin-right: 8px;
  white-space: pre-wrap;
  font-size: 10px;
  height: 22px;
  line-height: 1.1;
}
.nbcols-2 .observation-extract {
  max-width: 240px;
}
.nbcols-3 .observation-extract {
  max-width: 160px;
}
.nbcols-4 .observation-extract {
  max-width: 100px;
}
.rate-indicator.rate-indicator-other {
  display: none;
}
:deep(.list-button) i.f7-icons {
  position: relative;
  top: 5px;
}
.rate-indicator {
  display: block;
  font-size: 12px;
}
@media screen and (max-width: 1024px) {
  .nbcols-2 .observation-extract {
    max-width: 180px;
  }
  .nbcols-3 .observation-extract {
    max-width: 100px;
  }
  .nbcols-4 {
    .observation-extract {
      display: none;
    }
    .rate-indicator.rate-indicator-other {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .observation-extract {
    display: none;
  }
  .rate-indicator.rate-indicator-other {
    display: block;
  }
}
</style>
