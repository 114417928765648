import modules, {all} from "./edlDataStructureModule";
import entries, {build} from "@/data/edlDataStructureModuleEntry";
import entrySimple from "./edlDataStructureModuleEntrySimple";
import rate from "@/data/edlDataStructureModuleEntryRate";
import _ from 'lodash';
import {unobserve} from "@/store/helper";
import {fillIds} from "@/composables/prepare";

export const newEdlData = (details = null) => {
  let edl;
  const template = details.template || 'default';

  //Handle templates
  edl = all.filter((module) => module.templates !== undefined && module.templates.includes(template) && module.addToBaseVersion === true);
  edl = edl.map((module) => useCorrectTemplateEntries(module, template));

  //Handle different base structures depending on the details given by the "info" step
  if(details.cellar) {
    edl.splice(edl.length - 1, 0, useCorrectTemplateEntries(modules.cellar, template));
  }
  if(details.garage) {
    edl.splice(edl.length - 1, 0, useCorrectTemplateEntries(modules.garage, template));
  }
  if(details.toiletInBathroom) { //Remove toilet module, add toiletComplex to bathroom module
    let toiletIndex = edl.findIndex((module) => module.name === modules.toilet.name);
    if(toiletIndex !== -1) {
      edl.splice(toiletIndex, 1);
    }

    let bathroomIndex = edl.findIndex((module) => module.name === modules.bathroom.name);
    if(bathroomIndex !== -1) {
      //TODO: this is not sustainable for multi-template system...
      let toiletComplex = template === 'default' ?
        build(entries.toiletComplex, [rate.condition, rate.cleaning, rate.state, rate.typeToiletTank, rate.typeToiletLid]):
        build(entrySimple.toiletComplex, [rate.condition, rate.cleaning, rate.state]);
      let bathtubIndex = edl[bathroomIndex].entry.findIndex((entry) => entry.name === entries.bathtub.name);
      if(bathtubIndex !== -1) {
        edl[bathroomIndex].entry.splice(bathtubIndex + 1, 0, toiletComplex);//adds toilet complex right after bathtub
      } else {
        edl[bathroomIndex].entry.splice(edl[bathroomIndex].entry.length - 1, 0, toiletComplex);//adds toilet complex at the end
      }
    }
  }
  if(details.nbRooms > 1) {
    let roomIndex = edl.findIndex((module) => module.name === modules.room.name);
    for(let i = details.nbRooms; i >= 2; i--) {
      let newRoom = unobserve(useCorrectTemplateEntries(modules.room, template));
      newRoom.name = newRoom.name + " " + i;
      if (roomIndex !== -1) {
        edl.splice(roomIndex + 1, 0, newRoom); //add rooms in the right order
      } else {
        edl.splice(edl.length - 1, 0, newRoom); //add rooms in the right order
      }
    }
  }

  return fillIds(edl);
};

const useCorrectTemplateEntries = (module, template) => {
  let templateModule = structuredClone(module); //Clone, so as to not modify original module definition
  if (!_.isArray(module.entry)) { //There are different entry lists for each templates
    templateModule.entry = _.has(module.entry, template) ? module.entry[template] : module.entry['default'];
  } //else use entry as is => it is the same for all templates
  return templateModule;
}

const addRateDiffToConvention = (convention, moduleName, entryName, subEntryName, rate, input, output) => {
  let diff = {input, output};
  let path = [moduleName, entryName];
  if(subEntryName) {
    path.push(subEntryName);
  }
  path.push(rate);

  return _.set(convention, path, diff);
}

const compareEntriesForConvention = (outputRates, inputRates, convention, moduleName, entryName, subEntryName) => {
  _.forEach(outputRates, (outputRate, rate) => {
    if(rate !== 'photo' && rate !== 'toQuote' && rate !== 'preQuote') {
      let inputRate = inputRates[rate] || null;

      //Case empty string, treat as null
      inputRate = (inputRate === '') ? null : inputRate;
      outputRate = (outputRate === '') ? null : outputRate;

      if(rate === 'number') {
        inputRate = _.isInteger(inputRate) ? inputRate.toString() : inputRate;
        outputRate = _.isInteger(outputRate) ? outputRate.toString() : outputRate;
      }
      let trimmedInputRate = inputRate && _.isString(inputRate) ? inputRate.trim() : inputRate;
      let trimmedOutputRate = outputRate && _.isString(outputRate) ? outputRate.trim() : outputRate;
      if (inputRate !== undefined && trimmedInputRate !== trimmedOutputRate) {
        convention = addRateDiffToConvention(convention, moduleName, entryName, subEntryName, rate, inputRate, outputRate);
      }
    }
  });
}

export const buildConvention = (inputInfo, inputEdl, outputInfo, outputEdl) => {
  let convention = {};
  const manuelle = outputInfo.conventionSortieManuelle !== undefined && outputInfo.conventionSortieManuelle === true;

  if(inputInfo.comment !== outputInfo.comment) {
    _.set(convention, 'comment', {
      input: inputInfo.comment,
      output: outputInfo.comment
    });
  }

  _.forEach(outputEdl, (outputModule) => {
    let moduleName = outputModule.name;
    let inputModule = _.find(inputEdl, (m) => {
      return m.id === outputModule.id;
    });

    if(inputModule === undefined) {
      inputModule = [];
    }

    _.forEach(outputModule.entry, (outputEntry) => {
      let entryName = outputEntry.name;
      let inputEntry = _.find(inputModule.entry, (e) => {
        if(outputEntry.id !== undefined && e.id !== undefined) {
          return e.id === outputEntry.id
        } else {
          return e.name === outputEntry.name;
        }
      });

      if (outputEntry.content !== undefined) { //Subentries
        if(inputEntry === undefined) {
          inputEntry = {content: []};
        }

        _.forEach(outputEntry.content, (outputSubEntry) => {
          let subEntryName = outputSubEntry.name;
          let inputSubEntry = _.find(inputEntry.content, (s) => {
            if(outputSubEntry.id !== undefined && s.id !== undefined) {
              return s.id === outputSubEntry.id
            } else {
              return s.name === outputSubEntry.name;
            }
          });

          if(inputSubEntry === undefined) {
            inputSubEntry = {rate: {}};
          }

          if(!manuelle || (outputSubEntry.rate['toQuote'] !== undefined && outputSubEntry.rate['toQuote'] === true)){
            compareEntriesForConvention(outputSubEntry.rate, inputSubEntry.rate, convention, moduleName, entryName, subEntryName);
          }
        });
      } else {
        if(inputEntry === undefined) {
          inputEntry = {rate: {}};
        }

        if(!manuelle || (outputEntry.rate['toQuote'] !== undefined && outputEntry.rate['toQuote'] === true)) {
          compareEntriesForConvention(outputEntry.rate, inputEntry.rate, convention, moduleName, entryName, null);
        }
      }
    })
  });

  return convention;
}

export const autoEdlFilteredRatesByIndex = ["cleaning"];
export const autoEdlFilteredRatesByLabel = ["Nature"];

export default {
  newEdlData,
  buildConvention
}


