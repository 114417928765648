<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="$t('Récapitulatif')"
      :back="true"
    >
      <template #back-actions>
        <a @click="backToEdit">
          <i class="f7-icons small-icon">chevron_left</i> <span>{{ $t('Précédent') }}</span>
        </a>
      </template>
      <template #global-actions>
        <f7-link
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="logout"
        >
          <i class="f7-icons">power</i>
        </f7-link>
      </template>
    </navigation-top>

    <f7-page-content ref="recap">
      <edl-recap-content
        :edl="edl"
        :info="info"
        :scroll="currentScroll"
        @track="trackScroll"
      />
      <f7-row>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            large
            raised
            fill
            @click="goToSignature"
          >
            {{ $t('Valider et signer') }}
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import NavigationTop from "../../layout/NavigationTop";
  import _ from "lodash";
  import autoEdl from "@/components/mixins/autoEdl";
  import EdlRecapContent from "@/components/pages/Edl/EdlRecap/EdlRecapContent.vue";
  import {unobserve} from "@/store/helper";
  import {fillIds} from "@/composables/prepare";

  export default {
    name: "AutoEdlRecap",
    components: {
      NavigationTop,
      EdlRecapContent
    },
    mixins: [firewall, autoEdl],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        preloader: null,
        edl: null,
        info: null,
        currentScroll: 0,
        throttledHandleScroll: null
      }
    },
    watch: {
      me: {
        handler: 'setup'
      }
    },
    beforeUnmount: function () {
      this.$refs.recap.$el.removeEventListener('scroll', this.throttledHandleScroll);
    },
    methods: {
      setup: function () {
        this.setCurrentLocalEdlId(this.autoEdl.id);
        let edl = unobserve(this.getCurrentLocalEdl);
        this.edl = fillIds(edl.edl); //make sure all level ids are defined
        this.info = _.omit(edl, ['edl']);
      },
      backToEdit: function () {
        this.updateAutoEdl({id: this.userId, path: 'recap', value: false});
        this.f7router.navigate({name: 'autoEdl'}, {reloadAll: true});
      },
      goToSignature: function () {
        this.updateAutoEdl({id: this.userId, path: 'signature', value: true});
        this.f7router.navigate({name: 'autoEdlSignature'}, {reloadAll: true});
      },
      trackScroll: function () {
        this.throttledHandleScroll = _.throttle(this.handleScroll, 100);
        this.$refs.recap.$el.addEventListener('scroll', this.throttledHandleScroll);
      },
      handleScroll: function () {
        this.currentScroll = this.$refs.recap.$el.scrollTop;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .small-icon {
    font-size: 16px;
  }
  .header-module {
    font-size: 34px;
  }
  .header-entry {
    font-size: 20px;
  }
  .header-sub-entry {
    color: black;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .rate-name {
    font-weight: bold;
    font-size: 16px;
  }
  .rate {
    font-size: 16px;
  }
  .photos {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--f7-theme-color-grey);
    :deep(.block-header) {
      width: 100%;
    }
    .photo {
      float: left;
      height: auto;
      img {
        width: 100%; /* or any custom size */
        height: auto;
        object-fit: contain;
      }
    }
  }
  .row.photos-context {
    margin-top: 10px;
  }
  .nl2br {
    white-space: pre-line;
  }
</style>
