<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      show-sub-nav
      back-title="Retour"
      back="edlsRemote"
    >
      <template #global-actions>
        <p
          v-if="isLoading"
          class="status"
        >
          Chargement...
        </p>
        <p
          v-else-if="isDownloadingCsv"
          class="status"
        >
          <f7-preloader/> Téléchargement...
        </p>
        <a
          v-else-if="hasCostingsHistory"
          class="button action"
          @click="exportCsv"
        >
          <i class="f7-icons">table</i> Export Chiffrages (.csv)
        </a>
      </template>
    </navigation-top>
    <navigation-bottom current="edls" />

    <f7-page-content ref="history">
      <f7-block v-if="history">
        <f7-card class="global-info">
          <f7-card-content class="edl-info">
            <f7-row>
              <f7-col>
                <p class="edl-info-element"><strong>Référence</strong> <span>{{history[0].edl.housingRef}}</span></p>
              </f7-col>
              <f7-col>
                <p class="edl-info-element"><strong>Adresse</strong> <span class="nl2br">{{history[0].edl.address}}</span></p>
              </f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </f7-block>
      <f7-block v-for="(instance, index) in history" :key="instance.edl.id">
        <f7-card>
          <f7-card-header>
            <span>EDL {{instance.edl.edlIO}} #{{instance.edl.id}}</span>
            <span class="edl-date">le {{ formatDate(instance.edl.date, 'PPPp') }}</span>
          </f7-card-header>
          <f7-card-content class="edl-info">
            <f7-row>
              <f7-col>
                <p class="edl-info-element"><strong>Locataire</strong> <span>{{instance.edl.renter}}</span></p>
                <p class="edl-info-element"><strong>Technicien</strong> <span>{{instance.edl.name}}</span></p>
              </f7-col>
              <f7-col>
                <p class="edl-info-element"><strong>Synchronisé</strong> <span>le {{formatDate(instance.edl.syncDate, 'PPPp')}}</span></p>
                <p class="edl-info-element" v-if="instance.edl.comment"><strong>Commentaire</strong> <span class="nl2br">{{instance.edl.comment}}</span></p>
              </f7-col>
            </f7-row>
            <hr v-if="instance.costings.length > 0">
            <f7-row v-if="instance.costings.length > 0" class="edl-costings">
              <f7-col>
                <p class="edl-costings-title">Chiffrage</p>
                <p class="edl-costings-updated">dernière mise à jour le {{formatDate(instance.edl.lastUpdatedDate, 'PPPp')}}</p>
                <f7-row class="edl-costings-header">
                  <f7-col width="30" medium="15">Module</f7-col>
                  <f7-col width="40" medium="30">Élément</f7-col>
                  <f7-col class="show-for-large" width="30">Travaux</f7-col>
                  <f7-col width="15" medium="10">HT</f7-col>
                  <f7-col class="show-for-large text-align-center" width="5">%TVA</f7-col>
                  <f7-col width="15" medium="10" class="text-align-right">TTC</f7-col>
                </f7-row>
                <f7-row class="edl-costings-row" v-for="(costing, index) in instance.costings" :key="'costing_'+index">
                  <f7-col width="30" medium="15">{{ costing.module }}</f7-col>
                  <f7-col width="40" medium="30">{{ costing.name }}</f7-col>
                  <f7-col class="show-for-large" width="30">{{ costing.task }}</f7-col>
                  <f7-col width="15" medium="10"><span v-if="costing.cost">{{ formatCurrency(costing.cost) }}€</span><span v-else>-</span></f7-col>
                  <f7-col class="show-for-large text-align-center" width="5"><span v-if="costing.cost">{{ costing.taxRate/100 }}%</span><span v-else>-</span></f7-col>
                  <f7-col width="15" medium="10" class="text-align-right"><span v-if="costing.cost">{{ formatCurrency(costing.cost * (1 + costing.taxRate / 10000)) }}€</span><span v-else>-</span></f7-col>
                </f7-row>
                <f7-row class="edl-costings-total-header">
                  <f7-col width="70" medium="75"></f7-col>
                  <f7-col width="15" medium="10">HT</f7-col>
                  <f7-col class="show-for-large text-align-center" width="5">TVA</f7-col>
                  <f7-col width="15" medium="10" class="text-align-right">TTC</f7-col>
                </f7-row>
                <f7-row class="edl-costings-total">
                  <f7-col width="70" medium="75" class="edl-costings-total-title">TOTAL</f7-col>
                  <f7-col width="15" medium="10">{{formatCurrency(calculateTotalHT(instance.costings))}}€</f7-col>
                  <f7-col class="show-for-large text-align-center" width="5">{{formatCurrency(calculateTotalTVA(instance.costings))}}€</f7-col>
                  <f7-col width="15" medium="10" class="text-align-right">{{formatCurrency(calculateTotalTTC(instance.costings))}}€</f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
        <f7-row v-if="index < history.length - 1" class="duplicated-from">
          <f7-col><p>dupliqué à partir de</p><i class="f7-icons">arrow_down_circle_fill</i></f7-col>
        </f7-row>
      </f7-block>

    </f7-page-content>
  </f7-page>
</template>

<script>
import NavigationBottom from "@/components/layout/NavigationBottom.vue";
import NavigationTop from "@/components/layout/NavigationTop.vue";
import costing from "@/components/mixins/costing";
import firewall from "@/components/mixins/firewall";
import {f7ready} from "framework7-vue";
import api from "@/api";
import formatDate from "@/components/filters/format_date";
import currency from "currency.js";
import _ from "lodash";

export default {
  name: "EdlHistory",
  components: {NavigationTop, NavigationBottom},
  mixins: [costing, firewall],
  props: {
    edlId: {
      type: String,
      default: null
    },
    f7router: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      history: null,
      hasCostingsHistory: false,
      isLoading: false,
      isDownloadingCsv: false
    };
  },
  computed: {
    title: function () {
      return "Historique de l'EDL #"+this.edlId;
    }
  },
  mounted: function () {
    f7ready(() => {
      this.fetchHistory();
    });
  },
  methods: {
    fetchHistory: function () {
      this.isLoading = true;
      api.getHistory(this.edlId).then((response) => {
        this.history = response.data.history;
        this.hasCostingsHistory = response.data.hasCostingsHistory;
        this.isLoading = false;
      });
    },
    exportCsv: function () {
      this.isDownloadingCsv = true;
      api.downloadHistoryCsv(this.edlId).then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = URL.createObjectURL(blob);
        link.download = 'HistoriqueChiffragesEDLs_'+(this.history[0].edl.housingRef || this.history[0].edl.id).replace(" ", "")+'.csv'; //Gives name to file but also forces a download
        link.click();
        //URL.revokeObjectURL(link.href); //Makes any reload of the url impossible
        this.isDownloadingCsv = false;
      }).catch((error) => {
        this.throwError(
          "Une erreur est survenue lors de l'export du CSV. Veuillez nous contacter si cela se reproduit.",
          "Erreur téléchargement",
          _.has(error, 'response.data.message') ? error.response.data.message : error.message,
          error
        );
        this.isDownloadingCsv = false;
      });
    },
    calculateTotalHT: function (costings) {
      return costings.reduce((total, costing) => {
        total += costing.cost;
        return total;
      },0);
    },
    calculateTotalTVA: function (costings) {
      return costings.reduce((total, costing) => {
        total += costing.cost * costing.taxRate / 10000;
        return total;
      },0);
    },
    calculateTotalTTC: function (costings) {
      return costings.reduce((total, costing) => {
        total += costing.cost * (1 + costing.taxRate / 10000);
        return total;
      },0);
    },
    formatDate(value, pattern) {
      return formatDate(value, pattern);
    },
    formatCurrency: function (value) {
      return currency(value, {fromCents: true, separator: " ", decimal: ",", symbol: ""}).format();
    },
  }
}
</script>

<style scoped lang="scss">
  .block {
    margin: 0;
  }
  .nl2br {
    display: block;
    white-space: pre;
  }
  .action {
    .f7-icons {
      margin-right: 8px;
    }
  }
  .status {
    --f7-preloader-size: 22px;
    --f7-preloader-color: white;
    .preloader {
      .preloader-inner-line {
        height: 35%;
        width: 10%;
      }
      margin-right: 5px;
    }
  }
  .global-info {
    background: var(--f7-theme-color-dark-grey);
    color: white;
  }
  .duplicated-from {
    text-align: center;
    i {
      color: var(--f7-theme-color-very-dark-grey);
    }
    p {
      color: var(--f7-theme-color-dark-grey);
      font-size: small;
      margin: 0 0 5px 0;
    }
  }
  .edl-date {
    font-size: small;
    color: var(--f7-theme-color-very-dark-grey);
    font-style: italic;
  }
  .edl-info {
    p {
      margin: 2px 0 2px 0;
    }
    .edl-info-element {
      span {
        margin-left: 5px;
      }
    }
    hr {
      margin: 30px 0 30px 0;
      border: 0.5px solid var(--f7-theme-color-grey);
    }
  }
  .edl-costings {
    margin-bottom: 10px;
    .edl-costings-title {
      font-size: large;
      margin: 5px 0 5px 0;
      text-align: center;
      display: block;
    }
    .edl-costings-updated {
      text-align: center;
      font-size: x-small;
      margin-bottom: 10px;
      color: var(--f7-theme-color-dark-grey);
    }
    .edl-costings-header {
      font-weight: bold;
    }
    .edl-costings-header, .edl-costings-row {
      & > div {
        margin-top: 8px;
        padding-left: 3px;
        padding-right: 5px;
        line-height: 16px;
      }
    }
    .edl-costings-total{
      padding: 0 5px 10px 0;
      background-color: var(--f7-theme-color-light-grey);
      color: var(--f7-theme-color-very-dark-grey);
      .edl-costings-total-title {
        font-weight: bold;
        text-align: right;
        padding-right: 40px;
      }
    }
    .edl-costings-total-header{
      margin-top: 10px;
      padding: 10px 5px 0 0;
      background-color: var(--f7-theme-color-light-grey);
      color: var(--f7-theme-color-very-dark-grey);
      font-weight: bold;
    }
  }

  @media screen and (max-width: 767px) {
    .status {
      display: none;
    }
    .show-for-large {
      display: none !important;
    }
  }
</style>
