<template>
  <f7-popup
    :opened="opened"
    @popup:closed="saveCommentPopup"
  >
    <f7-page bg-color="white">
      <f7-navbar>
        <f7-nav-title>Commentaires EDL #{{ edlId }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="saveCommentPopup">
            OK
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <f7-input
          placeholder="Ajoutez des commentaires sur cet EDL ici, par exemple pour noter des changements ayant eut lieu avant l'EDL de sortie..."
          class="comment-input"
          type="textarea"
          :value="value"
          resizable
          @input="updateCommentPopupContent"
        />
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
export default {
  name: "EdlListComment",
  props: {
    opened: {
      type: Boolean
    },
    value: {
      type: String,
      default: ""
    },
    edlId: {
      type: Number,
      default: null
    }
  },
  emits: ['update', 'save'],
  methods: {
    updateCommentPopupContent: function (event) {
      this.$emit('update', event.target.value);
    },
    saveCommentPopup: function () {
      this.$emit('save');
    },
  }
}
</script>

<style scoped>

</style>
