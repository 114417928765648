<template>
  <f7-toolbar
    tabbar
    position="bottom"
  >
    <f7-link
      :tab-link-active="isActive('edls')"
      text="EDLs enregistrés"
      icon-ios="f7:doc_checkmark"
      @click="navigateTo('edlsRemote')"
    />
    <f7-link
      :tab-link-active="isActive('current')"
      text="EDLs en cours"
      icon-ios="f7:pencil_ellipsis_rectangle"
      @click="navigateTo('edlsLocal')"
    />
    <f7-link
      :tab-link-active="isActive('new')"
      text="Nouvel EDL"
      icon-ios="f7:plus_square"
      @click="handleNewEdl"
    />
    <f7-link
      :tab-link-active="isActive('account')"
      text="Mon compte"
      icon-ios="f7:gear_alt"
      @click="navigateTo('account')"
    />

    <f7-actions
      ref="newEdlSelectType"
      :opened="newEdlActionsOpened"
    >
      <f7-actions-group>
        <f7-actions-label>Choisissez un type d'EDL à créer</f7-actions-label>
        <f7-actions-button
          bold
          @click="navigateTo('newEdl', {edlType: 'entrant', edlTemplate: 'default'})"
        >
          Entrant
        </f7-actions-button>
        <f7-actions-button
          bold
          @click="navigateTo('newEdl', {edlType: 'entrant', edlTemplate: 'simple'})"
        >
          Entrant simplifié
        </f7-actions-button>
        <f7-actions-button @click="navigateTo('newEdl', {edlType: 'sortant', edlTemplate: 'default'})">
          Sortant
        </f7-actions-button>
        <f7-actions-button @click="navigateTo('newEdl', {edlType: 'sortant', edlTemplate: 'simple'})">
          Sortant simplifié
        </f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">
          Annuler
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-toolbar>
</template>

<script>
  import { f7 } from "framework7-vue";
  export default {
    name: "NavigationBottom",
    props: {
      current: {
        type: String,
        default: null
      }
    },
    data: function () {
      return {
        newEdlActionsOpened: false
      };
    },
    methods: {
      isActive: function (name) {
        return this.current === name;
      },
      navigateTo: function (name, params = null) {
        f7.view.main.router.navigate({name, params});
      },
      handleNewEdl: function () {
        this.newEdlActionsOpened = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .toolbar {
    border-top: 1px solid #ceced3;
    .link {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 400px) {
    .toolbar {
      .link {
        font-size: 10px;
      }
    }
  }
</style>
