import entry, {build, addableEntries} from "./edlDataStructureModuleEntry";
import entrySimple, {addableEntries as addableEntriesSimple} from "./edlDataStructureModuleEntrySimple";
import rate from "./edlDataStructureModuleEntryRate";
import _ from "lodash";

const templates = {
  'default': {
    'addSuffix': "(détaillé)",
    'addableEntries': addableEntries
  },
  'simple': {
    'addSuffix': "(simplifié)",
    'addableEntries': addableEntriesSimple
  }
}

const energy = {
  'name': 'Energie',
  'displayNumber': 2, // Ordre d'affichage à l'écran
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'cantBeAdded': true, // Ne peut pas être ajouté manuellement (doit être ajouté automatiquement lors de la création de l'edl)
  'entry': [
    build(entry.hotwaterHeating),
    build(entry.heating),
    build(entry.electricMeter),
    build(entry.hotwaterMeter ),
    build(entry.hotwaterMeter), //Duplicate as there can be two
    build(entry.photos)
  ]
};

const keys = {
  'name': 'Clés',
  'displayNumber': 1, // Ordre d'affichage à l'écran
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'cantBeAdded': true, // Ne peut pas être ajouté manuellement (doit être ajouté automatiquement lors de la création de l'edl)
  'entry': [
    build(entry.keys),
    build(entry.photos)
  ]
};

const entrance = {
  'name': 'Entrée',
  'displayNumber': 3, // Ordre d'affichage à l'écran
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'entry': {
    'default':[
      build(entry.walls),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.storage),
      build(entry.mezzanine),
      build(entry.radiators),
      build(entry.ventilation),
      build(entry.equipmentFurniture),
      build(entry.electricSystemComplex),
      build(entry.photos)
    ],
    'simple':[
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling ),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entrySimple.windows),
      build(entrySimple.radiators),
      build(entrySimple.ventilation),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const livingRoom = {
  'name': 'Salon',
  'displayNumber': 4, // Ordre d'affichage à l'écran
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'entry': {
    'default': [
      build(entry.walls),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.storage),
      build(entry.radiators),
      build(entry.ventilation),
      build(entry.equipmentFurniture),
      build(entry.electricSystemComplex),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entrySimple.windows),
      build(entrySimple.radiators),
      build(entrySimple.ventilation),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const room = {
  'name': 'Chambre',
  'displayNumber': 6, // Ordre d'affichage à l'écran
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'entry': {
    'default': [
      build(entry.walls),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.storage),
      build(entry.radiators),
      build(entry.ventilation),
      build(entry.equipmentFurniture),
      build(entry.electricSystemComplex),
      build(entry.photos)
    ],
    'simple':[
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entrySimple.windows),
      build(entrySimple.radiators),
      build(entrySimple.ventilation),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  },
};

const kitchen = {
  'name': 'Cuisine',
  'displayNumber': 5, // Ordre d'affichage à l'écran
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'entry': {
    'default': [
      build(entry.walls),
      build(entry.tiles),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.sinkComplex),
      build(entry.radiators),
      build(entry.ventilation),
      build(entry.equippedKitchen),
      build(entry.equipmentFurniture),
      build(entry.electricSystemComplex),
      build(entry.photos)
    ],
    'simple':[
      build(entrySimple.walls),
      build(entrySimple.tiles ),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entrySimple.windows),
      build(entrySimple.sinkComplex),
      build(entrySimple.kitchenFurniture),
      build(entrySimple.equippedKitchen),
      build(entrySimple.radiators),
      build(entrySimple.ventilation),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  },
};

const hallway = {
  'name': 'Couloir',
  'templates': ['default', 'simple'],
  'displayNumber': 7, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default': [
      build(entry.walls, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),
      build(entry.floor, [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]),
      build(entry.ceiling, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeCeiling2]),
      build(entry.door, [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const cupboard = {
  'name': 'Placard',
  'templates': ['default', 'simple'],
  'displayNumber': 14, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default': [
      build(entry.walls),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const dressingRoom = {
  'name': 'Dressing',
  'templates': ['default', 'simple'],
  'displayNumber': 13, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default':[
      build(entry.walls),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const laundryRoom = {
  'name': 'Buanderie',
  'templates': ['default', 'simple'],
  'displayNumber': 11, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default':[
      build(entry.walls),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const bathroom = {
  'name': 'Salle de bain',
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'displayNumber': 9, // Ordre d'affichage à l'écran
  'entry': {
    'default': [
      build(entry.walls),
      build(entry.tiles),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.sinkComplex, [rate.condition, rate.cleaning, rate.state, rate.typeBathroomSink, rate.typeDrain, rate.typeFaucet, rate.typeKitchenSplashback, rate.typeSiphon, rate.typeSeal, rate.typeKitchenUpperElement, rate.typeSinkCabinet]),
      build(entry.radiators),
      build(entry.ventilation),
      build(entry.bathtub),
      build(entry.shower),
      build(entry.equipmentBathroom),
      build(entry.equipmentFurniture),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.tiles),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entrySimple.windows),
      build(entrySimple.sinkComplex),
      build(entrySimple.bathroomFurniture),
      build(entrySimple.radiators),
      build(entrySimple.ventilation),
      build(entrySimple.shower),
      build(entrySimple.bathtub),
      // build(entrySimple.toiletComplex, [rate.condition, rate.cleaning, rate.state]), //ADDED if no separate WC
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const toilet = {
  'name': 'WC',
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'displayNumber': 10, // Ordre d'affichage à l'écran
  'entry': {
    'default':[
      build(entry.walls),
      build(entry.tiles),
      build(entry.floor),
      build(entry.ceiling),
      build(entry.plinths),
      build(entry.door),
      build(entry.windows),
      build(entry.toiletComplex),
      build(entry.radiators),
      build(entry.ventilation),
      build(entry.handWash),
      build(entry.equipmentToilet),
      build(entry.equipmentFurniture),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.tiles),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.door),
      build(entrySimple.windows),
      build(entrySimple.ventilation),
      build(entrySimple.toiletComplex),
      build(entrySimple.handWash),
      build(entry.equipmentFurniture),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const extension = {
  'name': 'Annexe',
  'templates': ['default', 'simple'],
  'displayNumber': 15, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default': [
      build(entry.walls, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),
      build(entry.floor, [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]),
      build(entry.ceiling, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeCeiling2]),
      build(entry.door, [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const cellar = {
  'name': 'Cave',
  'templates': ['default', 'simple'],
  'displayNumber': 16, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default': [
      build(entry.walls, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),
      build(entry.floor, [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]),
      build(entry.ceiling, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeCeiling2]),
      build(entry.door, [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const garage = {
  'name': 'Garage/Box',
  'templates': ['default', 'simple'],
  'displayNumber': 17, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default':[
      build(entry.walls, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),
      build(entry.floor, [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]),
      build(entry.ceiling, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeCeiling2]),
      build(entry.door, [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.door),
      build(entry.equipmentFurniture),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const terrace = {
  'name': 'Terrasse/Balcon',
  'templates': ['default', 'simple'],
  'displayNumber': 12, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default':[
      build(entry.walls, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),
      build(entry.floor, [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]),
      build(entry.ceiling, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),//case terrace with ceiling, using wall type
      build(entry.door, [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]),
      build(entry.railing),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple': [
      build(entrySimple.walls, [rate.condition, rate.cleaning]),
      build(entrySimple.railing, [rate.condition, rate.cleaning, rate.state]),
      build(entrySimple.floor, [rate.condition, rate.cleaning]),
      build(entry.equipmentFurniture),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const garden = {
  'name': 'Jardin',
  'templates': ['default', 'simple'],
  'displayNumber': 18, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': [
    build(entry.fence),
    build(entry.lawn),
    build(entry.vegetation),
    build(entry.electricSystemSimple),
    build(entry.photos)
  ]
};

const stairs = {
  'name': 'Escalier',
  'templates': ['default', 'simple'],
  'displayNumber': 8, // Ordre d'affichage à l'écran
  'addToBaseVersion': false, // Doit être ajouté par default à la création d'un EDL
  'entry': {
    'default':[
      build(entry.walls, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeWall2]),
      build(entry.floor, [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]),
      build(entry.ceiling, [rate.condition, rate.cleaning, rate.color, rate.state, rate.typeCeiling2]),
      build(entry.door, [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]),
      build(entry.steps),
      build(entry.railing),
      build(entry.electricSystemSimple),
      build(entry.photos)
    ],
    'simple':[
      build(entrySimple.walls),
      build(entrySimple.floor),
      build(entrySimple.ceiling),
      build(entrySimple.plinths),
      build(entrySimple.steps),
      build(entrySimple.railing),
      build(entrySimple.windows),
      build(entry.equipmentFurniture),
      build(entrySimple.storage),
      build(entrySimple.electricSystemSimple),
      build(entrySimple.lighting),
      build(entry.photos)
    ]
  }
};

const generalEquipment = {
  'name': 'Equipements intérieurs/extérieurs',
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'displayNumber': 19, // Ordre d'affichage à l'écran
  'cantBeAdded': true, // Ne peut pas être ajouté manuellement (doit être ajouté automatiquement lors de la création de l'edl)
  'entry': [
    build(entry.smokeDetector),
    build(entry.letterboxLock),
    build(entry.letterbox),
    build(entry.intercom),
    build(entry.doorbell),
    build(entry.fuseboard),
    build(entry.photos)
  ]
};

const generalCleaning = {
  'name': 'Etat de propreté général',
  'displayNumber': 99,
  'templates': ['default', 'simple'],
  'addToBaseVersion': true,
  'cantBeAdded': true, // Ne peut pas être ajouté manuellement (doit être ajouté automatiquement lors de la création de l'edl) | est ajouté par le controller
  'entry': [
    build(entry.cleaning)
  ]
};

const modules = {
  energy,
  keys,
  entrance,
  livingRoom,
  kitchen,
  room,
  toilet,
  bathroom,
  cellar,
  garage,
  extension,
  hallway,
  stairs,
  terrace,
  garden,
  laundryRoom,
  dressingRoom,
  cupboard,
  generalEquipment,
  generalCleaning
};

export const all = Object.values(modules);

export const generateAddableModules = () => {
  const allModules = all
    .filter(module => module.cantBeAdded === undefined || module.cantBeAdded === false);

  const addableModulesForAllTemplates = [];
  allModules.forEach((module) => {
    if(_.isArray(module.entry)) {
      module.addName = module.name;
      addableModulesForAllTemplates.push(module);
    } else if(_.isObject(module.entry)) {
      module.templates.forEach((template) => {
        let templateModule = structuredClone(module);
        templateModule.entry = module.entry[template];
        templateModule.addName = module.name + " " +templates[template].addSuffix;
        addableModulesForAllTemplates.push(templateModule);
      });
    } else {
      console.error('Addable module.entry is not Array nor Object');
    }
  });

  return addableModulesForAllTemplates.sort((a,b) => a.name.localeCompare(b.name));
}

export const generateAddableEntries = () => {
  const addableEntriesForAllTemplates = [];
  _.forEach(templates, (templateInfo, template, ) => {
    templateInfo.addableEntries.forEach((entry) => {
      let templateEntry = structuredClone(entry);
      templateEntry.addName = entry.name + (template !== 'default' ? (" " + templateInfo.addSuffix) : "");
      addableEntriesForAllTemplates.push(templateEntry);
    });
  });

  return addableEntriesForAllTemplates.sort((a,b) => a.name.localeCompare(b.name));
}

export default modules;
