<template>
  <f7-popup
    ref="mediaEditPopup"
    :opened="isOpen"
    class="media-edit-popup"
    @popup:opened="resizeMediaEdit"
    @popup:closed="close"
  >
    <f7-page ref="mediaEditPage">
      <f7-navbar title="Edition du media">
        <f7-nav-right>
          <f7-link popup-close>
            Annuler
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <div
          ref="mediaEditContainer"
          class="media-edit-container"
          :style="'background-image: url('+media+');'
            +'width:'+containerDimensions.maxWidth+'px;'
            +'height:'+containerDimensions.height+'px;'"
        >
          <VueSignaturePad
            v-if="isOpen"
            ref="mediaEdit"
            :width="containerDimensions.width+'px'"
            :height="containerDimensions.height+'px'"
            :images="[media]"
            :options="{ penColor }"
            :custom-style="{
              'margin-left':(containerDimensions.maxWidth - containerDimensions.width)/2+'px',
              'margin-top':(containerDimensions.maxHeight - containerDimensions.height)/2+'px'
            }"
          />
          <f7-row class="margin-top">
            <f7-col width="40">
              <f7-button
                outline
                @click="undo"
              >
                Corriger
              </f7-button>
            </f7-col>
            <f7-col width="15" />
            <f7-col width="5">
              <f7-button
                color="black"
                fill
                :class="{'active-pen': penColor === 'black'}"
                @click="penColor = 'black'"
              />
            </f7-col>
            <f7-col width="5">
              <f7-button
                color="blue"
                fill
                :class="{'active-pen': penColor === 'blue'}"
                @click="penColor = 'blue'"
              />
            </f7-col>
            <f7-col width="5">
              <f7-button
                color="green"
                fill
                :class="{'active-pen': penColor === 'green'}"
                @click="penColor = 'green'"
              />
            </f7-col>
            <f7-col width="5">
              <f7-button
                color="red"
                fill
                :class="{'active-pen': penColor === 'red'}"
                @click="penColor = 'red'"
              />
            </f7-col>
            <f7-col width="5">
              <f7-button
                color="yellow"
                fill
                :class="{'active-pen': penColor === 'yellow'}"
                @click="penColor = 'yellow'"
              />
            </f7-col>
            <f7-col width="5">
              <f7-button
                color="white"
                fill
                :class="{'active-pen': penColor === 'white'}"
                @click="penColor = 'white'"
              />
            </f7-col>
            <f7-col width="5">
              <i class="f7-icons">pencil</i>
            </f7-col>
          </f7-row>
          <f7-row class="margin-top">
            <f7-col width="40">
              <f7-button
                outline
                @click="clear"
              >
                Recommencer
              </f7-button>
            </f7-col>
            <f7-col width="40">
              <f7-button
                fill
                @click="save"
              >
                Enregistrer
              </f7-button>
            </f7-col>
          </f7-row>
        </div>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import {PhotoDatabase, resizeBase64} from "@/composables/photoStorage";

export default {
  name: "EdlEditRatePhotoEditor",
  props: {
    media: {
      type: String,
      default: null
    },
    mediaEditPopupOpened: {
      type: Boolean,
      default: false
    },
    photoDimensions: {
      type: Object,
      default: () => {
        return {
          width: null,
          height: null
        };
      }
    },
    photoId: {
      type: Number,
      default: null
    },
    rate: {
      type: Object,
      default: null
    }
  },
  emits: ['close', 'update'],
  data: function () {
    return {
      penColor: 'white',
      containerDimensions: {
        width: null,
        height: null,
        maxWidth: null,
        maxHeight: null
      },
    }
  },
  computed: {
    isOpen: function () {
      return this.media !== null
        && this.mediaEditPopupOpened
        && this.photoDimensions.width !== null
        && this.photoDimensions.height !== null;
    }
  },
  methods: {
    resizeMediaEdit: function () {
      if(this.media !== null) {
        this.$refs.mediaEdit.resizeCanvas();
        let maxWidth = 400;
        let maxHeight = 400;
        let pageWidth = this.$refs.mediaEditPage.$el.clientWidth;
        let pageHeight = this.$refs.mediaEditPage.$el.clientHeight;
        let ratio = this.photoDimensions.width / this.photoDimensions.height;
        if (ratio >= 1) { //landscape
          if (this.photoDimensions.width > maxWidth) {
            this.containerDimensions.width = maxWidth;
            this.containerDimensions.height = maxWidth / ratio;
          } else {
            this.containerDimensions.width = this.photoDimensions.width;
            this.containerDimensions.height = this.photoDimensions.height;
          }
        } else { //portrait
          if (this.photoDimensions.height > maxHeight) {
            this.containerDimensions.height = maxHeight;
            this.containerDimensions.width = maxHeight * ratio;
          } else {
            this.containerDimensions.width = this.photoDimensions.width;
            this.containerDimensions.height = this.photoDimensions.height;
          }
        }

        this.containerDimensions.maxWidth = pageWidth ? Math.max(0.95 * pageWidth, maxWidth) : maxWidth;
        this.containerDimensions.maxHeight = pageHeight ? Math.min(0.85 * pageHeight, maxHeight) : maxHeight;

        this.$nextTick(() => {
          this.$refs.mediaEdit.resizeCanvas();
        });
      }
    },
    undo: function () {
      this.$refs.mediaEdit.undoSignature();
    },
    clear: function () {
      this.$refs.mediaEdit.clearSignature();
    },
    save: function () {
      const { data } = this.$refs.mediaEdit.saveSignature();
      if (!data) {
        this.close();
        return;
      }
      this.$refs.mediaEdit.mergeImageAndSignature(data).then((result) => {
        resizeBase64(result, 800, 800, 'image/jpeg').then((resizedData) => {
          //Use new connexion to database, as using photoDb threw an error:
          //(InvalidStateError: Failed to execute 'objectStore' on 'IDBTransaction': The transaction has finished.)
          const photoDb = new PhotoDatabase();
          photoDb.updatePhoto(this.photoId, resizedData).then(() => {
            this.$emit('update', {
              'other': this.rate.other,
              'photo': {
                _ref: this.rate.photo._ref,
                _data: resizedData
              }
            });
            this.clear();
            this.close();
            photoDb.close();
          }).catch((error) => {
            console.error('Error updating edited photo', error);
            throw error;
          });
        });
      });
    },
    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
  .media-edit-popup {
    .media-edit-container {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .active-pen {
    outline: 2px solid var(--f7-theme-color-very-dark-grey);
  }
</style>
