<template>
  <f7-row class="row-opaque-bg">
    <f7-col
      xsmall="20"
      medium="30"
      width="5"
    />
    <f7-col
      xsmall="60"
      medium="40"
      width="90"
      class="margin-top margin-bottom"
    >
      <f7-button
        large
        raised
        fill
        @click="submit"
      >
        {{ name }}
      </f7-button>
    </f7-col>
    <f7-col
      xsmall="20"
      medium="30"
      width="5"
    />
  </f7-row>
</template>

<script>
export default {
  name: "EdlSubmitRow",
  props: {
    name: {
      type: String,
      default: 'Signer et clôturer cet EDL'
    }
  },
  emits: ['submit'],
  methods: {
    submit() {
      this.$emit('submit');
    }
  }
}
</script>

<style scoped>

</style>
