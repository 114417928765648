import rate from "./edlDataStructureModuleEntryRate";

const walls = {
  'name': 'Murs',
  'defaultRates': [rate.condition, rate.cleaning, rate.typeWall1],
  'autoFillObservation': "wallCeiling",
};

const floor = {
  'name': 'Sols',
  'defaultRates': [rate.condition, rate.cleaning, rate.typeFloor1],
  'autoFillObservation': "floor",
  'cantBeAdded': true,
};

const ceiling = {
  'name': 'Plafond',
  'defaultRates': [rate.condition, rate.cleaning, rate.typeCeiling1],
  'autoFillObservation': "wallCeiling",
  'cantBeAdded': true,
};

const plinths = {
  'name': 'Plinthes',
  'defaultRates': [rate.condition, rate.cleaning, rate.typePlinths],
  'autoFillObservation': "plinth",
  'cantBeAdded': true,
};

const door = {
  'name': 'Porte',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'autoFillObservation': "door",
};

const windows = {
  'name': 'Fenêtres',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'autoFillObservation': "window",
};

const storage = {
  'name': 'Rangements/Placards',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'autoFillObservation': "storage",
};

const steps = {
  'name': 'Marches',
  'defaultRates': [rate.condition, rate.cleaning],
  'cantBeAdded': true
};

const railing = {
  'name': 'Rambarde',
  'defaultRates': [rate.condition, rate.cleaning],
  'cantBeAdded': true,
};

const radiators = {
  'name': 'Radiateurs',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number, rate.typeRadiator],
  'autoFillObservation': "radiator",
  'cantBeAdded': true,
};

const ventilation = {
  'name': 'Ventilation',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeVentilation],
  'autoFillObservation': "vent",
  'cantBeAdded': true,
};

const electricSystemSimple = {
  'name': 'Installations Prise(s)/Interrupteur(s)',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const lighting = {
  'name': 'Lumière(s)',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number]
};

const tiles = {
  'name': 'Faïence',
  'defaultRates': [rate.condition, rate.cleaning],
  'cantBeAdded': true,
};

const sinkComplex = {
  'name': 'Complexe Évier/Vasque/Lavabo',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const toiletComplex = {
  'name': 'Complexe WC',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const equippedKitchen = {
  'name': 'Cuisine équipée',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'cantBeAdded': true,
  'content': [
    {
      'name': 'Meubles',
    },
    {
      'name': 'Four/Micro-onde',
      'autoFillObservation': "oven",
    },
    {
      'name': 'Plaques de cuisson',
      'autoFillObservation': "cookingPlate",
    },
    {
      'name': 'Réfrigérateur',
    },
    {
      'name': 'Hotte',
      'autoFillObservation': "hood",
    },
    {
      'name': 'Lave-vaisselle',
    },
    {
      'name': 'Lave-linge',
    }
  ],
  'orderAlphabetically': false
};

const handWash = {
  'name': 'Lave mains',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const bathtub = {
  'name': 'Baignoire',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'autoFillObservation': "bathtub",
};

const shower = {
  'name': 'Douche',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'autoFillObservation': "bathtub",
};

const kitchenFurniture = {
  'name': 'Meuble(s) Cuisine',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const bathroomFurniture = {
  'name': 'Meuble(s) Salle de bain',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const entries = {
  walls,
  floor,
  ceiling,
  plinths,
  door,
  windows,
  storage,
  steps,
  railing,
  radiators,
  ventilation,
  electricSystemSimple,
  lighting,
  tiles,
  sinkComplex,
  toiletComplex,
  equippedKitchen,
  handWash,
  bathtub,
  shower,
  kitchenFurniture,
  bathroomFurniture,
};

export const all = Object.values(entries);

export const addableEntries = all
  .filter(entry => entry.cantBeAdded === undefined || entry.cantBeAdded === false)
  .sort((a,b) => a.name.localeCompare(b.name));

//See edlDataStructureModuleEntry.js for other base functions

export default entries;
