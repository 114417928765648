<template>
  <f7-row ref="recap">
    <f7-col>
      <f7-row
        v-show="currentModule"
        class="current-module"
      >
        <f7-col>
          <f7-block-header
            v-if="currentModule"
            class="header-module"
          >
            {{ $t(currentModule) }}
          </f7-block-header>
        </f7-col>
      </f7-row>
      <f7-row
        v-for="(module, moduleName) in convention"
        :key="moduleName"
        ref="modules"
        :header="$t(moduleName)"
      >
        <f7-col>
          <f7-block-header class="header-module">
            {{ $t(moduleName) }}
          </f7-block-header>
          <f7-row class="header-io">
            <f7-col width="25" />
            <f7-col
              width="35"
              class="header-io-col"
            >
              {{ $t('Entrant') }}
            </f7-col>
            <f7-col
              width="5"
              class="arrow-separator"
            >
              <i class="f7-icons">arrow_right</i>
            </f7-col>
            <f7-col
              width="35"
              class="header-io-col"
            >
              {{ $t('Sortant') }}
            </f7-col>
          </f7-row>
          <f7-block class="no-padding-horizontal">
            <div
              v-for="(entry, entryName) in module"
              :key="moduleName+'_'+entryName"
              class="entry"
            >
              <f7-col v-if="!hasSubEntries(entry)">
                <f7-row>
                  <f7-col
                    width="25"
                    class="header-entry"
                  >
                    {{ $t(entryName) }}
                  </f7-col>
                  <f7-col width="35">
                    <p
                      v-for="(diff, rate) in entry"
                      :key="moduleName+'_'+entryName+'_input_'+rate"
                      class="diff"
                    >
                      <span class="rate">{{ $t(rateLabel(rate)) }}</span> {{ $t(defaultValue(diff.input, '-')) }}
                    </p>
                  </f7-col>
                  <f7-col width="5" />
                  <f7-col width="35">
                    <p
                      v-for="(diff, rate) in entry"
                      :key="moduleName+'_'+entryName+'_output_'+rate"
                      class="diff"
                    >
                      <span class="rate">{{ $t(rateLabel(rate)) }}</span> {{ $t(defaultValue(diff.output, '-')) }}
                    </p>
                  </f7-col>
                </f7-row>
              </f7-col>
              <f7-col v-else>
                <f7-row
                  v-for="(subEntry, subEntryName) in entry"
                  :key="moduleName+'_'+entryName+'_'+subEntryName"
                  class="sub-entry"
                >
                  <f7-col>
                    <f7-row>
                      <f7-col
                        width="25"
                        class="header-entry"
                      >
                        {{ $t(entryName) }} > <span class="header-sub-entry">{{ $t(subEntryName) }}</span>
                      </f7-col>
                      <f7-col width="35">
                        <p
                          v-for="(diff, rate) in subEntry"
                          :key="moduleName+'_'+entryName+'_'+subEntryName+'_input_'+rate"
                          class="diff"
                        >
                          <span class="rate">{{ $t(rateLabel(rate)) }}</span> {{ $t(defaultValue(diff.input, '-')) }}
                        </p>
                      </f7-col>
                      <f7-col width="5" />
                      <f7-col width="35">
                        <p
                          v-for="(diff, rate) in subEntry"
                          :key="moduleName+'_'+entryName+'_'+subEntryName+'_output_'+rate"
                          class="diff"
                        >
                          <span class="rate">{{ $t(rateLabel(rate)) }}</span> {{ $t(defaultValue(diff.output, '-')) }}
                        </p>
                      </f7-col>
                    </f7-row>
                  </f7-col>
                </f7-row>
              </f7-col>
            </div>
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row v-if="commentConvention">
        <f7-col>
          <f7-block-header class="header-module">
            {{ $t('Commentaire général') }}
          </f7-block-header>
          <f7-row class="header-io">
            <f7-col
              width="45"
              class="header-io-col"
            >
              {{$t('Entrant')}}
            </f7-col>
            <f7-col
              width="10"
              class="arrow-separator"
            >
              <i class="f7-icons">arrow_right</i>
            </f7-col>
            <f7-col
              width="45"
              class="header-io-col"
            >
              {{$t('Sortant')}}
            </f7-col>
          </f7-row>
          <f7-block>
            <f7-block class="no-padding-horizontal">
              <f7-row>
                <f7-col width="45">
                  <p class="diff">
                    {{ commentConvention.input }}
                  </p>
                </f7-col>
                <f7-col width="10" />
                <f7-col width="45">
                  <p class="diff">
                    {{ commentConvention.output }}
                  </p>
                </f7-col>
              </f7-row>
            </f7-block>
          </f7-block>
        </f7-col>
      </f7-row>
    </f7-col>
  </f7-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import {getConfig} from "@/data/edlDataStructureModuleEntryRate";
import hasMedia from "@/components/mixins/hasMedia";
import {buildConvention} from "@/data/edlDataBuilder";
import autoEdl from "@/components/mixins/autoEdl";
import offline from "@/components/mixins/offline";
import {unobserve} from "@/store/helper";
import { f7 } from "framework7-vue";
import {fillIds} from "@/composables/prepare";
import language from "@/components/mixins/language";

export default {
    name: "EdlRecapConventionContent",
    mixins: [hasMedia, autoEdl, offline, language],
    props: {
      edl: {
        type: Object,
        default: null
      },
      info: {
        type: Object,
        default: null
      },
      scroll: {
        type: Number,
        default: 0
      }
    },
    emits: ['ready', 'track', 'skip'],
    data: function () {
      return {
        convention: null,
        commentConvention: null,
        modulePositions: {},
        currentModule: null,
      }
    },
    computed: {
      ...mapGetters('edlPersist', [
        'getLocalEdlById'
      ])
    },
    watch: {
      scroll: {
        handler: 'updateCurrentModule'
      },
      info: {
        handler: 'setup',
        immediate: true
      },
      edl: {
        handler: 'setup',
        immediate: true
      }
    },
    beforeUnmount: function () {
      window.removeEventListener('resize', this.setupCurrentModuleHeader);
    },
    methods: {
      ...mapActions('edlTemp', [
        'fetchRemoteEdlById'
      ]),
      rateLabel: function (name) {
        if (name === 'other') {
          return "Observations";
        }
        if (_.startsWith(name, 'typeCustom')) {
          name = 'typeCustom$';
        }
        let config = getConfig(name);
        return config ? config.label : name;
      },
      hasSubEntries: function (entry) {
        const objectDepth = (o) =>
          Object (o) === o ? 1 + Math .max (-1, ... Object .values(o) .map (objectDepth)) : 0;

        return objectDepth(entry) === 3;
      },
      setup: function () {
        if(!this.convention && this.info && this.edl) {
          let duplicateId = +this.info.duplicateFromEdlID;
          if(duplicateId === undefined) {
            this.skipConvention();
          } else {
            let localDuplicate = this.getLocalEdlById(duplicateId);
            if (localDuplicate) {
              this.skipConvention('local');
              //Could show convention, but there will not be a convention on server side, so we skip it
              //this.setupConvention(localDuplicate);
            } else if(this.isOnline) {
              this.fetchRemoteEdlById(this.info.duplicateFromEdlID).then((response) => {
                this.setupConvention(response.data.data);
              }).catch((error) => { //Skip if can't find duplicate edl
                console.error(error);
                this.skipConvention('not found');
              });
            } else {
              this.skipConvention('offline');
            }
          }
        }
      },
      setupConvention: function (entry) {
        let entryEdl = entry.edl;
        let entryInfo = _.omit(entry, ['edl']);
        entryEdl = fillIds(entryEdl);
        this.convention = buildConvention(entryInfo, entryEdl, unobserve(this.info), unobserve(this.edl));
        if (this.convention.comment !== undefined) {
          this.commentConvention = this.convention.comment;
          this.convention = _.omit(this.convention, ['comment']);
        }
        this.$emit('ready');
        this.setupCurrentModuleHeader();
      },
      setupCurrentModuleHeader: function () {
        let modules = this.$refs.modules;
        this.modulePositions = _.reduce(modules, (positions, module) => {
          let name = module.$attrs.header;
          positions[name] = module.$el.offsetTop;
          return positions;
        }, {});
        window.addEventListener('resize', this.setupCurrentModuleHeader);
        this.$emit('track');
      },
      updateCurrentModule: function () {
        let current = _.reduce(this.modulePositions, (max, position, name) => {
          if(position > this.scroll) {
            return max;
          } else if(max === null) {
            return {name, position};
          } else if(max.position < position) {
            return {name, position};
          } else {
            return max;
          }
        }, null);
        this.currentModule = current ? current.name : null;
      },
      skipConvention: function (reason) {
        this.$emit('ready');
        let message = "Il n'est pas possible d'afficher une convention de sortie : ";
        switch(reason) {
          case 'local':
            message += "l'EDL que vous avez dupliqué était un EDL non-enregistré sur nos serveurs, un comparatif ne sera donc pas possible.";
            break;
          case 'offline':
            message += "vous êtes hors-ligne. La convention sera disponible une fois synchronisé.";
            break;
          case 'not-found':
            message += "l'EDL dupliqué ne semble plus être disponible sur nos serveurs. Merci de nous contacter pour vérifier la situation";
            break;
          default:
            message = message.replace(' : ', '');
        }
        f7.dialog.create({
          text: message,
          buttons: [{
            text: 'OK',
            onClick: () => {
              this.$emit('skip');
            }
          }]
        });
      },
      defaultValue: function (value, defaultValue) {
        return value !== null && value !== '' && value !== undefined ? value : defaultValue;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .current-module {
    position: fixed;
    height: 50px;
    width: 100%;
    z-index: 100;
    .header-module {
      margin: 0;
    }
  }
  .header-module {
    background: var(--f7-theme-color-grey);
    font-size: 34px;
    margin-bottom: 0;
  }
  .header-entry {
    font-size: 18px;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
  }
  .header-sub-entry {
    color: black;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .diff {
    margin: 0;
    padding: 5px;
    text-align: center;
    white-space: pre-wrap;
  }
  .header-io {
    margin: 0;
    background: var(--f7-theme-color-dark-grey);
    color: white;
    .header-io-col {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  .arrow-separator {
    i {
      font-size: 12px;
    }
    text-align: center;
  }
  .rate {
    font-weight: bold;
    display: block;
  }
  .nl2br {
    white-space: pre-line;
  }
</style>
