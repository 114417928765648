<template>
  <f7-button
    :text="text"
    :tooltip="text"
    :active="isActive"
    raised
    round
    @click="select"
  />
</template>

<script>
  export default {
    name: "EdlEditObservation",
    props: {
      singular: {
        type: String,
        default: null
      },
      plural: {
        type: String,
        default: null
      },
      isPlural: {
        type: Boolean
      },
      isFeminine: {
        type: Boolean
      },
      checkActive: {
        type: String,
        default: null
      }
    },
    emits: ['forceGenre', 'select'],
    computed: {
      text: function () {
        if(this.nonCountable) {
          return this.singular;
        }
        if(this.isPlural) {
          return this.finalPlural;
        } else {
          return this.singular;
        }
      },
      finalPlural: function () {
        if(this.plural) {
          return this.plural;
        } else {
          return this.singular;
        }
      },
      isActive: function () {
        return this.checkActive === this.text;
      }
    },
    watch: {
      text: function (newText, oldText) {
        if(this.checkActive === oldText) {
          this.select();
        }
      }
    },
    methods: {
      select: function () {
        this.$emit('forceGenre', this.isFeminine ? 'feminine' : 'masculine');
        this.$emit('select', this.text);
      }
    }
  }
</script>

<style scoped>

</style>
