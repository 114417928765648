import api from '../../api';

// initial state
const state = () => ({
  autoEdls: [],
})

// getters
const getters = {
  getAllAutoEdls: state => state.autoEdls,
  getAutoEdlById: state => id => state.autoEdls.find(autoEdl => autoEdl.id === id)
}

// actions
const actions = {
  fetchAutoEdls({commit}) {
    return api.autoEdls()
      .then(response => {
        if(response.status === 200) {
          commit('pushAutoEdls', response.data)
        } else {
          console.error('fetchAutoEdls response status ' + response.status);
        }
      });
  },
  createAutoEdl({commit}, payload) {
    return api.createAutoEdl(payload)
      .then((response) => {
        let autoEdl = response.data
        commit('pushAutoEdl', autoEdl)

        return autoEdl
      })
  },
  deleteAutoEdl({commit}, autoEdl) {
    return api.deleteAutoEdl(autoEdl.id)
      .then(() => {
        commit('popAutoEdl', autoEdl);
      })
    ;
  },
};

// mutations
const mutations = {
  pushAutoEdls(state, autoEdls) {
      state.autoEdls = state.autoEdls.filter(stateAutoEdl => ! autoEdls.find (newAutoEdl => stateAutoEdl.id === newAutoEdl.id) ).concat(autoEdls);
  },
  pushAutoEdl(state, autoEdl) {
    let index = state.autoEdls.findIndex(item => item.id === autoEdl.id)

    if (index >= 0)
      state.autoEdls.splice(index, 1, autoEdl)
    else
      state.autoEdls.push(autoEdl)
  },
  popAutoEdl(state, autoEdl) {
    state.autoEdls.splice(state.autoEdls.findIndex(item => item.id === autoEdl.id), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
