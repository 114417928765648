import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  methods: {
    ...mapGetters('edlPersist', [
      'getNameFromContext'
    ]),
    getPhotoId: function (photo) {
      if(photo === null || photo === undefined || photo._data === undefined) {
        return null;
      }
      return + _.trimStart(photo._data, '#IndexedDb:');
    },
    getPhotoTitle: function (photo) {
      if(photo === null || photo === undefined || photo._data === undefined) {
        return '';
      }

      let ref = photo._ref;
      if(ref === undefined) {
        return '';
      }

      if(ref.includes('#Resource')) {
        return 'Photo existante';
      } else if (ref.includes('#Upload')) {
        return 'Nouvelle photo';
      }
    },
    getPhotoSubTitle: function (rate) {
      if(rate.other !== null) {
        return rate.other;
      } else {
        if(rate.photo === undefined) {
          return '';
        }

        let ref = rate.photo._ref;
        if(ref === undefined) {
          return '';
        }

        return this.extractFilenameFromRef(ref) ? this.extractFilenameFromRef(ref) : '';
      }
    },
    getPhotoReference: function (rate) {
      if(rate.photo === undefined) {
        return null;
      }

      let ref = rate.photo._ref;
      if(ref === undefined) {
        return null;
      }

      return this.extractFilenameFromRef(ref);
    },
    extractFilenameFromRef: function (ref) {
      if(ref.includes('#Resource')) {
        return ref.replace('#Resource:', '');
      } else if (ref.includes('#Upload')) {
        return ref.split(':')[1];
      }

      return null;
    },
    getPhotoContext: function (photo) {
      return photo !== null && photo !== undefined && photo._context !== undefined
        ? this.getNameFromContext()(photo._context)
        : null;
    }
  }
}
