import api from '../../api';
import _ from 'lodash';
import {injectPhotosIntoEdl, deleteEdlPhotos, prunePhotos} from "@/composables/photoStorage";
import {unobserve} from "@/store/helper";

const state = {
  currentLocalEdlId: null,
  localEdls: [],
  autoEdls: {},
};

const getters = {
  getLocalEdlById: (state) => (id) => {
    let localEdls = state.localEdls;
    return localEdls ? localEdls.find((edl) => {
      return edl.localId === id;
    }): null;
  },
  getLocalEdlByKey: (state) => (key) => {
    return key !== null && state.localEdls[key] !== undefined
      ? state.localEdls[key]
      : null;
  },
  getKeyForLocalEdlById: (state) => (id) => {
    let localEdls = state.localEdls;
    return localEdls ? localEdls.findIndex((edl) => {
      return edl.localId === id;
    }): null;
  },
  getLatestLocalEdlId: (state) => {
    return state.localEdls[state.localEdls.length - 1].localId;
  },
  getCurrentLocalEdl: (state, getters) => {
    return getters.getLocalEdlById(state.currentLocalEdlId);
  },
  getAutoEdlById: (state) => (id) => {
    return state.autoEdls[id];
  },
  getNameFromContext: (state, getters) => (context) => {
    let currentLocalEdl = getters.getCurrentLocalEdl;
    if(currentLocalEdl === null || currentLocalEdl === undefined) {
      return null;
    }
    let edl = currentLocalEdl.edl;
    if(edl === null || edl === undefined) {
      return null;
    }
    let ids = context.split(".");
    if(ids.length === 0) {
      return null;
    }

    let moduleId = parseInt(ids.shift());
    let contextModule = _.find(edl, (module) => {
      return module.id === moduleId;
    });
    let entryId = parseInt(ids.shift());
    let contextEntry = _.find(contextModule.entry, (entry) => {
      return entry.id === entryId;
    });
    if(ids.length > 0 && contextEntry?.content !== undefined) {
      let subEntryId = parseInt(ids.shift());
      contextEntry = _.find(contextEntry.content, (entry) => {
        return entry.id === subEntryId;
      });
    }

    return contextEntry !== undefined && contextEntry.name !== undefined ? contextEntry.name : null;
  }
};

// actions
const actions = {
  addLocalEdl({commit}, edl) {
    commit('localEdl', {key: null, edl: edl});
  },
  setCurrentLocalEdlId({commit}, id) {
    commit('currentLocalEdlId', id);
  },
  updateCurrentLocalEdlData({commit, state, getters}, data) {
    let key = getters.getKeyForLocalEdlById(state.currentLocalEdlId);
    commit('localEdlData', {key, data});
  },
  updateCurrentLocalEdlInfo({commit, state, getters}, data) {
    let key = getters.getKeyForLocalEdlById(state.currentLocalEdlId);
    commit('localEdlInfo', {key, data});
  },
  deleteLocalEdl({commit, getters, state}, localId) {
    let localEdl = unobserve(getters.getLocalEdlById(localId));
    let key = getters.getKeyForLocalEdlById(localId);
    return new Promise((resolve, reject) => {
      deleteEdlPhotos(localEdl).then(() => {
        commit('deleteLocalEdl', key);
        prunePhotos(state.localEdls.map((localEdl) => localEdl.localId));
      }).catch((error) => {
        reject({error, localId});
      });
    });
  },
  uploadLocalEdl({commit, getters}, {localId, profileId, sendEmailToRenters}) {
    let localEdl = unobserve(getters.getLocalEdlById(localId));
    localEdl.profileId = profileId; //set id of the profile that is uploading the edl
    localEdl.sendEmailToRenters = sendEmailToRenters;
    return new Promise((resolve, reject) => {
      injectPhotosIntoEdl(localEdl).then((edl) => {
        api.createEdl(edl).then((response) => {
          let syncedEdl = unobserve(getters.getLocalEdlById(localId));
          syncedEdl.id = response.data.id;
          syncedEdl.syncDate = response.data.date;
          let key = getters.getKeyForLocalEdlById(localId)
          commit('localEdl', {key, edl: syncedEdl});
          resolve({localId, syncedId: response.data.id});
        }).catch((error) => {
          reject({error, localId});
        });
      });
    });
  },
  createAutoEdlForId({commit}, id) {
    commit('createAutoEdl', id);
  },
  updateAutoEdl({commit}, payload) {
    commit('autoEdl', payload);
  }
};

// mutations
const mutations = {
  currentLocalEdlId(state, payload) {
    state.currentLocalEdlId = payload;
  },
  localEdl(state, {key, edl}) {
    if(key !== null) {
      state.localEdls[key] = edl;
    } else {
      state.localEdls.push(edl);
    }
  },
  localEdlData(state, {key, data}) {
    let localEdl = unobserve(state.localEdls[key]);
    localEdl.edl = data;
    state.localEdls[key] = localEdl;
  },
  localEdlInfo(state, {key, data}) {
    let localEdl = unobserve(state.localEdls[key]);
    _.forEach(data, (value, key) => {
      localEdl[key] = value;
    });
    state.localEdls[key] = localEdl;
  },
  deleteLocalEdl(state, payload) {
    let localEdls = unobserve(state.localEdls);
    localEdls.splice(payload, 1);
    state.localEdls = localEdls;
  },
  createAutoEdl(state, id) {
    let autoEdls = unobserve(state.autoEdls);
    autoEdls[id] = {};
    state.autoEdls = autoEdls;
  },
  autoEdl(state, {id, path, value}) {
    let autoEdls = unobserve(state.autoEdls);
    _.set(autoEdls[id], path, value);
    state.autoEdls = autoEdls;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
