<template>
  <f7-popup
    class="observation-popup"
    tablet-fullscreen
    :opened="opened"
    @popup:closed="fixPopupCloseOnSafari"
    @popup:opened="adjustLayout"
  >
    <f7-page ref="popup">
      <f7-navbar
        ref="navbar"
        title="Observations rapides"
      >
        <f7-nav-right>
          <f7-link
            @click="close"
            icon-ios="f7:multiply_circle"
          />
        </f7-nav-right>
      </f7-navbar>
      <div ref="alwaysShow">
        <f7-block
          class="no-margin-vertical padding-half"
          strong
        >
          <f7-input
            class="observation-popup-input"
            type="textarea"
            :value="observationText"
            placeholder="Observation"
            @change="updateObservationText"
          />
        </f7-block>
        <f7-block class="center no-margin arrow-icon">
          <f7-icon ios="f7:arrow_up" />
        </f7-block>
        <f7-block class="no-margin-top margin-bottom-half">
          <f7-row>
            <f7-col class="center margin-bottom-half">
              <f7-button
                large
                :disabled="isCurrentQuickObservationEmpty"
                text="AJOUTER"
                @click="addObservation"
              />
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col class="center">
              <f7-chip
                v-for="type in order"
                :key="type"
                :text="getValueForType(type)"
                deleteable
                :media-bg-color="getColorForType(type)"
                @delete="deleteType(type)"
              >
                <template #media>
                  <f7-icon :ios="getIconForType(type)" />
                </template>
              </f7-chip>
            </f7-col>
          </f7-row>
        </f7-block>
      </div>
      <div
        class="scroll-under"
        :style="scrollUnderStyle"
      >
        <!-- Disabled function, keep in case useful again
        <f7-block class="margin-top-half no-margin-bottom">
          <f7-row>
            <f7-col width="45">
              <f7-segmented class="no-margin-bottom" raised tag="p">
                <f7-button @click="isFeminine = false" :active="!isFeminine">Masculin</f7-button>
                <f7-button @click="isFeminine = true" :active="isFeminine">Féminin</f7-button>
              </f7-segmented>
            </f7-col>
            <f7-col width="10"></f7-col>
            <f7-col width="45">
              <f7-segmented class="no-margin-bottom" raised tag="p">
                <f7-button @click="isPlural = false" :active="!isPlural">Singulier</f7-button>
                <f7-button @click="isPlural = true" :active="isPlural">Pluriel</f7-button>
              </f7-segmented>
            </f7-col>
          </f7-row>
        </f7-block>
        -->

        <f7-icon
          class="section-icon"
          :ios="getIconForType('number')"
          :color="getColorForType('number')"
        />
        <f7-block
          strong
          class="no-margin-vertical"
        >
          <f7-row>
            <f7-col
              v-for="digit in digits"
              :key="digit"
              width="10"
            >
              <f7-button
                :text="digit"
                raised
                @click="updateNumber(digit)"
              />
            </f7-col>
          </f7-row>
        </f7-block>

        <f7-icon
          class="section-icon"
          :ios="getIconForType('quantitative')"
          :color="getColorForType('quantitative')"
        />
        <f7-block
          strong
          class="no-margin-vertical"
        >
          <f7-row>
            <f7-col
              v-for="(autoFillObservationQuantitative, index) in quantitatives[locale]"
              :key="index"
              width="25"
            >
              <edl-edit-observation-qualifier
                :qualifier="autoFillObservationQuantitative"
                :is-feminine="isFeminine"
                :is-plural="isPlural"
                :check-active="quantitative"
                @select="updateQuantitative"
                @force-plural="forcePlural"
              />
            </f7-col>
          </f7-row>
        </f7-block>

        <f7-icon
          class="section-icon"
          :ios="getIconForType('observation')"
          :color="getColorForType('observation')"
        />
        <f7-block
          strong
          class="no-margin-vertical"
        >
          <f7-row>
            <f7-col
              v-for="(autoFillObservation, index) in autoFillObservations"
              :key="index"
              width="33"
            >
              <edl-edit-observation
                :singular="autoFillObservation.singular"
                :plural="autoFillObservation.plural"
                :is-plural="isPlural"
                :is-feminine="autoFillObservation.feminine || false"
                :check-active="observation"
                @select="updateObservation"
                @clear="clear"
                @force-genre="forceGenre"
              />
            </f7-col>
          </f7-row>
        </f7-block>

        <f7-icon
          class="section-icon"
          :ios="getIconForType('qualitative')"
          :color="getColorForType('qualitative')"
        />
        <f7-block
          strong
          class="no-margin-vertical"
        >
          <f7-row>
            <f7-col
              v-for="(autoFillObservationQualitative, index) in qualitatives[locale].sort(sortAlphabetically)"
              :key="index"
              width="33"
            >
              <edl-edit-observation-qualifier
                :qualifier="autoFillObservationQualitative"
                :is-feminine="isFeminine"
                :is-plural="isPlural"
                :check-active="qualitative"
                @select="updateQualitative"
              />
            </f7-col>
          </f7-row>
        </f7-block>

        <f7-icon
          class="section-icon"
          :ios="getIconForType('localisation')"
          :color="getColorForType('localisation')"
        />
        <f7-block
          strong
          class="no-margin-vertical"
        >
          <f7-row>
            <f7-col
              v-for="(autoFillObservationLocalisation, index) in localisations[locale].sort(sortAlphabetically)"
              :key="index"
              width="33"
            >
              <f7-button
                :text="autoFillObservationLocalisation"
                :active="localisation && localisation.includes(autoFillObservationLocalisation)"
                raised
                @click="updateLocalisation(autoFillObservationLocalisation)"
              />
            </f7-col>
          </f7-row>
        </f7-block>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
  import EdlEditObservationQualifier from "./EdlEditObservationQualifier.vue";
  import EdlEditObservation from "./EdlEditObservation.vue";
  import {
    quantitatives,
    qualitatives,
    localisations,
    sortAlphabetically
  } from "@/data/edlDataStructureAutoFillObservation";
  import _ from "lodash";

  export default {
    name: "EdlEditObservationPopup",
    components: {EdlEditObservationQualifier, EdlEditObservation},
    props: {
      opened: {
        type: Boolean,
        default: false
      },
      observationText: {
        type: String,
        default: null
      },
      autoFillObservations: {
        type: Object,
        default: null
      }
    },
    emits: ['update', 'close'],
    data: function () {
      return {
        number: null,
        digits: Array.from({length: 10}, (_, i) => ((i + 1) % 10).toString()),
        quantitative: null,
        quantitatives,
        qualitative: null,
        qualitatives,
        observation: null,
        localisation: null,
        localisations,
        isPlural: false,
        isFeminine: false,
        order: [],
        scrollUnderStyle: null
      };
    },
    computed: {
      isCurrentQuickObservationEmpty: function () {
        return this.currentQuickObservation === "";
      },
      currentQuickObservation: function () {
        let value = "";
        this.order.forEach((type) => {
          if(value === "") {
            value = this.$data[type];
          } else {
            value += " " + this.$data[type];
          }
        });
        return value;
      },
      locale: function () {
        return this.$i18n.locale;
      }
    },
    watch: {
      number: function (newNumber) {
        this.isPlural = parseInt(newNumber) > 1;
      },
      isCurrentQuickObservationEmpty: function () {
        this.$nextTick(this.adjustLayout);
      }
    },
    created() {
      window.addEventListener("resize", this.adjustLayout);
    },
    unmounted() {
      window.removeEventListener("resize", this.adjustLayout);
    },
    methods: {
      sortAlphabetically,
      adjustLayout: function () {
        let alwaysShow = this.$refs.alwaysShow;
        let popup = this.$refs.popup;
        let navbar = this.$refs.navbar;

        this.scrollUnderStyle =
          (alwaysShow !== undefined && popup !== undefined && navbar !== undefined) ? {
            height: (popup.$el.clientHeight - navbar.$el.clientHeight - alwaysShow.clientHeight - 10) + 'px'
          } : null;
      },
      updateNumber: function (digit) {
        if(!this.number) {
          this.number = digit;
        } else {
          this.number = this.number + digit;
        }
        this.insertOrder('number');
      },
      updateQuantitative: function (value) {
        this.quantitative = value;
        this.insertOrder('quantitative');
      },
      updateObservation: function (value) {
        this.observation = value;
        this.insertOrder('observation');
      },
      updateQualitative: function (value) {
        this.qualitative = value;
        this.insertOrder('qualitative');
      },
      updateLocalisation: function (value) {
        if(!this.localisation) {
          this.localisation = value;
        } else {
          this.localisation = this.localisation + " " + value;
        }
        this.insertOrder('localisation');
      },
      getValueForType: function (type) {
        return this.$data[type];
      },
      deleteType: function (type) {
        this.$data[type] = null;
        _.remove(this.order, (orderedType) => orderedType === type);
      },
      getColorForType: function (type) {
        switch(type){
          case 'number':
            return 'purple';
          case 'quantitative':
            return 'orange';
          case 'observation':
            return 'green';
          case 'qualitative':
            return 'blue';
          case 'localisation':
            return 'pink';
        }
      },
      getIconForType: function (type) {
        switch(type){
          case 'number':
            return 'f7:number';
          case 'quantitative':
            return 'f7:plusminus';
          case 'observation':
            return 'f7:search';
          case 'qualitative':
            return 'f7:tag';
          case 'localisation':
            return 'f7:move';
        }
      },
      insertOrder: function (type) {
        if(!this.order.includes(type)) {
          this.order.push(type);
        }
      },
      addObservation: function () {
        if(!this.isCurrentQuickObservationEmpty) {
          let quickObservation = _.capitalize(this.currentQuickObservation);
          if(this.observationText !== "" && this.observationText !== null) {
            quickObservation = this.observationText+"\n"+quickObservation;
          }
          this.$emit('update', quickObservation);
          this.clear();
        }
      },
      updateObservationText: function (event) {
        this.$emit('update', event.target.value);
      },
      clear: function () {
        this.number = this.quantitative = this.observation = this.qualitative = this.localisation = null;
        this.order = [];
      },
      forceGenre: function (genre) {
        switch(genre) {
          case 'masculine':
            this.isFeminine = false;
            break;
          case 'feminine':
            this.isFeminine = true;
            break;
        }
      },
      forcePlural: function () {
        this.isPlural = true;
      },
      close: function () {
        this.$emit('close');
      },
      fixPopupCloseOnSafari: function () {
        //Patch to fix Safari issue, popup still rendered on screen after close until resize
        window.dispatchEvent(new Event('resize'));
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scroll-under {
    border-top: 1px solid var(--f7-block-strong-border-color);
    overflow-y: scroll;
  }
  .center {
    text-align: center;
  }
  .arrow-icon i {
    font-size: 16px;
  }
  .section-icon {
    position: relative;
    top: 20px;
    left: 2px;
    font-size: 14px;
    z-index: 2;
  }
  :deep(.observation-popup-input) textarea {
    height: 75px;
  }
  .add-observation-button {

  }
</style>
