<template>
  <f7-list-item
    class="edit-info-toggle"
    :title="labelTranslated"
  >
    <f7-toggle
      template="after"
      :checked="value"
      @toggle:change="set"
    />
    <template #media>
      <f7-icon
        v-if="conditional"
        f7="arrow_turn_down_right"
      />
    </template>
  </f7-list-item>
</template>

<script>
import _ from "lodash";

export default {
  name: "EdlInfoToggle",
  props: {
    path: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      default: null
    },
    conditional: {
      type: Boolean,
      default: false
    }
  },
  emits: ['set'],
  computed: {
    value: function () {
      return this.info ? this.convertFromData(_.get(this.info, this.path)) : false;
    },
    labelTranslated: function () {
      return this.$t(this.label);
    }
  },
  methods: {
    set: function (value) {
      this.$emit('set', {
        path: this.path,
        value: this.convertToData(value)
      });
    },
    convertFromData: function (value) {
      switch(value) {
        case 'oui':
          return true;
        case 'non':
        case undefined:
          return false;
        default:
          console.error('Value for toggle of path '+this.path+' is wrong');
          return false;
      }
    },
    convertToData: function (value) {
      return value ? 'oui' : 'non';
    }
  }
}
</script>

<style lang="scss">
.list .edit-info-toggle > div {
  min-height: 67px;
}
.list .edit-info-toggle .item-content .item-media i.f7-icons {
  color: var(--f7-theme-color-dark-grey);
}
</style>
