<template>
  <f7-popup
    :opened="opened"
    :tablet-fullscreen="true"
    @popup:closed="close"
  >
    <f7-page bg-color="white">
      <f7-navbar>
        <f7-nav-left>
          <f7-button
            v-if="type === 'convention' && isDownloadingConvention(edlId)"
            class="popup-nav-preloader"
          >
            <f7-preloader /> Téléchargement en cours...
          </f7-button>
          <f7-button
            v-else-if="type === 'convention' && isGeneratingConvention(edlId)"
            class="popup-nav-preloader"
          >
            <f7-preloader /> Génération en cours...
          </f7-button>
          <f7-link
            v-else-if="type === 'convention' && isGenerated(edlId, 'convention')"
            :class="{disabled: !isOnline}"
            tooltip="Télécharger le PDF de la convention de sortie de l'EDL"
            @click="downloadEdlConventionPdf(edlId)"
          >
            <i class="f7-icons">square_arrow_down</i> Télécharger le PDF
          </f7-link>
          <f7-link
            v-else-if="type === 'convention' && !isGenerated(edlId, 'convention')"
            :class="{disabled: !isOnline}"
            tooltip="Générer le PDF de la convention de sortie de l'EDL pour pouvoir le télécharger"
            @click="generateEdlConventionPdf(edlId)"
          >
            <i class="f7-icons">cursor_rays</i> Générer le PDF
          </f7-link>
          <f7-link
            v-if="type === 'convention' && isGenerated(edlId, 'convention') && !isGeneratingConvention(edlId)"
            class="show-for-large small"
            :class="{disabled: !isOnline}"
            tooltip="Re-générer le PDF de la convention de sortie de l'EDL, si besoin, en cas d'erreur ou de mise à jour"
            @click="generateEdlConventionPdf(edlId)"
          >
            <i class="f7-icons">arrow_counterclockwise_circle</i> Re-générer le PDF
          </f7-link>
          <f7-button
            v-if="type === 'edl' && isDownloading(edlId)"
            class="popup-nav-preloader"
          >
            <f7-preloader /> Téléchargement en cours...
          </f7-button>
          <f7-button
            v-else-if="type === 'edl' && isGenerating(edlId)"
            class="popup-nav-preloader"
          >
            <f7-preloader /> Génération en cours...
          </f7-button>
          <f7-link
            v-else-if="type === 'edl' && isGenerated(edlId, 'edl')"
            :class="{disabled: !isOnline}"
            tooltip="Télécharger le PDF de l'EDL"
            @click="downloadEdlPdf(edlId)"
          >
            <i class="f7-icons">square_arrow_down</i> Télécharger le PDF
          </f7-link>
          <f7-link
            v-else-if="type === 'edl' && !isGenerated(edlId, 'edl')"
            :class="{disabled: !isOnline}"
            tooltip="Générer le PDF de l'EDL pour pouvoir le télécharger"
            @click="generateEdlPdf(edlId)"
          >
            <i class="f7-icons">cursor_rays</i> Générer le PDF
          </f7-link>
          <f7-link
            v-if="type === 'edl' && isGenerated(edlId, 'edl') && !isGenerating(edlId)"
            class="show-for-large small"
            :class="{disabled: !isOnline}"
            tooltip="Re-générer le PDF de l'EDL, si besoin, en cas d'erreur ou de mise à jour"
            @click="generateEdlPdf(edlId)"
          >
            <i class="f7-icons">arrow_counterclockwise_circle</i> Re-générer le PDF
          </f7-link>
        </f7-nav-left>
        <f7-nav-title>Aperçu</f7-nav-title>
        <f7-nav-right>
          <f7-input
            type="select"
            :value="locale"
            @change="switchLanguage"
            @focus="showFullLanguageLabel = true"
            @blur="showFullLanguageLabel = false"
          >
            <option
              v-for="lang in availableLanguages"
              :key="'lang_'+lang.locale"
              :value="lang.locale"
            >
              {{ dynamicLanguageLabel(lang) }}
            </option>
          </f7-input>
          <f7-link
            v-if="type === 'convention'"
            class="toggle-show-costs-button"
            small
            fill
            raised
            @click="toggleShowCosts(edlId)"
          >
            {{ showCosts(edlId) ? 'Masquer le chiffrage' : 'Afficher le chiffrage' }}
          </f7-link>
          <f7-link popup-close>
            <i class="f7-icons">multiply_circle</i>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block style="height: 100%">
        <iframe
          :src="content"
          width="100%"
          height="100%"
          :style="{'border': 'none'}"
        />
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import api from "@/api";
import _ from "lodash";
import {f7} from "framework7-vue";
import {mapActions, mapGetters} from "vuex";
import offline from "@/components/mixins/offline";
import language from "@/components/mixins/language";
import errors from "@/components/mixins/errors";

export default {
  name: "EdlListPreview",
  mixins: [offline, language, errors],
  props: {
    opened: {
      type: Boolean
    },
    type: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    },
    edlId: {
      type: Number,
      default: null
    }
  },
  emits: ['close', 'emit', 'reload'],
  data: function () {
    return {
      edl: null,
      downloading: [],
      downloadingConvention: [],
      generating: [],
      wasGenerated: [],
      generatingConvention: [],
      wasGeneratedConvention: [],
      listeningIfGeneratingTimeout: null,
      eventSource: null
    }
  },
  watch: {
    opened: function (isOpened) {
      if(isOpened) {
        this.listenIfGenerating(this.edlId, this.type);
      } else {
        if(this.listeningIfGeneratingTimeout !== null) {
          clearTimeout(this.listeningIfGeneratingTimeout);
          this.listeningIfGeneratingTimeout = null;
        }
      }
    },
    edlId: function (edlId, previousEdlId) {
      if(edlId !== null && edlId !== previousEdlId) {
        this.edl = this.getRemoteEdlById()(this.edlId);
      }
    }
  },
  beforeUnmount: function () {
    if(this.eventSource) {
      this.eventSource.close();
    }
  },
  methods: {
    ...mapActions('edlTemp', [
      'updateEdlShowCosts'
    ]),
    ...mapGetters('edlTemp', [
      'getRemoteEdlById'
    ]),
    close: function () {
      this.$emit('close');
    },
    downloadEdlPdf: function (id) {
      this.downloading.push(id);
      let downloadingIndex = this.downloading.indexOf(id);
      api.downloadEdlPdf(id)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = URL.createObjectURL(blob);
          link.download = 'EtatDesLieux_'+id+'.pdf'; //Gives name to file but also forces a download
          link.click();
          //URL.revokeObjectURL(link.href); //Makes any reload of the url impossible
          this.downloading.splice(downloadingIndex, 1);
        }).catch((error) => {
        this.throwError(
          "Une erreur est survenue lors du téléchargement du PDF. Veuillez nous contacter si cela se reproduit.",
          "Erreur téléchargement",
          _.has(error, 'response.data.message') ? error.response.data.message : error.message,
          error
        );
        this.downloading.splice(downloadingIndex, 1);
      });
    },
    downloadEdlConventionPdf: function (id) {
      this.downloadingConvention.push(id);
      let downloadingIndex = this.downloadingConvention.indexOf(id);
      api.downloadEdlConventionPdf(id)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = URL.createObjectURL(blob);
          link.download = 'Convention_'+id+'.pdf'; //Gives name to file but also forces a download
          link.click();
          this.downloadingConvention.splice(downloadingIndex, 1);
        }).catch((error) => {
        this.throwError(
          "Une erreur est survenue lors du téléchargement du PDF de la convention de sortie. Veuillez nous contacter si cela se reproduit.",
          "Erreur téléchargement",
          _.has(error, 'response.data.message') ? error.response.data.message : error.message,
          error
        );
        this.downloadingConvention.splice(downloadingIndex, 1);
      });
    },
    generateEdlPdf: function (id) {
      let wasGeneratedIndex = this.wasGenerated.indexOf(id);
      if (wasGeneratedIndex !== undefined) {
        this.wasGenerated.splice(wasGeneratedIndex, 1);
      }
      this.generating.push(id);
      api.generateEdlPdf(id)
        .then(() => {
          this.listenIfGenerating(id, 'edl');
        }).catch((error) => {
        this.throwError(
          "Une erreur est survenue lors de la demande de génération du PDF. Veuillez nous contacter si cela se reproduit.",
          "Erreur génération",
          _.has(error, 'response.data.message') ? error.response.data.message : error.message,
          error
        );
        let generatingIndex = this.generating.indexOf(id);
        if(generatingIndex !== undefined) {
          this.generating.splice(generatingIndex, 1);
        }
      });
    },
    generateEdlConventionPdf: function (id) {
      let wasGeneratedConventionIndex = this.wasGeneratedConvention.indexOf(id);
      if (wasGeneratedConventionIndex !== undefined) {
        this.wasGeneratedConvention.splice(wasGeneratedConventionIndex, 1);
      }
      this.generatingConvention.push(id);
      api.generateConventionPdf(id)
        .then(() => {
          this.listenIfGenerating(id, 'convention');
        }).catch((error) => {
        this.throwError(
          "Une erreur est survenue lors de la demande de génération du PDF. Veuillez nous contacter si cela se reproduit.",
          "Erreur génération",
          _.has(error, 'response.data.message') ? error.response.data.message : error.message,
          error
        );
        let generatingConventionIndex = this.generatingConvention.indexOf(id);
        if (generatingConventionIndex !== undefined) {
          this.generatingConvention.splice(generatingConventionIndex, 1);
        }
      });
    },
    listenIfGenerating: function (edlId, type) {
      //handle SSE event
      this.listeningIfGeneratingTimeout = setTimeout(() => {
        if(this.eventSource) {
          this.eventSource.close();
        }
        switch(type) {
          case 'edl': {
            this.eventSource = api.isGeneratedEdlPdf(edlId);
            const generatingIndex = this.generating.indexOf(edlId);
            this.eventSource.addEventListener("message", (event) => {
              if (event.data === 'generated') {
                this.eventSource.close();
                if (generatingIndex !== undefined) {
                  this.generating.splice(generatingIndex, 1);
                }
                this.wasGenerated.push(edlId);
              }
            });
            break;
          }
          case 'convention': {
            this.eventSource = api.isGeneratedConventionPdf(edlId);
            const generatingConventionIndex = this.generatingConvention.indexOf(edlId);
            this.eventSource.addEventListener("message", (event) => {
              if (event.data === 'generated') {
                this.eventSource.close();
                if (generatingConventionIndex !== undefined) {
                  this.generatingConvention.splice(generatingConventionIndex, 1);
                }
                this.wasGeneratedConvention.push(edlId);
              }
            });
            break;
          }
          default:
            console.error('PDF type '+type+' is not handled');
            break;
        }
      }, 500);
    },
    toggleShowCosts: function (edlId) {
      f7.preloader.show();
      this.updateEdlShowCosts({
        id: edlId,
        showCosts: !this.showCosts()
      }).then((response) => {
        this.edl = response.data;
        this.$emit('reload', edlId, 'convention');
        f7.preloader.hide();
      });
    },
    showCosts: function () {
      return _.has(this.edl, 'showCosts') ? this.edl.showCosts : false;
    },
    isDownloading: function (id) {
      return this.downloading.includes(id);
    },
    isGenerating: function (id) {
      return (
          this.generating.includes(id)
          || (this.edl && this.edl.pdfsGenerating ? this.edl.pdfsGenerating.includes('edl') : false)
        )
        && !this.wasGenerated.includes(id);
    },
    isDownloadingConvention: function (id) {
      return this.downloadingConvention.includes(id);
    },
    isGeneratingConvention: function (id) {
      return this.generatingConvention.includes(id);
    },
    isGenerated: function (id, type) {
      if (type === 'edl' && this.wasGenerated.includes(id)) {
        return true;
      } else if (type === 'convention' && this.wasGeneratedConvention.includes(id)) {
        return true;
      }
      return this.edl && this.edl.pdfsGenerated ? this.edl.pdfsGenerated.includes(type) : false;
    },
    switchLanguage: function (event) {
      let newLocale = event.target.value !== "" ? event.target.value : null;
      f7.preloader.show();
      api.updateEdlLocale(this.edlId, newLocale).then((response) => {
        this.edl = response.data;
        this.$emit('reload', this.edlId, this.type);
        f7.preloader.hide();
      });
    }
  }
}
</script>

<style scoped>

</style>
