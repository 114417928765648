<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      :back="{name: 'edl', params: {localId}}"
    >
      <template #global-actions>
        <f7-input
          type="select"
          :value="locale"
          @change="switchLanguage"
          @focus="showFullLanguageLabel = true"
          @blur="showFullLanguageLabel = false"
        >
          <option
            v-for="lang in availableLanguages"
            :key="'lang_'+lang.locale"
            :value="lang.locale"
          >
            {{ dynamicLanguageLabel(lang) }}
          </option>
        </f7-input>
      </template>
    </navigation-top>
    <navigation-bottom current="new" />

    <f7-page-content>
      <f7-row
        v-for="signer in signers"
        :key="'signer_'+signer.name"
        class="signature margin-top"
      >
        <f7-col>
          <f7-row class="signature-info">
            <f7-col>
              <h2 class="margin-bottom-half">
                {{ $t(signer.label) }}
              </h2>
              <h3
                v-if="signer.fullname"
                class="text-align-center"
              >
                {{ signer.fullname }}
              </h3>
              <f7-block
                v-if="signer.legal"
                class="no-margin-top"
              >
                <p>
                  {{ $t("Le soussigné :") }}
                </p>
                <ul>
                  <li>
                    {{ $t("reconnait avoir reçu un exemplaire du présent état des lieux qui fait partie intégrante du contrat de location dont il ne peut être dissocié.") }}
                  </li>
                  <li>
                    {{ $t("s’engage à faire référence au présent état des lieux lors du départ du locataire.") }}
                  </li>
                  <li>
                    {{ $t("accepte et reconnait exact le présent état des lieux sous réserve du bon fonctionnement des installations sanitaires et électriques et des équipements de chauffage qui n'ont pu être vérifiés ce jour") }}
                  </li>
                  <li v-if="info.duplicateFromEdlID !== undefined && info.edlIO === 'Sortant'">
                    {{ $t("autorise la gérance à prélever sur sa garantie éventuelle le montant des frais résultant des réserves et dégradations constatées de la présente convention de sortie, mais jusqu'à concurrence de ce dernier montant") }}
                  </li>
                </ul>
              </f7-block>
            </f7-col>
          </f7-row>
          <f7-row class="signature-container">
            <f7-col class="signature-before" />
            <f7-col class="signature-field">
              <f7-block class="no-margin-vertical">
                <VueSignaturePad
                  :ref="'signature_'+signer.name"
                  height="100%"
                  :options="{backgroundColor: 'white', dotSize: 0.3,}"
                />
              </f7-block>
            </f7-col>
            <f7-col class="signature-after" />
          </f7-row>
          <f7-row class="signature-actions">
            <f7-col>
              <f7-button @click="clearSignature(signer)">
                {{ $t('Recommencer la signature') }}
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="20" />
        <f7-col
          width="60"
          class="margin-top margin-bottom"
        >
          <f7-button
            v-if="saving"
            large
            raised
            fill
            disabled
          >
            {{$t('Validation en cours...')}}
          </f7-button>
          <f7-button
            v-else-if="errorButton"
            class="color-theme-red"
            large
            raised
            fill
            disabled
          >
            {{ errorButton }}
          </f7-button>
          <f7-button
            v-else
            large
            raised
            fill
            @click="validate"
          >
            {{$t('Valider les signatures')}}
          </f7-button>
          <p class="text-align-center color-very-dark-grey color-theme">
            <em>{{$t('Une fois signé, ce document ne sera plus modifiable')}}</em>
          </p>
        </f7-col>
        <f7-col width="20" />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import NavigationTop from "../../layout/NavigationTop";
  import NavigationBottom from "../../layout/NavigationBottom";
  import {mapActions, mapGetters} from "vuex";
  import _ from "lodash";
  import {PhotoDatabase} from "@/composables/photoStorage";
  import {unobserve} from "@/store/helper";
  import errors from "@/components/mixins/errors";
  import language from "@/components/mixins/language";

  const photoDb = new PhotoDatabase();

  export default {
    name: "EdlSignature",
    components: {
      NavigationTop,
      NavigationBottom
    },
    mixins: [firewall, errors, language],
    props: {
      localId: {
        type: String,
        default: null
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        info: null,
        dimensions: {
          width: null,
          height: null,
        },
        saving: false,
        errorButton: null
      }
    },
    computed: {
      ...mapGetters('edlPersist', [
        'getCurrentLocalEdl'
      ]),
      title: function () {
        if(!this.nbRenters || this.nbRenters <= 1) {
          return 'Signature';
        } else {
          return 'Signatures';
        }
      },
      nbRenters: function () {
        return this.info ? (this.info.nbRenters || 1) : null;
      },
      signers: function () {
        if(!this.info) {
          return [];
        }

        let signers = [];

        if(this.info.garant) {
          signers.push({
            name: 'garant',
            label: 'Représentant',
            legal: true,
            fullname: this.info.garant.name
          });
        }

        //Renters
        for(let i = 1; i <= this.nbRenters; i++) {
          if(i === 1) {
            if(this.info['renter'].represented === undefined || this.info['renter'].represented === 'non' || this.info['renter'].represented === false || this.info.garant === null) {
              signers.push({
                name: 'renter',
                label: 'Locataire',
                legal: true,
                fullname: this.info['renter'].name
              });
            }
          } else {
            if(_.has(this.info, 'renter'+i)) {
              if (this.info['renter' + i].represented === undefined || this.info['renter' + i].represented === 'non' || this.info['renter' + i].represented === false || this.info.garant === null) {
                signers.push({
                  name: 'renter' + i,
                  label: 'Locataire #' + i,
                  legal: true,
                  fullname: this.info['renter' + i].name
                });
              }
            } else {
              console.log('Renter '+i+' not found, skipping. Probably a difference between nbRenters and actual renters');
            }
          }
        }

        //Owner or mandataire
        signers.push({
          name: 'owner',
          label: 'Propriétaire ou mandataire',
          legal: false
        });

        //Paraphes
        signers.push({
          name: 'paraphes',
          label: this.nbRenters > 1
            ? 'Initiales (paraphes) locataires et propriétaire/mandataire'
            : 'Initiales (paraphes) locataire et propriétaire/mandataire',
          legal: false
        });

        return signers;
      }
    },
    watch: {
      info: {
        handler: 'updateInfo',
        deep: true
      }
    },
    mounted: function () {
      let localId = +this.localId; //converts to number
      this.setCurrentLocalEdlId(localId);
      this.setup();

      let width = Math.min((80/100 * window.innerWidth), 600);
      let height = Math.min((40/100 * window.innerHeight), 2/3 * width, 400);
      let ratio = width/height;

      if(ratio >= 1){
        this.dimensions.width = width;
        this.dimensions.height = width / ratio;
      } else {
        this.dimensions.height = height;
        this.dimensions.width = width * ratio;
      }
    },
    methods: {
      ...mapActions('edlPersist', [
        'setCurrentLocalEdlId',
        'updateCurrentLocalEdlInfo'
      ]),
      setup: function () {
        let edl = unobserve(this.getCurrentLocalEdl);
        this.info = _.omit(edl, ['edl']);
      },
      updateInfoAtPath: function (path, value) {
        this.info = _.set(unobserve(this.info), path, value);
      },
      updateInfo: function () {
        this.updateCurrentLocalEdlInfo(this.info);
      },
      validate: function () {
        this.saving = true;
        this.saveSignatures().then(() => {
          this.updateInfoAtPath('open', false);
          this.updateInfoAtPath('date', Date.now()); //Replace creation date with signature date
          this.f7router.navigate({name: 'edlsLocal'}, {reloadAll: true});
        }).catch((error) => {
          console.error('Error saving signatures', error);
          if(error.type !== undefined && error.type === 'missing') {
            this.errorButton = 'Signature manquante : "'+error.signer.label+'"';
          } else {
            this.errorButton = 'Erreur inconnue';
            this.throwError(
              "Une erreur est survenue lors de la sauvegarde des signatures sur votre appareil.",
              "Erreur de sauvegarde des signatures",
              _.has(error, 'message') && _.has(error, 'name') ? error.name + " - " + error.message : error,
              error
            );
          }
          this.saving = false;

          setTimeout(() => {
            this.errorButton = null;
          }, 5000);
        });
      },
      saveSignatures: function () {
        return new Promise((resolve, reject) => {
          let promises = [];
          _.each(this.signers, (signer) => {
            let signaturePad = this.$refs['signature_' + signer.name][0];
            const {isEmpty, data} = signaturePad.saveSignature();

            if(!isEmpty) {
              let ref = '#Signature:' + signer.name + '_' + this.info.date;
              promises.push(new Promise((resolveAddPhoto, rejectAddPhoto) => {
                photoDb.addPhoto(ref, this.info.localId, null, data).then((id) => {
                  this.updateInfoAtPath(['signatures', signer.name], {
                    _ref: ref,
                    _data: '#IndexedDb:' + id
                  });
                  resolveAddPhoto();
                }).catch(() => {
                  photoDb.getPhotoByRefAndLocalEdlId(ref, this.localId).then((photo) => {
                    photoDb.updatePhoto(photo.id, data)
                      .then(resolveAddPhoto)
                      .catch((updateError) => {
                        console.error(updateError);
                        rejectAddPhoto(updateError);
                      });
                  }).catch((getExistingError) => {
                    console.error(getExistingError);
                    rejectAddPhoto(getExistingError);
                  });
                });
              }));
            } else {
              reject({type: 'missing', signer: signer});
            }
          });

          Promise.all(promises).then(resolve).catch(reject);
        });
      },
      clearSignature: function (signer) {
        let signaturePad = this.$refs['signature_' + signer.name][0];
        signaturePad.clearSignature();
      },
      switchLanguage: function (event) {
        this.info.locale = event.target.value !== "" ? event.target.value : null;
        this.updateInfo();
        this.showFullLanguageLabel = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .row.signature {
    .signature-info {
      h2 {
        text-align: center;
      }
      .block {
        padding: 0 10% 0 10%;
      }
    }
    .signature-container {
      height: 300px;
      .signature-field {
        text-align: center;
        height: 300px;
        width: 60%;
        .block {
          height: 100%;
        }
      }
      .signature-before, .signature-after {
        width: 20%;
      }
    }
    .signature-actions {
      text-align: center;
      height: 50px;
    }
  }

  @media screen and (max-width: 1000px) {
    .row.signature {
      .signature-container {
        .signature-field {
          width: 70%;
        }
        .signature-before, .signature-after {
          width: 15%;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .row.signature {
      .signature-container {
        .signature-field {
          width: 80%;
        }
        .signature-before, .signature-after {
          width: 10%;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .row.signature {
      .signature-container {
        .signature-field {
          width: 100%;
        }
        .signature-before, .signature-after {
          width: 0;
        }
      }
    }
  }
</style>
