<template>
  <f7-list
    :inset="immersive"
    sortable
    sortable-opposite
    @sortable:sort="sort"
  >
    <f7-list-item
      v-for="(entry, entryIndex) in currentModule.entry"
      :key="'entry'+entryIndex"
      link
      :title="$t(entry.name)"
      :class="{opened: entry.opened || false, selected: current.entry === entryIndex}"
      swipeout
      @click="select('entry', entryIndex)"
    >
      <template #media>
        <edl-edit-list-rate-indicators-before
          type="entry"
          :entry="entry"
          :has-context-gallery="hasContextGallery(entry, 'entry')"
          :current-photos-entry="currentPhotosEntry"
        />
      </template>
      <template
        v-if="entry.content === undefined"
        #after
      >
        <edl-edit-list-rate-indicators-after
          :entry="entry"
          :has-context-gallery="hasContextGallery(entry, 'entry')"
        />
      </template>
      <template
        v-else
        #after
      >
        <i
          v-if="entry !== currentPhotosEntry && subEntriesHaveAtLeastOneIndicator(entry)"
          class="f7-icons rate-indicator"
        >ellipsis</i>
      </template>
      <f7-swipeout-actions left>
        <f7-swipeout-button
          close
          class="color-grey"
          @click="unopened('entry', entryIndex)"
        >
          <i class="f7-icons">flag_fill</i>
        </f7-swipeout-button>
        <f7-swipeout-button
          v-if="!cantRename.includes(entry.name)"
          close
          class="color-theme-blue"
          @click="rename(entryIndex)"
        >
          <i class="f7-icons">pencil</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions right>
        <f7-swipeout-button
          v-if="!cantDelete.includes(entry.name)"
          close
          class="color-red"
          @click="deleteEntry(entryIndex)"
        >
          <i class="f7-icons">trash</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
    </f7-list-item>
  </f7-list>
</template>

<script>
import edlEditList from "@/components/mixins/edlEditList";
import EdlEditListRateIndicatorsAfter from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListRateIndicatorsAfter.vue";
import EdlEditListRateIndicatorsBefore from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListRateIndicatorsBefore.vue";
import hasGallery from "@/components/mixins/hasGallery";

export default {
  name: "EdlEditListEntry",
  components: {EdlEditListRateIndicatorsAfter, EdlEditListRateIndicatorsBefore},
  mixins: [edlEditList, hasGallery],
  props: {
    currentModule: {
      type: Object,
      required: true
    },
    current: {
      type: Object,
      required: true
    },
    currentPhotosEntry: {
      type: Object,
      default: null
    },
    contextGallery: {
      type: Array,
      default: null
    },
    forceCameraCapture: {
      type: Boolean,
      default: false
    },
    cantRename: {
      type: Array,
      default: () => []
    },
    cantDelete: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Array,
      default: () => []
    },
    immersive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sort', 'select', 'rename', 'unopened', 'delete', 'addNewPhotos'],
  methods: {
    sort: function (event) {
      this.$emit('sort', event);
    },
    select: function (type, index) {
      this.$emit('select', type, index);
    },
    unopened: function (type, index) {
      this.$emit('unopened', type, index);
    },
    rename: function (moduleIndex) {
      this.$emit('rename', moduleIndex);
    },
    deleteEntry: function (index) {
      this.$emit('delete', index);
    },
    addNewPhotos: function (event) {
      this.$emit('addNewPhotos', event);
    }
  }
}
</script>

<style lang="scss" scoped>
li.selected {
  background-color: var(--f7-theme-color);
  color: var(--f7-theme-color-light-grey);
  --f7-list-chevron-icon-color: var(--f7-theme-color-light-grey);
  :deep(.item-inner):after {
    background-color: var(--f7-theme-color);
  }
}
li:not(.selected).opened {
  background-color: var(--f7-theme-color-grey);
}
.swiper-slide {
  background: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 200px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
:deep(.file-input) {
  visibility: hidden;
}
:deep(.list-button) i.f7-icons {
  position: relative;
  top: 5px;
}
.photo-count, .number-count {
  width: 13px;
  height: 13px;
  background-color: var(--f7-theme-color-dark-grey);
  color: white;
  padding: 4px;
  border-radius: 50%;
  text-align: center;
  line-height: 13px;
  margin-left: -5px;
  margin-right: -4px;
}
.rate-indicator {
  display: block;
  font-size: 12px;
}
</style>
