<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      :back="true"
    >
      <template #back-actions>
        <a @click="backToRecap">
          <i class="f7-icons small-icon">chevron_left</i> <span>{{ $t("Précédent") }}</span>
        </a>
      </template>
      <template #global-actions>
        <f7-link
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="logout"
        >
          <i class="f7-icons">power</i>
        </f7-link>
      </template>
    </navigation-top>

    <f7-page-content>
      <f7-row
        v-for="signer in signers"
        :key="'signer_'+signer.name"
        class="signature margin-top"
      >
        <f7-col>
          <f7-row class="signature-info">
            <f7-col>
              <h2 class="margin-bottom-half">
                {{ $t(signer.label) }}
              </h2>
              <h3
                v-if="signer.fullname"
                class="text-align-center"
              >
                {{ signer.fullname }}
              </h3>
              <f7-block
                v-if="signer.legal"
                class="no-margin-top"
              >
                <p>
                  {{ $t("Le soussigné :") }}
                </p>
                <ul>
                  <li>
                    {{ $t("reconnait avoir reçu un exemplaire du présent état des lieux qui fait partie intégrante du contrat de location dont il ne peut être dissocié.") }}
                  </li>
                  <li>
                    {{ $t("s’engage à faire référence au présent état des lieux lors du départ du locataire.") }}
                  </li>
                  <li>
                    {{ $t("accepte et reconnait exact le présent état des lieux sous réserve du bon fonctionnement des installations sanitaires et électriques et des équipements de chauffage qui n'ont pu être vérifiés ce jour") }}
                  </li>
                  <li v-if="info.duplicateFromEdlID !== undefined && info.edlIO === 'Sortant'">
                    {{ $t("autorise la gérance à prélever sur sa garantie éventuelle le montant des frais résultant des réserves et dégradations constatées de la présente convention de sortie, mais jusqu'à concurrence de ce dernier montant") }}
                  </li>
                </ul>
              </f7-block>
            </f7-col>
          </f7-row>
          <f7-row class="signature-container">
            <f7-col class="signature-before" />
            <f7-col class="signature-field">
              <f7-block class="no-margin-vertical">
                <VueSignaturePad
                  :ref="'signature_'+signer.name"
                  height="100%"
                  :options="{backgroundColor: 'white', dotSize: 0.3,}"
                />
              </f7-block>
            </f7-col>
            <f7-col class="signature-after" />
          </f7-row>
          <f7-row class="signature-actions">
            <f7-col>
              <f7-button @click="clearSignature(signer)">
                {{ $t('Recommencer la signature') }}
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="20" />
        <f7-col
          width="60"
          class="margin-top margin-bottom"
        >
          <f7-button
            v-if="saving"
            large
            raised
            fill
            disabled
          >
            {{ $t('Validation en cours...') }}
          </f7-button>
          <f7-button
            v-else-if="error"
            class="color-theme-red"
            large
            raised
            fill
            disabled
          >
            {{ $t(error) }}
          </f7-button>
          <f7-button
            v-else-if="signers.length <= 2"
            large
            raised
            fill
            @click="validate"
          >
            {{ $t('Valider la signature') }}
          </f7-button>
          <f7-button
            v-else
            large
            raised
            fill
            @click="validate"
          >
            {{ $t('Valider les signatures') }}
          </f7-button>
          <p class="text-align-center color-very-dark-grey color-theme">
            <em>{{ $t("Une fois signé, ce document ne sera plus modifiable") }}</em>
          </p>
        </f7-col>
        <f7-col width="20" />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import NavigationTop from "../../layout/NavigationTop";
  import {mapActions} from "vuex";
  import _ from "lodash";
  import {PhotoDatabase} from "@/composables/photoStorage";
  import autoEdl from "@/components/mixins/autoEdl";
  import {unobserve} from "@/store/helper";

  const photoDb = new PhotoDatabase();

  export default {
    name: "AutoEdlSignature",
    components: {
      NavigationTop
    },
    mixins: [firewall, autoEdl],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        info: null,
        dimensions: {
          width: null,
          height: null,
        },
        saving: false,
        error: null
      }
    },
    computed: {
      title: function () {
        if(this.signers.length <= 2) {
          return this.$t('Signature');
        } else {
          return this.$t('Signatures');
        }
      },
      nbRenters: function () {
        return this.info ? (this.info.nbRenters || 1) : null;
      },
      signers: function () {
        if(!this.info) {
          return [];
        }

        let signers = [];

        if(this.info.garant) {
          signers.push({
            name: 'garant',
            label: 'Représentant',
            legal: true,
            fullname: this.info.garant.name
          });
        }

        //Renters
        for(let i = 1; i <= this.nbRenters; i++) {
          if(i === 1) {
            if(this.info['renter'].represented === undefined || this.info['renter'].represented === false || this.info['renter'].represented === 'non' || this.info.garant === null) {
              signers.push({
                name: 'renter',
                label: 'Locataire',
                legal: true,
                fullname: this.info['renter'].name
              });
            }
          } else {
            if(this.info['renter'+i].represented === undefined || this.info['renter'+i].represented === false || this.info['renter'+i].represented === 'non' || this.info.garant === null) {
              signers.push({
                name: 'renter' + i,
                label: 'Locataire ' + i,
                legal: true,
                fullname: this.info['renter'+i].name
              });
            }
          }
        }

        //Paraphes
        signers.push({
          name: 'paraphes',
          label: 'Initiales (paraphes)',
          legal: false
        });

        return signers;
      }
    },
    watch: {
      me: {
        handler: 'setup'
      },
      info: {
        handler: 'updateInfo',
        deep: true
      }
    },
    mounted: function () {
      let width = Math.min((80/100 * window.innerWidth), 600);
      let height = Math.min((40/100 * window.innerHeight), 2/3 * width, 400);
      let ratio = width/height;

      if(ratio >= 1){
        this.dimensions.width = width;
        this.dimensions.height = width / ratio;
      } else {
        this.dimensions.height = height;
        this.dimensions.width = width * ratio;
      }
    },
    methods: {
      ...mapActions('edlPersist', [
        'updateCurrentLocalEdlInfo'
      ]),
      setup: function () {
        this.setCurrentLocalEdlId(this.autoEdl.id);
        let edl = unobserve(this.getCurrentLocalEdl);
        this.info = _.omit(edl, ['edl']);
      },
      updateInfoAtPath: function (path, value) {
        this.info = _.set(unobserve(this.info), path, value);
      },
      updateInfo: function () {
        this.updateCurrentLocalEdlInfo(this.info);
      },
      backToRecap: function () {
        this.updateAutoEdl({id: this.userId, path: 'signature', value: false});
        this.f7router.navigate({name: 'autoEdlRecap'},{reloadAll: true});
      },
      validate: function () {
        this.saving = true;
        this.saveSignatures().then(() => {
          this.updateAutoEdl({id: this.userId, path: 'done', value: true});
          this.updateInfoAtPath('date', Date.now());
          this.f7router.navigate({name: 'autoEdlDone'}, {reloadAll: true});
        }).catch((error) => {
          console.error('Error saving signatures', error);
          if(error.type !== undefined && error.type === 'missing') {
            this.error = 'Signature manquante : "'+error.signer.label+'"';
          } else {
            this.error = 'Erreur inconnue, veuillez nous contacter';
          }
          this.saving = false;

          setTimeout(() => {
            this.error = null;
          }, 5000);
        });
      },
      saveSignatures: function () {
        return new Promise((resolve, reject) => {
          let promises = [];
          _.each(this.signers, (signer) => {
            let signaturePad = this.$refs['signature_' + signer.name][0];
            const {isEmpty, data} = signaturePad.saveSignature();

            if(!isEmpty) {
              let ref = '#Signature:' + signer.name + '_' + this.info.date;
              promises.push(new Promise((resolveAddPhoto, rejectAddPhoto) => {
                photoDb.addPhoto(ref, this.info.localId, null, data).then((id) => {
                  this.updateInfoAtPath(['signatures', signer.name], {
                    _ref: ref,
                    _data: '#IndexedDb:' + id
                  });
                  resolveAddPhoto();
                }).catch((error) => {
                  photoDb.getPhotoByRefAndLocalEdlId(ref, this.autoEdl.id).then((photo) => {
                    photoDb.updatePhoto(photo.id, data)
                      .then(resolveAddPhoto)
                      .catch(() => {
                        rejectAddPhoto(error);
                      });
                  }).catch(() => {
                    rejectAddPhoto(error);
                  });
                });
              }));
            } else {
              reject({type: 'missing', signer: signer});
            }
          });

          Promise.all(promises).then(resolve).catch(reject);
        });
      },
      clearSignature: function (signer) {
        let signaturePad = this.$refs['signature_' + signer.name][0];
        signaturePad.clearSignature();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .small-icon {
    font-size: 16px;
  }
  .row.signature {
    .signature-info {
      h2 {
        text-align: center;
      }
      .block {
        padding: 0 10% 0 10%;
      }
    }
    .signature-container {
      height: 300px;
      .signature-field {
        text-align: center;
        height: 300px;
        width: 60%;
        .block {
          height: 100%;
        }
      }
      .signature-before, .signature-after {
        width: 20%;
      }
    }
    .signature-actions {
      text-align: center;
      height: 50px;
    }
  }

  @media screen and (max-width: 1000px) {
    .row.signature {
      .signature-container {
        .signature-field {
          width: 70%;
        }
        .signature-before, .signature-after {
          width: 15%;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .row.signature {
      .signature-container {
        .signature-field {
          width: 80%;
        }
        .signature-before, .signature-after {
          width: 10%;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .row.signature {
      .signature-container {
        .signature-field {
          width: 100%;
        }
        .signature-before, .signature-after {
          width: 0;
        }
      }
    }
  }
</style>
