<template>
  <f7-list-input
    v-if="show"
    ref="input"
    class="edl-info-input"
    :type="type"
    :value="value"
    :label="labelTranslated + (required ? '*' : '')"
    floating-label
    :error-message="$t('Vous devez renseigner ce champ')"
    :error-message-force="isInvalid"
    :calendar-params="calendarParams"
    :clear-button="type === 'datepicker'"
    @input="set"
    @calendar:change="set"
  >
    <template #media>
      <f7-icon
        v-if="conditional"
        f7="arrow_turn_down_right"
      />
    </template>
  </f7-list-input>
</template>

<script>
import edlInfoStructure from "../../../../data/edlInfoStructure";
import _ from "lodash";
import {unobserve} from "@/store/helper";

export default {
  name: "EdlInfoInput",
  props: {
    path: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    case: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    conditional: {
      type: Boolean,
      default: false
    },
    revealErrors: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: null
    }
  },
  emits: ['set'],
  data: function () {
    return {
      'infoLocal': null
    };
  },
  computed: {
    data: function () {
      return edlInfoStructure(this.path) ?? null;
    },
    value: function () {
      if(this.infoLocal) {
        let value = _.get(this.infoLocal, this.path);
        if((value === undefined || value === null) && this.initialValue !== undefined && this.initialValue !== null) {
          return this.type === 'datepicker' ? [this.initialValue] : this.initialValue;
        } else if (value !== undefined && value !== null) {
          if (this.type === 'datepicker') { //Date picker takes array as value
            if (value === '') {
              return [];
            } else if (value instanceof Date) {
              return [value];
            } else {
              return [new Date(value)];
            }
          } else {
            return value;
          }
        }
      }

      return '';
    },
    show: function () {
      return this.case ? this.convertBoolean(_.get(this.infoLocal, this.case)) : true;
    },
    labelTranslated: function () {
      return this.$t(this.label);
    },
    isInvalid: function () {
      return this.required && (this.value === '' || (this.revealErrors && this.value === null));
    },
    calendarParams: function () {
      if(this.type === 'datepicker') {
        return {
          locale: 'fr',
          closeOnSelect: true
        };
      }
      return null;
    }
  },
  watch: {
    info: {
      handler: function (info) {
        this.infoLocal = unobserve(info);
      },
      immediate: true,
      deep: true
    }
  },
  mounted: function () {
    if(this.initialValue !== null && this.type === 'datepicker') {
      this.$refs.input.setValue([this.initialValue]);
    }
  },
  methods: {
    set: _.debounce(function (event) {
      let value;
      //Transform value before saving based on type
      switch(this.type) {
        case 'datepicker':
          if(_.isArray(event)) {
            event = event.length > 0 ? event[0] : null;
          }
          value = event ? Date.parse(event) : '';
          break;
        default:
          value = event.target.value;
          break;
      }

      this.$emit('set', {
        path: this.path,
        value: value
      });
      this.updateInfoLocal(value);
    }, 100),
    convertBoolean: function (value) {
      switch(value) {
        case 'oui':
          return true;
        case 'non':
        case undefined:
          return false;
        default:
          console.error('Path for "case" in input '+this.case+' must be a boolean');
          return false;
      }
    },
    updateInfoLocal: function (value) { //Handle some annoying reactivity issues when updating infoLocal at deep path
      let pathArray = this.path.split('.');
      let finalPathKey = pathArray.pop();
      let infoLocal = pathArray.length > 0 ? _.get(this.infoLocal, pathArray) : this.infoLocal;
      infoLocal[finalPathKey] = value;
    }
  }
}
</script>

<style lang="scss">
.list .edl-info-input textarea {
  height: 44px;
}
.list .edl-info-input .item-media .f7-icons {
  top: 23px;
  color: var(--f7-theme-color-dark-grey);
}
.calendar-popover .toolbar, .calendar-sheet .toolbar {
  --f7-toolbar-bg-color: var(--f7-theme-color-tint);
  --f7-toolbar-bg-color-rgb: var(--f7-theme-color-tint-rgb);
}
</style>
