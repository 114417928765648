<template>
  <f7-block
    v-if="rateName === 'number' && immersive"
    class="immersive-block"
  >
    <f7-block-title>
      {{ label(rateName) }}
      <f7-button
        v-if="rate !== null && rate !== ''"
        class="immersive-cancel"
        icon-f7="multiply_circle"
        @click="resetField"
      />
    </f7-block-title>
    <f7-row>
      <f7-col>
        <f7-list-input
          type="number"
          :value="rate"
          :label="label(rateName)"
          floating-label
          :readonly="readonly"
          @input="updateWithDebounce"
        />
      </f7-col>
      <f7-col class="immersive-num-pad-container">
        <div class="immersive-list immersive-num-pad">
          <f7-button
            v-for="num in ['1', '2', '3', '4', '5', '6', '7', '8', '9']"
            :key="rateName+'_pad_'+num"
            :text="num"
            class="immersive-number"
            raised
            round
            @click="onClickNumber(num)"
          />
          <f7-button
            :key="rateName+'_pad_nothing'"
            class="immersive-number immersive-number-action hidden"
          />
          <f7-button
            :key="rateName+'_pad_0'"
            :text="'0'"
            class="immersive-number"
            raised
            round
            @click="onClickNumber('0')"
          />
          <f7-button
            :key="rateName+'_pad_delete'"
            icon-f7="delete_left"
            class="immersive-number immersive-number-action"
            :class="{'hidden': rate === '' || rate === null}"
            raised
            round
            @click="deleteLastNumber()"
          />
        </div>
      </f7-col>
    </f7-row>
  </f7-block>
  <f7-block
    v-else-if="rateInfo === 'input' && immersive"
    class="immersive-block"
  >
    <f7-block-title>
      {{ label(rateName) }}
      <f7-button
        v-if="rate !== null && rate !== ''"
        class="immersive-cancel"
        icon-f7="multiply_circle"
        @click="resetField"
      />
    </f7-block-title>
    <f7-row>
      <f7-col>
        <f7-list-input
          type="text"
          :value="rate"
          :label="label(rateName)"
          floating-label
          :readonly="readonly"
          @input="updateWithDebounce"
        />
      </f7-col>
    </f7-row>
  </f7-block>
  <f7-block
    v-else-if="immersive"
    class="immersive-block"
  >
    <f7-block-title>
      {{ label(rateName) }}
      <f7-button
        v-if="rate !== null && rate !== ''"
        class="immersive-cancel"
        icon-f7="multiply_circle"
        @click="resetChoice"
      />
    </f7-block-title>
    <div class="immersive-list">
      <f7-button
        v-for="(option, index) in rateInfo"
        :key="rateName+'_'+option+index"
        :text="$t(option)"
        class="immersive-option"
        :active="rate === option"
        raised
        round
        @click="onClickImmersive(option)"
      />
      <f7-button
        :key="rateName+'_custom'"
        :text="$t('Personnaliser...')"
        class="immersive-option immersive-option-custom"
        @click="onClickImmersive('new')"
      />
    </div>
  </f7-block>
  <f7-list-input
    v-else-if="rateName === 'number'"
    type="number"
    :value="rate"
    :label="label(rateName)"
    floating-label
    :readonly="readonly"
    @input="updateWithDebounce"
  />
  <f7-list-input
    v-else-if="rateInfo === 'input'"
    type="text"
    :value="rate"
    :label="label(rateName)"
    floating-label
    :readonly="readonly"
    @input="updateWithDebounce"
  />
  <f7-list-input
    v-else
    type="select"
    :value="rate"
    :label="label(rateName)"
    floating-label
    :readonly="readonly"
    @change="update"
  >
    <template #root-start>
      <i
        v-if="rateName === 'cleaning' && rate !== undefined && rate !== null && rate !== '' && rate !== 'Propre'"
        class="f7-icons rate-section-icon"
      >
        paintbrush
      </i>
      <i
        v-else-if="rateName === 'state' && rate !== undefined && (rate === 'Ne fonctionne pas' || rate === 'Dysfonctionnement')"
        class="f7-icons rate-section-icon"
      >
        bolt_slash_fill
      </i>
    </template>
    <option />
    <option
      v-for="(option, index) in rateInfo"
      :key="rateName+'_'+option+index"
      :value="option"
      :selected="rate === option"
    >
      {{ $t(option) }}
    </option>
    <option value="new">
      {{ $t("Personnaliser...") }}
    </option>
  </f7-list-input>
</template>

<script>
  import {getConfig} from "@/data/edlDataStructureModuleEntryRate";
  import _ from "lodash";
  import { f7 } from "framework7-vue";

  export default {
    name: "EdlEditRateInput",
    props: {
      rateInfo: {
        type: [Object, String],
        default: null
      },
      rate: {
        type: String,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      },
      immersive: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update', 'addRateInfo'],
    data: function () {
      return {
        selectKey: 0
      }
    },
    computed: {
      rateName: function () {
        return this.$.vnode.key;
      }
    },
    methods: {
      label: function (name) {
        if (_.startsWith(name, 'typeCustom')) {
          name = 'typeCustom$';
        }

        let config = getConfig(name);
        return config ? this.$t(config.label) : this.$t(name);
      },
      updateWithDebounce: _.debounce(function (event) {
        this.update(event);
      }, 200),
      update: function (event) {
        let value = event.target.value;
        if(value === 'new') {
          this.createNewRateInfo(this.rateName);
        } else {
          this.$emit('update', {
            rate: this.rateName,
            value: value
          });
        }
      },
      onClickImmersive: function (value) {
        if(value === 'new') {
          this.createNewRateInfo(this.rateName);
        } else {
          this.$emit('update', {
            rate: this.rateName,
            value: value
          });
        }
      },
      onClickNumber: function (num) {
        this.$emit('update', {
          rate: this.rateName,
          value: this.rate === null ? num : "" + this.rate + num
        });
      },
      deleteLastNumber: function () {
        this.$emit('update', {
          rate: this.rateName,
          value: this.rate.slice(0, -1)
        });
      },
      resetField: function () {
        this.$emit('update', {
          rate: this.rateName,
          value: ""
        });
      },
      resetChoice: function () {
        this.$emit('update', {
          rate: this.rateName,
          value: null
        });
      },
      createNewRateInfo: function (rateName) {
        f7.dialog.prompt('Entrez votre nouvelle valeur à ajouter pour "'+this.label(rateName)+'" :', (result) => {
          if(result !== "") {
            this.$emit('addRateInfo', {
              'rateName': rateName,
              'value': result
            });
          }
        }, null);
      }
    }
  }
</script>

<style scoped lang="scss">
  .rate-section-icon {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 12px;
    color: var(--f7-theme-color-dark-grey);
  }
  .immersive-block {
    :deep(input) {
      font-size: 24px !important;
    }
    .block-title {
      font-size: 20px;
      line-height: 25px;
      font-family: var(--f7-font-family);
      text-align: center;
      margin-bottom: 20px;
    }
    .immersive-cancel {
      display: inline-block;
      position: absolute;
      top: -3px;
      color: var(--f7-theme-color-grey);
      :deep(i) {
        font-size: 18px;
      }
    }
    .immersive-list {
      display: flex;
      align-items: stretch;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .immersive-option {
        margin-left: 10px;
        margin-bottom: 10px;
        display: inline-block;
        height: 2.4em;
        font-size: 16px;
        line-height: 2.4em;
        padding-left: 10px;
        padding-right: 10px;
        &-custom {
          text-transform: none;
          color: var(--f7-theme-color-dark-grey);
        }
      }
      .immersive-number {
        width: 45px;
        margin-left: 6px;
        margin-bottom: 6px;
        display: inline-block;
        height: 2.4em;
        font-size: 18px;
        line-height: 2.4em;
        padding-left: 15px;
        padding-right: 15px;
        &-action {
          :deep(i) {
            font-size: 16px;
          }
          padding-left: 10px;
          padding-right: 10px;
        }
        &.hidden {
          opacity: 0;
        }
      }
    }
    .immersive-num-pad-container {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      .immersive-num-pad {
        width: 160px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .immersive-block {
      .block-title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
      }
      .immersive-list {
        .immersive-option {
          height: 2.2em;
          font-size: 14px;
          line-height: 2.2em;
          padding-left: 8px;
          padding-right: 8px;
        }
        .immersive-number {
          width: 40px;
          margin-left: 6px;
          margin-bottom: 6px;
          height: 2.2em;
          font-size: 17px;
          line-height: 2.2em;
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      .immersive-num-pad-container {
        .immersive-num-pad {
          width: 140px;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    .immersive-block {
      .block-title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .immersive-list {
        .immersive-option {
          height: 2.1em;
          font-size: 12.5px;
          line-height: 2.1em;
          padding-left: 5px;
          padding-right: 5px;
        }
        .immersive-number {
          width: 35px;
          margin-left: 4px;
          margin-bottom: 4px;
          height: 2.0em;
          font-size: 15px;
          line-height: 2.0em;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }
</style>
