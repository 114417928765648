<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Utilisateurs du groupe"
      :back="back"
    >
      <template #global-actions="{toggleSubNav, showSubNav}">
        <f7-link
          :class="{disabled: !isOnline}"
          :href="f7router.generateUrl({name: 'newUser'})"
          icon-f7="plus_circle"
          text="Nouveau"
        />
        <f7-link
          class="button color-white"
          @click="toggleSubNav"
        >
          <i
            v-if="!showSubNav"
            class="f7-icons"
          >search</i>
          <i
            v-else
            class="f7-icons"
          >chevron_up</i>
        </f7-link>
      </template>
      <template #subnav>
        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title, .item-text, .item-subtitle"
          :disable-button="!theme.aurora"
        />
      </template>
    </navigation-top>

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-block-title v-if="hasParentGroup">
        Liste des utilisateurs du groupe {{currentGroup?.parent.name}} > {{ currentGroup?.name}} ({{users.length}})
      </f7-block-title>
      <f7-block-title v-else>
        Liste des utilisateurs du groupe {{currentGroup?.name}} ({{users.length}})
      </f7-block-title>
      <f7-list
        v-if="!noUsers"
        class="search-list"
        media
      >
        <f7-list-item
          v-for="user in users"
          :key="user.id"
          swipeout
          :title="user.login"
          :subtitle="user.email"
          :link="f7router.generateUrl({name: 'user', params: {id: user.id}})"
          :class="{disabled: !isOnline}"
          media-item
          @swipeout:delete="deleteUser(user)"
        >
          <template #media>
            <i
              v-if="user.admin"
              class="f7-icons"
              title="Administrateur"
            >person_crop_square_fill</i>
            <i
              v-else
              class="f7-icons"
            >person_crop_circle</i>
            <img
              v-if="user.logo"
              :src="user.logo"
              class="logo"
            >
          </template>
          <f7-swipeout-actions right>
            <f7-swipeout-button
              delete
              confirm-text="Êtes-vous certains de vouloir supprimer cet utilisateur ? Cette action est définitive."
            >
              Supprimer
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
      <f7-block
        v-else
        inset
        strong
      >
        <p>Aucun utilisateur n'est relié à ce groupe.</p>
        <p v-if="!noGroups">Parcourez la liste des sous-groupes pour trouver des utilisateurs qui leur sont liés.</p>
      </f7-block>
      <f7-block-title
        v-if="!noGroups"
      >
        Liste des sous-groupes
      </f7-block-title>
      <f7-list
        v-if="!noGroups"
        media
      >
        <f7-list-item
          v-for="group in groups"
          :key="group.id"
          :title="group.name"
          :link="f7router.generateUrl({name: 'userListGroup', params: {id: group.id}})"
          :class="{disabled: !isOnline}"
          media-item
        >
          <template #media>
            <img
              v-if="group.logo"
              :src="group.logo"
              class="logo-group"
            >
            <i v-else>
              <f7-icon
                f7="person_3"
                size="30"
              />
            </i>
          </template>
          <template #after>
            <span>{{ group.userCount }}</span>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import {f7, theme} from "framework7-vue";
  import {nextTick} from "vue";
  import offline from "@/components/mixins/offline";
  import {deleteUser} from "@/api";

  export default {
    name: "UserListGroup",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall, offline],
    props: {
      id: {
        type: String,
        required: true
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters('user', {
        'getUsers': 'getUsersByGroupId',
      }),
      ...mapGetters('userGroup', {
        'getGroup': 'getUserGroupById',
        'getGroups': 'getUserGroupsByParentId',
      }),
      currentGroupId() {
        return parseInt(this.id)
      },
      currentGroup() {
        return this.getGroup(this.currentGroupId);
      },
      hasParentGroup() {
        return this.currentGroup?.parent !== undefined && this.currentGroup?.parent !== null;
      },
      users() {
        return this.getUsers(this.currentGroupId);
      },
      groups() {
        return this.getGroups(this.currentGroupId);
      },
      noUsers() {
        return this.users !== null && this.users.length === 0;
      },
      noGroups() {
        return this.groups !== null && this.groups.length === 0;
      },
      theme: function () {
        return theme;
      },
      back: function () {
        if(this.hasParentGroup && this.me?.group?.parent === undefined) {
          return {name: 'userListGroup', params: {id: this.currentGroup.parent.id}};
        } else if(this.isSuperAdmin) {
          return {name: 'users'};
        } else {
          return {name: 'account'};
        }
      }
    },
    mounted() {
      nextTick(() => {
        this.limitToAdmins();

        f7.preloader.show();
        this.pagePreloaderRunning = true;
        this.fetchUsers(this.currentGroupId).finally(() => f7.preloader.hide());
        this.fetchUserGroups();
      });
    },
    methods: {
      deleteUser,
      ...mapActions('user', [
        'fetchUsers',
        'deleteUser',
      ]),
      ...mapActions('userGroup', [
        'fetchUserGroups',
      ])
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 25px;
    left: 25px;
  }
  .logo-group {
    width: 30px;
    height: 30px;
  }
</style>
