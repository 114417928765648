<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Nouveau Groupe"
      back="userGroups"
    />

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list no-hairlines-md>
        <f7-list-input
          :value="userGroup.name"
          label="Nom"
          type="text"
          placeholder="Stark Corporation"
          required
          validate
          validate-on-blur
          clear-button
          autofocus
          @input="userGroup.name = $event.target.value"
        >
          <template #media>
            <f7-icon f7="person" />
          </template>
        </f7-list-input>

        <f7-list-input
          v-if="isSuperAdmin"
          type="select"
          :value="userGroup.parentId"
          @input="userGroup.parentId = +$event.target.value"
        >
          <option value="">
            ----- Groupe ----- (optionnel)
          </option>
          <option
            v-for="group in groups"
            :key="group.id"
            :value="group.id"
          >
            {{ group.name }}
          </option>
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-input>
      </f7-list>
      <f7-block-title>Options du groupe</f7-block-title>
      <f7-list no-hairlines-md>
        <f7-list-item header="Désactiver la modification des mots de passe par l’utilisateur">
          <template #media>
            <f7-icon f7="lock_slash_fill" />
          </template>
          <f7-toggle
            :checked="userGroup.lockPasswords"
            @change="userGroup.lockPasswords = $event.target.checked"
          />
        </f7-list-item>

        <f7-list-item header="Partager les EDLs entre chaque utilisateur du groupe">
          <template #media>
            <f7-icon f7="person_2_square_stack" />
          </template>
          <f7-toggle
            :checked="userGroup.shareEdls"
            @change="userGroup.shareEdls = $event.target.checked"
          />
        </f7-list-item>

        <f7-list-item header="Limiter les utilisateurs du groupe à un seul profil">
          <template #media>
            <f7-icon f7="person_alt_circle" />
          </template>
          <f7-toggle
            :checked="userGroup.uniqueProfile"
            @change="userGroup.uniqueProfile = $event.target.checked"
          />
        </f7-list-item>

        <f7-list-item header="Afficher les EDLs de tous les profils par défaut dans la liste des EDLs sauvegardés">
          <template #media>
            <f7-icon f7="person_2_alt" />
          </template>
          <f7-toggle
            :checked="userGroup.defaultListAllProfiles"
            @change="userGroup.defaultListAllProfiles = $event.target.checked"
          />
        </f7-list-item>
      </f7-list>
      <f7-list inset>
        <f7-list-button
          title="Enregistrer"
          @click="submit"
        />
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import { f7 } from "framework7-vue";
  import {nextTick} from "vue";

  export default {
    name: "UserGroupAdd",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        userGroup: {
          name: null,
          parentId: null,
          lockPasswords: false
        }
      }
    },
    computed: {
      ...mapGetters('userGroup', {
        'groups': 'getRootUserGroups',
      }),
      formIsValid() {
        return this.userGroup.name;
      }
    },
    mounted() {
      this.limitToAdmins();
      if(this.isSuperAdmin) {
        nextTick(() => {
          f7.preloader.show();
          this.fetchUserGroups().finally(() => f7.preloader.hide());
        });
      } else {
        this.userGroup.parentId = this.me.groupId;
      }
    },
    methods: {
      ...mapActions('userGroup', [
        'createUserGroup',
        'fetchUserGroups',
      ]),
      ...mapGetters('userGroup', [
        'getAllChildrenOfGroupId',
      ]),
      submit() {
        if (!this.formIsValid) {
          f7.dialog.alert('Veuillez corriger les informations renseignées !', 'Erreur')
          return
        }

        f7.preloader.show('Enregistrement en cours...');
        this.createUserGroup(this.userGroup).then((newUserGroup) => {
          f7.preloader.hide()
          this.f7router.navigate({name: 'userGroup', params: { id: newUserGroup.id }})
        })
        // @TODO Manage form errors returns by API
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
