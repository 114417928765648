<template>
  <f7-list-input
    type="select"
    :value="value"
    :label="label + (required ? '*' : '')"
    floating-label
    error-message="Vous devez choisir une valeur"
    :error-message-force="isInvalid"
    @change="set"
  >
    <option
      v-for="option in data"
      :key="path+'_'+option"
      :value="option"
    >
      {{ option }}
    </option>
  </f7-list-input>
</template>

<script>
  import _ from "lodash";
  import edlInfoStructure from "../../../../data/edlInfoStructure";

  export default {
    name: "EdlInfoSelect",
    props: {
      path: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      info: {
        type: Object,
        default: null
      },
      required: {
        type: Boolean
      },
      revealErrors: {
        type: Boolean
      },
    },
    emits: ['set'],
    computed: {
      data: function () {
        return edlInfoStructure(this.path) ?? null;
      },
      value: function () {
        return this.info ? _.get(this.info, this.path) : '';
      },
      isInvalid: function () {
        return this.required && (this.value === '' || (this.revealErrors && this.value === null));
      }
    },
    methods: {
      set: function (event) {
        this.$emit('set', {
          path: this.path,
          value: event.target.value
        });
      }
    }
  }
</script>

<style scoped>

</style>
