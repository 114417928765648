<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Nouvel utilisateur"
      back="users"
    />

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list no-hairlines-md>
        <f7-list-input
          :value="user.login"
          label="Nom d'utilisateur *"
          type="text"
          placeholder="VotreResidence"
          info="Merci d'éviter l'utilisation d'espaces"
          required
          validate
          validate-on-blur
          clear-button
          autofocus
          @input="user.login = $event.target.value"
        >
          <template #media>
            <f7-icon f7="person" />
          </template>
        </f7-list-input>

        <f7-list-input
          :value="user.email"
          label="E-mail *"
          type="email"
          placeholder="nom@votreresidence.fr"
          required
          validate
          validate-on-blur
          clear-button
          @input="user.email = $event.target.value"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-input>

        <f7-list-input
          :value="user.password"
          label="Mot de passe (optionnel)"
          type="password"
          placeholder="Laisser vide pour générer un mot de passe aléatoire (recommandé)"
          info="Le mot de passe sera envoyé à l'e-mail ci-dessus"
          validate
          validate-on-blur
          clear-button
          @input="user.password = $event.target.value"
        >
          <template #media>
            <f7-icon f7="lock_fill" />
          </template>
        </f7-list-input>

        <f7-list-input
          type="select"
          :value="user.groupId"
          :required="!isSuperAdmin"
          @input="user.groupId = +$event.target.value"
        >
          <option
            v-if="isSuperAdmin"
            value=""
          >
            ----- Groupe ----- (optionnel)
          </option>
          <option
            v-for="group in groups"
            :key="group.id"
            :value="group.id"
          >
            {{ group.name }}
          </option>
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-input>
        <f7-list-item
          :header="me.groupId ? 'Administrateur groupe' : 'Super admin'"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="lock_shield" />
          </template>
          <f7-toggle
            :checked="user.admin"
            @change="user.admin = $event.target.checked"
          />
        </f7-list-item>
      </f7-list>
      <f7-block-title>Options de l'utilisateur</f7-block-title>
      <f7-list>
        <f7-list-item v-if="isSuperAdmin" header="Activer les Auto-EDLs">
          <template #media>
            <f7-icon f7="wand_stars" />
          </template>
          <f7-toggle
            :checked="user.canAutoEdl"
            @change="user.canAutoEdl = $event.target.checked"
          />
        </f7-list-item>
        <f7-list-item header="Limiter l'utilisateur à un seul profil">
          <template #media>
            <f7-icon f7="person_alt_circle" />
          </template>
          <f7-toggle
            :checked="user.uniqueProfile"
            @change="user.uniqueProfile = $event.target.checked"
          />
        </f7-list-item>
        <f7-list-item header="Afficher les EDLs de tous les profils par défaut dans la liste des EDLs sauvegardés">
          <template #media>
            <f7-icon f7="person_2_alt" />
          </template>
          <f7-toggle
            :checked="user.defaultListAllProfiles"
            @change="user.defaultListAllProfiles = $event.target.checked"
          />
        </f7-list-item>
      </f7-list>
      <f7-list inset>
        <f7-list-button
          title="Enregistrer"
          @click="submit"
        />
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import { f7 } from "framework7-vue";

  export default {
    name: "UserAdd",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        user: {
          login: null,
          email: null,
          groupId: null,
          admin: false
        }
      }
    },
    computed: {
      ...mapGetters('userGroup', [
        'getAllUserGroups',
      ]),
      groups() {
        return this.getAllUserGroups;
      },
      formIsValid() {
        return this.user.login && this.user.email && (this.user.groupId !== null || this.isSuperAdmin);
      }
    },
    mounted() {
      this.limitToAdmins();
      this.$nextTick(() => {
        f7.preloader.show();
        this.pagePreloaderRunning = true;
        this.fetchUserGroups().finally(() => f7.preloader.hide());
        if(!this.isSuperAdmin) {
          this.user.groupId = this.me.groupId;
        }
      });
    },
    methods: {
      ...mapActions('user', [
        'createUser',
      ]),
      ...mapActions('userGroup', [
        'fetchUserGroups',
      ]),
      submit() {
        if (!this.formIsValid) {
          f7.dialog.alert('Veuillez corriger les informations renseignées !', 'Erreur')
          return
        }

        f7.preloader.show('Enregistrement en cours...');
        this.createUser(this.user).then((newUser) => {
          f7.preloader.hide()
          this.f7router.navigate({name: 'user', params: { id: newUser.id }})
        })
        // @TODO Manage form errors returns by API
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
