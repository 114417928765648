<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Auto-EDL"
      :back="canNavigatePrevious"
    >
      <template #back-actions>
        <a @click="previous">
          <i class="f7-icons small-icon">chevron_left</i> <span>{{ $t("Précédent") }}</span>
        </a>
      </template>
      <template #global-actions>
        <f7-link
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="logout"
        >
          <i class="f7-icons">power</i>
        </f7-link>
      </template>
    </navigation-top>

    <f7-page-content>
      <f7-row
        v-show="!comment"
        class="navigation extendable"
      >
        <f7-col class="navigation-content">
          <p
            v-if="currentModule"
            class="navigation-module"
          >
            {{ $t(currentModule.name) }}
          </p>
          <i
            v-if="currentEntry"
            class="f7-icons"
          >chevron_down</i>
          <p
            v-if="currentEntry"
            class="navigation-entry"
          >
            {{ $t(currentEntry.name) }}
          </p>
          <i
            v-if="currentSubEntry"
            class="f7-icons"
          >chevron_down</i>
          <p
            v-if="currentSubEntry"
            class="navigation-sub-entry"
          >
            {{ $t(currentSubEntry.name) }}
          </p>
        </f7-col>
      </f7-row>
      <f7-row
        v-show="comment"
        class="navigation extendable"
      >
        <f7-col class="navigation-content">
          <p class="navigation-comment">
            {{ $t("Commentaire général") }}
          </p>
          <i class="f7-icons">chevron_down</i>
        </f7-col>
      </f7-row>

      <input
        ref="photoInput"
        class="file-input"
        type="file"
        accept="image/*"
        multiple
        @change="addNewPhotos"
      >

      <f7-row
        v-if="!comment"
        ref="mainView"
        class="extendable"
      >
        <f7-col
          v-if="isSubEntrySelected"
          class="rate-section"
        >
          <edl-edit-rate
            :entry="currentSubEntry"
            :rate-info="currentEntry.rateInfo"
            :context-gallery="contextGallery"
            :photo-context="currentPhotoContext"
            :manual-costing="isManualCosting"
            :auto-fill-observations="currentAutoFillObservations"
            auto-edl
            :auto-edl-photo="showPhotos"
            @update="updateRate"
            @add-photo="openPhotoInput"
            @add-rate-info="addRateInfo"
          />
        </f7-col>
        <f7-col
          v-else-if="isEntrySelected && !hasSubEntries"
          class="rate-section"
        >
          <edl-edit-rate
            :entry="currentEntry"
            :rate-info="currentEntry.rateInfo"
            :context-gallery="contextGallery"
            :photo-context="currentPhotoContext"
            :manual-costing="isManualCosting"
            :auto-fill-observations="currentAutoFillObservations"
            auto-edl
            :auto-edl-photo="showPhotos"
            @update="updateRate"
            @add-photo="openPhotoInput"
            @add-rate-info="addRateInfo"
          />
        </f7-col>
      </f7-row>
      <f7-row
        v-else
        class="extendable"
      >
        <f7-col>
          <f7-block strong>
            <f7-input
              id="comment"
              type="textarea"
              :value="info.comment"
              class="general-comment"
              @input="updateComment"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            v-if="addingPhotos > 0"
            type="button"
            large
            raised
            fill
            disabled
          >
            {{ $t('Chargement...') }}
          </f7-button>
          <f7-button
            v-else-if="comment"
            type="button"
            large
            raised
            fill
            @click="goToRecap"
          >
            {{ $t('Valider et signer') }}
          </f7-button>
          <f7-button
            v-else-if="!showPhotos"
            type="button"
            large
            raised
            fill
            @click="validate"
          >
            {{ $t('Valider') }}
          </f7-button>
          <f7-button
            v-else-if="!awaitingPhotos && beforeGalleryCount === 0 && !hasAddedNewPhotos"
            type="button"
            large
            raised
            fill
            @click="next"
          >
            {{ $t('Continuer sans photo') }}
          </f7-button>
          <f7-button
            v-else-if="!awaitingPhotos && !hasAddedNewPhotos"
            type="button"
            large
            raised
            fill
            @click="next"
          >
            {{ $t('Continuer') }}
          </f7-button>
          <f7-button
            v-else-if="!hasAddedNewPhotos && beforeGalleryCount === 0"
            type="button"
            large
            raised
            fill
            disabled
          >
            {{ $t('Photo obligatoire') }}
          </f7-button>
          <f7-button
            v-else-if="!hasAddedNewPhotos"
            type="button"
            large
            raised
            fill
            disabled
          >
            {{ $t('Nouvelle photo obligatoire') }}
          </f7-button>
          <f7-button
            v-else
            type="button"
            large
            raised
            fill
            @click="next"
          >
            {{ $t('Valider') }}
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
import firewall from "../../mixins/firewall";
import {mapActions} from "vuex";
import EdlEditRate from "../Edl/EdlEdit/EdlEditRate/EdlEditRate.vue";
import {getObservations} from "@/data/edlDataStructureAutoFillObservation";
import _ from "lodash";
import {convertFileToBase64, PhotoDatabase, resizeBase64} from "@/composables/photoStorage";
import hasMedia from "@/components/mixins/hasMedia";
import NavigationTop from "@/components/layout/NavigationTop";
import autoEdl from "@/components/mixins/autoEdl";
import {unobserve} from "@/store/helper";
import language from "@/components/mixins/language";
import {fillIds} from "@/composables/prepare";

const photoDb = new PhotoDatabase();

export default {
  name: "AutoEdlEdit",
  components: {NavigationTop, EdlEditRate},
  mixins: [firewall, hasMedia, autoEdl, language],
  props: {
    f7router: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      info: null,
      edl: null,
      current: {
        module: null,
        entry: null,
        subEntry: null
      },
      showPhotos: false,
      awaitingPhotos: false,
      addingPhotos: 0,
      beforeRate: null,
      beforeGalleryCount: null,
      comment: false,
      gallery: [],
      openingFileSelector: false,
      newPhotoContext: null
    }
  },
  computed: {
    currentPath: function () {
      let path = [];
      if(this.isModuleSelected) {
        path.push(this.current.module);
      }
      if(this.isEntrySelected) {
        path.push('entry');
        path.push(this.current.entry);
      }
      if(this.isSubEntrySelected) {
        path.push('content');
        path.push(this.current.subEntry);
      }

      return path;
    },
    isModuleSelected: function () {
      return this.current.module !== null;
    },
    currentPathForModule: function () {
      if(!this.isModuleSelected) {
        return null;
      }
      let path = unobserve(this.currentPath);
      return path.slice(0,1);
    },
    currentModule: function () {
      if(!this.isModuleSelected) {
        return null;
      }
      return _.get(this.edl, this.currentPathForModule);
    },
    isEntrySelected: function () {
      return this.current.entry !== null;
    },
    currentPathForEntry: function () {
      if(!this.isEntrySelected) {
        return null;
      }
      let path = unobserve(this.currentPath);
      return path.slice(0,3);
    },
    currentEntry: function () {
      if(!this.isEntrySelected) {
        return null;
      }
      return _.get(this.edl, this.currentPathForEntry);
    },
    isSubEntrySelected: function () {
      return this.current.subEntry !== null;
    },
    hasSubEntries: function () {
      if(!this.isEntrySelected) {
        return false;
      }
      return this.currentEntry.content !== undefined;
    },
    currentEntryIsPhotos: function () {
      return this.currentEntry && this.currentEntry.name === "Photos";
    },
    currentPathForSubEntry: function () {
      if(!this.isSubEntrySelected) {
        return null;
      }
      return this.currentPath
    },
    currentSubEntry: function () {
      if(!this.isSubEntrySelected) {
        return null;
      }
      return _.get(this.edl, this.currentPathForSubEntry);
    },
    currentRate: function () {
      let entry = _.get(this.edl, this.currentPath);
      return entry !== undefined ? entry.rate : null;
    },
    currentRateHasChanged: function () {
      return !_.isEqual(this.currentRate, this.beforeRate);
    },
    currentAutoFillObservations: function () {
      if(this.currentSubEntry && this.currentSubEntry.autoFillObservation !== undefined) {
        return getObservations(this.currentSubEntry.autoFillObservation, this.locale);
      } else if (this.currentEntry) {
        return getObservations(this.currentEntry.autoFillObservation, this.locale);
      } else {
        return null;
      }
    },
    currentPhotoContext: function () {
      let contextIds =
        this.currentModule.id
        +"."
        + this.currentEntry.id;

      if(this.currentSubEntry) {
        contextIds += "."+this.currentSubEntry.id;
      }

      return contextIds;
    },
    currentPathForPhotosEntry: function () {
      if(this.currentModule === null) {
        return null;
      }
      let photosEntryIndex = _.findIndex(this.currentModule.entry, (entry) => {
        return entry.name === "Photos";
      });
      if(photosEntryIndex === undefined) {
        return null;
      } else {
        return [this.current.module, 'entry', photosEntryIndex];
      }
    },
    currentPhotosEntry: function () {
      if(!this.currentPathForPhotosEntry) {
        return null;
      }
      return _.get(this.edl, this.currentPathForPhotosEntry);
    },
    contextGallery: function () {
      return this.currentPhotosEntry ? _.filter(_.map(this.gallery, (data, index) => {
        let contextPhoto = {data};
        let photo = this.currentPhotosEntry.content[index];
        if(photo !== null && photo !== undefined
          && photo.rate !== undefined && photo.rate.photo !== null && photo.rate.photo !== undefined) {
          contextPhoto.rate = photo.rate;
          if(photo.rate.photo._context !== undefined) {
            contextPhoto.context = photo.rate.photo._context;
          }
        }
        return contextPhoto;
      }), (photo) => {
        return photo.context !== undefined && photo.context === this.currentPhotoContext;
      }): null;
    },
    hasAddedNewPhotos: function () {
      return this.contextGallery ? this.beforeGalleryCount < this.contextGallery.length : false;
    },
    isManualCosting: function () {
      return this.info.conventionSortieManuelle !== undefined && this.info.conventionSortieManuelle;
    },
    canNavigatePrevious: function () {
      if (this.current.module > 0) {
        return true;
      } else if (this.current.entry > 0) {
        return true;
      } else return this.current.subEntry !== null && this.current.subEntry > 0;
    }
  },
  watch: {
    me: {
      handler: 'setup'
    },
    edl: {
      handler: 'updateEdl',
      deep: true
    },
    info: {
      handler: 'updateInfo',
      deep: true
    },
    currentModule: function () {
      if(this.currentPhotosEntry) {
        this.loadGallery(this.currentPhotosEntry);
      } else {
        this.gallery = [];
      }
    },
  },
  methods: {
    ...mapActions('edlPersist', [
      'updateCurrentLocalEdlData',
      'updateCurrentLocalEdlInfo'
    ]),
    setup: function () {
      if(this.autoEdl === undefined) { //case re-connected after auto-edl on same device
        this.f7router.navigate({name:'edlsLocal'});
      } else {
        this.setCurrentLocalEdlId(this.autoEdl.id);
        let edl = unobserve(this.getCurrentLocalEdl);
        this.edl = fillIds(edl.edl); //make sure all level ids are defined
        this.info = _.omit(edl, ['edl']);
        this.initCurrent();
      }
    },
    updateAtPath: function (path, value) {
      this.edl = _.set(unobserve(this.edl), path, value);
    },
    updateEdl: function () {
      if(this.getCurrentLocalEdl !== this.edl) {
        this.updateCurrentLocalEdlData(this.edl);
      }
    },
    updateInfo: function () {
      this.updateCurrentLocalEdlInfo(this.info);
    },
    updateRate: function ({rate, value}) {
      this.edl = _.set(unobserve(this.edl), this.getCurrentPathForRate(rate), value);
    },
    updateComment: function (event) {
      this.info.comment = event.target.value;
    },
    addRateInfo: function ({rateName, value}) {
      if(this.isSubEntrySelected) {
        let entry = unobserve(this.currentEntry);
        entry.rateInfo[rateName].push(value);
        this.updateAtPath(this.currentPathForEntry, entry);
        let subEntry = unobserve(this.currentSubEntry);
        subEntry.rate[rateName] = value;
        this.updateAtPath(this.currentPathForSubEntry, subEntry);
      } else {
        let entry = unobserve(this.currentEntry);
        entry.rateInfo[rateName].push(value);
        entry.rate[rateName] = value;
        this.updateAtPath(this.currentPathForEntry, entry);
      }
    },
    saveCurrent: function () {
      this.updateAutoEdl({
        id: this.userId,
        path: 'current',
        value: this.current
      });
      this.updateAutoEdl({
        id: this.userId,
        path: 'comment',
        value: this.comment
      });
    },
    initCurrent: function () {
      if (this.autoEdl.current !== undefined) {
        this.current = _.clone(this.autoEdl.current);
      } else {
        this.current.module = 0;
        this.current.entry = 0;
        if (this.hasSubEntries) {
          this.current.subEntry = 0;
        }
      }
      this.comment = _.clone(this.autoEdl.comment);
      this.setBeforeRate();
    },
    validate: function () {
      if (this.currentPhotosEntry !== null) {
        if(this.currentRateHasChanged) {
          this.awaitingPhotos = true;
        }
        this.showPhotos = true;
        this.beforeGalleryCount = this.contextGallery !== null ? this.contextGallery.length : 0;
      } else {
        this.awaitingPhotos = this.showPhotos = false;
        this.next();
      }
    },
    next: function () {
      if (this.hasSubEntries) {
        this.nextSubEntry();
      } else {
        this.nextEntry();
      }
      this.awaitingPhotos = false;
      this.showPhotos = false;
      this.setBeforeRate();
      this.saveCurrent();
    },
    nextSubEntry: function () {
      let nextKey = this.current.subEntry + 1;
      if(this.currentEntry.content[nextKey] !== undefined) {
        this.current.subEntry++;
      } else {
        this.nextEntry();
      }
    },
    nextEntry: function () {
      if(this.current.subEntry !== null) {
        this.current.subEntry = null;
      }
      let nextKey = this.current.entry + 1;
      if(this.currentModule.entry[nextKey] !== undefined) {
        this.current.entry++;
        if(this.currentEntryIsPhotos) {
          if(this.currentModule.entry.length - 1 === this.current.entry) {
            this.nextModule();
          } else {
            this.nextEntry();
          }
        }
        if(this.hasSubEntries) {
          this.current.subEntry = 0;
        }
      } else {
        this.nextModule();
      }
    },
    nextModule: function () {
      let nextKey = this.current.module + 1;
      if(this.edl[nextKey] !== undefined) {
        this.current.module++;
        if(this.current.entry !== 0) {
          this.current.entry = 0;
        }
        if(this.hasSubEntries) {
          this.current.subEntry = 0;
        } else {
          this.current.subEntry = null;
        }
        if(this.currentEntryIsPhotos) {
          if(this.currentModule.entry.length - 1 === this.current.entry) {
            this.nextModule();
          } else {
            this.nextEntry();
          }
        }
      } else {
        this.showComment();
      }
    },
    previous: function () {
      if (this.comment) {
        this.comment = false;
      } else if (this.hasSubEntries) {
        this.previousSubEntry();
      } else {
        this.previousEntry();
      }
      if (this.currentEntryIsPhotos) {
        this.previousEntry();
      }
      this.awaitingPhotos = false;
      this.showPhotos = false;
      this.setBeforeRate();
      this.saveCurrent();
    },
    previousSubEntry: function () {
      let previousKey = this.current.subEntry - 1;
      if(previousKey >= 0 && this.currentEntry.content[previousKey] !== undefined) {
        this.current.subEntry--;
      } else {
        this.previousEntry();
      }
    },
    previousEntry: function () {
      let previousKey = this.current.entry - 1;
      if(previousKey >= 0 && this.currentModule.entry[previousKey] !== undefined) {
        this.current.entry--;
        if(this.currentEntryIsPhotos) {
          this.previousEntry();
        }
        this.current.subEntry = this.hasSubEntries ? this.currentEntry.content.length - 1 : null;
      } else {
        this.previousModule();
      }
    },
    previousModule: function () {
      let previousKey = this.current.module - 1;
      if(previousKey >= 0 && this.edl[previousKey] !== undefined) {
        this.current.module--;
        this.current.entry = this.currentModule.entry.length - 1;
        if(this.currentEntryIsPhotos) {
          this.previousEntry();
        }
        this.current.subEntry = this.hasSubEntries ? this.currentEntry.content.length - 1 : null;
      }
    },
    showComment: function () {
      this.comment = true;
      this.saveCurrent();
      this.$forceUpdate();
    },
    setBeforeRate: function () {
      this.beforeRate = unobserve(this.currentRate);
    },
    getCurrentPathForRate: function (rate) {
      let path = unobserve(this.currentPath);
      path.push('rate');
      path.push(rate);
      return path;
    },
    getMedia: function (index) {
      return this.gallery[index];
    },
    openPhotoInput: function (withContext = false) {
      this.openingFileSelector = true;
      this.$refs.photoInput.click();
      this.newPhotoContext = withContext ? this.currentPhotoContext : null;
      setTimeout(() => {
        this.openingFileSelector = false;
      }, 2000);
    },
    addNewPhotos: function (event) {
      let files = event.target.files;
      this.addingPhotos = files.length;
      Array.from(files).forEach((file) => {
        convertFileToBase64(file).then((data) => {
          resizeBase64(data, 800, 800, file.type).then((resizedData) => {
            this.addNewPhotoToModule(resizedData, file.name);
            event.target.value = null;
            this.addingPhotos--;
          });
        });
      });
    },
    addNewPhotoToModule: function (data, filename) {
      let now = new Date;
      let ref = "#Upload:"+filename+":"+now.toISOString();
      let photoContext = this.newPhotoContext !== null ? unobserve(this.newPhotoContext) : null;
      photoDb.addPhoto(ref, this.info.localId, photoContext, data)
        .then((id) => {
          let photo = {
            _ref: ref,
            _data: '#IndexedDb:'+id
          };
          if(this.newPhotoContext !== null) {
            photo._context = photoContext;
          }
          let rate = {
            photo,
            'other': null
          };
          let content = {
            name: "Photo",
            opened: false,
            rate
          };
          let photosEntry = unobserve(this.currentPhotosEntry);
          photosEntry.content.push(content);
          this.updateAtPath(this.currentPathForPhotosEntry, photosEntry);
          this.loadGallery(this.currentPhotosEntry);
        })
       .catch((error) => {
         console.error(error);
       })
      ;
    },
    loadGallery: function (photosEntry) {
      let photoIndexedDbIds = _.map(photosEntry.content, (photo) => this.getPhotoId(photo.rate.photo));
      let photoPromises = photoIndexedDbIds.map((id) => {
        return id ? photoDb.getPhoto(id) : null;
      });
      _.compact(photoPromises);
      Promise.all(photoPromises).then((photos) => {
        this.gallery = photos.map((photo) => photo ? photo.data : null);
      });
    },
    hasContextGallery: function (entry, type, parent = null) {
      let entryContext = this.currentModule.id;
      if(type === 'entry') {
        entryContext += "." + entry.id;
      } else {
        if(!parent) {
          parent = this.currentEntry;
        }
        entryContext += "." + parent.id + "." + entry.id;
      }

      return _.filter(this.contextGallery, (photo) => {
        return photo.context !== undefined && photo.context === entryContext;
      }).length > 0;
    },
    goToRecap: function () {
      this.updateAutoEdl({id: this.userId, path: 'recap', value: true});
      this.f7router.navigate({name: 'autoEdlRecap'}, {reloadAll: true});
    },
  }
}
</script>

<style lang="scss" scoped>
  .navigation {
    display:none;
  }
  li[selected="selected"] {
    background-color: var(--f7-theme-color);
    color: var(--f7-theme-color-light-grey);
    --f7-list-chevron-icon-color: var(--f7-theme-color-light-grey);
    :deep(.item-inner):after {
      background-color: var(--f7-theme-color);
    }
  }
  li:not([selected="selected"]).opened {
    background-color: var(--f7-theme-color-grey);
  }
  .list.inset {
    --f7-list-inset-side-margin: 8px;
  }
  .rate-section {
    .list {
      border-left: 4px solid var(--f7-theme-color-tint);
    }
  }
  .general-comment {
    width: 100%;
    height: 100px;
    :deep(textarea) {
      height: 100px;
    }
  }
  .swiper-slide {
    background: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 200px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }
  :deep(.file-input) {
    visibility: hidden;
  }
  .media-item {
    --f7-list-media-item-title-font-weight: 400;
    :deep(.item-title) {
      font-size: 12px;
    }
    :deep(.item-subtitle) {
      font-style: italic;
      font-size: 14px;
    }
  }
  :deep(.list-button) i.f7-icons {
    position: relative;
    top: 5px;
  }
  .add-element-button {
    top: 5px;
  }
  .small-icon {
    font-size: 16px;
  }
  .medium-icon {
    font-size: 24px;
  }
  .list-action-button {
    z-index: 10;
    width: 10px;
  }
  :deep(.popover-inner) {
    max-height: 400px;
  }
  .rate-indicator {
    display: block;
    font-size: 12px;
  }
  .photo-count {
    width: 13px;
    height: 13px;
    background-color: var(--f7-theme-color-dark-grey);
    color: white;
    padding: 4px;
    border-radius: 50%;
    text-align: center;
    line-height: 13px;
    margin-left: -5px;
    margin-right: -4px;
  }
  .navigation {
    display:block;
    .navigation-content {
      width: 100%;
      display: block;
      top: 58px;
      text-align: center;
      i {
        margin-top: 2px;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 18px;
      }
      p {
        margin-top: 6px;
        margin-bottom: 4px;
        color: var(--f7-theme-color-very-dark-grey);
        &.navigation-module, &.navigation-comment {
          font-weight: bold;
          font-size: 28px;
        }
        &.navigation-entry {
          font-size: 24px;
        }
        &.navigation-sub-entry {
          font-size: 18px;
        }
      }
    }
  }
</style>
