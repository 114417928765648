<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      show-sub-nav
      :back="isModified?null:'edlsRemote'"
      :back-title="isModified?'':'Retour'"
    >
      <template #global-actions>
        <p
          v-if="isLoading"
          class="status"
        >
          Chargement...
        </p>
        <p
          v-else-if="isSaving"
          class="status"
        >
          Enregistrement...
        </p>
        <p
          v-else-if="isSaved"
          class="status"
        >
          Enregistré !
        </p>
      </template>
    </navigation-top>
    <navigation-bottom v-if="!isPublicRoute" current="edls" />

    <f7-page-content ref="costing">
      <f7-row ref="recap">
        <f7-col>
          <f7-row
            v-for="(module, moduleName) in inputCosting"
            :key="moduleName"
            ref="modules"
            :header="$t(moduleName)"
          >
            <f7-col>
              <f7-block-header class="header-module">
                {{ $t(moduleName) }}
              </f7-block-header>
              <f7-block class="no-padding-horizontal no-margin-top">
                <edl-costing-line
                  v-for="line in module"
                  :key="moduleName+'_'+line.name"
                  :line="line"
                  @update="update"
                />
              </f7-block>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
      <f7-row
        v-if="isModified"
        class="submit-row"
      >
        <f7-col
          width="5"
          medium="30"
        />
        <f7-col
          width="90"
          medium="40"
          class="margin-top margin-bottom"
        >
          <f7-button
            large
            raised
            fill
            @click="regenerateEdlConventionPdf"
          >
            Terminer
          </f7-button>
        </f7-col>
        <f7-col
          width="5"
          medium="30"
        />
      </f7-row>
      <f7-row class="total">
        <f7-col width="60" large="85"></f7-col>
        <f7-col class="total-content" width="40" large="15">
          <div class="total-content-header">TOTAL</div>
          <div>{{ formatCurrency(totalWithoutTaxes) }}€&nbsp;&nbsp;&nbsp;HT</div>
          <div>{{ formatCurrency(totalTaxes) }}€&nbsp;TVA</div>
          <div>{{ formatCurrency(totalWithTaxes) }}€&nbsp;TTC</div>
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
import NavigationTop from "@/components/layout/NavigationTop";
import NavigationBottom from "@/components/layout/NavigationBottom";
import {mapActions, mapGetters, mapState} from "vuex";
import {unobserve} from "@/store/helper";
import costing from "@/components/mixins/costing";
import EdlCostingLine from "@/components/pages/Costing/EdlCostingLine.vue";
import {extractCostingPath} from "@/composables/costing";
import _ from "lodash";
import firewall from "@/components/mixins/firewall";
import {f7, f7ready} from "framework7-vue";
import api from "@/api";

export default {
  name: "EdlCosting",
  components: {EdlCostingLine, NavigationTop, NavigationBottom},
  mixins: [costing, firewall],
  props: {
    edlId: {
      type: String,
      default: null
    },
    f7router: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      inputCosting: null,
      isSaved: false,
      isModified: false
    };
  },
  computed: {
    ...mapState('costing', [
      'isLoading',
      'isSaving'
    ]),
    ...mapGetters('costing', [
      'getCostingByEdlId'
    ]),
    title: function () {
      return "Chiffrage de l'EDL #"+this.edlId;
    },
    totalWithoutTaxes: function () {
      return _.reduce(this.inputCosting, (total, module) => {
        return total + _.reduce(module, (lineTotal, line) => {
          return line.enabled ? lineTotal + line.cost : lineTotal;
        }, 0.0);
      }, 0.0);
    },
    totalTaxes: function () {
      return _.reduce(this.inputCosting, (total, module) => {
        return total + _.reduce(module, (lineTotal, line) => {
          return line.enabled ? lineTotal + (line.cost * line.taxRate/10000) : lineTotal;
        }, 0.0);
      }, 0.0);
    },
    totalWithTaxes: function () {
      return _.reduce(this.inputCosting, (total, module) => {
        return total + _.reduce(module, (lineTotal, line) => {
          return line.enabled ? lineTotal + (line.cost * (1 + line.taxRate/10000)) : lineTotal;
        }, 0.0);
      }, 0.0);
    }
  },
  watch: {
    isSaving: function (isSaving, wasSaving) {
      if (wasSaving && !isSaving) {
        this.isSaved = true;
        this.isModified = true;
        setTimeout(() => {
          this.isSaved = false;
        }, 2000);
      }
    }
  },
  mounted: function () {
    f7ready(() => {
      this.fetchCosting({edlId: this.edlId, publicToken: this.publicToken}).then(() => {
        this.inputCosting = unobserve(this.getCostingByEdlId(this.edlId));
      });
      this.fetchUserCalibration({userId: this.me.id});
      this.fetchCalibrationConfig({});
    });
  },
  methods: {
    ...mapActions('costing', [
      'fetchUserCalibration',
      'fetchCalibrationConfig',
      'fetchCosting',
      'updateCosting'
    ]),
    update: function ({costingId, attribute, value}) {
      //Update locally
      let {costingModule, costingIndex} = extractCostingPath(this.inputCosting, costingId);
      _.set(this.inputCosting, [costingModule, costingIndex, attribute], value);

      //Update in store & api
      this.updateCosting({
        edlId: this.edlId,
        costingId,
        attribute,
        value
      });
    },
    regenerateEdlConventionPdf: function () {
      f7.preloader.show();
      this.pagePreloaderRunning = true;
      api.generateConventionPdf(this.edlId)
        .then(() => {
          f7.preloader.hide();
        })
        .catch((error) => {
          f7.preloader.hide();
          this.throwError(
            "Une erreur est survenue lors de la demande de génération du PDF. Veuillez nous contacter si cela se reproduit.",
            "Erreur génération",
            _.has(error, 'response.data.message') ? error.response.data.message : error.message,
            error
          );
        })
        .finally(() => {
          this.f7router.navigate({name: 'edlsRemote'});
        });
    },
  }
}
</script>

<style lang="scss" scoped>
  .status {
    margin-right: 5px;
  }
  .total {
    position: fixed;
    bottom: 40px;
    width: 100%;
    height: 100px;
    background-color: var(--f7-theme-color-grey);
    box-shadow: 0px -1px 4px var(--f7-theme-color-dark-grey);
    z-index: 100;
    opacity: 0.9;
    div.total-content {
      margin-top: 10px;
      padding-right: 20px;
      position: relative;
      text-align: right;
      .total-content-header {
        font-weight: bold;
      }
    }
  }
  .submit-row {
    position: fixed;
    z-index: 200;
    bottom: 55px;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
  }
  .header-module {
    background: var(--f7-theme-color-grey);
    font-size: 34px;
    margin-bottom: 0px;
  }
  .header-entry {
    font-size: 24px;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
    background: var(--f7-theme-color-dark-grey);
    color: white;
  }
  .header-sub-entry {
    color: black;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .diff {
    font-size: 18px;
    margin-top: 10px;
    margin-left: 16px;
  }
  .diff-header {
    padding-left: 16px;
  }
  .header-io {
    margin: 0;
    background: var(--f7-theme-color-very-dark-grey);
    color: white;
    .header-io-col {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  .arrow-separator {
    i {
      font-size: 12px;
    }
    text-align: center;
  }
  .rate {
    font-weight: bold;
    display: block;
  }
  .nl2br {
    white-space: pre-line;
  }
  .input-cost, .input-tax-select {
    .input-cost-suffix {
      margin-right: 5px;
      margin-left: 5px;
      position: relative;
      width: 5%;
    }
  }

  :deep(.input-cost) {
    input.input-with-value {
      text-align: right;
    }
  }

  :deep(.input-tax-select) {
    .input-dropdown {
      select.input-with-value {
        text-align: right;
        transform: translateX(35px);
      }
      &::before {
        transform: translateX(40px);
      }
    }
  }
</style>
