import _ from "lodash";

export const quantitatives = {
  'fr': [
    {
      masculine: 'aucun',
      feminine: 'aucune'
    },
    {
      masculine: 'léger',
      feminine: 'légère',
      pluralMasculine: 'légers',
      pluralFeminine: 'légères',
      forcePlural: true
    },
    {
      masculine: 'quelques',
      forcePlural: true
    },
    {
      masculine: 'nombreux',
      feminine: 'nombreuses',
      forcePlural: true
    },
    {
      masculine: 'peu de',
      forcePlural: true
    },
    {
      masculine: 'beaucoup de',
      forcePlural: true
    },
    {
      masculine: 'partiellement'
    },
    {
      masculine: 'forts',
      feminine: 'fortes',
      forcePlural: true
    }
  ],
  'en': [
    {
      masculine: 'no',
    },
    {
      masculine: 'light',
    },
    {
      masculine: 'some',
      forcePlural: true
    },
    {
      masculine: 'many',
      forcePlural: true
    },
    {
      masculine: 'a few',
      forcePlural: true
    },
    {
      masculine: 'a lot of',
      forcePlural: true
    },
    {
      masculine: 'partially'
    },
    {
      masculine: 'strong',
    }
  ]
};

export const qualitatives = {
  'fr': [
    {
      masculine: 'fin',
      feminine: 'fine',
      pluralMasculine: 'fins',
      pluralFeminine: 'fines',
    },
    {
      masculine: 'profond',
      feminine: 'profonde',
      pluralMasculine: 'profonds',
      pluralFeminine: 'profondes',
    },
    {
      masculine: 'un peu',
    },
    {
      masculine: 'beaucoup',
    },
    {
      masculine: 'petit',
      feminine: 'petite',
      pluralMasculine: 'petits',
      pluralFeminine: 'petites',
    },
    {
      masculine: 'moyen',
      feminine: 'moyenne',
      pluralMasculine: 'moyens',
      pluralFeminine: 'moyennes',
    },
    {
      masculine: 'grand',
      feminine: 'grande',
      pluralMasculine: 'grands',
      pluralFeminine: 'grandes',
    },
    {
      masculine: 'rebouché',
      feminine: 'rebouchée',
      pluralMasculine: 'rebouchés',
      pluralFeminine: 'rebouchées'
    },
    {
      masculine: 'grossier',
      feminine: 'grossière',
      pluralMasculine: 'grossiers',
      pluralFeminine: 'grossières'
    },
    {
      masculine: 'ABSENT',
      feminine: 'ABSENTE',
      pluralMasculine: 'ABSENTS',
      pluralFeminine: 'ABSENTES'
    },
    {
      masculine: 'mal fixé',
      feminine: 'mal fixée',
      pluralMasculine: 'mal fixés',
      pluralFeminine: 'mal fixées'
    },
    {
      masculine: 'mal rebouché',
      feminine: 'mal rebouchée',
      pluralMasculine: 'mal rebouchés',
      pluralFeminine: 'mal rebouchées'
    },
    {
      masculine: 'bancal',
      feminine: 'bancale',
      pluralMasculine: 'bancals',
      pluralFeminine: 'bancales'
    }
  ],
  'en':[
    {
      masculine: 'thin',
    },
    {
      masculine: 'deep',
    },
    {
      masculine: 'a few',
    },
    {
      masculine: 'many',
    },
    {
      masculine: 'small',
    },
    {
      masculine: 'medium',
    },
    {
      masculine: 'big',
    },
    {
      masculine: 'filled',
    },
    {
      masculine: 'poorly done',
    },
    {
      masculine: 'broken',
    },
    {
      masculine: 'MISSING',
    },
    {
      masculine: 'difficult',
    },
    {
      masculine: 'warped',
    },
    {
      masculine: 'burnt',
    },
    {
      masculine: 'loosely attached',
    },
    {
      masculine: 'insecurely mounted',
    },
    {
      masculine: 'poorly patched',
    },
    {
      masculine: 'lopsided',
    }
  ]
};

export const localisations = {
  'fr':[
    'en haut',
    'en bas',
    'à gauche',
    'à droite',
    'au milieu',
    'sur arêtes',
    'sur la tranche',
    'par endroits',
    'sur contours'
  ],
  'en': [
    'above',
    'below',
    'on the left',
    'on the right',
    'in the middle',
    'on the edges',
    'on the slice',
    'in places',
    'on the outline'
  ]
};

const defaultObservations = {
  name: 'default',
  values: {
    'fr':[
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: 'trace sombre',
        plural: 'traces sombres',
        feminine: true,
      },
      {
        singular: 'trace de peinture',
        plural: 'traces de peinture',
        feminine: true,
      },
      {
        singular: 'trace de rebouchage',
        plural: 'traces de rebouchages',
        feminine: true,
      },
      {
        singular: "trace d'enduit",
        plural: "traces d'enduit",
        feminine: true,
      },
      {
        singular: "tache d'humidité",
        plural: "taches d'humidité",
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'noircissure',
        plural: 'noircissures',
        feminine: true
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'trou',
        plural: 'trous',
        feminine: false
      },
      {
        singular: 'griffure',
        plural: 'griffures',
        feminine: true
      },
      {
        singular: 'gonflement',
        plural: 'gonflements',
        feminine: false
      },
      {
        singular: 'se décolle',
        plural: 'se décollent',
        feminine: false
      },
      {
        singular: 'disjoint',
        plural: 'disjointe',
        feminine: true
      },
      {
        singular: 'se décroche',
        plural: 'se décrochent',
        feminine: false
      },
      {
        singular: 'oxydation',
        plural: 'oxydation',
        feminine: true
      },
      {
        singular: 'brûlure',
        plural: 'brûlures',
        feminine: true
      },
    ],
    'en': [
      {
        singular: 'trace',
        plural: 'traces',
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'dark trace',
        plural: 'dark traces',
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'filling trace',
        plural: 'filling traces',
      },
      {
        singular: "plaster trace",
        plural: "plaster traces"
      },
      {
        singular: "moisture stain",
        plural: "moisture stains"
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: 'blackening',
        plural: 'blackenings'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'hole',
        plural: 'holes'
      },
      {
        singular: 'cut',
        plural: 'cuts'
      },
      {
        singular: 'swelling',
        plural: 'swellings'
      },
      {
        singular: 'is peeling off',
        plural: 'are peeling off'
      },
      {
        singular: 'disjoint',
        plural: 'disjoints'
      },
      {
        singular: 'is coming undone',
        plural: 'are coming undone'
      },
      {
        singular: 'oxydation',
        plural: 'oxydation'
      },
      {
        singular: 'burn',
        plural: 'burns'
      },
    ]
  }
};

const wallCeiling = {
  name: 'wallCeiling',
  legacy: ['Plafond', 'Plafonds', 'Mur', 'Murs', 'Accès', 'Gauche', 'Face', 'Droite'],
  values: {
    'fr':[
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: "tache d'humidité",
        plural: "taches d'humidité",
        feminine: true
      },
      {
        singular: 'trace sombre',
        plural: 'traces sombres',
        feminine: true,
      },
      {
        singular: 'trace de rebouchage',
        plural: 'traces de rebouchages',
        feminine: true,
      },
      {
        singular: "trace d'adhésif",
        plural: "traces d'adhésifs",
        feminine: true,
      },
      {
        singular: "trace d'enduit",
        plural: "traces d'enduit",
        feminine: true,
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'écaille',
        plural: 'écailles',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'trou de cheville',
        plural: 'trous de chevilles',
        feminine: false
      },
      {
        singular: "défaut d'aspect",
        plural: "défauts d'aspect",
        feminine: false,
      },
      {
        singular: 'peinture fine',
        plural: 'peintures fines',
        feminine: true,
      },
      {
        singular: 'application peinture grossière',
        plural: 'applications peinture grossières',
        feminine: true
      },
      {
        singular: 'se décolle',
        plural: 'se décollent',
        feminine: false
      },
      {
        singular: 'gondole',
        plural: 'gondole',
        feminine: false
      },
      {
        singular: 'griffure',
        plural: 'griffures',
        feminine: true
      },
      {
        singular: 'fissure structurelle',
        plural: 'fissures structurelles',
        feminine: true
      },
      {
        singular: 'moisissure',
        plural: 'moisissures',
        feminine: true
      },
      {
        singular: 'jauni',
        plural: 'jaunis',
        feminine: false
      },
      {
        singular: 'trou',
        plural: 'trous',
        feminine: false
      },
      {
        singular: 'brûlure',
        plural: 'brûlures',
        feminine: true
      },
      {
        singular: 'trace de lessivage',
        plural: 'traces de lessivage',
        feminine: true
      },
      {
        singular: 'élément endommagé',
        plural: 'éléments endommagés',
        feminine: false
      },
      {
        singular: 'élément modifié',
        plural: 'éléments modifiés',
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: "moisture stain",
        plural: "moisture stains"
      },
      {
        singular: 'dark trace',
        plural: 'dark traces'
      },
      {
        singular: 'filling trace',
        plural: 'filling traces'
      },
      {
        singular: "adhesive trace",
        plural: "adhesive traces"
      },
      {
        singular: "plaster trace",
        plural: "plaster traces"
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'scale',
        plural: 'scales'
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'plug hole',
        plural: 'plug holes'
      },
      {
        singular: 'aspect defect',
        plural: 'aspect defects',
      },
      {
        singular: 'thin paint',
        plural: 'thin paint'
      },
      {
        singular: 'approximately applied paint',
        plural: 'approximately applied paint'
      },
      {
        singular: 'is peeling off',
        plural: 'are peeling off'
      },
      {
        singular: 'warp',
        plural: 'warps'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'structural crack',
        plural: 'structural cracks'
      },
      {
        singular: 'mold',
      },
      {
        singular: 'yellowing',
        plural: 'yellowings',
      },
      {
        singular: 'hole',
        plural: 'holes',
      },
      {
        singular: 'burn',
        plural: 'burns',
      },
      {
        singular: 'wash stain',
        plural: 'wash stains',
      },
      {
        singular: 'damaged element',
        plural: 'damaged elements',
      },
      {
        singular: 'modified element',
        plural: 'modified elements',
      }
    ]
  }
};

const floor = {
  name: 'floor',
  legacy: ['Sol', 'Sols', 'Rambarde', 'Rambardes', 'Escalier', 'Escaliers', 'Garde-corps'],
  values: {
    'fr':[
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: true
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'frottement',
        plural: 'frottements',
        feminine: false
      },
      {
        singular: 'gonflement',
        plural: 'gonflements',
        feminine: false
      },
      {
        singular: 'trace de brûlure',
        plural: 'traces de brûlures',
        feminine: true
      },
      {
        singular: 'marque de meubles',
        plural: 'marques de meubles',
        feminine: true
      },
      {
        singular: 'joint terni',
        plural: 'joints ternis',
        feminine: false
      },
      {
        singular: 'griffure',
        plural: 'griffures',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'défaut de pose',
        plural: 'défauts de pose',
        feminine: false
      },
      {
        singular: 'piqué',
        plural: 'piqués',
        feminine: false
      },
      {
        singular: "d'origine",
        plural: "d'origines",
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'friction mark',
        plural: 'friction marks'
      },
      {
        singular: 'swelling',
        plural: 'swellings'
      },
      {
        singular: 'burn mark',
        plural: 'burn marks'
      },
      {
        singular: 'furniture mark',
        plural: 'furniture marks'
      },
      {
        singular: 'tarnished seal',
        plural: 'tarnished seals',
      },
      {
        singular: 'scratch',
        plural: 'scratches',
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes',
      },
      {
        singular: 'installation defect',
        plural: 'installation defects',
      },
      {
        singular: 'stung',
        plural: 'stung',
      },
      {
        singular: "initially present",
        plural: "initially present"
      }
    ]
  }
};

const door = {
  name: 'door',
  legacy: ['Porte', 'Portes', 'Intérieur', 'Extérieur'],
  values: {
    'fr':[
      {
        singular: 'trace sombre',
        plural: 'traces sombres',
        feminine: true,
      },
      {
        singular: 'trace de rebouchage',
        plural: 'traces de rebouchages',
        feminine: true,
      },
      {
        singular: "trace d'adhésif",
        plural: "traces d'adhésifs",
        feminine: true,
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: "tache d'humidité",
        plural: "taches d'humidité",
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'écaille',
        plural: 'écailles',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'trou de cheville',
        plural: 'trous de chevilles',
        feminine: false
      },
      {
        singular: 'trou de vis',
        plural: 'trous de vis',
        feminine: false
      },
      {
        singular: 'défaut peinture',
        plural: 'défauts peinture',
        feminine: false,
      },
      {
        singular: 'application peinture grossière',
        plural: 'applications peinture grossières',
        feminine: true
      },
      {
        singular: 'marque ancien verrou',
        plural: 'marques anciens verrous',
        feminine: true
      },
      {
        singular: 'gonflement',
        plural: 'gonflements',
        feminine: false
      },
      {
        singular: 'ne rentre pas dans cadre',
        plural: 'ne rentrent pas dans cadre',
        feminine: true
      },
      {
        singular: 'griffure',
        plural: 'griffures',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: 'frotte au sol',
        plural: 'frottent au sol',
        feminine: true
      },
      {
        singular: 'moisissure',
        plural: 'moisissures',
        feminine: true
      },
      {
        singular: 'jauni',
        plural: 'jaunis',
        feminine: false
      },
      {
        singular: 'trou',
        plural: 'trous',
        feminine: false
      },
      {
        singular: 'brûlure',
        plural: 'brûlures',
        feminine: true
      },
      {
        singular: 'trace de lessivage',
        plural: 'traces de lessivage',
        feminine: true
      },
      {
        singular: 'élément endommagé',
        plural: 'éléments endommagés',
        feminine: false
      },
      {
        singular: 'élément modifié',
        plural: 'éléments modifiés',
        feminine: false
      },
      {
        singular: 'charnière défectueuse',
        plural: 'charnières défectueuses',
        feminine: true
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
    ],
    'en': [
      {
        singular: 'dark trace',
        plural: 'dark traces'
      },
      {
        singular: 'filling trace',
        plural: 'filling traces'
      },
      {
        singular: "adhesive trace",
        plural: "adhesive traces"
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: "moisture stain",
        plural: "moisture stains"
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'scale',
        plural: 'scales'
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'plug hole',
        plural: 'plug holes'
      },
      {
        singular: 'screw hole',
        plural: 'screw holes'
      },
      {
        singular: 'paint defect',
        plural: 'paint defects',
      },
      {
        singular: 'approximately applied paint',
        plural: 'approximately applied paint'
      },
      {
        singular: 'old lock mark',
        plural: 'old lock marks'
      },
      {
        singular: 'swelling',
        plural: 'swellings'
      },
      {
        singular: 'does not fit in the frame',
        plural: 'do not fit in the frame'
      },
      {
        singular: 'scratch',
        plural: 'scratches',
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'rubs on the floor',
        plural: 'rub on the floor'
      },
      {
        singular: 'mold',
      },
      {
        singular: 'yellowing',
        plural: 'yellowings',
      },
      {
        singular: 'hole',
        plural: 'holes',
      },
      {
        singular: 'burn',
        plural: 'burns',
      },
      {
        singular: 'wash stain',
        plural: 'wash stains',
      },
      {
        singular: 'damaged element',
        plural: 'damaged elements',
      },
      {
        singular: 'modified element',
        plural: 'modified elements',
      },
      {
        singular: 'faulty hinge',
        plural: 'faulty hinges',
      },
      {
        singular: 'crack',
        plural: 'cracks',
      },
    ]
  }
};

const plinth = {
  name: 'plinth',
  legacy: ['Plinthes', 'Plinthe'],
  values: {
    'fr':[
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'fendu',
        plural: 'fendus',
        feminine: false
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'gonflement',
        plural: 'gonflements',
        feminine: false
      },
      {
        singular: 'se décolle du mur',
        plural: 'se décollent du mur',
        feminine: true
      },
      {
        singular: 'écaille de peinture',
        plural: 'écailles de peinture',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: "trace d'adhésif",
        plural: "traces d'adhésifs",
        feminine: true
      },
      {
        singular: 'espace avec sol',
        plural: 'espaces avec sol',
        feminine: false
      },
      {
        singular: 'griffure',
        plural: 'griffures',
        feminine: true
      },
    ],
    'en': [
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'split',
        plural: 'splits'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'swelling',
        plural: 'swellings'
      },
      {
        singular: 'is peeling off the wall',
        plural: 'are peeling off the wall'
      },
      {
        singular: 'paint flake',
        plural: 'paint flakes',
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: "adhesive trace",
        plural: "adhesive traces"
      },
      {
        singular: 'gap with the floor',
        plural: 'gaps with the floor'
      },
      {
        singular: 'scratch',
        plural: 'scratches',
      },
    ]
  }
};

const lockHandle = {
  name: 'lockHandle',
  legacy: ['Serrure/Verrou', 'Poignée', 'Serrure', 'Verrou'],
  values: {
    'fr':[
      {
        singular: 'avec du jeu',
        plural: 'avec du jeu',
        feminine: false
      },
      {
        singular: 'bancale',
        plural: 'bancales',
        feminine: true
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: false
      },
      {
        singular: 'trace de peinture',
        plural: 'traces de peinture',
        feminine: true
      },
      {
        singular: 'avec clé',
        plural: 'avec clés',
        feminine: false
      },
      {
        singular: 'sans clé',
        plural: 'sans clés',
        feminine: false
      },
      {
        singular: 'accroche',
        plural: 'accroches',
        feminine: true
      },
      {
        singular: 'loquet',
        plural: 'loquets',
        feminine: false
      },
      {
        singular: "pêne n'accroche pas",
        plural: "pênes n'accrochent pas",
        feminine: false
      },
      {
        singular: 'pêne absent',
        plural: 'pênes absents',
        feminine: false
      },
      {
        singular: 'grippé',
        plural: 'grippés',
        feminine: false
      },
      {
        singular: 'mal installé',
        plural: 'mal installés',
        feminine: false
      },
      {
        singular: 'verrou bloqué peinture',
        plural: 'verrous bloqués peinture',
        feminine: false
      },
      {
        singular: 'poignée endommagée',
        plural: 'poignées endommagées',
        feminine: true
      },
    ],
    'en': [
      {
        singular: 'loose',
        plural: 'loose'
      },
      {
        singular: 'wobbly',
        plural: 'wobbly'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'with key',
        plural: 'with keys'
      },
      {
        singular: 'without key',
        plural: 'without keys'
      },
      {
        singular: 'grips',
        plural: 'grip'
      },
      {
        singular: 'latch',
        plural: 'latches'
      },
      {
        singular: "bolt does not catch",
        plural: "bolts do not catch",
      },
      {
        singular: 'missing bolt',
        plural: 'missing bolts',
      },
      {
        singular: 'jammed',
        plural: 'jammed',
      },
      {
        singular: 'poorly installed',
        plural: 'poorly installed',
      },
      {
        singular: 'blocked by paint',
        plural: 'blocked by paint'
      },
      {
        singular: 'damaged handle',
        plural: 'damaged handles',
      },
    ]
  }
}

const shutter = {
  name: 'shutter',
  legacy: ['Volet', 'Volets'],
  values: {
    'fr':[
      {
        singular: 'avec ficelle enrouleur',
        plural: 'avec ficelles enrouleur',
        feminine: true
      },
      {
        singular: 'avec manivelle',
        plural: 'avec manivelles',
        feminine: true
      },
      {
        singular: 'trace de rouille',
        plural: 'traces de rouille',
        feminine: true
      },
      {
        singular: 'écaille',
        plural: 'écailles',
        feminine: true
      },
      {
        singular: 'à-coup',
        plural: 'à-coups',
        feminine: false
      },
      {
        singular: 'jour à la fermeture',
        plural: 'jours à la fermeture',
        feminine: true
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'noircissure',
        plural: 'noircissures',
        feminine: true
      },
      {
        singular: 'avec interrupteur',
        plural: 'avec interrupteurs',
        feminine: false
      },
      {
        singular: 'support manivelle cassé',
        plural: 'supports manivelles cassés',
        feminine: false
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'difficile à utiliser',
        plural: 'difficiles à utiliser',
        feminine: false
      },
      {
        singular: 'déchirure',
        plural: 'déchirures',
        feminine: true
      },
      {
        singular: 'charnière endommagée',
        plural: 'charnières endommagées',
        feminine: true
      },
    ],
    'en':[
      {
        singular: 'with string attach',
        plural: 'with string attaches'
      },
      {
        singular: 'with crank',
        plural: 'with cranks'
      },
      {
        singular: 'trace of rust',
        plural: 'traces of rust'
      },
      {
        singular: 'scale',
        plural: 'scales'
      },
      {
        singular: 'jerky',
        plural: 'jerky'
      },
      {
        singular: 'lets daylight through',
        plural: 'let daylight through'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'blackening',
        plural: 'blackenings'
      },
      {
        singular: 'with a switch',
        plural: 'with switches',
      },
      {
        singular: 'broken crank support',
        plural: 'broken crank supports',
      },
      {
        singular: 'crack',
        plural: 'cracks',
      },
      {
        singular: 'difficult to use',
        plural: 'difficult to use',
      },
      {
        singular: 'cut',
        plural: 'cuts'
      },
      {
        singular: 'damaged hinge',
        plural: 'damaged hinges',
      },
    ]
  }
};

const window = {
  name: 'window',
  legacy: ['Fenêtres', 'Fenêtre', 'Fenetres', 'Fenetre', 'Vitre', 'Encadrement'],
  values: {
    'fr':[
      {
        singular: 'trace sombre',
        plural: 'traces sombres',
        feminine: true
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'trace adhésif',
        plural: 'traces adhésifs',
        feminine: true
      },
      {
        singular: 'trace de peinture',
        plural: 'traces de peinture',
        feminine: true
      },
      {
        singular: 'trou vis',
        plural: 'trous vis',
        feminine: false
      },
      {
        singular: 'trou perçage',
        plural: 'trous perçages',
        feminine: false
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'écaille',
        plural: 'écailles',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'accroche',
        plural: 'accroches',
        feminine: true
      },
      {
        singular: 'rayure vitre',
        plural: 'rayures vitre',
        feminine: true
      },
      {
        singular: 'poignée avec usure',
        plural: 'poignées avec usure',
        feminine: false
      },
      {
        singular: 'poignée avec du jeu',
        plural: 'poignées avec du jeu',
        feminine: false
      },
      {
        singular: 'joint coulant',
        plural: 'joints coulants',
        feminine: false
      },
      {
        singular: 'joint dégradé',
        plural: 'joints dégradés',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'ne ferme pas complètement',
        plural: 'ne ferment pas complètement',
        feminine: false
      },
      {
        singular: 'infiltration eau',
        plural: 'infiltrations eau',
        feminine: true
      },
      {
        singular: 'poignée cassée',
        plural: 'poignées cassées',
        feminine: true
      },
      {
        singular: 'charnière endommagée',
        plural: 'charnières endommagées',
        feminine: true
      },
      {
        singular: 'ferme mal',
        plural: 'ferment mal',
        feminine: true
      },
      {
        singular: 'vitre endommagée',
        plural: 'vitres endommagées',
        feminine: true
      },
      {
        singular: 'élément endommagé',
        plural: 'éléments endommagés',
        feminine: false
      },
    ],
    'en': [
      {
        singular: 'dark trace',
        plural: 'dark traces'
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: "adhesive trace",
        plural: "adhesive traces"
      },
      {
        singular: 'paint stain',
        plural: 'paint stains'
      },
      {
        singular: 'screw hole',
        plural: 'screw holes'
      },
      {
        singular: 'drilling hole',
        plural: 'drilling holes'
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'scale',
        plural: 'scales'
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'grips',
        plural: 'grip'
      },
      {
        singular: 'window scratch',
        plural: 'window scratches'
      },
      {
        singular: 'worn handle',
        plural: 'worn handles'
      },
      {
        singular: 'loose handle',
        plural: 'loose handles'
      },
      {
        singular: 'melted seal',
        plural: 'melted seals'
      },
      {
        singular: 'damaged seal',
        plural: 'damaged seals'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'does not fully close',
        plural: 'do not fully close'
      },
      {
        singular: 'leaky',
        plural: 'leaky'
      },
      {
        singular: 'broken handle',
        plural: 'broken handles',
      },
      {
        singular: 'damaged hinge',
        plural: 'damaged hinges',
      },
      {
        singular: 'does not fully close',
        plural: 'do not fully close',
      },
      {
        singular: 'damaged glass',
        plural: 'damaged glasses',
      },
      {
        singular: 'damaged element',
        plural: 'damaged elements',
      },
    ]
  }
};

const sink = {
  name: 'sink',
  legacy: ['Évier/Vasque/Lavabo', 'Complexe Évier/Vasque/Lavabo', 'Évier', 'Lavabo', 'Éviers', 'Lavabos', 'Faïence', 'Bac', 'Bonde'],
  values: {
    'fr':[
      {
        singular: "trace d'enduit",
        plural: "traces d'enduit",
        feminine: true
      },
      {
        singular: "trace d'adhésif",
        plural: "traces d'adhésifs",
        feminine: true
      },
      {
        singular: 'trace de peinture',
        plural: 'traces de peinture',
        feminine: true
      },
      {
        singular: 'noircissure',
        plural: 'noircissures',
        feminine: true
      },
      {
        singular: 'jauni',
        plural: 'jaunis',
        feminine: false
      },
      {
        singular: 'trou de cheville',
        plural: 'trous de chevilles',
        feminine: false
      },
      {
        singular: 'faïencé',
        plural: 'faïencés',
        feminine: false
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'joint noirci et/ou jauni',
        plural: 'joints noircis et/ou jaunis',
        feminine: false
      },
      {
        singular: 'oxydation',
        plural: 'oxydation',
        feminine: true
      },
    ],
    'en': [
      {
        singular: "plaster trace",
        plural: "plaster traces"
      },
      {
        singular: "adhesive trace",
        plural: "adhesive traces"
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'blackening',
        plural: 'blackenings'
      },
      {
        singular: 'yellowing',
        plural: 'yellowings'
      },
      {
        singular: 'plug hole',
        plural: 'plug holes'
      },
      {
        singular: 'crackled',
        plural: 'crackled'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'chipped',
        plural: 'chipped'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'blackened and/or yellowed seal',
        plural: 'blackened and/or yellowed seals'
      },
      {
        singular: 'oxydation',
        plural: 'oxydation',
      },
    ]
  }
};

const faucet = {
  name: 'faucet',
  legacy: ['Robinet', 'Robinets'],
  values: {
    'fr':[
      {
        singular: 'usure chrome',
        plural: 'usures chromes',
        feminine: true
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'mal fixé',
        plural: 'mal fixés',
        feminine: false
      },
      {
        singular: 'commande bancale',
        plural: 'commandes bancales',
        feminine: true
      },
      {
        singular: 'fuite socle',
        plural: 'fuites socle',
        feminine: true
      },
      {
        singular: 'fuite bec',
        plural: 'fuites bec',
        feminine: true
      },
      {
        singular: 'fuite pommeau',
        plural: 'fuites pommeau',
        feminine: true
      },
      {
        singular: 'fine rayure',
        plural: 'fines rayures',
        feminine: true
      },
      {
        singular: 'usure flexible',
        plural: 'usure flexibles',
        feminine: true
      },
      {
        singular: 'sortie eau obstruée',
        plural: 'sorties eau obstruées',
        feminine: true
      },
      {
        singular: "avec pommeau à l'italienne",
        plural: "avec pommeau à l'italienne",
        feminine: true
      },
      {
        singular: 'avec porte pommeau',
        plural: 'avec porte pommeau',
        feminine: true
      },
      {
        singular: 'oxydation',
        plural: 'oxydation',
        feminine: true
      },
      {
        singular: 'flexible endommagé',
        plural: 'flexible endommagé',
        feminine: false
      },
      {
        singular: 'élément endommagé',
        plural: 'éléments endommagés',
        feminine: false
      },
      {
        singular: 'élément modifié',
        plural: 'éléments modifiés',
        feminine: false
      },
      {
        singular: 'support bancal ',
        plural: 'support bancal ',
        feminine: false
      },
    ],
    'en': [
      {
        singular: 'worn chrome',
        plural: 'worn chromes'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'loosely fitted',
        plural: 'loosely fitted'
      },
      {
        singular: 'wobbly valve control',
        plural: 'wobbly valve controls'
      },
      {
        singular: 'base leak',
        plural: 'base leaks'
      },
      {
        singular: 'leaky spout',
        plural: 'leaky spouts'
      },
      {
        singular: 'leaky knob',
        plural: 'leaky knobs'
      },
      {
        singular: 'thin scratch',
        plural: 'thin scratches'
      },
      {
        singular: 'worn hose',
        plural: 'worn hoses'
      },
      {
        singular: 'blocked water outlet',
        plural: 'blocked water outlets'
      },
      {
        singular: "with an Italian-style knob",
        plural: "with Italian-style knobs"
      },
      {
        singular: 'with knob holder',
        plural: 'with knob holders'
      },
      {
        singular: 'oxydation',
        plural: 'oxydation'
      },
      {
        singular: 'damaged shower hose',
        plural: 'damaged shower hose'
      },
      {
        singular: 'damaged element',
        plural: 'damaged elements',
      },
      {
        singular: 'modified element',
        plural: 'modified elements'
      },
      {
        singular: 'wobbly mount',
        plural: 'wobbly mounts',
      },
    ]
  }
};

const sinkCabinet = {
  name: 'sinkCabinet',
  legacy: ['Meuble sous évier', 'Meubles sous évier', 'Meuble sous éviers', 'Meubles sous éviers', 'Elément haut', 'Eléments hauts', 'Eléments haut', 'Elément hauts'],
  values: {
    'fr':[
      {
        singular: 'porte',
        plural: 'portes',
        feminine: true
      },
      {
        singular: 'trace sur porte',
        plural: 'traces sur portes',
        feminine: true
      },
      {
        singular: 'trace sur rayon',
        plural: 'traces sur rayons',
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: false
      },
      {
        singular: 'bois gonflé par endroits',
        plural: 'bois gonflés par endroits',
        feminine: false
      },
      {
        singular: 'gond bancale',
        plural: 'gonds bancales',
        feminine: false
      },
      {
        singular: 'porte mal réglée',
        plural: 'portes mal réglées',
        feminine: true
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'poignée dégradée',
        plural: 'poignées dégradées',
        feminine: true
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'door',
        plural: 'doors'
      },
      {
        singular: 'mark on door',
        plural: 'marks on door'
      },
      {
        singular: 'mark on shelves',
        plural: 'marks on shelves'
      },
      {
        singular: 'splinter',
        plural: 'splinters'
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'swollen wood in places',
        plural: 'swollen wood in places'
      },
      {
        singular: 'wobbly hinge',
        plural: 'wobbly hinges'
      },
      {
        singular: 'poorly adjusted door',
        plural: 'poorly adjusted doors',
      },
      {
        singular: 'impact',
        plural: 'impacts',
      },
      {
        singular: 'degraded handle',
        plural: 'degraded handles'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'dent',
        plural: 'dents'
      }
    ]
  }
};

const storage = {
  name: 'storage',
  legacy: ['Rangements/Placards', 'Rangement/Placard', 'Rangements', 'Placards', 'Rangement', 'Placard'],
  values: {
    'fr':[
      {
        singular: 'porte',
        plural: 'portes',
        feminine: true
      },
      {
        singular: 'étagère',
        plural: 'étagères',
        feminine: true
      },
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: false
      },
      {
        singular: 'trou de cheville',
        plural: 'trous de chevilles',
        feminine: false
      },
      {
        singular: 'étagère avec tache',
        plural: 'étagères avec taches',
        feminine: true
      },
      {
        singular: 'ferme mal',
        plural: 'fermes mal',
        feminine: true
      },
      {
        singular: 'porte sortie du rail',
        plural: 'portes sorties des rails',
        feminine: true
      },
      {
        singular: 'roulette cassée',
        plural: 'roulettes cassées',
        feminine: true
      },
      {
        singular: 'encombrement',
        plural: 'encombrements',
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'door',
        plural: 'doors'
      },
      {
        singular: 'shelf',
        plural: 'shelves'
      },
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'plug hole',
        plural: 'plug holes'
      },
      {
        singular: 'stained shelf',
        plural: 'stained shelves'
      },
      {
        singular: 'closes badly',
        plural: 'close badly'
      },
      {
        singular: 'door out of rail',
        plural: 'doors out of rails'
      },
      {
        singular: 'broken wheel',
        plural: 'broken wheels'
      },
      {
        singular: 'clutter',
        plural: 'clutter'
      }
    ]
  }
};

const radiator = {
  name: 'radiator',
  legacy: ['Radiateurs', 'Radiateur'],
  values: {
    'fr':[
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'éclat peinture',
        plural: 'éclats peinture',
        feminine: false
      },
      {
        singular: 'grille noircie',
        plural: 'grilles noircies',
        feminine: true
      },
      {
        singular: 'grille tordue',
        plural: 'grilles tordues',
        feminine: true
      },
      {
        singular: 'vanne absente',
        plural: 'vannes absentes',
        feminine: true
      },
      {
        singular: 'vanne bloquée',
        plural: 'vannes bloquées',
        feminine: true
      },
      {
        singular: 'fuite robinet',
        plural: 'fuites robinets',
        feminine: true
      },
      {
        singular: 'écaille',
        plural: 'écailles',
        feminine: true
      },
      {
        singular: 'rouille',
        plural: 'rouilles',
        feminine: true
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'mal fixé',
        plural: 'mal fixés',
        feminine: false
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
    ],
    'en': [
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'paint chip',
        plural: 'paint chips'
      },
      {
        singular: 'blackened grill',
        plural: 'blackened grills'
      },
      {
        singular: 'bent grill',
        plural: 'bent grills'
      },
      {
        singular: 'absent valve',
        plural: 'absent valves'
      },
      {
        singular: 'blocked valve',
        plural: 'blocked valves'
      },
      {
        singular: 'leaky tap',
        plural: 'leaky taps'
      },
      {
        singular: 'scale',
        plural: 'scales'
      },
      {
        singular: 'rust',
        plural: 'rust'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'poorly attached',
        plural: 'poorly attached'
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
    ]
  }
};

const siphon = {
  name: 'siphon',
  legacy: ['Siphon', 'Siphons'],
  values: {
    'fr':[
      {
        singular: 'fuite',
        plural: 'fuites',
        feminine: true
      },
      {
        singular: 'bouchon absent',
        plural: 'bouchons absents',
        feminine: false
      },
      {
        singular: 'moisissure',
        plural: 'moisissures',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: false
      },
      {
        singular: 'rouille',
        plural: 'rouilles',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'leak',
        plural: 'leaks'
      },
      {
        singular: 'absent plug',
        plural: 'absent plugs'
      },
      {
        singular: 'mold',
        plural: 'mold'
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'rust',
        plural: 'rust'
      }
    ]
  }
};

const seal = {
  name: 'seal',
  legacy: ['Joint étanchéité', "Joint d'étanchéité", 'Joints étanchéité', "Joints d'étanchéité"],
  values: {
    'fr':[
      {
        singular: 'décollement',
        plural: 'décollements',
        feminine: false
      },
      {
        singular: 'trace noire',
        plural: 'traces noires',
        feminine: true
      },
      {
        singular: 'jauni',
        plural: 'jaunis',
        feminine: false
      },
      {
        singular: 'absent par endroits',
        plural: 'absents par endroits',
        feminine: false
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'application grossière',
        plural: 'applications grossières',
        feminine: true
      },
      {
        singular: 'moisissure',
        plural: 'moisissures',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'coming off',
        plural: 'coming off'
      },
      {
        singular: 'black mark',
        plural: 'black marks'
      },
      {
        singular: 'yellowed',
        plural: 'yellowed'
      },
      {
        singular: 'missing in some places',
        plural: 'missing in some places'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'poorly applied',
        plural: 'poorly applied'
      },
      {
        singular: 'mold',
        plural: 'mold'
      }
    ]
  }
};

const splashback = {
  name: 'splashback',
  legacy: ['Credence', 'Crédence', 'Credences', 'Crédences'],
  values: {
    'fr':[
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: true
      },
      {
        singular: 'projection de gras',
        plural: 'projections de gras',
        feminine: true
      },
      {
        singular: 'se décolle',
        plural: 'se décolle par endroits',
        feminine: false
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: "trace d'enduit",
        plural: "traces d'enduit",
        feminine: true
      },
      {
        singular: "trace d'adhésif",
        plural: "traces d'adhésifs",
        feminine: true
      },
      {
        singular: 'trace de peinture',
        plural: 'traces de peinture',
        feminine: true
      },
      {
        singular: 'noircissure',
        plural: 'noircissures',
        feminine: true
      },
      {
        singular: 'jauni',
        plural: 'jaunis',
        feminine: false
      },
      {
        singular: 'trou de cheville',
        plural: 'trous de chevilles',
        feminine: false
      },
      {
        singular: 'faïencé',
        plural: 'faïencés',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'joint noirci et/ou jauni',
        plural: 'joints noircis et/ou jaunis',
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'grease splash',
        plural: 'grease splashes'
      },
      {
        singular: 'is coming off',
        plural: 'are coming off'
      },
      {
        singular: 'chip',
        plural: 'chips'
      },
      {
        singular: "plaster trace",
        plural: "plaster traces"
      },
      {
        singular: "adhesive trace",
        plural: "adhesive traces"
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'blackening',
        plural: 'blackenings'
      },
      {
        singular: 'yellowing',
        plural: 'yellowings'
      },
      {
        singular: 'plug hole',
        plural: 'plug holes'
      },
      {
        singular: 'crackled',
        plural: 'crackled'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'blackened and/or yellowed seal',
        plural: 'blackened and/or yellowed seals'
      }
    ]
  }
}

const cookingPlate = {
  name: 'cookingPlate',
  legacy: ['Plaques de cuisson', 'Plaque de cuisson'],
  values: {
    'fr':[
      {
        singular: 'trace de frottement',
        plural: 'traces de frottements',
        feminine: true
      },
      {
        singular: 'trace cuisson',
        plural: 'traces cuisson',
        feminine: true
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: true
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: true
      },
      {
        singular: 'effacement commandes',
        plural: 'effacement commandes',
        feminine: false
      },
      {
        singular: 'moisissure',
        plural: 'moisissures',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'rubbing mark',
        plural: 'rubbing marks'
      },
      {
        singular: 'cooking mark',
        plural: 'cooking marks'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'thin scratch',
        plural: 'thin scratches'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'control wearing off',
        plural: 'controls wearing off'
      },
      {
        singular: 'mold',
        plural: 'mold'
      }
    ]
  }
};

const hood = {
  name: 'hood',
  legacy: ['Hotte'],
  values: {
    'fr':[
      {
        singular: 'filtre sale',
        plural: 'filtres sales',
        feminine: false
      },
      {
        singular: 'trace de gras',
        plural: 'traces de gras',
        feminine: true
      },
      {
        singular: 'grille jaunie',
        plural: 'grilles jaunies',
        feminine: true
      },
      {
        singular: 'grille tordue',
        plural: 'grilles tordues',
        feminine: true
      },
      {
        singular: 'lumière HS',
        plural: 'lumières HS',
        feminine: true
      },
      {
        singular: 'rayure',
        plural: 'rayures',
        feminine: true
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'dirty filter',
        plural: 'dirty filters'
      },
      {
        singular: 'grease stain',
        plural: 'grease stains'
      },
      {
        singular: 'yellowed grill',
        plural: 'yellowed grills'
      },
      {
        singular: 'bent grill',
        plural: 'bent grills'
      },
      {
        singular: 'light out of service',
        plural: 'lights out of service'
      },
      {
        singular: 'scratch',
        plural: 'scratches'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      }
    ]
  }
};

const oven = {
  name: 'oven',
  legacy: ['Four/Micro-onde', 'Four', 'Micro-onde'],
  values: {
    'fr':[
      {
        singular: 'trace de cuisson interne',
        plural: 'traces de cuisson internes',
        feminine: true
      },
      {
        singular: 'effacement commandes',
        plural: 'effacements commandes',
        feminine: true
      },
      {
        singular: 'rayure sur vitre',
        plural: 'rayures sur vitre',
        feminine: true
      },
      {
        singular: 'dégradation du joint',
        plural: 'dégradations du joint',
        feminine: true
      },
      {
        singular: 'poignée bancale',
        plural: 'poignée bancale',
        feminine: true
      },
      {
        singular: 'poignée usée',
        plural: 'poignée usée',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'internal cooking mark',
        plural: 'internal cooking marks'
      },
      {
        singular: 'control wearing off',
        plural: 'controls wearing off'
      },
      {
        singular: 'scratch on window',
        plural: 'scratches on window'
      },
      {
        singular: 'deteriorated seal',
        plural: 'deteriorated seals'
      },
      {
        singular: 'wobbly knob',
        plural: 'wobbly knobs'
      },
      {
        singular: 'worn handle',
        plural: 'worn handles'
      }
    ]
  }
};

const bathtub = {
  name: 'bathtub',
  legacy: ['Baignoire', 'Douche', 'Vasque'],
  values: {
    'fr':[
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'rayure dans bac',
        plural: 'rayures dans bac',
        feminine: true
      },
      {
        singular: 'bac terni',
        plural: 'bac terni',
        feminine: false
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'faïencé',
        plural: 'faïencés',
        feminine: false
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'porosité',
        plural: 'porosités',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'scratch in the tub',
        plural: 'scratches in the tub'
      },
      {
        singular: 'tarnished tub',
        plural: 'tarnished tubs'
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: 'chip',
        plural: 'chips'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'crackled',
        plural: 'crackled'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains'
      },
      {
        singular: 'porosity',
        plural: 'porosities'
      }
    ]
  }
};

const bathPannel = {
  name: 'bathPannel',
  legacy: ['Tablier de baignoire'],
  values: {
    'fr':[
      {
        singular: 'accroc',
        plural: 'accrocs',
        feminine: true
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'écaille',
        plural: 'écailles',
        feminine: true
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'carreau fendu',
        plural: 'carreaux fendus',
        feminine: false
      },
      {
        singular: 'moisissure',
        plural: 'moisissures',
        feminine: true
      },
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: 'gonflement',
        plural: 'gonflements',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'dent',
        plural: 'dents'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'scale',
        plural: 'scales'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'split tile',
        plural: 'split tiles'
      },
      {
        singular: 'mold',
        plural: 'mold'
      },
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'swelling',
        plural: 'swellings'
      }
    ]
  }
};

const vent = {
  name: 'vent',
  legacy: ['Ventilation', 'VMC'],
  values: {
    'fr':[
      {
        singular: 'ficelle bloqué',
        plural: 'ficelles bloquées',
        feminine: true
      },
      {
        singular: 'trace',
        plural: 'traces',
        feminine: true
      },
      {
        singular: 'ternissure',
        plural: 'ternissures',
        feminine: true
      },
      {
        singular: 'tache',
        plural: 'taches',
        feminine: true
      },
      {
        singular: 'tache peinture',
        plural: 'taches peinture',
        feminine: true
      },
      {
        singular: 'mal fixée',
        plural: 'mal fixées',
        feminine: false
      },
      {
        singular: 'grille cassée',
        plural: 'grilles cassées',
        feminine: true
      },
      {
        singular: 'grille absente',
        plural: 'grilles absentes',
        feminine: true
      }
    ],
    'en': [
      {
        singular: 'blocked cord',
        plural: 'blocked cords'
      },
      {
        singular: 'trace',
        plural: 'traces'
      },
      {
        singular: 'tarnish',
        plural: 'tarnishes'
      },
      {
        singular: 'stain',
        plural: 'stains'
      },
      {
        singular: 'paint stain',
        plural: 'paint stains',
      },
      {
        singular: 'poorly attached',
        plural: 'poorly attached'
      },
      {
        singular: 'broken grill',
        plural: 'broken grills'
      },
      {
        singular: 'missing grill',
        plural: 'missing grills'
      }
    ]
  }
};

const tank = {
  name: 'tank',
  legacy: ['Cuve/Réservoir', 'Cuve', 'Réservoir'],
  values: {
    'fr':[
      {
        singular: 'éclat',
        plural: 'éclats',
        feminine: false
      },
      {
        singular: 'fissure',
        plural: 'fissures',
        feminine: true
      },
      {
        singular: 'faïencé',
        plural: 'faïencés',
        feminine: true
      },
      {
        singular: 'impact',
        plural: 'impacts',
        feminine: false
      },
      {
        singular: 'trace coulure',
        plural: 'traces coulures',
        feminine: true
      },
      {
        singular: 'fuite',
        plural: 'fuites',
        feminine: true
      },
      {
        singular: 'mal fixé',
        plural: 'mal fixés',
        feminine: false
      },
      {
        singular: 'émail dégradé',
        plural: 'émaux dégradés',
        feminine: true
      },
    ],
    'en': [
      {
        singular: 'chip',
        plural: 'chips'
      },
      {
        singular: 'crack',
        plural: 'cracks'
      },
      {
        singular: 'crackled',
        plural: 'crackled'
      },
      {
        singular: 'impact',
        plural: 'impacts'
      },
      {
        singular: 'dripping trace',
        plural: 'dripping traces'
      },
      {
        singular: 'leak',
        plural: 'leaks'
      },
      {
        singular: 'loosely attached',
        plural: 'loosely attached'
      },
      {
        singular: 'degraded enamel',
        plural: 'degraded enamels'
      },
    ]
  }
};

const flush = {
  name: 'flush',
  legacy: ['Chasse d eau', "Chasse d'eau"],
  values: {
    'fr':[
      {
        singular: 'fuite',
        plural: 'fuites',
        feminine: true
      },
      {
        singular: 'robinet bloqué/non étanche',
        plural: 'robinet bloqué/non étanche',
        feminine: false
      },
      {
        singular: 'coule constamment',
        plural: 'coule constamment',
        feminine: false
      },
      {
        singular: 'commande bancale',
        plural: 'commande bancale',
        feminine: false
      }
    ],
    'en': [
      {
        singular: 'leak',
        plural: 'leaks'
      },
      {
        singular: 'blocked/leaky valve',
        plural: 'blocked/leaky valves'
      },
      {
        singular: 'constantly running',
        plural: 'constantly running'
      },
      {
        singular: 'wobbly control',
        plural: 'wobbly controls'
      }
    ]
  }
};

const autoFillObservations = {
  defaultObservations,
  wallCeiling,
  floor,
  door,
  plinth,
  lockHandle,
  shutter,
  window,
  sink,
  faucet,
  sinkCabinet,
  storage,
  radiator,
  siphon,
  seal,
  splashback,
  hood,
  cookingPlate,
  oven,
  bathtub,
  bathPannel,
  vent,
  tank,
  flush
};

export const all = Object.values(autoFillObservations);

export const getObservations = (name, locale, defaultsIfNotFound = false) => {
  let autoFillObservation = _.find(autoFillObservations, (autoFillObservation) =>
    autoFillObservation.name === name || autoFillObservation.legacy === name || _.includes(autoFillObservation.legacy, name)
  );
  return autoFillObservation !== undefined
    ? autoFillObservation.values[locale]?.sort(sortAlphabetically)
    : (defaultsIfNotFound ? defaultObservations.values[locale]?.sort(sortAlphabetically) : null);
};

export const sortAlphabetically = (a, b) => {
  if(_.has(a, 'singular') && _.has(b, 'singular')) {
    return a.singular.localeCompare(b.singular);
  } else if(_.has(a, 'masculine') && _.has(b, 'masculine')) {
    return a.masculine.localeCompare(b.masculine);
  } else {
    return a.localeCompare(b);
  }
}

export default {
  getObservations,
  sortAlphabetically,
  all,
  quantitatives,
  qualitatives,
  localisations
};
