<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top title="EDLs en cours">
      <template #global-actions="{toggleSubNav, showSubNav}">
        <f7-link
          class="button color-white"
          @click="toggleSubNav"
        >
          <i
            v-if="!showSubNav"
            class="f7-icons"
          >search</i>
          <i
            v-else
            class="f7-icons"
          >chevron_up</i>
        </f7-link>
      </template>
      <template #subnav>
        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title, .item-header"
          :disable-button="!theme.aurora"
        />
      </template>
    </navigation-top>

    <navigation-bottom current="current" />

    <f7-page-content>
      <f7-block-title>
        Liste de mes EDLs en cours sur cet appareil
        <div
          v-if="isAdmin"
          class="auto-delete float-right"
        >
          <span v-if="autoDelete">Suppression automatique après enregistrement</span>
          <span v-else>Suppression manuelle après enregistrement</span>
          <f7-toggle
            :checked="autoDelete"
            tooltip="Suppression automatique ou manuelle après l'enregistrement de l'EDL"
            @toggle:change="autoDelete = !autoDelete"
          />
        </div>
      </f7-block-title>
      <f7-list
        v-if="!noEdls"
        class="search-list"
      >
        <f7-list-item
          v-for="edl in localEdls"
          :key="edl.localId"
          swipeout
          :header="getHeader(edl)"
          :title="getTitle(edl)"
          :disabled="isDeleting(edl.localId)"
          @swipeout:deleted="deleteEdl(edl.localId)"
        >
          <template #media>
            <i
              v-if="isSynced(edl)"
              class="f7-icons color-green"
            >doc_checkmark</i>
            <i
              v-else-if="isReadyToSync(edl)"
              class="f7-icons color-blue"
            >doc_fill</i>
            <i
              v-else
              class="f7-icons color-grey"
            >doc_fill</i>
          </template>
          <template #footer>
            EDL {{ edl.edlIO }} du {{ formatDate(edl.date, 'PPPpp') }} / {{ edl.bien.genre }} - {{ edl.bien.typeLogement }} <span
              v-if="edl.bien.numAppart"
              class="edl-reference"
            >({{ edl.bien.numAppart }})</span>
          </template>
          <template #after>
            <div>
              <p
                v-if="isSynced(edl)"
                class="small edl-status"
              >
                Enregistré le {{ formatDate(edl.syncDate, 'PPPpp') }}
              </p>
              <p
                v-else-if="isReadyToSync(edl) && !isLoading(edl.localId)"
                class="small edl-status"
              >
                Prêt à enregistrer
              </p>
              <p
                v-else-if="isLoading(edl.localId)"
                class="small edl-status"
              >
                Enregistrement en cours...
              </p>
              <p
                v-else
                class="small edl-status"
              >
                En cours d'édition
              </p>
              <div class="segmented segmented-raised-ios edl-actions">
                <f7-link
                  v-if="isReadyToSync(edl) && !isLoading(edl.localId) && !isSynced(edl)"
                  class="button color-blue"
                  :class="{disabled: !isOnline}"
                  tooltip="Enregistrer l'EDL"
                  @click="uploadEdl(edl.localId)"
                >
                  <i class="f7-icons">arrow_up_doc_fill</i>
                </f7-link>
                <f7-button v-else-if="isReadyToSync(edl) && isLoading(edl.localId) && !isSynced(edl)">
                  <f7-preloader size="30" />
                </f7-button>
                <f7-link
                  v-else-if="!isSynced(edl)"
                  class="button color-very-dark-grey"
                  tooltip="Continuer l'EDL"
                  @click="editEdl(edl.localId)"
                >
                  <i class="f7-icons">pencil</i>
                </f7-link>
                <f7-link
                  v-else-if="isSynced(edl)"
                  class="button color-red"
                  tooltip="Supprimer l'EDL"
                  @click="deleteSyncedEdl(edl)"
                >
                  <i class="f7-icons">trash</i>
                </f7-link>
                <f7-link
                  class="button color-orange"
                  tooltip="Dupliquer l'EDL"
                  @click="duplicateEdl(edl)"
                >
                  <i class="f7-icons">doc_on_doc</i>
                </f7-link>
              </div>
            </div>
          </template>
          <f7-swipeout-actions right>
            <f7-swipeout-button
              v-if="!isSynced(edl)"
              delete
              :confirm-text="'Êtes-vous certains de vouloir supprimer cet EDL de l\'appareil&nbsp;? '+getDeleteHelp(edl)"
            >
              Supprimer
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
      <f7-block
        v-else
        inset
        strong
      >
        <p>
          Vous n'avez pas d'EDLs en cours sur cet appareil.<br><br>
          Commencez par en créer un via le menu du bas en cliquant sur "Nouvel EDL", ou dupliquez un ancien EDL en cliquant sur "EDLs enregistrés".
        </p>
      </f7-block>
      <f7-block class="load-all-block">
        <f7-button
          v-if="isMultipleReadyToSync"
          preloader
          preloader-size="24"
          :loading="isLoadingAll"
          small
          right
          icon-f7="arrow_up_doc_fill"
          icon-size="16"
          class="color-theme-blue float-right"
          @click="uploadAll"
        >
          Tout enregistrer
        </f7-button>
      </f7-block>
    </f7-page-content>

    <f7-actions :opened="duplicateEdlSelectTypeOpened">
      <f7-actions-group>
        <f7-actions-label>Dupliquer vers un EDL :</f7-actions-label>
        <f7-actions-button
          :bold="duplicateFromType === 'Sortant'"
          @click="doDuplicateEdl(duplicateFromId, 'entrant')"
        >
          Entrant
        </f7-actions-button>
        <f7-actions-button
          :bold="duplicateFromType === 'Entrant'"
          @click="doDuplicateEdl(duplicateFromId, 'sortant')"
        >
          Sortant
        </f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">
          Annuler
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import {mapActions, mapState} from "vuex";
  import NavigationTop from "../../layout/NavigationTop";
  import NavigationBottom from "../../layout/NavigationBottom";
  import _ from "lodash";
  import errors from "@/components/mixins/errors";
  import offline from "@/components/mixins/offline";
  import formatDate from "@/components/filters/format_date";
  import { f7, theme } from "framework7-vue";
  import {nextTick} from "vue";
  import hasConvention from "@/components/mixins/hasConvention";

  export default {
    name: "EdlListLocal",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall, errors, offline, hasConvention],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        loading: [],
        deleting: [],
        isLoadingAll: false,
        duplicateFromId: null,
        duplicateFromType: null,
        duplicateEdlSelectTypeOpened: false,
        autoDelete: true
      };
    },
    computed: {
      ...mapState('edlPersist', [
        'localEdls',
      ]),
      noEdls: function () {
        return this.localEdls !== null && this.localEdls.length === 0;
      },
      theme: function () {
        return theme;
      },
      isMultipleReadyToSync: function () {
        return _.reduce(this.localEdls, (readyToSync, localEdl) => {
          if(this.isReadyToSync(localEdl)) {
            readyToSync++;
          }
          return readyToSync;
        }, 0) > 1;
      }
    },
    mounted: function () {
      nextTick(() => {
        f7.preloader.show();
        this.pagePreloaderRunning = true;
        if(this.localEdls !== null) {
          f7.preloader.hide();
        }
      });
    },
    methods: {
      ...mapActions('edlPersist', [
        'deleteLocalEdl',
        'uploadLocalEdl',
      ]),
      isReadyToSync: function (edl) {
        return edl.open !== undefined && edl.open === false;
      },
      isSynced: function (edl) {
        return edl.id !== undefined;
      },
      isLoading: function (localId) {
        return this.loading.includes(localId);
      },
      isDeleting: function (localId) {
        return this.deleting.includes(localId);
      },
      editEdl: function (localId) {
        this.f7router.navigate({name: 'edl', params: {localId}});
      },
      deleteSyncedEdl: function (edl) {
        f7.dialog.confirm('Êtes-vous certains de vouloir supprimer cet EDL de l\'appareil&nbsp;? '+this.getDeleteHelp(edl), () => {
          this.deleteEdl(edl.localId);
        });
      },
      deleteEdl: function (localId) {
        this.deleting.push(localId);
        this.deleteLocalEdl(localId).catch((error) => {
          _.pull(this.deleting, localId);
          console.error(error);
        });
      },
      duplicateEdl: function (edl) {
        this.duplicateFromId = edl.localId;
        this.duplicateFromType = edl.edlIO;
        this.duplicateEdlSelectTypeOpened = true;
      },
      doDuplicateEdl: function (duplicateFromId, edlType) {
        this.f7router.navigate({
          name: 'duplicateEdl',
          params: {
            duplicateFromId,
            edlType,
            source: 'local'
          }
        });
      },
      uploadEdl: function (localId) {
        this.loading.push(localId);
        f7.dialog.confirm('Souhaitez-vous envoyer l\'EDL par email au(x) locataire(s) dès maintenant ?', 'Envoi EDL par email', () => {
          f7.dialog.preloader('Enregistrement de l\'EDL avec envoi d\'emails au(x) locataire(s)...');
          this.doUpload(localId, true);
        }, () => {
          f7.dialog.preloader('Enregistrement de l\'EDL...');
          this.doUpload(localId, false);
        });
      },
      doUpload: function (localId, sendEmailToRenters, loadingAll = false) {
        this.uploadLocalEdl({
          localId,
          profileId: this.selectedProfile.id,
          sendEmailToRenters
        }).then((result) => {
          this.handlePostUploadSuccess(result, loadingAll);
        }).catch((error) => {
          this.handlePostUploadError(error);
        });
      },
      handlePostUploadSuccess: function ({localId, syncedId}, loadingAll) {
        if(!loadingAll || (loadingAll && this.loading.length === 1)) {
          f7.dialog.close();
          f7.dialog.preloader('Enregistré !');
        }
        setTimeout(() => {
          if(!loadingAll || (loadingAll && this.loading.length === 1)) {
            f7.dialog.close();
            if(this.isLoadingAll === true) {
              this.isLoadingAll = false;
            }
          }

          _.pull(this.loading, localId);
          console.log(localId, this.autoDelete, syncedId, _.isFinite(syncedId), this.autoDelete && syncedId !== null && _.isFinite(syncedId))
          if(this.autoDelete && syncedId !== null && _.isFinite(syncedId)) {
            console.log('delete', localId);
            this.deleteEdl(localId);
          }
          this.$forceUpdate();

        }, 1000);
      },
      handlePostUploadError: function ({error, localId}) {
        _.pull(this.loading, localId);
        this.$forceUpdate();
        this.throwError(
          "Un problème est survenu lors de l'enregistrement de votre EDL. Veuillez nous contacter si cela se reproduit.",
          "Erreur enregistrement EDL local #"+localId,
          _.has(error, 'response.data.detail') ? error.response.data.detail : error.message,
          error
        );
      },
      uploadAll: function () {
        f7.dialog.confirm('Êtes-vous sûr de vouloir enregistrer tous les EDLs qui sont prêts ?', 'Confirmation', () => {
          this.isLoadingAll = true;
          f7.dialog.confirm('Souhaitez-vous envoyer les EDLs par email aux locataires dès maintenant ?', 'Envoi EDLs par email', () => {
            f7.dialog.preloader('Enregsitrement des EDLs avec envoi d\'emails aux locataires...');
            this.doUploadAll(true);
          }, () => {
            f7.dialog.preloader('Enregistrement des EDLs...');
            this.doUploadAll(false);
          });
        });
      },
      doUploadAll: function (sendEmailToRenters) {
        _.each(this.localEdls, (localEdl) => {
          if(this.isReadyToSync(localEdl)) {
            this.loading.push(localEdl.localId);
          }
        });

        _.each(this.localEdls, (localEdl, index) => {
          if(this.isReadyToSync(localEdl)) {
            setTimeout(() => {
              this.doUpload(localEdl.localId, sendEmailToRenters, true);
            }, 1500*index); //add artificial space between sending, else causes errors
          }
        });
      },
      getHeader: function (edl) {
        let header = '';
        if (edl.bien.mandataire !== undefined && edl.bien.mandataire !== null) {
          header += edl.bien.mandataire;
        }
        if (edl.owner.proprietaireNom !== undefined && edl.owner.proprietaireNom !== null) {
          header += header !== '' ? ' - '+edl.owner.proprietaireNom : edl.owner.proprietaireNom;
        }
        return header;
      },
      getTitle: function (edl) {
        return edl.renter.name;
      },
      getDeleteHelp: function (edl) {
        if(this.isSynced(edl)) {
          return 'Comme celui-ci a été enregistré, vous pourrez le retrouver dans l\'onglet \'EDLs enregistrés\'';
        } else {
          return 'N\'étant pas encore enregistré, il sera définitivement perdu !';
        }
      },
      formatDate(value, pattern) {
        return formatDate(value, pattern);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .edl-actions {
    a.button {
      min-width: 60px;
    }
  }
  .f7-icons {
    font-size: 30px;
  }
  .list .item-after {
    flex-shrink: 2;
  }
  .button {
    height: 50px;
  }
  .edl-reference {
    font-weight: bold;
  }
  .edl-id {
    text-align: left;
    margin: 0;
    font-size: 8px;
    color: var(--f7-theme-color-dark-grey);
    width: 30px;
  }
  .list i {
    color: var(--f7-theme-color);
  }
  p.small {
    font-size: 14px;
  }
  p.edl-status {
    display: inline;
    float: left;
    margin-right: 15px;
  }
  .auto-delete {
    font-size: 12px;
    font-weight: normal;
    --f7-toggle-height: 20px;
    --f7-toggle-width: 30px;
    label {
      margin-left: 5px;
    }
  }
  .load-all-block {
    margin-top: 0;
  }

  @media screen and (max-width: 550px) {
    .segmented {
      display: block;
    }
    .ios .segmented-raised-ios .button:not(.button-outline) {
      border-left: none;
    }
    .edl-actions {
      float: right;
      max-width: 60px;
      a.button {
        max-width: 60px;
      }
      .f7-icons {
        font-size: 30px;
      }
    }
    .button {
      height: 46px;
    }
    p.edl-status {
      display: block;
      float: none;
      margin-right: 0;
      margin-top: 1px;
      margin-bottom: 8px;
    }
    :deep(.item-after) {
      max-width: 40%;

      p {
        white-space: normal;
        text-align: right;
      }
    }
  }
</style>
