import {f7} from "framework7-vue";
import Pica from "pica";
import {convertFileToBase64} from "@/composables/photoStorage";

export default {
  methods: {
    updateLogo() {
      this.$refs.logoInput.click();
    },
    uploadLogo(event) {
      f7.dialog.preloader('Chargement du nouveau logo...');
      const logo = event.target.files[0];
      this.resizeLogo(logo)
        .then((data) => {
            this.doUploadLogo({logo: data}) //doUploadLogo must be defined in context of mixin use
              .then(() => f7.dialog.close())
              .catch(() => f7.dialog.alert('Le nouveau logo n\'a pas pu être chargé correctement, veuillez nous contacter'));
          }
        );
    },
    async resizeLogo(logo) {
      return new Promise((resolve, reject) => {
        let image = new Image();
        let type = logo.extension;
        let to = document.createElement("canvas");
        to.width = 150;
        to.height = 150;
        image.onload = () => {
          let pica = new Pica();
          pica.resize(image, to, {
            alpha: true,
            unsharpAmount: 160,
            unsharpRadius: 0.8,
            unsharpThreshold: 1
          }).then((canvasImgResized) => {
            pica.toBlob(canvasImgResized, type, 0.8).then((blob) => resolve(convertFileToBase64(blob)));
          }).catch((error) => {
            reject(error);
          });
        }
        convertFileToBase64(logo).then((data) => {
          image.src = data;
        });
      })
    }
  }
}
