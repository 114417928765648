<template>
  <div class="entry">
    <f7-row>
      <f7-col class="header-entry">
        {{ line.name }}
        <div class="toggle-entry">
          <span
            v-if="line.enabled"
            class="show-for-large"
          >
            Inclus dans le chiffrage
          </span>
          <span
            v-else
            class="show-for-large"
          >
            Exclus du chiffrage
          </span>
          <f7-toggle
            tooltip="Cocher/Décocher pour utiliser ou non le chiffrage dans la convention de sortie"
            :checked="line.enabled"
            @toggle:change="toggleEnable"
          />
        </div>
      </f7-col>
    </f7-row>
    <f7-col>
      <f7-row class="align-items-strech">
        <f7-col
          large="30"
          width="100"
        >
          <f7-block-title class="diff-header">
            Entrant
          </f7-block-title>
          <f7-block class="diff nl2br">
            {{ defaultValue(line.beforeDescription,'-') }}
          </f7-block>
        </f7-col>
        <f7-col
          large="30"
          width="100"
        >
          <f7-block-title class="diff-header">
            Sortant
          </f7-block-title>
          <f7-block class="diff nl2br">
            {{ defaultValue(line.afterDescription,'-') }}
          </f7-block>
        </f7-col>
        <f7-col
          large="40"
          width="100"
          class="no-margin-top"
        >
          <f7-block-title class="diff-header">
            Coût
          </f7-block-title>
          <f7-block>
            <f7-list inset>
              <f7-list-input
                :value="line.action"
                type="select"
                class="input-action-select"
                label="Action"
                inline-label
                @change="updateAction($event.target.value)"
              >
                <option
                  v-for="(action, index) in calibrationConfigActions"
                  :key="'action_'+index"
                  :value="action"
                >
                  {{ getActionLabel(action) }}
                </option>
              </f7-list-input>
              <f7-list-input
                :value="line.task"
                type="textarea"
                label="Description des travaux"
                floating-label
                @change="updateTask($event.target.value)"
              />
              <f7-list-input
                v-if="lineCalibration"
                :value="formatCurrency(line.measure)"
                type="text"
                class="input-cost"
                label="Mesure"
                inline-label
                @change="updateMeasure($event.target.value)"
              >
                <template #content-end>
                  <span
                    v-if="isCoating"
                    class="input-cost-suffix"
                  >m²</span>
                </template>
              </f7-list-input>
              <f7-list-item
                v-if="line.action !== 'clean'"
              >
                <f7-list-item-cell
                  class="flex-shrink-1 antiquated-rate-label"
                >
                  Vétusté
                </f7-list-item-cell>
                <f7-list-item-cell
                  class="flex-shrink-0 antiquated-rate-slider"
                >
                  <f7-range
                    class="antiquated-rate"
                    :min="0"
                    :max="100"
                    :step="5"
                    :value="line.wear !== null ? line.wear : 0"
                    @rangeChange="updateWear"
                  />
                </f7-list-item-cell>
                <f7-list-item-cell class="flex-shrink-1 antiquated-rate-value">
                  {{line.wear !== null ? line.wear : 0}} %
                </f7-list-item-cell>
              </f7-list-item>
              <f7-list-input
                :value="formatCurrency(line.cost)"
                type="text"
                class="input-cost"
                label="HT"
                inline-label
                @change="updateCost($event.target.value)"
              >
                <template #root-start>
                  <div class="input-cost-auto">{{ isAutoCost ? 'Chiffrage automatique' : 'Chiffrage manuel' }}</div>
                </template>
                <template #content-end>
                  <span class="input-cost-suffix">€</span>
                </template>
              </f7-list-input>
              <f7-list-input
                type="select"
                :value="line.taxRate"
                class="input-tax-select"
                label="TVA"
                inline-label
                @change="updateTaxRate($event.target.value)"
              >
                <template #content-end>
                  <span class="input-cost-suffix">&nbsp;</span>
                </template>
                <option value="2000">
                  20%
                </option>
                <option value="1000">
                  10%
                </option>
                <option value="550">
                  5,5%
                </option>
                <option value="0">
                  Pas de TVA
                </option>
              </f7-list-input>
              <f7-list-input
                disabled
                label="TTC"
                type="text"
                class="input-cost"
                :value="formatCurrency(withTaxes)"
                inline-label
              >
                <template #content-end>
                  <span class="input-cost-suffix">€</span>
                </template>
              </f7-list-input>
            </f7-list>
          </f7-block>
        </f7-col>
      </f7-row>
    </f7-col>
  </div>
</template>

<script>
import costing from "@/components/mixins/costing";
import {getCalibrationKeyByEntryName} from "@/composables/costing";
import {mapGetters} from "vuex";
import _ from "lodash";
import {unobserve} from "@/store/helper";
import firewall from "@/components/mixins/firewall";

export default {
  name: "EdlCostingLine",
  mixins: [costing, firewall],
  props: {
    line: {
      type: Object,
      default: null
    }
  },
  emits: ['update'],
  data: function () {
    return {
      calibration: null,
      currentManualFullCost: null
    };
  },
  computed: {
    ...mapGetters('costing', [
      'getCalibrationByUserId',
      'calibrationConfig'
    ]),
    withTaxes: function () {
      return this.line.cost * (1 + this.line.taxRate/10000);
    },
    parentName: function () {
      return this.line.name.includes('>') ? this.line.name.split(' > ')[0] : undefined;
    },
    entryName: function () {
      return this.line.name.includes('>') ? this.line.name.split(' > ')[1] : this.line.name;
    },
    calibrationElementKey: function () {
      return getCalibrationKeyByEntryName(this.entryName, this.parentName, this.calibrationConfig);
    },
    isCoating: function () {
      return this.line.nature !== undefined && this.line.nature !== null;
    },
    calibrationConfigActions: function () {
      return _.without(this.calibrationConfig.actions, 'all');
    },
    fullCalibrationKey: function () {
      const parts = [];
      if(this.calibrationElementKey) {
        parts.push(this.calibrationElementKey);
      } else {
        return null;
      }

      if(this.line.nature) {
        parts.push(this.line.nature);
      } else {
        parts.push('all')
      }

      if(this.line.action) {
        parts.push(this.line.action);
      } else {
        return null;
      }

      return parts.join('.');
    },
    calibrationKeysByPriority: function () {
      if(!this.fullCalibrationKey) {
        return null;
      }

      let calibrationKeys = [this.fullCalibrationKey];

      let calibrationWithAllNatures = this.line.nature ? this.fullCalibrationKey.replace(this.line.nature, 'all') : null;
      if(calibrationWithAllNatures) {
        calibrationKeys.push(calibrationWithAllNatures);
      }

      let calibrationWithAllActions = this.fullCalibrationKey.replace(this.line.action, 'all');
      calibrationKeys.push(calibrationWithAllActions);

      let calibrationWithAllNaturesAndActions = calibrationWithAllNatures ? calibrationWithAllNatures.replace(this.line.action, 'all') : null;
      if(calibrationWithAllNaturesAndActions) {
        calibrationKeys.push(calibrationWithAllNaturesAndActions);
      }

      return calibrationKeys;
    },
    lineCalibration: function () {
      if(this.calibrationKeysByPriority === null) {
        return null;
      }

      let firstFound = undefined;
      let priority = 0;

      while(firstFound === undefined && this.calibrationKeysByPriority[priority] !== undefined) {
        firstFound = _.find(this.calibration, (value, key) => {
          return this.calibrationKeysByPriority[priority] ===  key;
        });
        priority++;
      }

      return firstFound !== undefined ? firstFound : null;
    },
    lineCalibrationUnitCost: function () {
      if(!this.lineCalibration) {
        return null
      } else {
        if(_.has(this.lineCalibration, 'user')) {
          return this.lineCalibration.user.unitCost * this.calculateWearCostDecreaseRate(this.line.wear);
        } else if(_.has(this.lineCalibration, 'group')) {
          return this.lineCalibration.group.unitCost * this.calculateWearCostDecreaseRate(this.line.wear);
        } else {
          console.error('Calibration has no unitCost defined');
          return null;
        }
      }
    },
    calculatedAutoCost: function () {
      return this.line.measure/100 * this.lineCalibrationUnitCost;
    },
    isAutoCost: function () {
      return this.lineCalibration && this.line.cost === this.calculatedAutoCost;
    }
  },
  mounted: function () {
    this.calibration = unobserve(this.getCalibrationByUserId(this.me.id));
    if(this.line.wear !== 100) {
      this.currentManualFullCost = this.line.cost / (1- this.line.wear / 100);
    }
  },
  methods: {
    updateTask: function (value) {
      this.update('task', value);
    },
    updateCost: function (value) {
      this.update('wear', 0);
      this.update('cost', this.unformatCurrency(value));
      this.currentManualFullCost = this.unformatCurrency(value);
    },
    updateTaxRate: function (value) {
      this.update('taxRate', parseFloat(value));
    },
    updateMeasure: function (value) {
      this.update('measure', this.unformatCurrency(value));
      this.currentManualFullCost = null;
      this.autoCost();
    },
    updateAction: function (value) {
      this.update('action', value);
      if(value === 'clean') {
        this.updateWear(0);
      } else if (this.lineCalibration) {
        this.autoCost();
      }
    },
    updateWear: function (value) {
      this.update('wear', value);
      if(this.lineCalibration && this.currentManualFullCost === null) {
        this.autoCost();
      } else {
        this.update('cost', this.currentManualFullCost * this.calculateWearCostDecreaseRate(this.line.wear));
      }
    },
    toggleEnable: function () {
      this.update('enabled', !this.line.enabled);
    },
    update: function (attribute, value) {
      this.$emit('update', {
        costingId: this.line.id,
        attribute,
        value
      });
    },
    autoCost: function () {
      this.update('cost', this.calculatedAutoCost);
    },
    defaultValue: function (value, defaultValue) {
      return value !== null && value !== '' && value !== undefined ? value : defaultValue;
    },
    calculateWearCostDecreaseRate: function (wear) {
      return 1-(wear/100);
    },
  }
}
</script>

<style lang="scss" scoped>
  .status {
    margin-right: 5px;
  }
  .header-module {
    background: var(--f7-theme-color-grey);
    font-size: 34px;
    margin-bottom: 0px;
  }
  .header-entry {
    font-size: 24px;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
    background: var(--f7-theme-color-dark-grey);
    color: white;
  }
  .toggle-entry {
    float: right;
    padding-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
    background: var(--f7-theme-color-dark-grey);
    --f7-toggle-width: 35px;
    --f7-toggle-height: 20px;
    .toggle {
      margin-top: -4px;
    }
    span {
      font-size: 14px;
      line-height: 20px;
      top: -3px;
      position: relative;
      right: 10px;
    }
  }
  .header-sub-entry {
    color: black;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .diff {
    font-size: 18px;
    margin-top: 10px;
    margin-left: 16px;
  }
  .diff-header {
    padding-left: 16px;
  }
  .header-io {
    margin: 0;
    background: var(--f7-theme-color-very-dark-grey);
    color: white;
    .header-io-col {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  .arrow-separator {
    i {
      font-size: 12px;
    }
    text-align: center;
  }
  .rate {
    font-weight: bold;
    display: block;
  }
  .nl2br {
    white-space: pre-line;
  }
  .input-cost, .input-tax-select {
    .input-cost-suffix {
      margin-right: 5px;
      margin-left: 5px;
      position: relative;
      width: 5%;
    }
    .input-cost-auto {
      font-size: 10px;
      text-align: center;
      width: 100%;
      position: relative;
      height: 5px;
      top: 5px;
      //right: 10px;
      color: var(--f7-theme-color-dark-grey);
    }
  }
  .antiquated-rate-value {
    text-align: left;
  }

  :deep(.input-cost) {
    input.input-with-value {
      text-align: right;
    }
  }

  :deep(.input-tax-select){
    .input-dropdown {
      select.input-with-value {
        text-align: right;
        transform: translateX(35px);
      }
      &::before {
        transform: translateX(40px);
      }
    }
  }

  :deep(.input-action-select){
    .input-dropdown {
      select.input-with-value {
        text-align: right;
      }
      &::before {
        transform: translateX(5px);
      }
    }
  }

  .item-cell.antiquated-rate-slider {
    width: 70%;
  }

  @media screen and (max-width: 1440px) {
    .item-cell.antiquated-rate-slider {
      width: 50%;
    }
  }

  @media screen and (max-width: 1024px) {
    .item-cell.antiquated-rate-slider {
      width: 80%;
    }
  }

  @media screen and (max-width: 720px) {
    .item-cell.antiquated-rate-slider {
      width: 70%;
    }
  }

  @media screen and (max-width: 550px) {
    .item-cell.antiquated-rate-slider {
      width: 60%;
    }
  }

  @media screen and (max-width: 480px) {
    .toggle-entry span {
      display: none;
    }

    .item-cell.antiquated-rate-slider {
      width: 50%;
    }
  }

  @media screen and (max-width: 360px) {
    .toggle-entry span {
      display: none;
    }

    .item-cell.antiquated-rate-slider {
      width: 40%;
    }
  }
</style>
