<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top title="Je choisis un profil">
      <template #global-actions>
        <f7-link
          class="button"
          @click="toggleListActions"
        >
          <f7-icon f7="gear" />
        </f7-link>
      </template>
    </navigation-top>
    <f7-page-content>
      <f7-list v-if="!noProfiles">
        <f7-list-item
          v-for="profile in profiles"
          :key="profile.id"
          @click="select(profile.id, profile.passcode)"
        >
          <template #media>
            <i class="f7-icons">person_alt_circle</i>
          </template>
          <template #title>
            {{ profile.name }}
            <i
              v-show="profile.passcode"
              class="f7-icons has-passcode"
            >lock_circle</i>
            <f7-badge
              v-if="profile.default"
              class="margin-left"
              color="red"
            >
              Profil principal
            </f7-badge>
          </template>
          <template
            v-if="showListActions"
            #after
          >
            <f7-segmented>
              <f7-button
                v-if="!profile.default"
                small
                class="display-flex margin-left-half"
                icon-f7="suit_heart"
                @click="markAsDefault(profile.id)"
              />
              <f7-button
                small
                class="display-flex margin-left-half"
                color="red"
                icon-f7="trash"
                @click="remove(profile.id)"
              />
            </f7-segmented>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block
        v-else
        inset
        strong
      >
        <p>Vous n'avez pas de profil défini pour votre utilisateur.</p>
        <p>Commencez par en créer un.</p>
      </f7-block>
      <f7-list inset v-if="!me.uniqueProfile || me.profiles.length < 1">
        <f7-list-button
          title="Ajouter un profil"
          @click="create"
        />
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import NavigationTop from "../../layout/NavigationTop";
  import {mapActions, mapState} from "vuex";
  import { f7 } from "framework7-vue";

  export default {
    name: "ProfileSelection",
    components: {NavigationTop},
    mixins: [firewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        showListActions: false
      };
    },
    computed: {
      ...mapState('me', [
        'me',
      ]),
      profiles: function () {
        return this.me && this.me.profiles !== undefined ? this.me.profiles : null;
      },
      noProfiles: function () {
        return this.profiles !== null && this.profiles.length === 0;
      }
    },
    mounted() {
      if(this.me.uniqueProfile && this.selectedProfile === null) {
        if(this.noProfiles) {
          this.create();
        } else {
          let defaultProfile = this.profiles.find(p => p.default === true);
          if (defaultProfile === undefined) {
            defaultProfile = this.profiles[0]; //use first profile
          }
          this.$nextTick().then(() => {
            this.select(defaultProfile.id, defaultProfile.passcode);
          });
        }
      }
    },
    methods: {
      ...mapActions('me', {
        'selectProfile': 'selectProfile',
        'addProfile': 'addProfile',
        'deleteProfile': 'deleteProfile',
        'defaultProfile': 'defaultProfile',
      }),
      select: function (id, jumbledPasscode) {
        if (this.showListActions === true) { //prevent click
          return;
        }

        if(jumbledPasscode) {
          this.checkPasscode(jumbledPasscode, () => {
            this.selectProfile(id);
            this.f7router.navigate({name: 'edlsLocal'}, {reloadAll: true});
          });
        } else {
          this.selectProfile(id);
          this.f7router.navigate({name: 'edlsLocal'}, {reloadAll: true});
        }
      },
      create: function () {
        f7.dialog.prompt('Choisissez un nom pour votre nouveau profil', (value) => {
          this.addProfile(value);
          if(this.me.uniqueProfile) {
            f7.preloader.show();
            setTimeout(() => {
              const defaultProfile = this.profiles.find(p => p.default === true);
              this.select(defaultProfile.id, defaultProfile.passcode);
            }, 1000);
          }
        });
      },
      markAsDefault(id) {
        this.defaultProfile(id);
      },
      remove(id) {
        f7.dialog.confirm('Confirmer-vous la suppression du profil ', 'Suppression', () => {
          this.deleteProfile(id);
        });
      },
      toggleListActions() {
        this.showListActions = !this.showListActions;
      },
      checkPasscode(passcodeToCheckAgainst, callback) {
        f7.dialog.create({
          text: "L'accès à ce profil a été protégé par un code secret",
          title: 'Code secret',
          content:
            '<div class="dialog-input-field item-input">' +
            '<div class="item-input-wrap">' +
            '<input class="dialog-input text-align-center" type="tel" placeholder="Code à 4 chiffres" required validate pattern="[0-9]{4}" data-error-message=" ">' +
            '</div>' +
            '</div>',
          buttons: [
            {
              text: 'Valider',
              bold: true,
              close: true,
              onClick: (dialog) => {
                let passcode = dialog.$el.find('.dialog-input').val();
                let jumbledPasscode = this.jumblePasscode(passcode);

                if(jumbledPasscode === passcodeToCheckAgainst) {
                  callback();
                } else {
                  f7.dialog.create({
                    title: "Erreur",
                    text: "Code secret erroné",
                    buttons: [
                      {
                        text: 'OK',
                        bold: true,
                        close: true
                      }
                    ]
                  }).open();
                }
              }
            }
          ]
        }).open();
      },
      jumblePasscode(passcode) { //Same algorithm as PHP counterpart. Very basic, non-secure passcode jumble to obfuscate passcode (non-critical security).
        let alphabetized = "";
        const alphabet = [...'abcdefghijklmnopqrstuvwxyz'];
        const digits = passcode.split("").reverse();
        digits.forEach((digit) => {
          alphabetized += alphabet[digit];
        });
        return alphabetized.replace(/[a-z]/gi, letter => String.fromCharCode(letter.charCodeAt(0) + (letter.toLowerCase() <= 'm' ? 13 : -13)));
      }
    }
  }
</script>

<style lang="scss" scoped>
  .has-passcode {
    font-size: 16px;
    color: var(--f7-theme-color-dark-grey);
  }
</style>
