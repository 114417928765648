<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Auto-EDLs"
      back="account"
    >
      <template #global-actions="{toggleSubNav}">
        <f7-link
          class="button color-white"
          @click="toggleSubNav"
        >
          <i class="f7-icons">search</i>
        </f7-link>
        <f7-link
          :href="f7router.generateUrl({name: 'newAutoEdl'})"
          icon-f7="plus_circle"
          text="Nouveau"
        />
      </template>
      <template #subnav>
        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title, .item-subtitle, .item-text"
          :disable-button="!$theme.aurora"
        />
      </template>
    </navigation-top>

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list
        v-if="!noAutoEdls"
        media-list
        class="search-list"
      >
        <f7-list-item
          v-for="autoEdl in autoEdls"
          :key="autoEdl.id"
          swipeout
          :title="autoEdl.email"
          :subtitle="getEdlName(autoEdl)"
          @swipeout:delete="deleteAutoEdl(autoEdl)"
        >
          <template #text>
            {{ getEdlDescription(autoEdl) }}<br>
            <span v-if="autoEdl.autoEdl">
              Réalisé le {{ formatDate(autoEdl.date, 'PPPpp') }} par {{ autoEdl.autoEdl.renter }}
            </span>
            <span
              v-else
              class="small"
            >
              Demandé le {{ formatDate(autoEdl.date, 'PPPpp') }}
            </span>
          </template>
          <template #media>
            <i
              v-if="autoEdl.autoEdl"
              class="f7-icons color-theme"
            >checkmark_circle_fill</i>
            <i
              v-else
              class="f7-icons color-theme color-theme-orange"
            >clock</i>
          </template>
          <template #after>
            <div class="segmented segmented-raised-ios actions">
              <f7-link
                class="button"
                @click="showLoginInfo(autoEdl)"
              >
                <i class="f7-icons">lock_circle</i>
              </f7-link>
            </div>
          </template>
          <f7-swipeout-actions right>
            <f7-swipeout-button
              delete
              confirm-text="Êtes-vous certains de vouloir supprimer cet Auto-EDL ? Cette action est définitive."
            >
              Supprimer
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </f7-page-content>
    <f7-popup
      :opened="showPopup"
      @popup:close="hideLoginInfo"
    >
      <f7-card v-if="loginInfo">
        <f7-card-header>Login</f7-card-header>
        <f7-card-content>
          <p>
            {{ loginInfo.login }}
            <a
              v-if="copiedLogin"
              class="copy"
            ><i class="f7-icons">checkmark_alt</i> copié !</a>
            <a
              v-else
              class="copy"
              @click="copyToClipboard(loginInfo.login, hasCopiedLogin)"
            ><i class="f7-icons">doc_on_clipboard</i> copier</a>
          </p>
        </f7-card-content>
      </f7-card>
      <f7-card v-if="loginInfo">
        <f7-card-header>Mot de passe</f7-card-header>
        <f7-card-content>
          <p>
            {{ loginInfo.password }}
            <a
              v-if="copiedPassword"
              class="copy"
            ><i class="f7-icons">checkmark_alt</i> copié !</a>
            <a
              v-else
              class="copy"
              @click="copyToClipboard(loginInfo.password, hasCopiedPassword)"
            ><i class="f7-icons">doc_on_clipboard</i> copier</a>
          </p>
        </f7-card-content>
      </f7-card>
    </f7-popup>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import formatDate from "@/components/filters/format_date";
  import Clipboard from "clipboard";
  import { f7 } from "framework7-vue";
  import {nextTick} from "vue";

  export default {
    name: "AutoEdlList",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        showPopup: false,
        loginInfo: null,
        copiedLogin: false,
        copiedPassword: false
      }
    },
    computed: {
      ...mapGetters('autoEdl', {
        'autoEdls': 'getAllAutoEdls',
      }),
      noAutoEdls() {
        return this.autoEdls !== null && this.autoEdls.length === 0;
      }
    },
    mounted() {
      nextTick(() => {
        this.limitToCanAutoEdls();

        f7.preloader.show();
        this.pagePreloaderRunning = true;
        this.fetchAutoEdls().finally(() => f7.preloader.hide());
      });
    },
    methods: {
      ...mapActions('autoEdl', [
        'fetchAutoEdls',
        'deleteAutoEdl',
      ]),
      getEdlName: function (autoEdl) {
        return autoEdl.autoEdlDuplicatedFrom.agent + '-' + autoEdl.autoEdlDuplicatedFrom.renter + '[' + autoEdl.autoEdlDuplicatedFrom.edlIO + ']'
      },
      getEdlDescription: function (autoEdl) {
        return "Etage et porte : " + (autoEdl.autoEdlPreData.etage || '-') +" • N° d'appartement : "+ (autoEdl.autoEdlPreData.numAppart || '-');
      },
      showLoginInfo: function (autoEdl) {
        this.showPopup = true;
        this.loginInfo = autoEdl;
      },
      hideLoginInfo: function () {
        this.showPopup = false;
        this.loginInfo = null;
      },
      copyToClipboard: function (toCopy, _callback) {
        let fakeElement = document.createElement('button')
        let clipboard = new Clipboard(fakeElement, {
          text: function () {
            return toCopy;
          },
          action: function () {
            return 'copy'
          },
          container: document.body,
        });
        clipboard.on('success', function (e) {
          clipboard.destroy();
          _callback(undefined, e);
        });
        clipboard.on('error', function (e) {
          clipboard.destroy();
          _callback(e, undefined);
        });
        fakeElement.click();
      },
      hasCopiedLogin: function () {
        this.copiedLogin = true;
        setTimeout(() => {
          this.copiedLogin = false;
        }, 3000);
      },
      hasCopiedPassword: function () {
        this.copiedPassword = true;
        setTimeout(() => {
          this.copiedPassword = false;
        }, 3000);
      },
      formatDate: function (value, pattern) {
        return formatDate(value, pattern);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .actions {
    margin-top: 6px;
    .f7-icons {
      margin-top: 4px;
      color: var(--f7-theme-color-dark-grey);
    }
  }
  .f7-icons {
    font-size: 18px;
  }
  .list .item-after {
    flex-shrink: 2;
  }
  .button {
    height: 24px;
  }
  .modal-in {
    max-height: 240px;
  }
  .copy {
    float: right;
  }
</style>
