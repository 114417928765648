<template>
  <ul class="top-separator">
    <i class="f7-icons rate-section-icon">photo</i>
    <f7-list-button
      v-if="!addingPhotos"
      @click="$emit('openPhotoInput')"
    >
      <f7-preloader v-if="openingFileSelector" />
      <i
        v-else
        class="f7-icons"
      >camera</i>
    </f7-list-button>
    <f7-list-item
      v-else
      media-item
      text="Chargement de photos..."
    >
      <template #media>
        <f7-preloader />
      </template>
    </f7-list-item>
    <f7-swiper
      v-if="gallery && nbPhotos > 0"
      ref="gallery"
      navigation
      pagination
    >
      <f7-swiper-slide
        v-for="(photo, index) in gallery"
        :id="index"
        ref="gallerySlider"
        :key="'photo'+index"
        :zoom="true"
      >
        <img :src="photo.data" />
      </f7-swiper-slide>
    </f7-swiper>
    <f7-list-item
      v-else
      class="no-photo"
    >
      {{ $t('Aucune photo associée à cet élément') }}
    </f7-list-item>
    <f7-link
      v-if="nbPhotos > 0"
      class="media-edit-button"
      @click="openMediaEditPopup"
    >
      <edl-edit-rate-photo-editor
        :media="currentPhoto"
        :media-edit-popup-opened="mediaEditPopupOpened"
        :photo-dimensions="photoDimensions"
        :photo-id="currentPhotoId"
        :rate="currentPhotoRate"
        @close="closeMediaEditPopup"
        @update="update"
      />
      <i
        class="f7-icons"
      >pencil_outline</i>
    </f7-link>
  </ul>
</template>

<script>
import EdlEditRatePhotoEditor from "@/components/pages/Edl/EdlEdit/EdlEditRate/EdlEditRatePhotoEditor.vue";
import hasMedia from "@/components/mixins/hasMedia";

export default {
  name: "EdlEditRatePhotoGallery",
  components: {EdlEditRatePhotoEditor},
  mixins: [hasMedia],
  props: {
    gallery: {
      type: Array,
      default: () => []
    },
    nbPhotos: {
      type: Number,
      default: 0
    },
    addingPhotos: {
      type: Number,
      default: 0
    },
    openingFileSelector: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openPhotoInput', 'update'],
  data: function () {
    return {
      mediaEditPopupOpened: false,
      currentPhoto: null,
      currentPhotoId: null,
      currentPhotoRate: null,
      photoDimensions: {
        width: null,
        height: null
      }
    }
  },
  watch: {
    currentPhoto: function (currentPhoto) {
      if (currentPhoto !== null) {
        let img = new Image();
        img.onload = () => {
          this.photoDimensions.width = img.width;
          this.photoDimensions.height = img.height;
        };
        img.src = currentPhoto;
      } else {
        this.media = ""; //Else temp 404 error trying to get a null asset
      }
    }
  },
  methods: {
    openMediaEditPopup: function () {
      let currentIndex = this.$refs.gallerySlider[0].swiperRef.value.realIndex;
      this.currentPhoto = this.$refs.gallerySlider[currentIndex].$el.querySelector('img').src;
      this.currentPhotoRate = this.gallery[currentIndex].rate;
      this.currentPhotoId = this.getPhotoId(this.currentPhotoRate.photo);
      this.mediaEditPopupOpened = true;
    },
    closeMediaEditPopup: function () {
      this.currentPhoto = null;
      this.currentPhotoId = null;
      this.mediaEditPopupOpened = false;
    },
    update: function (payload) {
      this.$emit('update', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-photo {
    font-size: 12px;
    font-style: italic;
    :deep(.item-inner) {
      padding-top: 50px;
      padding-bottom: 50px;
      display: inline-block;
      text-align: center;
    }
    color: var(--f7-theme-color-grey);
  }
  .rate-section-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    color: var(--f7-theme-color-dark-grey);
  }
  .media-edit-button {
    position: absolute;
    top: 50px;
    right: 5px;
    width: 44px;
    height: 28px;
    text-align: center;
    color: white;
    background: rgba(var(--f7-theme-color-light-grey-rgb), 0.5);
    border-radius: 5px;
    i {
      font-size: 22px;
    }
  }
  .immersive {
    .media-edit-button {
      top: 50px;
      left: 50%;
      transform: translateX(-22px);
    }
  }
</style>
