import _ from "lodash";

export default {
  computed: {
    hasConvention: function () {
      if(this.info === undefined || this.info === null) {
        return false;
      }
      return this.edlHasConvention(this.info);
    }
  },
  methods: {
    edlHasConvention: function (edl) {
      if(_.has(edl, 'hasConvention')) {
        return edl.hasConvention;
      } else { //Needed inside EdlEdit
        let duplicateId = edl.duplicateFromEdlID !== undefined ? edl.duplicateFromEdlID : null;
        let duplicateIO = edl.duplicateFromEdlIO !== undefined ? edl.duplicateFromEdlIO : null;
        return duplicateId
          && duplicateIO === "Entrant"
          && (edl.edlIO === "Sortant" || edl.isCaseColloc);
      }
    },
  }
}
