<template>
  <f7-list-item media-item>
    <template #media>
      <img :src="media">
      <f7-link
        class="media-edit-button"
        :class="{immersive: immersive}"
        @click="mediaEditPopupOpened = true"
      >
        <i class="f7-icons">pencil_outline</i>
      </f7-link>
    </template>
    <edl-edit-rate-photo-editor
      :media="media"
      :media-edit-popup-opened="mediaEditPopupOpened"
      :photo-id="photoId"
      :rate="rate"
      :photo-dimensions="photoDimensions"
      @close="mediaEditPopupOpened = false"
      @update="update"
    />
  </f7-list-item>
</template>

<script>
  import {PhotoDatabase} from "@/composables/photoStorage";
  import hasMedia from "@/components/mixins/hasMedia";
  import EdlEditRatePhotoEditor from "@/components/pages/Edl/EdlEdit/EdlEditRate/EdlEditRatePhotoEditor.vue";

  const photoDb = new PhotoDatabase();

  export default {
    name: "EdlEditRatePhoto",
    components: {EdlEditRatePhotoEditor},
    mixins: [hasMedia],
    props: {
      rate: {
        type: Object,
        default: null
      },
      immersive: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update'],
    data: function () {
      return {
        photoId: null,
        photo: null,
        photoDimensions: {
          width: null,
          height: null
        },
        media: null,
        isEditing: false,
        mediaEditPopupOpened: false
      };
    },
    watch: {
      rate: {
        handler: function (rate) {
          this.photo = null;
          this.photoId = this.getPhotoId(rate.photo);
          photoDb.getPhoto(this.photoId).then((photo) => {
            this.photo = photo;
          });
        },
        immediate: true,
        deep: true
      },
      media: function (media) {
        if (media !== null) {
          let img = new Image();
          img.onload = () => {
            this.photoDimensions.width = img.width;
            this.photoDimensions.height = img.height;
          };
          img.src = media;
        } else {
          this.media = ""; //Else temp 404 error trying to get a null asset
        }
      },
      photo: {
        handler: function (photo) {
          this.media = photo !== null && photo !== undefined ? photo.data : null;
        },
        deep: true
      }
    },
    methods: {
      update: function (payload) {
        this.$emit('update', payload);
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.item-media) {
    width: 100%;
    padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
    img {
      width: 100%;
      border-radius: 10px;
    }
    .media-edit-button {
      position: absolute;
      top: 15px;
      right: 20px;
      width: 40px;
      height: 20px;
      text-align: center;
      color: white;
      background: rgba(var(--f7-theme-color-light-grey-rgb), 0.5);
      border-radius: 5px;
      i {
        font-size: 20px;
      }
      &.immersive {
        top: 20px;
        left: 50%;
        transform: translateX(-22px);
      }
    }
  }
</style>
