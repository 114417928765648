<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top title="Auto-EDL">
      <template #global-actions>
        <f7-link
          class="no-margin-horizontal no-padding-horizontal button color-white"
          @click="logout"
        >
          <i class="f7-icons">power</i>
        </f7-link>
      </template>
    </navigation-top>

    <f7-page-content>
      <f7-row v-if="isOpen">
        <f7-col width="100">
          <f7-block>
            <p class="text-center">
              <strong>{{ $t("Pour valider définitivement votre état des lieux,") }}</strong><br> <span>{{ $t("cliquez ci-dessous") }}</span>
            </p>
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row v-else>
        <f7-col width="100">
          <f7-block>
            <p><strong>{{ $t("Merci, c'est tout bon !") }}</strong></p>
            <p><strong>{{ $t("Vous n'avez plus rien à faire.") }}</strong><br><span>{{ $t("Vous pouvez vous déconnecter en cliquant ci-dessous") }}</span></p>
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row v-if="isOpen">
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            v-if="loading"
            large
            raised
            fill
            disabled
          >
            {{ $t("Soumission de l'EDL en cours...") }}
          </f7-button>
          <f7-button
            v-else
            large
            raised
            fill
            @click="uploadEdl"
          >
            {{ $t('Je soumets mon EDL') }}
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
      <f7-row v-else>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            large
            raised
            fill
            @click="logout"
          >
            {{ $t('Déconnexion') }}
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../mixins/firewall";
  import NavigationTop from "../../layout/NavigationTop";
  import {mapActions} from "vuex";
  import autoEdl from "@/components/mixins/autoEdl";
  import offline from "@/components/mixins/offline";
  import _ from "lodash";
  import {unobserve} from "@/store/helper";

  export default {
    name: "AutoEdlDone",
    components: {
      NavigationTop
    },
    mixins: [firewall, autoEdl, offline],
    data: function () {
      return {
        info: null,
        loading: false
      }
    },
    computed: {
      isOpen: function () {
        return this.info ? this.info.open : true;
      }
    },
    watch: {
      me: {
        handler: 'setup'
      },
      info: {
        handler: 'updateInfo',
        deep: true
      }
    },
    methods: {
      ...mapActions('edlPersist', [
        'updateCurrentLocalEdlInfo',
        'uploadLocalEdl',
        'deleteLocalEdl',
      ]),
      setup: function () {
        if(this.autoEdl) {
          this.setCurrentLocalEdlId(this.autoEdl.id);
          let edl = unobserve(this.getCurrentLocalEdl);
          this.info = _.omit(edl, ['edl']);
        }
      },
      updateInfoAtPath: function (path, value) {
        this.info = _.set(unobserve(this.info), path, value);
      },
      updateInfo: function () {
        this.updateCurrentLocalEdlInfo(this.info);
      },
      uploadEdl: function () {
        this.loading = true;
        this.uploadLocalEdl({
          localId: this.autoEdl.id,
          profileId: null //set server side
        }).then(({localId, syncedId}) => {
            this.updateInfoAtPath('open', false);
            this.loading = false;
            this.$forceUpdate();
            if(syncedId !== null && _.isFinite(syncedId)) {
              this.deleteEdl(localId);
            }
          })
          .catch(({error, localId}) => {
            this.loading = false;
            this.$forceUpdate();
            this.throwError(
              "Un problème est survenu lors de la synchronisation de votre EDL. Veuillez nous contacter si cela se reproduit.",
              "Erreur synchronisation EDL local #"+localId,
              _.has(error, 'response.data.message') ? error.response.data.message : error.message,
              error
            );
          });
      },
      deleteEdl: function (localId) {
        this.deleteLocalEdl(localId).catch((error) => {
          console.error(error);
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  p {
    text-align: center;
    font-size: 20px;
  }
</style>
