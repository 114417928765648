import api from '../../api';
import {unobserve} from "@/store/helper";

const state = {
  me: null,
  selectedProfile: null,
};

// actions
const actions = {
  fetchMe({commit}) {
    return api.me()
      .then(response => {
        commit('me', response.data)
      });
  },
  fetchUserForPublicAction({commit}, {edlId, publicToken}) {
    return api.me(edlId, publicToken).then(response => {
      commit('me', response.data)
    });
  },
  updateMe({state, commit}, changes) {
    return api.updateMe(state.me.id, changes)
      .then((response) => {
        commit('me', response.data)
      })
  },
  updatePassword({state}, {password}) {
    return api.updatePassword(state.me.id, password);
  },
  updatePasscode({state, commit}, {passcode}) {
    return api.updatePasscode(state.selectedProfile.id, passcode).then((response) => {
      let newProfile = response.data;
      let me = unobserve(state.me);
      me.profiles = me.profiles.map(item => {
        if(item.id === newProfile.id) {
          return newProfile
        } else {
          return item
        }
      });
      commit('me', me);
      commit('selectedProfile', newProfile);
    });
  },
  updatePlatformToken({state, commit}, {platformToken}) {
    return api.updatePlatformToken(state.me.id, platformToken).then(() => {
      commit('setPlatformToken', platformToken);
    });
  },
  selectProfile({commit, state}, id) {
    let profiles = state.me.profiles;
    if(!profiles) {
      console.error('User has no profiles though one was selected');
    } else {
      let profile = profiles.find(item => item.id === id)
      if(profile === undefined) {
        console.error('Selected profile with ID '+id+' does not exist in store');
      } else {
        commit('selectedProfile', profile);
      }
    }
  },
  resetSelectedProfile({commit}) {
    commit('selectedProfile', null);
  },
  resetUser({commit}) {
    commit('me', null);
  },
  addProfile({commit, state}, name) {
    api.createProfile(name)
      .then((response) => {
        let me = unobserve(state.me);
        me.profiles.push(response.data);
        commit('me', me);
      }).catch((error) => console.log(error))
    ;
  },
  deleteProfile({commit, state}, id) {
    api.deleteProfile(id)
      .then(() => {
        let me = unobserve(state.me);
        me.profiles = me.profiles.filter(item => item.id !== id)
        commit('me', me);
      }).catch((error) => console.log(error))
    ;
  },
  defaultProfile({commit, state}, id) {
    api.defaultProfile(id)
      .then(() => {
        let me = unobserve(state.me);
        me.profiles = me.profiles.map(profile => {
          profile.default = profile.id === id

          return profile
        });
        commit('me', me);
      }).catch((error) => console.log(error))
    ;
  },
  updateProfile({commit, state}, payload) {
    return api.updateProfile(state.selectedProfile.id, payload)
      .then((response) => {
        let newProfile = response.data;
        let me = unobserve(state.me);
        me.profiles = me.profiles.map(item => {
          if(item.id === newProfile.id) {
            return newProfile
          } else {
            return item
          }
        });
        commit('me', me);
        commit('selectedProfile', newProfile);
      }).catch((error) => console.log(error))
    ;
  }
};

// mutations
const mutations = {
  me(state, payload) {
    state.me = payload;
  },
  selectedProfile(state, payload) {
    state.selectedProfile = payload;
  },
  setPlatformToken(state, payload) {
    state.me.platformToken = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
