<template>
  <!-- Main Framework7 App component where we pass Framework7 params -->
  <f7-app v-bind="f7params">
    <!-- initial page is specified in routes.js -->
    <f7-view
      main
      url="/"
    />
  </f7-app>
</template>

<script>
  import routes from '@/routes';

  export default {
    data: function () {
      return {
        f7params: {
          id: "ddh.soft",
          name: "DDH",
          theme: "ios",
          routes,
          view: {
            browserHistory: true,
            browserHistorySeparator: '',
            browserHistoryOnLoad: true,
            browserHistoryInitialMatch: true,
          },
          sortable: {
            moveElements: false
          },
          dialog: {
            title: 'Attention',
            buttonOk: 'Je confirme',
            buttonCancel: 'Annuler',
          },
        }
      }
    }
  }
</script>

<style>

</style>
