export default {
  computed: {
    startPages: function () {
      if (this.pagination.count <= 5) {
        return this.range(1, this.pagination.count);
      } else if (this.currentPage <= 3) {
        return this.range(1, 4);
      } else {
        return [1];
      }
    },
    middlePages: function () {
      if (this.pagination.count <= 5) {
        return null;
      } else if (this.currentPage <= 3) {
        return null;
      } else if (this.pagination.count - this.currentPage < 3) {
        return null;
      } else {
        return this.range(this.currentPage - 1, this.currentPage + 1);
      }
    },
    endPages: function () {
      if (this.pagination.count <= 5) {
        return null;
      } else if (this.currentPage <= 3) {
        return [this.pagination.count];
      } else if (this.pagination.count - this.currentPage < 3) {
        return this.range(this.pagination.count - 3, this.pagination.count);
      } else {
        return [this.pagination.count];
      }
    }
  },
  methods: {
    changePage: function (page) {
      this.changeCurrentPage(page);
    },
    range: function (start, end) {
      let total = end - start + 1;
      return Array(total).fill().map((x,i)=> i + start);
    }
  }
}
