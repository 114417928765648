<template>
  <f7-row class="breadcrumb-navigation extendable">
    <f7-col
      v-show="isModuleSelected"
      class="breadcrumb-navigation-content"
    >
      <f7-button @click="navigationGoBack">
        <i class="f7-icons">arrow_left</i>
      </f7-button>
      <span v-if="currentModule"><a @click="navigationGoBack('module')">{{ currentModule.name }}</a></span>
      <span v-if="currentEntry"><i class="f7-icons">chevron_right</i><a @click="navigationGoBack('entry')">{{ currentEntry.name }}</a></span>
      <span v-if="currentSubEntry"><i class="f7-icons">chevron_right</i><a @click="navigationGoBack('subEntry')">{{ currentSubEntry.name }}</a></span>
    </f7-col>
  </f7-row>
</template>

<script>
export default {
  name: "EdlEditBreadcrumbNavigation",
  props: ['currentModule', 'currentEntry', 'currentSubEntry', 'isModuleSelected'],
  emits: ['back'],
  methods: {
    navigationGoBack(to = null) {
      this.$emit('back', to);
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-navigation {
  display:block;
  height: 40px;
  .breadcrumb-navigation-content {
    width: 96%;
    display: inline-block;
    top: 58px;
    position: fixed;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
    height: 24px;
    .button {
      float: left;
    }
    .button i {
      margin-top: 2px;
      margin-left: 3px;
      margin-right: 3px;
      font-size: 18px;
    }
    span {
      position: relative;
      top: 3px;
      a {
        color: var(--f7-theme-color-very-dark-grey);
        font-size: 16px;
      }
      i {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
}
</style>
