<template>
  <f7-row
    v-if="addingEnabled"
    class="row-opaque-bg extendable"
  >
    <f7-col :width="addElementsColWidth">
      <f7-button
        class="add-element-button"
        popover-open=".add-module-popover"
      >
        Ajouter
      </f7-button>
      <f7-popover
        class="add-module-popover"
        @popover:closed="toggleAddingEnabled"
      >
        <f7-list>
          <f7-list-item
            v-for="(newModule, newModuleIndex) in addableModules"
            :key="'newModule'+newModuleIndex"
            popover-close
            link
            :title="$t(newModule.addName)"
            @click="addModule(newModule)"
          >
            <template #media>
              <i class="f7-icons small-icon">plus</i>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-popover>
    </f7-col>
    <f7-col
      v-if="isModuleSelected"
      :width="addElementsColWidth"
    >
      <f7-button
        class="add-element-button"
        popover-open=".add-entry-popover"
      >
        Ajouter
      </f7-button>
      <f7-popover
        class="add-entry-popover"
        @popover:closed="toggleAddingEnabled"
      >
        <f7-list>
          <f7-list-item
            v-for="(newEntry, newEntryIndex) in addableEntries"
            :key="'newEntry'+newEntryIndex"
            popover-close
            link
            :title="$t(newEntry.addName)"
            @click="addEntry(newEntry)"
          >
            <template #media>
              <i class="f7-icons small-icon">plus</i>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-popover>
    </f7-col>
    <f7-col
      v-if="hasSubEntries"
      :width="addElementsColWidth"
    />
    <f7-col
      v-if="(hasSubEntries && isSubEntrySelected) || (!hasSubEntries && isEntrySelected)"
      :width="addElementsColWidth"
    >
      <f7-button
        class="add-element-button"
        popover-open=".add-rate-popover"
      >
        Ajouter
      </f7-button>
      <f7-popover
        class="add-rate-popover"
        @popover:closed="toggleAddingEnabled"
      >
        <f7-list>
          <f7-list-item
            v-for="(newRate, newRateIndex) in addableRates"
            :key="'newRate'+newRateIndex"
            popover-close
            link
            :title="$t(newRate.label)"
            @click="addRate(newRate)"
          >
            <template #media>
              <i class="f7-icons small-icon">plus</i>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-popover>
    </f7-col>
  </f7-row>
</template>

<script>
import {generateAddableEntries, generateAddableModules} from "@/data/edlDataStructureModule";
import {addableRates} from "@/data/edlDataStructureModuleEntryRate";

export default {
  name: "EdlEditAdding",
  props: ['addingEnabled', 'isModuleSelected', 'isEntrySelected', 'isSubEntrySelected', 'hasSubEntries', 'nbCols'],
  emits: ['toggle', 'addModule', 'addEntry', 'addRate'],
  data: function () {
    return {
      addableModules: generateAddableModules(),
      addableEntries: generateAddableEntries(),
      addableRates,
    }
  },
  computed: {
    addElementsColWidth: function () {
      switch(this.nbCols) {
        case 1:
          return 100;
        case 2:
          return 50;
        case 3:
          return 33;
        case 4:
          return 25;
      }
      return 100;
    },
  },
  methods: {
    toggleAddingEnabled: function () {
      this.$emit('toggle');
    },
    addModule: function (moduleToAdd) {
      this.$emit('addModule', moduleToAdd);
    },
    addEntry: function (entryToAdd) {
      this.$emit('addEntry', entryToAdd);
    },
    addRate: function (rateToAdd) {
      this.$emit('addRate', rateToAdd);
    }
  }
}
</script>

<style lang="scss" scoped>
.add-element-button {
  top: 5px;
}
.popover {
  --f7-popover-width: 750px;
}
:deep(.popover-inner) {
  max-height: 400px;
}
.small-icon {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .popover {
    --f7-popover-width: 470px;
  }
}

@media screen and (max-width: 480px) {
  .popover {
    --f7-popover-width: 280px;
  }
}
</style>
