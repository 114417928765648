<template>
  <f7-button
    :text="text"
    :active="isActive"
    raised
    round
    @click="select"
  />
</template>

<script>
  export default {
    name: "EdlEditObservationQualifier",
    props: {
      qualifier: {
        type: Object,
        default: null
      },
      isPlural: {
        type: Boolean,
        default: false
      },
      isFeminine: {
        type: Boolean,
        default: false
      },
      checkActive: {
        type: String,
        default: null
      }
    },
    emits: ['select', 'forcePlural'],
    computed: {
      text: function () {
        if(this.isPlural) {
          if(this.isFeminine) {
            return this.textPluralFeminine;
          } else {
            return this.textPluralMasculine;
          }
        } else {
          if(this.isFeminine) {
            return this.textSingularFeminine;
          } else {
            return this.textSingularMasculine;
          }
        }
      },
      textPluralFeminine: function () {
        if(this.qualifier.pluralFeminine !== undefined) {
          return this.qualifier.pluralFeminine;
        } else if (this.qualifier.pluralMasculine !== undefined) {
          return this.qualifier.pluralMasculine;
        } else {
          return this.textSingularFeminine;
        }
      },
      textSingularFeminine: function () {
        if (this.qualifier.feminine !== undefined) {
          return this.qualifier.feminine;
        } else {
          return this.qualifier.masculine;
        }
      },
      textPluralMasculine: function () {
        if (this.qualifier.pluralMasculine !== undefined) {
          return this.qualifier.pluralMasculine;
        } else {
          return this.qualifier.masculine;
        }
      },
      textSingularMasculine: function () {
        return this.qualifier.masculine;
      },
      isActive: function () {
        return this.checkActive === this.text;
      }
    },
    watch: {
      text: function (newText, oldText) {
        if(this.checkActive === oldText) {
          this.select();
        }
      }
    },
    methods: {
      select: function () {
        this.$emit('select', this.text);
        if(this.qualifier.forcePlural !== undefined && this.qualifier.forcePlural) {
          this.$emit('forcePlural');
        }
      }
    }
  }
</script>

<style scoped>

</style>
