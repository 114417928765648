import {f7} from "framework7-vue";
import _ from "lodash";
import {differenceInSeconds} from "date-fns";
import {mapMutations, mapState} from "vuex";

export default {
  mounted: function() {
    window.onerror = this.generalErrorsListener;
    window.addEventListener("unhandledrejection", this.generalErrorsListener, true);
  },
  computed: {
    ...mapState('errors', [
      'error',
      'recentErrors',
      'timingInterval'
    ]),
    recentErrorsCount: function () {
      return this.recentErrors.length;
    }
  },
  methods: {
    ...mapMutations('errors', [
      'setCurrentError',
      'addRecentError',
      'removeRecentErrorAtIndex',
      'setTimingInterval',
      'clearTimingInterval'
    ]),
    throwError: function (message, title = null, details = null, object = null) {
      if(!this.errorAlreadyThrownRecently(message, title)) {
        const error = {message, title, details, object, thrownAt: new Date()};
        this.addRecentError(error);
        this.setCurrentError(error);
      }
    },
    generalErrorsListener: function (event, ...[source, lineno, colno, error]) { //args = {source, lineno, colno, error}
      console.error('General error', {event, source, lineno, colno, error});
      if (_.isString(event) && source !== undefined && lineno !== undefined && colno !== undefined && error !== undefined) {
        this.throwError(event, "Erreur - "+error, "line "+lineno+":"+colno+" in "+source);
      } else if (event.message !== undefined) { // Chrome sometimes provides this
        this.throwError(event.message, "Erreur", event.lineno+" of file: "+event.filename);
      } else if(event.reason !== undefined) {
        if(event.reason.response?.status !== undefined && event.reason.response.status === 401) {
          if(event.reason.isLimited) { //Does not have correct access to resource, go to login (nothing to do here for that)
            this.throwError("Vous n'avez pas les droits pour consulter cette ressource, vérifiez l'adresse URL utilisée", "Accès refusé");
          } else { //Connection expired
            this.throwError("Votre connexion à DDH a expirée, vous allez être redirigés vers la page de connexion pour vous reconnecter", "Connexion perdue");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else if(event.reason.message === "Network Error") {
          if(this.isOnline !== undefined && this.isOnline === false) {
            this.throwError("Vous n'êtes plus connectés à Internet, certaines fonctionnalités ne seront plus possibles (vous pouvez tout de même continuer à réaliser vos EDL hors ligne)", "Attention");
          } else {
            this.throwError("Mauvaise connexion à Internet, certaines fonctionnalités risquent de mal fonctionner (vous pouvez tout de même continuer à réaliser vos EDL hors ligne)", "Attention");
          }
        }
        else {
          this.throwError("General error - Type "+event.type, "Erreur", event.reason.message !== undefined ? event.reason.message : JSON.stringify(event.reason), event);
        }
      } else {
        this.throwError("General error - Type "+event.type, "Erreur", "From element: "+ JSON.stringify(event.target), event);
      }
    },
    errorAlreadyThrownRecently: function (message, title) {
      return _.find(this.recentErrors, (recentError) => {
        return recentError.title === title && recentError.message === message;
      }) !== undefined;
    },
    handleErrorTiming: function (active) {
      if(active && this.timingInterval === null) {
        this.setTimingInterval(setInterval(() => {
          this.recentErrors.forEach((error, errorIndex) => {
            if(differenceInSeconds(new Date(), error.thrownAt) >= 2) {
              this.removeRecentErrorAtIndex(errorIndex);
            }
          });
        }, 500));
      } else if(!active && this.timingInterval !== null) {
        this.clearTimingInterval();
      }
    },
    showErrorDialog: _.debounce(function (error) {
      let showingDetails = false;
      let buttons = [
        {
          text: 'OK',
          bold: true,
          close: true
        }
      ];

      if(error.details) {
        buttons.push({
          text: 'Détails techniques',
          close: false,
          onClick: (dialog) => {
            if(!showingDetails && error.details) {
              dialog.setText(error.details);
              if(error.object) {
                console.error(error.object);
                console.log({...error.object});
              }
              showingDetails = true;
            } else if(!showingDetails && error.object) {
              dialog.setText(JSON.stringify(error.object));
              showingDetails = true;
            } else if(showingDetails) {
              dialog.setText(error.message);
              showingDetails = false;
            }
          }
        });
      }

      f7.dialog.create({
        title: error.title || "Erreur technique",
        text: error.message,
        buttons,
        verticalButtons: true,
      }).open();
    },100)
  },
  watch: {
    error: {
      handler: 'showErrorDialog',
      deep: true
    },
    recentErrorsCount: function (count, oldCount) {
      if(count !== oldCount) {
        if (count > 0) {
          this.handleErrorTiming(true);
        } else {
          this.handleErrorTiming(false);
        }
      }
    }
  }
}
