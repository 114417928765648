import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import _ from "lodash";
import errors from "@/components/mixins/errors";
import offline from "@/components/mixins/offline";

import { f7, f7ready } from "framework7-vue";
import publicFirewall from "@/components/mixins/publicFirewall";

export default {
  mixins: [errors, offline, publicFirewall],
  data: function () {
    return {
      loadingInterval: null,
      pagePreloaderRunning: false
    };
  },
  computed: {
    ...mapState('security', [
      'jwtToken'
    ]),
    ...mapState('me', [
      'me',
      'selectedProfile'
    ]),
    isAutoEdl: function () {
      return this.me?.isAutoEdl || false;
    },
    isAdmin: function () {
      return this.me?.admin || false;
    },
    isSuperAdmin: function () {
      return this.isAdmin && this.me?.groupId === null;
    },
    isConnectedToPlatform: function () {
      return this.me?.platformToken !== undefined && this.me?.platformToken !== null;
    },
    isAuthenticated: function () {
      return this.me !== undefined && this.me !== null;
    },
    canAutoEdl: function () {
      return this.me !== undefined && this.me.canAutoEdl;
    }
  },
  mounted: function () {
    f7ready(() => {
      //Show loader while f7Route is not available as it is needed for the firewall
      f7.preloader.show();

      //An interval is needed because having f7.view.main.router.currentRoute in a computed is not reactive
      this.loadingInterval = setInterval(() => {
        if(f7.view.main.router.currentRoute.route !== undefined) {
          if(!this.pagePreloaderRunning) {
            f7.preloader.hide();
          }
          clearInterval(this.loadingInterval);

          this.publicToken = _.has(f7.view.main.router.currentRoute.params, 'publicToken') ? f7.view.main.router.currentRoute.params.publicToken : null;

          //Handle firewall
          if (this.hasPublicToken && this.isPublicRoute) {
            //Is public, don't do anything
          } else if (!this.jwtToken) {
            this.goToLogin();
          } else if (!this.me && this.isOnline) { //First connexion
            this.fetchMe()
              .then(this.handleLoginSuccess)
              .catch(() => {
                this.refreshLogin()
                  .then(this.handleLoginSuccess)
                  .catch(this.handleLoginFailure);
              });
          } else if (this.me && this.isOnline) { //Switch user/Update user, non-blocking
            this.fetchMe()
              .then(this.handleLoginSuccess)
              .catch(() => {
                this.refreshLogin()
                  .then(this.handleLoginSuccess);
                //No catch, case is actually offline but not well handled by the mobile browser
              });
          } else if (this.isAutoEdl) { //Auto-EDL
            this.handleAutoEdlRouting();
          } else if (!this.me) { //Case offline + no user
            this.throwError("Vous devez être connecté pour poursuivre");
            this.goToLogin();
          }
        }
      }, 100);
    });
  },
  methods: {
    ...mapActions('security', [
      'resetJwtToken',
      'refreshLogin'
    ]),
    ...mapActions('me', [
      'fetchMe',
    ]),
    ...mapMutations('me', {
      'setMe': 'me'
    }),
    ...mapGetters('edlPersist', [
      'getAutoEdlById'
    ]),
    ...mapActions('edlPersist', [
      'createAutoEdlForId'
    ]),
    handleLoginSuccess: function () {
      if (this.hasPublicToken && this.isPublicRoute) {
        //don't do anything
      } else if (this.isAutoEdl) {
        this.handleAutoEdlRouting();
      } else if (!this.selectedProfile || !_.some(this.me.profiles, profile => profile.id === this.selectedProfile.id)) {
        this.goToProfileSelection();
      } else if (this.selectedProfile && this.selectedProfile.passcode !== null && f7.views.main.router.previousRoute.name === "login") {
        this.goToProfileSelection();
      } else if(!this.isAutoEdl && f7.views.main.router.currentRoute.name.includes('autoEdl') && !f7.views.main.router.currentRoute.path.includes('mon-compte')) {
        f7.view.main.router.navigate({name: 'edlsLocal'}, {reloadAll: true});
      }
    },
    handleLoginFailure: function () {
      this.resetJwtToken();
      this.goToLogin();
    },
    handleAutoEdlRouting: function () {
      let id = this.me.id;
      let autoEdl = this.getAutoEdlById()(id);

      if(autoEdl === undefined) {
        this.createAutoEdlForId(id);
        this.handleAutoEdlRouting();
      } else {
        if (autoEdl.locale === undefined || autoEdl.locale === null) {
          if (f7.views.main.router.currentRoute.name !== 'autoEdlLang') {
            f7.views.main.router.navigate({name: 'autoEdlLang'}, {reloadAll: true});
          }
        } else if (autoEdl.id === undefined || autoEdl.id === null) {
          if (f7.views.main.router.currentRoute.name !== 'autoEdlInfo') {
            f7.views.main.router.navigate({name: 'autoEdlInfo'}, {reloadAll: true});
          }
        } else if (autoEdl.done !== undefined && autoEdl.done === true) {
          if (f7.views.main.router.currentRoute.name !== 'autoEdlDone') {
            f7.views.main.router.navigate({name: 'autoEdlDone'}, {reloadAll: true});
          }
        } else if (autoEdl.signature !== undefined && autoEdl.signature === true) {
          if (f7.views.main.router.currentRoute.name !== 'autoEdlSignature') {
            f7.views.main.router.navigate({name: 'autoEdlSignature'}, {reloadAll: true});
          }
        } else if (autoEdl.recap !== undefined && autoEdl.recap === true) {
          if (f7.views.main.router.currentRoute.name !== 'autoEdlRecap') {
            f7.views.main.router.navigate({name: 'autoEdlRecap'}, {reloadAll: true});
          }
        } else {
          if (f7.views.main.router.currentRoute.name !== 'autoEdl') {
            f7.views.main.router.navigate({name: 'autoEdl'}, {reloadAll: true});
          }
        }
      }
    },
    goToLogin: function () {
      this.$nextTick().then(() => {
        f7.views.main.router.navigate({name: 'login'}, {reloadAll: true});
      });
    },
    goToProfileSelection: function () {
      this.$nextTick().then(() => {
        console.log('goToProfileSelection');
        f7.views.main.router.navigate({name: 'profileSelection'}, {reloadAll: true});
      });
    },
    limitToSuperAdmins: function () {
      if(!this.isSuperAdmin) {
        f7.views.main.router.navigate({name:'account'});
      }
    },
    limitToAdmins: function () {
      if(!this.isSuperAdmin && !this.isAdmin) {
        f7.views.main.router.navigate({name:'account'});
      }
    },
    limitToCanAutoEdls: function () {
      if(!this.isSuperAdmin && !this.canAutoEdl) {
        f7.views.main.router.navigate({name:'account'});
      }
    }
  }
}
