//TODO: replace with translations ?
import currency from "currency.js";
export default {
  methods: {
    getActionLabel: function (action) {
      switch (action) {
        case 'all':
          return "Toutes";
        case 'repair':
          return "Réparer";
        case 'replace':
          return "Remplacer";
        case 'clean':
          return "Nettoyer";
        default:
          return "";
      }
    },
    getTypeLabel: function (type) {
      switch (type) {
        case 'equipment':
          return "Équipement";
        case 'coating':
          return "Revêtement";
        default:
          return "";
      }
    },
    formatCurrency: function (value) {
      return currency(value, {fromCents: true, separator: " ", decimal: ",", symbol: ""}).format();
    },
    unformatCurrency: function (value) {
      return parseInt(Math.round(value.replace(" ", "").replace(",", ".") * 100));
    },
  }
}
