import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { translations } from './i18n';
import App from './components/App';
import './registerServiceWorker';
import store from './store';
import VueVisible from 'vue-visible';
import VueSignaturePad from 'vue-signature-pad';

import 'framework7-icons/css/framework7-icons.css';
import 'framework7/css/bundle';
import './css/ddh-theme.css';

// Import F7 Bundle
import Framework7 from 'framework7/lite-bundle';

// Import F7-Vue Plugin Bundle (with all F7 components registered)
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Init F7-Vue Plugin
Framework7.use(Framework7Vue);

// Internationalization/translation system
const locales = [
  'fr',
  'en'
];
const i18n = createI18n({
  locale: 'fr', // set default locale
  fallbackLocale: 'fr', // set fallback locale
  availableLocales: locales,
  messages: translations(locales),
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

// create Vue app
const app = createApp(App)
  .use(store)
  .use(VueVisible)
  .use(VueSignaturePad)
  .use(i18n)
;

// register all Framework7 Vue components by passing Vue app instance there
registerComponents(app);

app.mount('#app');
