<template>
  <div
    v-if="pagination && pagination.count > 1"
    class="pagination"
  >
    <div class="pagination-inner">
      <a
        class="first-page"
        :class="{disabled: currentPage === 1}"
        @click="changePage(1)"
      >
        <i class="f7-icons">chevron_left_2</i>
      </a>
      <a
        class="previous-page"
        :class="{disabled: currentPage === 1}"
        @click="changePage(currentPage - 1)"
      >
        <i class="f7-icons">chevron_left</i>
      </a>

      <a
        v-for="p in startPages"
        :key="'page'+p"
        class="page-numbers"
        :class="{'current': p === currentPage}"
        @click="changePage(p)"
      >{{ p }}</a>

      <span
        v-if="middlePages"
        class="page-numbers dots"
      >…</span>
      <a
        v-for="p in middlePages"
        :key="'page'+p"
        class="page-numbers"
        :class="{'current': p === currentPage}"
        @click="changePage(p)"
      >{{ p }}</a>

      <span
        v-if="endPages"
        class="page-numbers dots"
      >…</span>
      <a
        v-for="p in endPages"
        :key="'page'+p"
        class="page-numbers"
        :class="{'current': p === currentPage}"
        @click="changePage(p)"
      >{{ p }}</a>

      <a
        class="next-page"
        :class="{disabled: currentPage === pagination.count}"
        @click="changePage(currentPage + 1)"
      >
        <i class="f7-icons">chevron_right</i>
      </a>
      <a
        class="last-page"
        :class="{disabled: currentPage === pagination.count}"
        @click="changePage(pagination.count)"
      >
        <i class="f7-icons">chevron_right_2</i>
      </a>
    </div>
  </div>
</template>

<script>
  import {mapMutations, mapState} from "vuex";
  import pagination from "@/components/mixins/pagination";

  export default {
    name: "EdlListPagination",
    mixins: [pagination],
    computed: {
      ...mapState('edlTemp', {
        'currentPage': 'remoteEdlsPage',
        'pagination': 'remoteEdlsPagination'
      }),
    },
    methods: {
      ...mapMutations('edlTemp', {
        'changeCurrentPage': 'remoteEdlsPage'
      }),
    }
  }
</script>

<style scoped lang="scss">
  .pagination{
    position: absolute;
    right: 10px;
    margin-top: -5px;
    i {
      font-size: 10px;
    }
    .pagination-inner{
      text-align: center;
      font-size: 10px;
      display: flex;
      justify-content:center;
      .first-page,
      .last-page,
      .previous-page,
      .next-page,
      .page-numbers{
        color: var(--f7-theme-color-very-dark-grey);
        text-decoration: none;
        width: 25px;
        height: 25px;
        margin-right: 1px;
        border-radius: 2px;
        display: flex;
        justify-content:center;
        align-items:center;
        &:not(.current){
          cursor: pointer;
        }
        &:hover,
        &.current{
          color: var(--f7-theme-color-light-grey);
          background-color: var(--f7-theme-color);
        }
        &.dots:hover{
          color: var(--f7-theme-color-very-dark-grey);
          background-color: transparent;
          cursor: default;
        }
      }
      .first-page,
      .last-page,
      .previous-page,
      .next-page {
        background-color: var(--f7-theme-color-grey);
        &.disabled{
          color: var(--f7-theme-color-dark-grey);
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
</style>
