import _ from "lodash";

export default {
  methods: {
    hasContextGallery: function (entry, type, parent = null) {
      let entryContext = this.currentModule.id;
      if(type === 'entry') {
        entryContext += "." + entry.id;
      } else {
        if(!parent) {
          parent = this.currentEntry;
        }
        entryContext += "." + parent.id + "." + entry.id;
      }

      return _.some(this.contextGallery, (photo) => {
        return photo.context !== undefined && photo.context === entryContext;
      });
    },
  }
}
