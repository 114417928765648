import _ from "lodash";

//rateVariable = to stay coherent with legacy: used to set name of the variable in final edl json
//entryName = to stay coherent with legacy: used to filter select a single 'type' for subEntries

const cleaning = {
  'name': 'cleaning',
  'label': 'Ménage',
  'cantBeAdded': false,
  'value': ['Propre', 'Moyen', 'Calcaire', 'Poussière', 'Mal nettoyé', 'Sale']
};

const color = {
  'name': 'color',
  'label': 'Couleur',
  'cantBeAdded': false,
  'value': ['Blanc', 'Bleu', 'Gris', 'Jaune', 'Rouge', 'Vert', 'Rose', 'Orange', 'Noire', 'Mauve', 'Taupe', 'Violet']
};

const condition = {
  'name': 'condition',
  'label': 'Etat d\'usure',
  'cantBeAdded': false,
  'value': ['N.C', 'Neuf', 'Bon état', 'Etat usage', 'Etat moyen', 'Mauvais état', 'Dégradé', 'Hors service']
};

const state = {
  'name': 'state',
  'label': 'Fonctionnement',
  'cantBeAdded': false,
  'value': ['Fonctionne', 'Ne fonctionne pas', 'Dysfonctionnement', 'Non vérifiable', 'Etanche', 'Sous réserve']
};

const number = {
  'name': 'number',
  'label': 'Nombre',
  'cantBeAdded': false,
  'value': 'input'
};

const photo = {
  'name': 'photo',
  'label': 'Photo',
  'cantBeAdded': true,
  'value': 'input'
};

const serialNumber = {
  'name': 'serieNumber',
  'label': 'Numéro de série',
  'cantBeAdded': false,
  'value': 'input'
};

const kwPeak = {
  'name': 'observedKW',
  'label': 'Relevé heures pleines (KW)',
  'cantBeAdded': true,
  'value': 'input'
};

const kwOffPeak = {
  'name': 'observedKWHeureCreuse',
  'label': 'Relevé heures creuses (KW)',
  'cantBeAdded': true,
  'value': 'input'
};

const m3 = {
  'name': 'observedM3',
  'label': 'M3',
  'cantBeAdded': true,
  'value': 'input'
};

const typeCustom = {
  'name': 'typeCustom$',
  'rateVariable': 'type',
  'customName': true, //Name is customised replacing $ sign
  'label': 'Nature',
  'cantBeAdded': false,
  'value': [] //No values, they must be added by user
};

const typeSiphon = {
  'name': 'typeSiphon',
  'rateVariable': 'type',
  'entryName': 'Siphon',
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['PVC']
};

const typeSeal = {
  'name': 'typeJoint',
  'rateVariable': 'type',
  'entryName': ["Joint étanchéité", "Joint d'étanchéité", "Joints d'étanchéité"],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Silicone']
};

const typeWall1 = {
  'name': 'typeMur',
  'rateVariable': 'type',
  'entryName': ['Mur', 'Murs'],
  'subEntryName': ['Accès', 'Gauche', 'Face', 'Droite'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Faïence', 'Peinture', 'Lambris', 'Toile de verre', 'Papier peint', 'Tissu', 'PVC', 'Brique', 'Plâtre', 'Moquette murale', 'Crépi', 'Gouttelette', 'Tapisserie']
};

const typeWall2 = {
  'name': 'typeMur',
  'rateVariable': 'type',
  'entryName': ['Mur', 'Murs'],
  'subEntryName': ['Accès', 'Gauche', 'Face', 'Droite'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Béton', 'Peinture']
};

const typeFloor1 = {
  'name': 'typeSol',
  'rateVariable': 'type',
  'entryName': ['Sol', 'Sols'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Carrelage', 'Parquet', 'Parquet flottant', 'Plancher', 'Lino', 'Sol plastique', 'Moquette', 'Jonc de mer', 'Béton', 'Résine']
};

const typeFloor2 = {
  'name': 'typeSol',
  'rateVariable': 'type',
  'entryName': ['Sol', 'Sols'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Béton', 'Terre battue']
};

const typeCeiling1 = {
  'name': 'typePlafond',
  'rateVariable': 'type',
  'entryName': ['Plafond', 'Plafonds'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Parquet', 'Solives', 'Papier peint', 'Peinture', 'Lambris', 'Béton', 'Gouttelette', 'Tapisserie']
};

const typeCeiling2 = {
  'name': 'typePlafond',
  'rateVariable': 'type',
  'entryName': ['Plafond', 'Plafonds'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Béton', 'Peinture']
};

const typePlinths = {
  'name': 'typePlinthes',
  'rateVariable': 'type',
  'label': 'Nature',
  'entryName': ['Plinthe', 'Plinthes'],
  'cantBeAdded': true,
  'value': ['Carrelage', 'Bois', 'PVC', 'Inox', 'Plâtre']
};

const typeDoor1 = {
  'name': 'typePorte',
  'rateVariable': 'type',
  'entryName': ['Porte', 'Portes'],
  'subEntryName': ['Intérieur', 'Extérieur', 'Poignée', 'Barre de seuil'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Bois', 'Acier', 'Alu', 'PVC', 'Composite', 'Verre', 'Metal', 'Ceramique']
};

const typeDoor2 = {
  'name': 'typePorte',
  'rateVariable': 'type',
  'entryName': ['Porte', 'Portes'],
  'subEntryName': ['Intérieur', 'Extérieur', 'Poignée', 'Barre de seuil'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Acier', 'PVC', 'Alu', 'Bois']
};

const typeWindowFrame = {
  'name': 'typeEncadrement',
  'rateVariable': 'type',
  'entryName': ['Encadrement', 'Encadrements'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Bois', 'Alu', 'PVC']
};

const typeWindowPane = {
  'name': 'typeVitre',
  'rateVariable': 'type',
  'entryName': ['Vitre', 'Vitres'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Simple vitrage', 'Double vitrage']
};

const typeWindowShutters = {
  'name': 'typeVolet',
  'rateVariable': 'type',
  'entryName': ['Volet', 'Volets'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Bois', 'Alu', 'PVC', 'Composite', 'Métal']
};

const typeSink = {
  'name': 'typeBac',
  'rateVariable': 'type',
  'entryName': ['Bac', 'Vasque'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Inox', 'Céramique', 'Résine', 'Pierre', 'Béton ciré', 'Verre']
};

const typeDrain = {
  'name': 'typeBonde',
  'rateVariable': 'type',
  'entryName': ['Bonde', 'Bondes'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Inox', 'Résine', 'Laiton', 'Grès', 'Chrome', 'Cuivre']
};

const typeFaucet = {
  'name': 'typeRobinet',
  'rateVariable': 'type',
  'entryName': ['Robinet', 'Robinets'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Mitigeur', 'Mélangeur', 'Douchette', 'Mitigeur et douchette', 'Mélangeur et douchette']
};

const typeSinkCabinet = {
  'name': 'typeMeubleSSEvier',
  'rateVariable': 'type',
  'entryName': ['Meuble sous évier', 'Meubles sous évier'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Bois', 'Alu', 'PVC']
};

const typeKitchenUpperElement = {
  'name': 'typeElementHaut',
  'rateVariable': 'type',
  'entryName': ['Elément haut', "Eléments hauts", "Élément haut", "Éléments hauts"],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Bois']
};

const typeKitchenSplashback = {
  'name': 'typeCredence',
  'rateVariable': 'type',
  'entryName': ['Credence', 'Crédence'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Marbre', 'Céramique', 'Résine', 'Pierre', 'Béton ciré', 'Verre']
};

const typeBathPannel = {
  'name': 'typeTablier',
  'rateVariable': 'type',
  'entryName': 'Tablier de baignoire',
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Faïence', 'Bois', 'PVC', 'Plâtre']
};

const typeBathroomSink = {
  'name': 'typeEvier',
  'rateVariable': 'type',
  'entryName': ['Evier', 'Évier', 'Eviers', 'Éviers'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Marbre', 'Céramique', 'Résine', 'Pierre', 'Béton ciré', 'Verre']
};

const typeRadiator = {
  'name': 'typeRadiateur',
  'rateVariable': 'type',
  'entryName': ['Radiateur', 'Radiateurs'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Électrique', 'Eau chaude vanne simple', 'Eau chaude vanne thermostatique', 'Gaz', 'Bloc Clim Reversible']
};

// 26/04/2023: Replaced by new values for typeRadiator, as it is not possible to have multiple types for a same entry rate.
// const typeVanne = {
//   'name': 'typeVanne',
//   'rateVariable': 'typeVanne',
//   'entryName': ['Radiateur', 'Radiateurs'],
//   'label': 'Nature',
//   'cantBeAdded': true,
//   'value': ['Thermostatique', 'Simple']
// };

const typeVentilation = {
  'name': 'typeVentilation',
  'rateVariable': 'type',
  'entryName': ['Ventilation', 'Ventilations'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['VMC', 'Grille PVC', 'Grille métal']
};

const typeToiletTank = {
  'name': 'typeCube', //legacy error
  'rateVariable': 'type',
  'entryName': ["Cuve/Réservoir", "Chasse d eau", "Chasse d'eau", "Cube/Réservoir", "Cuve/Reservoir", "Cuve / Réservoir"],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Céramique', 'Porcelaine', 'Inox', 'Verre']
};

const typeToiletLid = {
  'name': 'typeAbattant',
  'rateVariable': 'type',
  'entryName': ['Abattant', 'Abattants'],
  'label': 'Nature',
  'cantBeAdded': true,
  'value': ['Bois', 'PVC']
};

const hotwaterEquipment = {
  'name': 'equipementEnergie1',
  'rateVariable': 'equipment',
  'entryName': ['Production eau chaude', "Production d'eau chaude"],
  'label': 'Équipement',
  'cantBeAdded': true,
  'value': ['Chaudière individuelle au gaz', 'Cumulus électrique', 'Chauffe-eau électrique', 'Chauffage collectif', 'Chauffage au sol']
};

const heatingEquipment = {
  'name': 'equipementEnergie2',
  'rateVariable': 'equipment',
  'entryName': ['Chauffage', 'Chauffages'],
  'label': 'Équipement',
  'cantBeAdded': true,
  'value': ['Radiateur gaz', 'Convecteur électrique', 'Chauffage au sol']
};

const typeElectricMeterReading = {
  'name': 'typeReleveCompteurElectrique',
  'rateVariable': 'typeReleve',
  'entryName': ['Compteur électrique', 'Compteur electrique', 'Compteurs électriques'],
  'label': 'Type de relevé',
  'cantBeAdded': true,
  'value': ['Electrique']
};

const typeHotwaterMeterReading = {
  'name': 'typeReleveCompteurEauGaz',
  'rateVariable': 'typeReleve',
  'entryName': ['Compteur eau et/ou gaz', 'Compteur eau', 'Compteur gaz'],
  'label': 'Type de relevé',
  'cantBeAdded': true,
  'value': ['Eau froide', 'Eau chaude', 'Gaz']
};

const rates = {
  cleaning,
  color,
  condition,
  state,
  number,
  photo,
  serialNumber,
  kwPeak,
  kwOffPeak,
  m3,
  typeCustom,
  typeSiphon,
  typeSeal,
  typeWall1,
  typeWall2,
  typeFloor1,
  typeFloor2,
  typeCeiling1,
  typeCeiling2,
  typePlinths,
  typeDoor1,
  typeDoor2,
  typeWindowFrame,
  typeWindowPane,
  typeWindowShutters,
  typeSink,
  typeDrain,
  typeFaucet,
  typeSinkCabinet,
  typeKitchenUpperElement,
  typeKitchenSplashback,
  typeBathPannel,
  typeBathroomSink,
  typeRadiator,
  typeVentilation,
  typeToiletTank,
  typeToiletLid,
  hotwaterEquipment,
  heatingEquipment,
  typeElectricMeterReading,
  typeHotwaterMeterReading,
};

export const all = Object.values(rates);

export const addableRates = all
  .filter(rate => rate.cantBeAdded === undefined || rate.cantBeAdded === false)
  .sort((a,b) => a.label.localeCompare(b.label));

export const getConfig = (name) => {
  return _.find(rates, (rate) => rate.name === name || rate.rateVariable === name);
};

export const typeRateVariables = ['type', 'typeReleve', 'equipment'];

export const getTypeRateForEntry = (entryName, subEntryName = null) => {
  return _.find(rates, (rate) => {
    if(rate.rateVariable === undefined || !typeRateVariables.includes(rate.rateVariable)) {
      //Only look for rate types
      return false;
    } else if(subEntryName && _.has(rate, 'entryName') && _.has(rate, 'subEntryName')) {
      //Prioritise if both subentry and entry (parent) match names
      return rate.entryName.includes(entryName) && rate.subEntryName.includes(subEntryName);
    } else if(!subEntryName && _.has(rate, 'entryName')) {
      //Case no subentry, only match entry name
      return rate.entryName.includes(entryName);
    } else if(subEntryName && _.has(rate, 'entryName')) {
      //Case subentry, but no subentry names were defined on the rate, match subentry to entry names
      return rate.entryName.includes(subEntryName);
    } else {
      //Not found
      return false;
    }
  });
};

export default rates;
